import { useHistory } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as Image } from 'assets/img/notFoundPage.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 336,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    border: `1px dashed ${theme.palette.common.secondaryDark}`,
    borderRadius: 12,
  },
}));

const EmptyDataProduct = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToDataProductsPage = () => {
    history.push(AppRoutes.DataProduct.path);
  };

  return (
    <div className={classes.container}>
      <Image />
      <Typography variant={TYPOGRAPHY_VARIANT.h4}>Oops!</Typography>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
        Data Product not found
      </Typography>
      <Button
        text='Data product page'
        onClick={navigateToDataProductsPage}
        fullWidth={false}
      />
    </div>
  );
};

export { EmptyDataProduct };
