import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { formatSearchValue, getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const LIMIT = 50;

const useCostsSourceTables = ({
  dataset,
  table,
  sourceTable,
  sourceDataset,
  searchValue,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  powerbiReportName,
  powerbiWorkspaceName,
  pipelineType,
  pipelineSubtypes,
  project,
  page,
  dataProductUUID,
  searchDataProductName,
  jobHash,
  isAlternativePlanSelected = false,
  enabled = true,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidJobHash = getIsValidUUID(jobHash);
  const isValidDataProductUUID = getIsValidUUID(dataProductUUID);

  const { isFetching: isFetchingSources, data } = useQuery(
    [
      QUERY_TYPES.jobSourcesCost,
      dataset,
      table,
      startDateUtc,
      endDateUtc,
      sourceTable,
      sourceDataset,
      searchValue,
      principalEmail,
      searchPrincipalEmail,
      jobTagNames,
      powerbiReportName,
      powerbiWorkspaceName,
      pipelineType,
      pipelineSubtypes,
      project,
      page,
      dataProductUUID,
      searchDataProductName,
      isAlternativePlanSelected,
    ],
    ({ queryKey }) => {
      const [url, dataset, table, start, end] = queryKey;
      const { dataset: newSearchSourceDataset, value: newSearchSourceValue } =
        formatSearchValue(searchValue);
      const params = new URLSearchParams();

      params.append('dataset', dataset);
      params.append('table', table);
      params.append('from', start);
      params.append('to', end);
      params.append('sourceTable', sourceTable || '');
      params.append(
        'sourceDataset',
        newSearchSourceDataset || sourceDataset || ''
      );
      params.append('sourceSearch', newSearchSourceValue || searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      powerbiReportName?.length > 0 &&
        params.append('powerbiReportName', powerbiReportName);
      powerbiWorkspaceName?.length > 0 &&
        params.append('powerbiWorkspaceName', powerbiWorkspaceName);
      params.append('pipelineType', pipelineType);
      params.append('onlyAltPlanSavings', isAlternativePlanSelected);
      params.append('page', page + 1);
      params.append('limit', LIMIT.toString());

      isValidDataProductUUID &&
        params.append('dataProductUuid', dataProductUUID);
      searchDataProductName?.length &&
        params.append('searchDataProductName', searchDataProductName);

      project && params.append('project', project);
      isValidJobHash && params.append('jobHash', jobHash);

      pipelineSubtypes?.forEach((pipelineSubtype) =>
        params.append('pipelineSubtypes', pipelineSubtype)
      );
      jobTagNames?.forEach((tag) => params.append('jobTagNames', tag));

      return fetcherGet(url, params);
    },
    {
      enabled:
        Boolean(table && dataset) && enabled && pipelineType !== undefined,
    }
  );

  const sources = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const sourcesCount = useMemo(() => {
    return data?.pagination?.total || 0;
  }, [data?.pagination?.total]);

  return { sources, isFetchingSources, sourcesCount };
};

export { useCostsSourceTables };
