import React from 'react';
import { useParams } from 'hooks/hooks.js';
import { useStyles } from './SLAWidget.styles.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { ComingSoon, CONTAINER_SIZE } from '../../components.js';
import { ReactComponent as ClockIcon } from 'assets/img/icons/clock-01.svg';

const MOCK_UUID = 'a18c9626-430a-44d4-b674-250223012e64';

const SLAWidget = () => {
  const classes = useStyles();
  const { uuid } = useParams();

  const visibleTableWithMockData = uuid === MOCK_UUID;

  return (
    <Widget
      label='Service Level Agreement'
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      {visibleTableWithMockData ? (
        <div className={classes.content}>
          <div className={classes.iconContainer}>
            <ClockIcon />
          </div>

          <div>
            <Typography variant={TYPOGRAPHY_VARIANT.h2}>99.1%</Typography>
            <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
              SLA achieved
            </Typography>
          </div>
        </div>
      ) : (
        <ComingSoon containerHeight={CONTAINER_SIZE.SMALL} />
      )}
    </Widget>
  );
};

export { SLAWidget };
