const QUERY_TYPES = {
  volume: '/api/v1/volume',
  tableAnalysisParams: '/api/v1/tables/analysis-params',
  volumeThreshold: '/api/v1/volume/threshold',
  metrics: '/api/v1/metrics',
  freshness: '/api/v1/freshness',
  tables: '/api/v1/tables',
  tablesCount: '/api/v1/tables/count',
  tablesAlerts: '/api/v1/tables/alerts',
  tablesIsAnomaly: '/api/v1/tables/is-anomaly',

  incidents: '/api/v1/incidents',
  incidentGroups: '/api/v1/incident-groups',
  incidentStatuses: '/api/v1/incidents/statuses',
  incidentGroupStatuses: '/api/v1/incidents/statuses-group',

  lookerIncidentGroups: '/api/v1/incident-groups-looker',

  nodes: '/api/v1/lineage/nodes',
  lineagePipelines: '/api/v1/lineage/pipelines',
  lineagePipelineNodes: '/api/v1/lineage/pipeline-nodes',

  tableMetaInfo: '/api/v1/tables/meta-info',
  tableInfo: '/api/v1/tables/info',
  dashboard: '/api/v1/dashboard/project',
  lastSeenPages: '/api/v1/last-tables',
  projectStatus: '/api/v1/project-status',
  tableDependencies: '/api/v1/table/dependencies',
  uniqueColumns: '/api/v1/unique-columns',
  tableColumns: '/api/v1/table-columns',
  datasets: '/api/v1/datasets',
  projects: '/openApi/v1/projects',
  users: '/api/v1/users',
  usersInvite: '/api/v1/users/invite',
  usersDelete: '/api/v1/users/delete',
  tableFrequency: '/api/v1/datasets/frequency',
  lookerDictionary: '/api/v1/project/looker/reports',
  lookerTableReport: '/api/v1/looker/table/report',
  reportDependencies: '/api/v1/looker/reports',
  dashboardElements: '/api/v1/looker/dashboards',
  uniqueDashboards: '/api/v1/looker/dashboards/unique',
  uniqueLooks: '/api/v1/looker/looks/unique',
  uniqueDashboardElements: '/api/v1/looker/dashboard-elements/unique',
  looker: '/api/v1/looker',
  lookerAlert: '/api/v1/looker/alert',
  projectTreeDatasets: '/api/v1/project-tree/datasets',
  projectTreeTables: '/api/v1/project-tree/tables',

  slack: '/api/v1/slack',
  slackChannelList: '/api/v1/slack/channel/list',
  slackSettings: '/api/v1/slack/settings',

  pagerDuty: '/api/v1/pagerduty',
  pagerDutySettings: '/api/v1/pagerduty/settings',
  pagerDutyConnect: '/api/v1/connect-pagerduty',
  pagerDutyDisconnect: '/api/v1/cancel-pagerduty',

  googleChat: '/api/v1/google-chat',
  googleChatConnect: '/api/v1/connect-google-chat',

  jira: '/api/v1/jira',
  jiraProjects: '/api/v1/jira/projects',
  jiraIssueTypes: '/api/v1/jira/project-issue-types',
  jiraAccounts: '/api/v1/jira/project-accounts',
  jiraFields: '/api/v1/jira/project-fields',
  jiraIssue: '/api/v1/jira/issue',

  jobProjectCost: '/api/v1/job/costs/project',
  jobDestinationsCost: '/api/v1/job/costs/destination',
  jobSourcesCost: '/api/v1/job/costs/sources',
  jobQuery: '/api/v1/job/query',
  jobPipelineSubtypes: '/api/v1/job/costs/pipeline-subtypes',
  deadEndTablesStats: '/api/v1/job/costs/dead-end',
  costsDailyStats: '/api/v1/job/costs/project/daily',
  pipelineTableStats: '/api/v1/job/table/stats',
  pipelineTags: '/api/v1/job/pipelines/tags',
  altPaymentPlan: '/api/v1/job/costs/alt-plan-savings',

  jobCostsBilling: '/api/v1/job/costs/billing',

  pipelineDestinationTables: '/api/v1/job/pipelines/destination',
  pipelineSourceTables: '/api/v1/job/pipelines/sources',
  jobProjectPipeline: '/api/v1/job/pipelines/project',
  jobProjectPrincipalEmails: '/api/v1/job/principal-emails',
  jobProjectTagName: '/api/v1/job/tags',
  pipelineLastJobs: '/api/v1/job/pipelines/last-jobs',
  jobVolume: '/api/v1/job/volume',
  jobFreshness: '/api/v1/job/freshness',
  jobFrequency: '/api/v1/job/pipelines/frequency',
  jobsCount: '/api/v1/job/pipelines/jobs-count',

  /** Data Quality */
  qualityCheckMetrics: '/api/v1/metrics/data',
  dataQualityScans: '/api/v1/data-quality/scans',
  dataQualityScanMetrics: '/api/v1/data-quality/scans/metrics',

  dataPlex: '/api/v1/data-plex',
  dataPlexLocations: '/api/v1/data-plex/locations',
  dataForm: '/api/v1/data-form',

  tableLabels: '/api/v1/table/labels',
  projectLabels: '/api/v1/project/labels',

  powerBIReports: '/api/v1/powerbi/reports',
  powerBIWorkspaces: '/api/v1/powerbi/workspaces',

  bqBillingData: '/api/v1/bq-billing',
  banners: '/api/v1/banners',

  tenants: '/admin/tenants',
  amplitudeEvent: '/openApi/v1/amplitude',
  errorBoundaryEvent: '/api/v1/ui/error',

  routineDetails: '/api/v1/routine',

  storageStats: '/api/v1/storage/costs/project',
  storageDatasetsList: '/api/v1/storage/costs/list',

  dataProduct: '/api/v1/data-product',
  dataProductList: '/api/v1/data-product/list',
  domain: '/api/v1/data-domain',
  domainList: '/api/v1/data-domain/list',
  executedJobs: '/api/v1/data-product/usage/executed-jobs',
  executedJobsByTime: '/api/v1/data-product/usage/executed-jobs-by-date',
  subscribers: '/api/v1/data-product/usage/subscribers',
};

export { QUERY_TYPES };
