import { Table, TableBody, TableContainer } from '@mui/material';
import { TableLoader } from 'Components/components.js';
import { TableHead } from './TableHead.js';
import { TableRow } from './TableRow.js';
import { useDataProductsPageContext } from 'context/context.js';

const DataProductsTable = () => {
  const { dataProductList, isFetchingDataProductList } =
    useDataProductsPageContext();

  return (
    <TableContainer>
      <Table>
        <TableHead />

        <TableBody>
          {isFetchingDataProductList ? (
            <TableLoader />
          ) : (
            dataProductList.map((dataProduct) => (
              <TableRow dataProduct={dataProduct} key={dataProduct.uuid} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DataProductsTable };
