const bytesToSize = (bytes = 0, useBinaryUnits = true, decimals = 2) => {
  if (bytes === null || bytes === undefined) {
    return <>&mdash;</>;
  }

  const base = useBinaryUnits ? 1024 : 1000;
  const units = useBinaryUnits
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(base));
  if (bytes === 0) {
    return '0';
  }
  const value = bytes / Math.pow(base, i);
  const formattedValue = value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  });
  return `${formattedValue} ${units[i]}`;
};

export { bytesToSize };
