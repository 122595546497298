import moment from 'moment/moment';
import { makeStyles } from '@mui/styles';
import {
  StyledTableCell,
  StyledTableRow,
  Typography,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import React from 'react';
import {
  Principal,
  Project,
  Type,
  LastActive,
} from './libs/table-head.enum.js';
import { DATE_FORMAT, SUBSCRIBER_TYPES } from 'constants/constants.js';

const MAX_EMAIL_CELL_WIDTH = 420;
const MAX_PROJECT_CELL_WIDTH = 200;

const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    '&.MuiTableRow-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  letterBlock: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    fontWeight: 600,
    borderRadius: 2,
  },
}));

const getLabelByType = (type) => {
  switch (type) {
    case SUBSCRIBER_TYPES.subscriber:
      return 'Subscriber';
    case SUBSCRIBER_TYPES.direct:
      return 'Direct';
    default:
      return '';
  }
};

const TableRow = ({ subscriber }) => {
  const tableClasses = useTableStyles();
  const { principal, project, type, lastActivity, color } = subscriber;

  const tableCellData = [
    {
      id: Principal.id,
      label: (
        <div className={tableClasses.flexContainer}>
          <div
            className={tableClasses.letterBlock}
            key={subscriber}
            style={{ backgroundColor: color }}
          >
            {principal[0].toUpperCase()}
          </div>
          <Typography
            variant={TYPOGRAPHY_VARIANT.h6}
            noWrap={true}
            tooltipText={principal}
          >
            {principal}
          </Typography>
        </div>
      ),
      align: Principal.align,
      maxWidth: MAX_EMAIL_CELL_WIDTH,
    },
    {
      id: Project.id,
      label: (
        <Typography noWrap={true} tooltipText={project}>
          {project}
        </Typography>
      ),
      align: Project.align,
      maxWidth: MAX_PROJECT_CELL_WIDTH,
    },
    {
      id: Type.id,
      label: getLabelByType(type),
      align: Type.align,
    },
    {
      id: LastActive.id,
      label: lastActivity
        ? moment(lastActivity).utc().format(DATE_FORMAT.monthYear)
        : null,
      align: LastActive.align,
    },
  ];

  return (
    <StyledTableRow className={tableClasses.tableRow}>
      {tableCellData.map((item) => (
        <StyledTableCell
          key={item.id}
          align={item.align}
          sx={{ maxWidth: item?.maxWidth }}
        >
          {item.label || <EmptyCell />}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

const EmptyCell = () => {
  return <span className='txt-grey-13-500'>&mdash;</span>;
};

export { TableRow };
