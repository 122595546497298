import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import { createFilter } from 'react-select';
import Select, { components } from 'react-windowed-select';
import { Image } from 'react-bootstrap';
import OptimizedOption from 'Components/Search/OptimizedOption.js';
import {
  createNewOption,
  getCustomStyles,
} from 'Components/Search/libs/helpers/helpers.js';
import Search from 'assets/img/search.svg';

const BASE_INPUT_HEIGHT = 46;

const MultiSelect = ({
  optionLabel = 'Search for:',
  values,
  onSelect,
  defaultSelectValues,
  isLoading = false,
  placeholderName = 'Start typing name...',
  getLabelFromResponseItem = null,
  getValueFromResponseItem = null,
  searchKey = null,
  label = '',
  inputHeight = BASE_INPUT_HEIGHT,
}) => {
  const selectRef = useRef();
  const customStyles = useMemo(
    () => getCustomStyles(optionLabel, false, true, inputHeight),
    [optionLabel, inputHeight]
  );

  const defaultOptions = useMemo(() => {
    return [
      ...defaultSelectValues.map((value) =>
        createNewOption(
          value,
          getLabelFromResponseItem,
          getValueFromResponseItem
        )
      ),
    ];
  }, [defaultSelectValues]);

  const options = useMemo(() => {
    return [
      ...values.map((value) =>
        createNewOption(
          value,
          getLabelFromResponseItem,
          getValueFromResponseItem
        )
      ),
    ];
  }, [values]);

  const handleChange = (items) => {
    onSelect(items);
  };

  if (!options.length) {
    return null;
  }

  return (
    <>
      {label.length > 0 && (
        <label htmlFor={searchKey} className='input-label'>
          {label} <span className='label-asterisk'>*</span>
        </label>
      )}
      <Select
        ref={selectRef}
        id={searchKey}
        key={searchKey}
        defaultValue={defaultOptions}
        isClearable
        isMulti
        isLoading={isLoading}
        styles={customStyles}
        components={{
          Option: OptimizedOption,
          DropdownIndicator: components.DropdownIndicator,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        placeholder={
          <div>
            <Image src={Search} width={20} height={20} />
            <span className='ms-2 txt-grey-13-500'>{placeholderName}</span>
          </div>
        }
        options={options}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
    </>
  );
};

export { MultiSelect };
