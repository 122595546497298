import React from 'react';
import {
  useCallback,
  useEffect,
  useState,
  useSyncTableToSearchParam,
  useDispatch,
  useSelector,
  useMemo,
} from 'hooks/hooks.js';
import { useStyles } from '../../Dictionary.styles.js';
import { useProjectTree } from 'context/context.js';
import {
  AMPL_PAGE_EVENT,
  DATA_STATUS,
  ORDER_TYPES,
  DictionaryTabs,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import { SourcesButton, GeneralSearch } from 'Components/components.js';
import ColumnTable from 'Components/Columns/ColumnTable.js';
import {
  headColumnName,
  headDataset,
  headDescription,
  headType,
} from 'Components/Columns/HeadCellsEnum.js';
import { getColumns, getUniqueColumns } from 'slices/actions.js';

const headCells = [headType, headColumnName, headDescription, headDataset];

const DictionaryColumn = ({
  page,
  rowsPerPage,
  handleChangePage,
  onChangeBackdrop,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(headColumnName.id);
  const {
    selectTable,
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
  } = useProjectTree();
  const { tableFromURL, clearSearchParams } = useSyncTableToSearchParam(
    selectTable?.table,
    selectTable?.dataset,
    null,
    DictionaryTabs.COLUMNS
  );
  const [options, setOptions] = useState([]);
  const [column, setColumn] = useState('');
  const [isColumnEqual, setIsColumnEqual] = useState(false);

  const { columns, uniqueColumns, columnsStatus, uniqueColumnsStatus } =
    useSelector((state) => ({
      columns: state.columns.columns,
      uniqueColumns: state.columns.uniqueColumns,
      columnsStatus: state.columns.columnsStatus,
      uniqueColumnsStatus: state.columns.uniqueColumnsStatus,
    }));

  const isLoadingColumns = useMemo(() => {
    return columnsStatus === DATA_STATUS.pending;
  }, [columnsStatus]);

  const isLoadingUniqueColumns = useMemo(() => {
    return uniqueColumnsStatus === DATA_STATUS.pending;
  }, [uniqueColumnsStatus]);

  useEffect(() => {
    if (uniqueColumnsStatus === DATA_STATUS.idle) {
      dispatch(getUniqueColumns({}));
    }
  }, [dispatch, uniqueColumnsStatus]);

  useEffect(() => {
    onChangeBackdrop(isLoadingColumns);
  }, [isLoadingColumns, onChangeBackdrop]);

  useEffect(() => {
    dispatch(
      getColumns({
        dataset: selectTable?.dataset || '',
        table: selectTable?.table || '',
        column,
        isColumnEqual,
        sortBy,
        order,
        page: page + 1,
        limit: rowsPerPage,
      })
    );
  }, [
    column,
    isColumnEqual,
    sortBy,
    order,
    selectTable,
    page,
    rowsPerPage,
    dispatch,
  ]);

  const handleColumnSearch = (value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(false);
  };
  const handleColumnSelect = (value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(!!value);
  };

  useEffect(() => {
    if (uniqueColumns.length) {
      setOptions(
        uniqueColumns.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueColumns]);

  useEffect(() => {
    if (!selectTable) {
      clearSearchParams();
    }
  }, [selectTable]);

  useEffect(() => {
    if (column.length) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> Tab Column -> selected column -> ${column}`
      );
    }
  }, [column]);

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === ORDER_TYPES.asc;
    handleChangePage({}, 0);
    setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
    setSortBy(property);
  };

  const onTableChange = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSelectTable(value);
    },
    [handleChangePage, setSelectTable]
  );

  useEffect(() => {
    if (onTableChange !== undefined) {
      setOnSelectTable({ onChange: onTableChange });
    }
  }, [onTableChange, setOnSelectTable]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (tableFromURL === null) {
      handleChangePage({}, 0);
      setSelectTable(null);
      setSelectDataset(null);
    }
  }, [handleChangePage, setSelectDataset, setSelectTable, tableFromURL]);

  return (
    <>
      <div className={classes.searchContainer}>
        <SourcesButton onClick={() => setIsOpen((prev) => !prev)} />
        <GeneralSearch
          optionLabel='Dictionary for:'
          options={options}
          onSearch={handleColumnSearch}
          onSelect={handleColumnSelect}
          isLoading={isLoadingUniqueColumns}
          className='tableSearch'
          placeholderName='Start typing column name...'
        />
      </div>

      <div>
        <ColumnTable
          tableColumns={columns}
          headCells={headCells}
          handleRequestSort={handleRequestSort}
          hasPadding={false}
        />
      </div>
    </>
  );
};

export { DictionaryColumn };
