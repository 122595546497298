import React from 'react';
import { useEffect, useState, useHistory } from 'hooks/hooks.js';
import { useStyles } from './DataProductCreator.styles.js';
import { BUTTON_VARIANT, Button, Toolbar } from 'Components/components.js';
import {
  CreateDataProductForm,
  DataProductCreatorHeader,
} from './components/components.js';
import {
  DataProductsPageContextProvider,
  useDataProductsPageContext,
} from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';

const DataProductCreatorContainer = () => {
  return (
    <DataProductsPageContextProvider>
      <DataProductCreator />
    </DataProductsPageContextProvider>
  );
};

const DataProductCreator = () => {
  const classes = useStyles();
  const history = useHistory();
  const { toolbarText, handleChangeToolbarText } = useDataProductsPageContext();

  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dataProduct;
  }, []);

  const handleChangeIsDisabledSaveButton = (value) => {
    setIsDisabledSaveButton(value);
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const saveDataProduct = () => {
    if (formData) {
      formData.save();
    }
  };

  const navigateToDataProductPage = () => {
    history.push(AppRoutes.DataProduct.path);
  };

  return (
    <section>
      <DataProductCreatorHeader />

      <div className={classes.mainSection}>
        <div className={classes.formContainer}>
          <CreateDataProductForm
            onSaveButtonDisabledChange={handleChangeIsDisabledSaveButton}
            onSaveDataProduct={handleFormData}
            onChangeActionText={handleChangeToolbarText}
          />
        </div>
      </div>

      <div>
        <Button
          text='Create'
          fullWidth={false}
          className='me-3 px-5'
          onClick={saveDataProduct}
          isDisabled={isDisabledSaveButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={navigateToDataProductPage}
        />
      </div>

      <Toolbar actionText={toolbarText} />
    </section>
  );
};

export default DataProductCreatorContainer;
