import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useStyles } from 'Components/Widget/Widget.styles.js';
import { useQueryParamsContext } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const StatsCard = ({ statsCard }) => {
  const classes = useStyles();
  const { count, iconPath, title, pipelineType } = statsCard;
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const selectedPipelineType = queryParams.get(SEARCH_PARAMS.PIPELINE_TYPE);
  const isSelectedCard = pipelineType.toString() === selectedPipelineType;

  const handleClickLink = (event) => {
    event.preventDefault();
    updateQueryParams({
      [SEARCH_PARAMS.PIPELINE_TYPE]: isSelectedCard ? '' : pipelineType,
    });
  };

  return (
    <Link to={'#'} onClick={(event) => handleClickLink(event)}>
      <Card border='white' style={{ borderRadius: 12 }}>
        <Card.Body className={clsx(classes.card, isSelectedCard && 'selected')}>
          <Row className='p-4'>
            <Col>
              <span
                className={clsx(
                  !count ? 'txt-lightGrey-24-700' : 'txt-mainDark-24-700'
                )}
              >
                {numberFormat(count)}
              </span>
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                {iconPath.length > 0 && <Image src={iconPath} height={16} />}
                <div className='txt-mainDark-13-500 my-1'>{title}</div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export { StatsCard };
