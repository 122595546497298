import React from 'react';

const ProjectChangeConfirmation = (projectName = '') => {
  return (
    <div className='text-center mt-4'>
      You are about to open resource from a different project
      <br />
      <span className='u-accent'>{projectName}</span>. Continue?
    </div>
  );
};

export { ProjectChangeConfirmation };
