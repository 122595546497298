import React from 'react';
import {
  useEffect,
  useState,
  useQuery,
  useExpandContent,
  useDispatch,
  useCallback,
  useGetIsAnomalyTable,
  useHistory,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import moment from 'moment/moment';
import { Checkbox } from '@mui/material';
import {
  DICTIONARY_TABLE_DOWNSTREAM,
  DICTIONARY_TABLE_UPSTREAM,
} from 'utils/constants.js';
import {
  QUERY_TYPES,
  DATE_FORMAT,
  MAX_ITEM_IN_CELL,
  DATASET_DEPENDENCIES,
  SEARCH_PARAMS,
  AMPL_PAGE_EVENT,
  DictionaryTabs,
} from 'constants/constants.js';
import { TABLE_NAME_WIDTH } from 'Pages/Dictionary/Dictionary.styles.js';
import {
  EditCell,
  StyledTableCell,
  StyledTableRow,
  Chip,
  OverflownText,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import {
  getFullTableName,
  bytesToSize,
  getAlertLabel,
  getAlertColorType,
  getReferencedType,
} from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { updateTableDesc } from 'slices/actions.js';
import {
  DictionaryTableDependingTable,
  DictionaryTableDependingSourceUri,
} from './components.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';

const DictionaryTableDetails = ({
  isItemSelected,
  tableInfo,
  handleClick,
  selectedStreamType,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { queryParams } = useQueryParamsContext();
  const [showUpstream, setShowUpstream] = useState(false);
  const [showDownstream, setShowDownstream] = useState(false);
  const isSuggestedPriority = queryParams.has(SEARCH_PARAMS.SUGGESTED_PRIORITY);
  const IconComponent = getReferencedType(tableInfo.type).icon;

  const scorePercentage =
    isSuggestedPriority && tableInfo?.score !== null
      ? Math.round(tableInfo.score * 100)
      : null;

  const { data: isAnomalyTable } = useGetIsAnomalyTable(
    tableInfo.dataset,
    tableInfo.table
  );

  const { data } = useQuery(
    [QUERY_TYPES.tableDependencies, tableInfo.dataset, tableInfo.table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, { dataset, table });
    }
  );

  const updateDesc = useCallback(
    (desc) =>
      dispatch(
        updateTableDesc({
          dataset: tableInfo.dataset,
          table: tableInfo.table,
          desc,
        })
      ),
    [dispatch, tableInfo]
  );

  const {
    content: upstreamData,
    handleChangeCollapse: handleChangeCollapseUpstream,
    renderCollapsedItemsNumber: renderUpstreamContent,
  } = useExpandContent(data?.up);
  const {
    content: downstreamData,
    handleChangeCollapse: handleChangeCollapseDownstream,
    renderCollapsedItemsNumber: renderDownstreamContent,
  } = useExpandContent(data?.down);

  useEffect(() => {
    if (data?.up && data?.down) {
      setShowUpstream(
        data.up.length !== 0 &&
          selectedStreamType?.[0].value !== DICTIONARY_TABLE_DOWNSTREAM
      );
      setShowDownstream(
        data.down.length !== 0 &&
          selectedStreamType?.[0].value !== DICTIONARY_TABLE_UPSTREAM
      );
    }
  }, [data, selectedStreamType]);

  const tableNameCell = getFullTableName(tableInfo.table, tableInfo.dataset);

  const onMonitors = (dataset, table) => {
    history.push({
      pathname: AppRoutes.Monitors.path,
      search: new URLSearchParams({
        [SEARCH_PARAMS.DATASET]: dataset,
        [SEARCH_PARAMS.TABLE]: table,
      }).toString(),
    });

    amplEvent(
      `${AMPL_PAGE_EVENT.dictionary} -> ${
        DictionaryTabs.TABLES
      } tab -> click table ${getFullTableName(
        table,
        dataset
      )} -> to Monitors page`
    );
  };

  const showDictionaryTableDependency = useCallback(
    (dependence) => {
      switch (dependence.type) {
        case DATASET_DEPENDENCIES.table:
          return (
            <DictionaryTableDependingTable
              key={`up/${tableNameCell}/${getFullTableName(
                dependence.data.table,
                dependence.data.dataset
              )}`}
              item={dependence.data}
              onMonitors={onMonitors}
            />
          );
        case DATASET_DEPENDENCIES.sourceUri:
          return (
            <DictionaryTableDependingSourceUri
              key={`up/${tableNameCell}/${dependence.data}`}
              sourceUri={dependence.data}
            />
          );
        default:
          return '';
      }
    },
    [tableNameCell]
  );

  return (
    <StyledTableRow
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <StyledTableCell className='checkboxContainer'>
        <Checkbox
          onClick={(event) => handleClick(event, tableInfo)}
          checked={isItemSelected}
          icon={<UncheckIcon />}
          checkedIcon={<CheckIcon />}
          className='checkbox'
        />
      </StyledTableCell>

      <StyledTableCell
        className={clsx(
          isAnomalyTable ? 'redBoldText' : 'blueBoldText',
          'cellWithIcon'
        )}
      >
        <IconComponent />
        <OverflownText
          onClick={() => onMonitors(tableInfo.dataset, tableInfo.table)}
          title={tableNameCell}
          maxWidth={TABLE_NAME_WIDTH}
          className='appLink'
        >
          <span style={{ fontWeight: 500 }}>{tableInfo.dataset}.</span>
          <br />
          {tableInfo.table}
        </OverflownText>
      </StyledTableCell>

      <StyledTableCell>
        <Chip
          label={getAlertLabel(tableInfo?.alertType)}
          color={getAlertColorType(tableInfo?.alertType)}
        />
      </StyledTableCell>

      {scorePercentage !== null && (
        <StyledTableCell>{scorePercentage}/100</StyledTableCell>
      )}

      <StyledTableCell className='nowrapText'>
        {tableInfo.creationTime
          ? moment(tableInfo.creationTime)
              .utc()
              .format(DATE_FORMAT.dayMonthYear)
          : 'N/A'}
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        {tableInfo.lastModifiedTime
          ? moment(tableInfo.lastModifiedTime)
              .utc()
              .format(DATE_FORMAT.dayMonthYear)
          : 'N/A'}
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        {typeof tableInfo.numBytes === 'number' && tableInfo.numBytes !== 0 ? (
          bytesToSize(tableInfo.numBytes)
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </StyledTableCell>

      <StyledTableCell
        className={clsx(
          showUpstream && showDownstream && 'streamContainer',
          'blueText'
        )}
      >
        {showUpstream && (
          <div>
            <div className='txt-grey-13-500'>Upstream</div>
            {upstreamData.map((item) => showDictionaryTableDependency(item))}
            {data.up.length > MAX_ITEM_IN_CELL && (
              <div
                className='txt-grey-13-500'
                onClick={handleChangeCollapseUpstream}
                style={{ cursor: 'pointer' }}
              >
                {renderUpstreamContent()}
              </div>
            )}
          </div>
        )}
        {showDownstream && (
          <div>
            <div className='txt-grey-13-500'>Downstream</div>
            {downstreamData.map((item) => showDictionaryTableDependency(item))}
            {data.down.length > MAX_ITEM_IN_CELL && (
              <div
                className='txt-grey-13-500'
                onClick={handleChangeCollapseDownstream}
                style={{ cursor: 'pointer' }}
              >
                {renderDownstreamContent()}
              </div>
            )}
          </div>
        )}
      </StyledTableCell>

      <StyledTableCell>
        <EditCell value={tableInfo.description} onEdit={updateDesc} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { DictionaryTableDetails };
