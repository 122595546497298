import React from 'react';
import {
  useJobsExecutionCount,
  useState,
  useMemo,
  useEffect,
} from 'hooks/hooks.js';
import { Divider, JobsExecution } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { JobsRunsTable } from './components/components.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const DEFAULT_FREQUENCY_MILLISECONDS = 3600 * 24 * 1000;

const calculateFrequency = (data) => {
  if (data?.length < 2) {
    return null;
  }

  const time1 = new Date(data.at(0).datetime).getTime();
  const time2 = new Date(data.at(1).datetime).getTime();

  return Math.abs(time2 - time1);
};

const JobExecutionWidget = ({ jobHash }) => {
  const {
    jobsExecution: { title, subtitle },
  } = getWidgetLabel();
  const { jobsExecutionCountData, isLoadingJobsExecutionCountData } =
    useJobsExecutionCount({ jobHash });

  const frequencyMilliseconds = calculateFrequency(jobsExecutionCountData);

  const [selectedJobExecutionDate, setSelectedJobExecutionDate] =
    useState(null);

  const defaultJobExecutionDate = useMemo(() => {
    return jobsExecutionCountData.at(-1)?.datetime;
  }, [jobsExecutionCountData]);

  useEffect(() => {
    if (defaultJobExecutionDate) {
      setSelectedJobExecutionDate(defaultJobExecutionDate);
    }
  }, [defaultJobExecutionDate]);

  const handleChangeJobExecutionDate = (value) => {
    setSelectedJobExecutionDate(value || defaultJobExecutionDate);
  };

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
      isLoading={isLoadingJobsExecutionCountData}
    >
      <>
        {jobsExecutionCountData.length ? (
          <>
            <JobsExecution
              data={jobsExecutionCountData}
              frequencyMilliseconds={
                frequencyMilliseconds || DEFAULT_FREQUENCY_MILLISECONDS
              }
              selectedDatetime={selectedJobExecutionDate}
              onChangeSelectedDate={handleChangeJobExecutionDate}
            />
            <Divider />
            <JobsRunsTable
              jobHash={jobHash}
              frequencyMilliseconds={
                frequencyMilliseconds || DEFAULT_FREQUENCY_MILLISECONDS
              }
              startDate={selectedJobExecutionDate}
            />
          </>
        ) : (
          <div className='txt-grey-13-500 text-center text-italic'>
            No data for the graph
          </div>
        )}
      </>
    </Widget>
  );
};

export { JobExecutionWidget };
