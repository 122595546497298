const NAME_ID = 'NAME';
const EMAIL_ID = 'EMAIL';
const SLACK_ID = 'SLACK';
const PRODUCTS_ID = 'PRODUCTS';
const COST_ID = 'COST';
const INCIDENTS_ID = 'INCIDENTS';
const EDIT_ID = 'EDIT';

const Name = {
  id: NAME_ID,
  label: 'Name',
  sort: false,
  align: 'left',
  isShow: true,
};
const Email = {
  id: EMAIL_ID,
  label: 'Email',
  sort: false,
  align: 'left',
  isShow: true,
};
const Slack = {
  id: SLACK_ID,
  label: 'Slack channel',
  sort: false,
  align: 'left',
  isShow: true,
};
const Products = {
  id: PRODUCTS_ID,
  label: 'Data Products',
  sort: false,
  align: 'right',
  isShow: true,
};
const Cost = {
  id: COST_ID,
  label: 'Monthly Cost',
  sort: false,
  align: 'right',
  isShow: true,
};
const Incidents = {
  id: INCIDENTS_ID,
  label: 'Open Incidents',
  sort: false,
  align: 'right',
  isShow: true,
};

const Edit = {
  id: EDIT_ID,
  label: '',
  align: 'right',
  sort: false,
  isShow: true,
};

export { Name, Email, Slack, Products, Cost, Incidents, Edit };
