import { Typography as MuiTypography } from '@mui/material';
import { Tooltip } from 'Components/components.js';
import { useEffect, useRef, useState } from 'react';

const TYPOGRAPHY_VARIANT = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'body1',
  body2: 'body2',
};

const TYPOGRAPHY_COLOR = {
  primary: 'primary',
  // secondary: 'secondary', TODO change default color to white
  textPrimary: 'textPrimary',
  textSecondary: 'textSecondary',
  error: 'error',
};

const Typography = ({
  children = null,
  variant = TYPOGRAPHY_VARIANT.body1,
  color = TYPOGRAPHY_COLOR.textPrimary,
  component = 'p',
  noWrap = false,
  tooltipText = '',
  className = '',
  sx = {},
}) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (noWrap && textRef.current) {
      setIsTruncated(textRef.current.offsetWidth < textRef.current.scrollWidth);
    }
  }, [noWrap, children]);

  return (
    <Tooltip title={isTruncated ? tooltipText : ''}>
      <MuiTypography
        ref={textRef}
        variant={variant}
        color={color}
        className={className}
        component={component}
        noWrap={noWrap}
        sx={sx}
      >
        {children}
      </MuiTypography>
    </Tooltip>
  );
};

export { Typography, TYPOGRAPHY_VARIANT, TYPOGRAPHY_COLOR };
