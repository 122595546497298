import React from 'react';
import {
  useState,
  useCallback,
  useEffect,
  usePipelineDestinationTables,
  usePipelineStats,
  useMemo,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Pipelines/Pipelines.styles.js';
import {
  Backdrop,
  Button,
  BUTTON_COLOR,
  PipelineStats,
  PipelinesDropdowns,
  PipelinesSearch,
} from 'Components/components.js';
import { usePipelinePageContext } from 'context/context.js';
import { PipelineTable } from './components/components.js';
import { getPipelineTimePeriodByFilter } from 'Pages/Pipelines/libs/helpers/helpers.js';
import {
  DEAD_END_TABLES,
  SEARCH_DESTINATION_TABLE,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_DESTINATION,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'constants/constants.js';
import {
  COST_EFFICIENCY_FILTER,
  PipelineDropdowns,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
  TIME_PERIOD_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const PipelineTab = () => {
  const classes = useStyles();
  const {
    defaultJobHash,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangePaginationTotal,
    searchValues,
    handleChangeIsJobHashFound,
  } = usePipelinePageContext();

  const [dropdownsValue, setDropdownsValue] = useState({
    [TABLE_ALERT_STATUS_FILTER]: null,
    [TIME_PERIOD_FILTER]: null,
    [TECHNOLOGY_FILTER]: null,
    [COST_EFFICIENCY_FILTER]: null,
  });

  const isDeadEndTablesSelected = useMemo(() => {
    return (
      dropdownsValue[COST_EFFICIENCY_FILTER]?.[0]?.value === DEAD_END_TABLES
    );
  }, [dropdownsValue]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const timePeriod = useMemo(() => {
    return getPipelineTimePeriodByFilter(
      dropdownsValue[TIME_PERIOD_FILTER]?.[0]?.value
    );
  }, [dropdownsValue]);

  const isAllowedDestinationRequest = useMemo(() => {
    return (
      !Object.values(dropdownsValue).some((value) => value === null) &&
      timePeriod !== null
    );
  }, [dropdownsValue, timePeriod]);

  const {
    destinationTables,
    destinationTablesNumber,
    isFetchingDestinationTables,
    refetchDestinationTables,
  } = usePipelineDestinationTables({
    startDate: timePeriod?.start,
    endDate: timePeriod?.end,
    page,
    rowsPerPage,
    searchDestinationTable: searchValues[SELECTED_DESTINATION]?.table,
    searchDestinationDataset: searchValues[SELECTED_DESTINATION]?.dataset,
    searchDestinationValue: searchValues[SEARCH_DESTINATION_TABLE],
    searchTable: searchValues[SELECTED_TABLE]?.table,
    searchDataset: searchValues[SELECTED_TABLE]?.dataset,
    searchValue: searchValues[SEARCH_TABLE],
    selectedEmail: searchValues[SELECTED_EMAIL],
    searchEmail: searchValues[SEARCH_EMAIL],
    selectedTags: searchValues[SELECTED_TAGS],
    selectedLabelKey: searchValues[SELECTED_LABEL]?.key,
    selectedLabelValue: searchValues[SELECTED_LABEL]?.value,
    searchLabel: searchValues[SEARCH_LABEL],
    powerbiReportName: searchValues[SELECTED_POWER_BI_REPORT],
    powerbiWorkspaceName: searchValues[SELECTED_POWER_BI_WORKSPACE],
    alertTypes: dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value,
    jobHash: defaultJobHash,
    isDeadEndTablesSelected,
    enabled: isAllowedDestinationRequest,
  });

  const { pipelineStats, refetchPipelineStats } = usePipelineStats(
    timePeriod?.start,
    timePeriod?.end
  );

  const handleRefreshPage = () => {
    handleChangePage({}, 0);
    refetchDestinationTables();
    refetchPipelineStats();
  };

  useEffect(() => {
    handleChangePaginationTotal(destinationTablesNumber);
  }, [destinationTablesNumber, handleChangePaginationTotal]);

  useEffect(() => {
    if (defaultJobHash && destinationTables.length > 0) {
      const isExistingPipeline = destinationTables.some(
        (item) => item.isSelected
      );
      handleChangeIsJobHashFound(isExistingPipeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultJobHash, destinationTables]);

  return (
    <>
      <PipelineStats pipelineStats={pipelineStats} />

      <div className={classes.mainContainer}>
        <PipelinesDropdowns
          onChangeDropdownsValue={handleChangeDropdownsValue}
          pipelineStats={pipelineStats}
          existingDropdown={PipelineDropdowns}
        />

        <div className={classes.searchContainer}>
          <PipelinesSearch />

          <Button
            onClick={handleRefreshPage}
            color={BUTTON_COLOR.secondary}
            text='Refresh'
            fullWidth={false}
            startIcon={<RefreshIcon />}
          />
        </div>

        <PipelineTable
          destinationTables={destinationTables}
          isFetchingDestinationTables={isFetchingDestinationTables}
          startDate={timePeriod?.start}
          endDate={timePeriod?.end}
        />

        <Backdrop isOpen={isFetchingDestinationTables} />
      </div>
    </>
  );
};

export { PipelineTab };
