import { useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  Button,
  BUTTON_VARIANT,
  MarkdownWithSyntaxHighlighting,
} from 'Components/components.js';

const TEXT_SIZE = 400;

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
  },
}));

const DescriptionWidget = ({ description = '' }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const isLongText = description.length > TEXT_SIZE;
  const shortDescription = description.slice(0, TEXT_SIZE);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className={classes.container}>
      {isLongText && !isExpanded ? (
        <>
          <MarkdownWithSyntaxHighlighting markdown={shortDescription} />
          {description.length > shortDescription.length && '...'}
          <Button
            onClick={toggleExpand}
            text='Show full description'
            variant={BUTTON_VARIANT.text}
            disableRipple={true}
            fullWidth={false}
            className='d-block ps-0 pb-0'
          />
        </>
      ) : (
        <>
          <MarkdownWithSyntaxHighlighting markdown={description} />
          {isLongText && (
            <Button
              onClick={toggleExpand}
              text='Hide full description'
              variant={BUTTON_VARIANT.text}
              disableRipple={true}
              fullWidth={false}
              className='d-block ps-0 pb-0'
            />
          )}
        </>
      )}
    </div>
  );
};

export { DescriptionWidget };
