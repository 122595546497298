import React from 'react';
import { Input } from 'Components/components.js';

const NameSection = ({ controlData, value, onChangeName }) => {
  const { label, id, isRequired, placeholder } = controlData;

  return (
    <Input
      id={id}
      value={value}
      onChange={onChangeName}
      label={label}
      isRequired={isRequired}
      autoFocus={true}
      placeholder={placeholder}
    />
  );
};

export { NameSection };
