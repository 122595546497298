import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processDataProductList = (data) => {
  return {
    dataProductList: data?.values || [],
    dataProductTotal: data?.pagination?.total || 0,
  };
};

const useGetDataProductList = ({
  page,
  limit,
  searchDataProduct,
  selectedDataProductUUID,
}) => {
  const isValidUUID = selectedDataProductUUID?.length
    ? getIsValidUUID(selectedDataProductUUID)
    : true;

  const { isFetching: isFetchingDataProductList, data } = useQuery(
    [
      QUERY_TYPES.dataProductList,
      page,
      limit,
      searchDataProduct,
      selectedDataProductUUID,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append('page', page + 1);
      params.append('limit', limit);

      searchDataProduct?.length && params.append('search', searchDataProduct);
      selectedDataProductUUID?.length &&
        params.append('uuid', selectedDataProductUUID);

      return fetcherGet(url, params);
    },
    { enabled: isValidUUID }
  );

  const processedData = useMemo(() => {
    return processDataProductList(data);
  }, [data]);

  return {
    dataProductList: processedData.dataProductList,
    dataProductTotal: processedData.dataProductTotal,
    isFetchingDataProductList,
  };
};

export { useGetDataProductList };
