import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useMessages, useQueryParamsContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { fetcherDelete } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES, SEARCH_PARAMS } from 'constants/constants.js';

const EVENT_MESSAGE = 'Delete data product -> uuid';
const INVALID_UUID_MESSAGE = 'Invalid UUID';

const useDeleteDataProduct = ({ dataProductUUID }) => {
  const queryClient = useQueryClient();
  const { setMessage } = useMessages();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const isValidUUID = getIsValidUUID(dataProductUUID);

  const dataProductFromUrl = queryParams.get(
    SEARCH_PARAMS.SELECTED_DATA_PRODUCT
  );

  const { mutateAsync: deleteDataProduct } = useMutation(
    () => {
      if (!isValidUUID) {
        setMessage(
          'An error occurred while deleting the data product. Invalid data product UUID provided.'
        );
        amplEvent(
          `${EVENT_MESSAGE} ${dataProductUUID} error -> ${INVALID_UUID_MESSAGE}`
        );
        throw new Error(INVALID_UUID_MESSAGE);
      }

      return fetcherDelete(`${QUERY_TYPES.dataProduct}/${dataProductUUID}`);
    },
    {
      onSuccess: (data) => {
        if (data.success) {
          amplEvent(`${EVENT_MESSAGE} ${dataProductUUID} success`);
          queryClient.invalidateQueries(QUERY_TYPES.dataProductList);
          queryClient.invalidateQueries(QUERY_TYPES.domainList);

          if (dataProductFromUrl === dataProductUUID) {
            updateQueryParams(
              { [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: null },
              { useReplace: true }
            );
          }
        } else {
          setMessage('An error occurred while deleting the data product');
          amplEvent(`${EVENT_MESSAGE} ${dataProductUUID} error-1`);
        }
      },
      onError: (error) => {
        if (error.message !== INVALID_UUID_MESSAGE) {
          amplEvent(`${EVENT_MESSAGE} ${dataProductUUID} error-2`);
          setMessage('An error occurred while deleting the data product');
        }
      },
    }
  );

  return { deleteDataProduct };
};

export { useDeleteDataProduct };
