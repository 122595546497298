import React from 'react';
import { useBqBillingData, useMemo, useEffect } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import {
  OverflownText,
  CostLabel,
  JobTagsTableCell,
  PipelineDetailsModal,
  SourceTableCell,
} from 'Components/components.js';
import { useModal, usePipelinePageContext } from 'context/context.js';
import { bytesToSize, millisecondsToTimeSlots } from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const {
  RESERVATION_WIDTH,
  PRINCIPAL_EMAIL_FULL_WIDTH,
  DESTINATION_TABLE_NAME_FULL_WIDTH,
} = PipelineTableCellStyles;
const BASE_COL_SPAN = 2;

const useStyles = makeStyles((theme) => ({
  insideTable: {
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingRight: 0,
      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
  },
}));

const ResourceTableSourceRaw = ({
  sourceData,
  destinationTable,
  scrollToRef,
  onChangeIsScrollRefRender,
}) => {
  const { setModal } = useModal();
  const { bqBillingData, currentBqBillingData } = useBqBillingData();
  const {
    selectedJobHash,
    handleChangeJobHash,
    pipelineDetailsLink,
    onClickCopyLink,
  } = usePipelinePageContext();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;

  const {
    jobHash,
    tables,
    sourceUri,
    principalEmail,
    jobTags,
    reservation,
    sourceProject,
    totalBytes,
    totalSlotsMs,
    costs,
    routines,
  } = sourceData;

  const sourcePaymentType = bqBillingData?.values?.find(
    (item) => item.project === sourceProject
  )?.paymentType;
  const isOnDemand = sourcePaymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const totalData = isOnDemand
    ? bytesToSize(totalBytes)
    : millisecondsToTimeSlots(totalSlotsMs);

  const sources = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  useEffect(() => {
    if (selectedJobHash && jobHash === selectedJobHash) {
      setModal(() => (
        <PipelineDetailsModal
          destinationTable={destinationTable}
          sourceData={sourceData}
          pipelineDetailsLink={pipelineDetailsLink}
          onClickCopyLink={onClickCopyLink}
          onClose={() => handleChangeJobHash(null)}
        />
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobHash]);

  useEffect(() => {
    if (scrollToRef) {
      onChangeIsScrollRefRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPipelineRawClick = () => {
    handleChangeJobHash(jobHash);
  };

  const colSpan = useMemo(() => {
    return isExistingReservationCell ? BASE_COL_SPAN + 1 : BASE_COL_SPAN;
  }, [isExistingReservationCell]);

  return (
    <TableRow
      ref={scrollToRef}
      onClick={onPipelineRawClick}
      className={clsx(jobHash === selectedJobHash && 'withBackground')}
    >
      <SourceTableCell
        sourceData={sources}
        sourceUri={sourceUri}
        maxWidth={DESTINATION_TABLE_NAME_FULL_WIDTH}
      />

      <TableCell colSpan={colSpan}>
        <InsideTable
          principalEmail={principalEmail}
          reservation={reservation}
          sourceProject={sourceProject}
          totalData={totalData}
          jobTags={jobTags}
          colSpan={colSpan}
          isExistingReservationCell={isExistingReservationCell}
        />
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {totalData.length ? (
          totalData
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
      </TableCell>
    </TableRow>
  );
};

const InsideTable = ({
  principalEmail,
  reservation,
  sourceProject,
  jobTags,
  colSpan,
  isExistingReservationCell,
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.insideTable}>
      <TableBody>
        <TableRow className='noBorder'>
          <TableCell style={{ width: PRINCIPAL_EMAIL_FULL_WIDTH }}>
            <OverflownText
              title={principalEmail}
              maxWidth={PRINCIPAL_EMAIL_FULL_WIDTH}
            >
              {principalEmail}
            </OverflownText>
          </TableCell>

          {isExistingReservationCell && (
            <TableCell align='left' className='nowrapText'>
              {reservation?.length ? (
                <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
                  {reservation}
                </OverflownText>
              ) : (
                <span className='txt-grey-13-500'>N/A</span>
              )}
            </TableCell>
          )}

          <TableCell align='right' className='nowrapText'>
            {sourceProject}
          </TableCell>
        </TableRow>

        {jobTags.length > 0 && (
          <TableRow className='noBorder'>
            <JobTagsTableCell jobTags={jobTags} colSpan={colSpan} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export { ResourceTableSourceRaw };
