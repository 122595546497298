import {
  useEffect,
  useMemo,
  useState,
  useQueryClient,
  useGetIncidentGroups,
} from 'hooks/hooks.js';
import {
  IncidentTabs,
  INCIDENT_STATUS,
  INCIDENT_TYPE,
  SEARCH_PARAMS,
  SEARCH_PARAMS_SEPARATOR,
} from 'constants/constants.js';
import { PositionLoader } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { useDateContext } from 'context/context.js';
import { QueryErrors } from '../components.js';
import { getPipelineIncidentTitle } from 'utils/helpers/helpers.js';
import { getIncidentsData } from './utils.js';
import { AppRoutes } from 'app-routes.js';

const ROWS_PER_PAGE = 5;

const ErrorsWidget = ({
  label,
  secondaryLabel,
  tableInfo,
  incidentGroupsTypes,
  isExisting,
  onChangeExistingData,
  getLinkPath,
  incidentType = INCIDENT_TYPE.LOG,
}) => {
  const queryClient = useQueryClient();
  const { startDate, endDate } = useDateContext();

  const [isLoading, setIsLoading] = useState(true);
  const [incidentsCount, setIncidentsCount] = useState(null);
  const [incidentsData, setIncidentsData] = useState([]);

  const [page, setPage] = useState(0);
  const [errorsRows, setErrorsRows] = useState([]);
  const [errorsTotal, setErrorsTotal] = useState(null);
  const [isFetchingRows, setIsFetchingRows] = useState(true);

  const incidentTypes = useMemo(() => {
    return incidentGroupsTypes?.map((type) => ({ value: type }));
  }, [incidentGroupsTypes]);
  const incidentTypesToSearchParam = useMemo(() => {
    return incidentTypes
      ?.map((incidentType) => incidentType.value)
      ?.join(SEARCH_PARAMS_SEPARATOR);
  }, [incidentTypes]);

  useEffect(() => {
    setIsLoading(true);
    setIncidentsCount(null);
    setPage(0);
    setErrorsRows([]);
    setErrorsTotal(null);
    setIsFetchingRows(true);
  }, [tableInfo?.dataset, tableInfo?.table]);

  const onPageChange = (page) => {
    setPage(page);
    setIsFetchingRows(true);
  };

  const {
    isIncidentGroupsFetching,
    incidentGroups,
    incidentsTotal,
    incidentsGroupPagination,
  } = useGetIncidentGroups({
    page,
    rowsPerPage: ROWS_PER_PAGE,
    dataset: tableInfo?.dataset,
    table: tableInfo?.table,
    searchTable: '',
    incidentGroupsTypes: incidentTypes,
    incidentGroupsAlert: [{ value: tableInfo?.alertType }],
    incidentGroupsStatuses: [{ value: INCIDENT_STATUS.ACTIVE }],
    enabled:
      !!tableInfo?.dataset &&
      !!tableInfo?.table &&
      incidentGroupsTypes !== null,
  });

  useEffect(() => {
    setIncidentsCount(incidentsTotal);
    setErrorsTotal(incidentsGroupPagination);

    if (incidentGroups.length > 0) {
      setIncidentsData([]);
      incidentGroups.forEach((incident) => {
        getIncidentsData({
          queryClient,
          incident,
          startDate,
          endDate,
          incidentStatuses: [INCIDENT_STATUS.ACTIVE],
        }).then((response) => {
          if (
            incidentGroups.find((i) => i.incidentId === incident.incidentId)
          ) {
            setIncidentsData((prev) => [
              ...prev,
              {
                incidentId: incident.incidentId,
                data: response?.values?.length ? response.values[0] : {},
                total: response?.pagination?.total || 0,
              },
            ]);
          }
        });
      });
    } else {
      setIncidentsData([]);
    }
  }, [incidentGroups, queryClient, startDate, endDate]);

  useEffect(() => {
    if (
      !isIncidentGroupsFetching &&
      incidentGroups.length === incidentsData.length
    ) {
      const newErrorsRows = incidentGroups.reduce((acc, incident) => {
        const incidentData = incidentsData.find(
          (i) => i.incidentId === incident.incidentId
        );
        if (!incidentData) {
          return acc;
        }
        acc.push({
          ...incident,
          data: incidentData.data,
          total: incidentData.total,
          details:
            incidentType === INCIDENT_TYPE.PIPELINE
              ? getPipelineIncidentTitle(
                  incident.anomalyType,
                  incident.metricType
                )
              : incidentData.data.error,
        });
        return acc;
      }, []);
      if (newErrorsRows.length) {
        setErrorsRows(newErrorsRows);
      } else {
        setErrorsRows([]);
      }
      setIsFetchingRows(false);
    }
  }, [incidentGroups, incidentType, incidentsData, isIncidentGroupsFetching]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (
      isExisting !== null &&
      incidentsCount !== null &&
      !isIncidentGroupsFetching &&
      incidentGroups.length === incidentsData.length
    ) {
      setIsLoading(false);
    }
  }, [
    isExisting,
    incidentsCount,
    isIncidentGroupsFetching,
    incidentsData,
    isLoading,
    incidentGroups.length,
  ]);

  const linkPath = useMemo(() => {
    return incidentsCount
      ? getLinkPath(AppRoutes.Incidents.path, {
          [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
          [SEARCH_PARAMS.TABLE_ALERT_TYPE]: '',
          [SEARCH_PARAMS.PIPELINE_INCIDENT_TYPE]: incidentTypesToSearchParam,
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentsCount]);

  return (
    <Widget
      label={label}
      secondaryLabel={secondaryLabel}
      incidentCount={incidentsCount}
      count={true}
      path={linkPath}
      linkName='See all'
      existingDivider={isExisting}
      initialSettings={{ size: 'L', height: 'auto' }}
    >
      {isLoading ? (
        <PositionLoader />
      ) : (
        <QueryErrors
          rows={errorsRows}
          rowsTotal={errorsTotal}
          rowsPage={page}
          rowsPerPage={ROWS_PER_PAGE}
          isFetchingRows={isFetchingRows}
          onPageChange={onPageChange}
          onChangeExistingQueryErrors={onChangeExistingData}
          incidentType={incidentType}
        />
      )}
    </Widget>
  );
};

export { ErrorsWidget };
