import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import {
  Modal,
  ProjectChangeConfirmation,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { useModal, useUserInfo } from 'context/context.js';

const DataAssetLink = ({ dataAsset }) => {
  const { setModal } = useModal();
  const { user } = useUserInfo();
  const history = useHistory();

  const { project, linkPath, name } = dataAsset;
  const currentProject = user.info.currentProject;

  const handleNavigation = () => {
    if (currentProject !== project) {
      setModal(() => (
        <Modal
          onAgree={() => history.push(linkPath)}
          titlePosition='center'
          ModalBodyComponent={() => ProjectChangeConfirmation(project)}
          agreeButtonText='Open'
        />
      ));
    }
  };

  const TypographyBlock = () => (
    <Typography
      color={TYPOGRAPHY_COLOR.primary}
      noWrap={true}
      tooltipText={name}
    >
      {name}
    </Typography>
  );

  return (
    <>
      {currentProject !== project ? (
        <Link to='#' onClick={handleNavigation}>
          <TypographyBlock />
        </Link>
      ) : (
        <Link to={linkPath}>
          <TypographyBlock />
        </Link>
      )}
    </>
  );
};

export { DataAssetLink };
