import React from 'react';
import { useGetDomain, useState, useEffect } from 'hooks/hooks.js';
import { useStyles } from 'Pages/DataProdact/DataProduct.styles.js';
import {
  AsyncGeneralSearch,
  Button,
  RightSideModal,
} from 'Components/components.js';
import { CreateDomainModalBody } from './components.js';
import {
  useDataProductsPageContext,
  useModal,
  useQueryParamsContext,
} from 'context/context.js';
import { QUERY_TYPES, SEARCH_PARAMS } from 'constants/constants.js';

const Search = () => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const { handleChangeToolbarText } = useDataProductsPageContext();
  const [currentDomain, setCurrentDomain] = useState(null);

  const searchDomain = queryParams.get(SEARCH_PARAMS.SEARCH_DOMAIN);
  const selectedDomainUUID = queryParams.get(SEARCH_PARAMS.SELECTED_DOMAIN);

  const { domain, isFetchingDomain } = useGetDomain({
    domainUUID: selectedDomainUUID,
  });

  useEffect(() => {
    if (domain) {
      setCurrentDomain(domain);
    }
    if (!domain && !isFetchingDomain) {
      setCurrentDomain(null);
    }
  }, [domain, isFetchingDomain]);

  const handleDomainSearch = (searchValue) => {
    updateQueryParams({
      [SEARCH_PARAMS.SEARCH_DOMAIN]: searchValue,
      [SEARCH_PARAMS.SELECTED_DOMAIN]: null,
    });
  };

  const handleDomainSelect = (selectValue) => {
    const newValue = selectValue.length ? selectValue : null;

    updateQueryParams({
      [SEARCH_PARAMS.SELECTED_DOMAIN]: newValue,
      [SEARCH_PARAMS.SEARCH_DOMAIN]: null,
    });
  };

  const createDomain = () => {
    setModal(() => (
      <RightSideModal
        title='Create domain'
        ModalBodyComponent={CreateDomainModalBody}
        mainBlockMargin={0}
        onChangeActionText={handleChangeToolbarText}
      />
    ));
  };

  const getLabelItem = (value) => {
    return `${value.name} / ${value.email}`;
  };
  const getValueItem = (value) => {
    return value.uuid;
  };

  return (
    <div className={classes.searchContainer}>
      <AsyncGeneralSearch
        onSearch={handleDomainSearch}
        onSelect={handleDomainSelect}
        defaultSelectValue={currentDomain}
        defaultSearchValue={searchDomain}
        searchUrl={`${QUERY_TYPES.domainList}/search`}
        searchQueryParam='search'
        className='searchInput'
        placeholderName='Start typing domain name...'
        getLabelFromRequestItem={getLabelItem}
        getValueFromRequestItem={getValueItem}
        getLabelFromResponseItem={getLabelItem}
        getValueFromResponseItem={getValueItem}
        disableCache={true}
      />

      <Button
        text='Create'
        onClick={createDomain}
        fullWidth={false}
        className={classes.createButton}
      />
    </div>
  );
};

export { Search };
