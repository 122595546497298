import { Table, TableBody, TableContainer } from '@mui/material';
import { TableLoader } from 'Components/components.js';
import { TableHead } from './TableHead.js';
import { TableRow } from './TableRow.js';
import { useDataProductsPageContext } from 'context/context.js';

const DomainsTable = () => {
  const { domainList, isFetchingDomainList } = useDataProductsPageContext();

  return (
    <TableContainer>
      <Table>
        <TableHead />

        <TableBody>
          {isFetchingDomainList ? (
            <TableLoader />
          ) : (
            domainList.map((domain) => (
              <TableRow domain={domain} key={domain.uuid} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DomainsTable };
