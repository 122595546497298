import React from 'react';
import { TableHeadWithCheckbox } from 'Components/components.js';
import {
  Name,
  Domain,
  AnalyticsHub,
  Subscribers,
  Cost,
  Incidents,
  Edit,
} from 'Pages/DataProdact/enums/enums.js';

const HEAD_CELLS = [
  Name,
  Domain,
  AnalyticsHub,
  Subscribers,
  Cost,
  Incidents,
  Edit,
];

const TableHead = () => {
  const headCells = HEAD_CELLS.filter((headCell) => headCell.isShow);

  return <TableHeadWithCheckbox headCells={headCells} />;
};

export { TableHead };
