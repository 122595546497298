import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processExecutedJobsData = (data) => {
  return {
    executedJobs: data?.values || [],
  };
};

const useGetExecutedJobs = ({ dataProductUUID, aggregatedBy }) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidUUID = getIsValidUUID(dataProductUUID);

  const { isFetching: isFetchingExecutedJobs, data } = useQuery(
    [
      QUERY_TYPES.executedJobs,
      dataProductUUID,
      startDateUtc,
      endDateUtc,
      aggregatedBy,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append('dataProductUuid', dataProductUUID);
      params.append('from', startDateUtc);
      params.append('to', endDateUtc);
      params.append('aggBy', aggregatedBy);

      return fetcherGet(url, params);
    },
    { enabled: Boolean(isValidUUID && aggregatedBy) }
  );

  const processedData = useMemo(() => {
    return processExecutedJobsData(data);
  }, [data]);

  return {
    executedJobs: processedData.executedJobs,
    isFetchingExecutedJobs,
  };
};

export { useGetExecutedJobs };
