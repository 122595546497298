import React from 'react';
import { makeStyles } from '@mui/styles';
import { TableHead, TableSortLabel, Checkbox } from '@mui/material';
import {
  StyledTableRow,
  StyledTableCell,
  Tooltip,
} from 'Components/components.js';
import { ReactComponent as SortIcon } from 'assets/img/dictionaryIcons/sortArrowIcon.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/delete-check-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/img/icons/ic-24-info.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
}));

const TableHeadWithCheckbox = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  selectedColumn,
}) => {
  const classes = useStyles();

  const createSortHandler = (headCell) => {
    if (!headCell.sort) {
      return;
    }
    onRequestSort(headCell.id);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {selectedColumn && (
          <StyledTableCell sx={{ width: 22, padding: '0 0 16px 12px' }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              icon={<UncheckIcon />}
              checkedIcon={<CheckIcon />}
              indeterminateIcon={<CheckIcon />}
              sx={{ padding: 0, width: 22, height: 22 }}
            />
          </StyledTableCell>
        )}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sx={{ paddingTop: 0 }}
            align={headCell?.align || 'left'}
          >
            <TableSortLabel
              onClick={() => createSortHandler(headCell)}
              className='txt-grey-13-500 nowrapText'
              hideSortIcon={!headCell.sort}
              active={headCell.sort}
              style={{ cursor: headCell.sort ? 'pointer' : 'default' }}
              IconComponent={SortIcon}
            >
              <span className='txt-grey-13-500'>{headCell.label}</span>
              {headCell?.tip && (
                <Tooltip title={headCell.tip} hasMaxWidthTooltip={true}>
                  <InfoIcon className={classes.icon} />
                </Tooltip>
              )}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export { TableHeadWithCheckbox };
