import React from 'react';
import { useState } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { SubscribersTable } from './components/components.js';

const SubscribersWidget = () => {
  const [totalSubscribers, setTotalSubscribers] = useState(0);

  const handleChangeTotalSubscribers = (totalSubscribers) => {
    setTotalSubscribers(totalSubscribers);
  };

  return (
    <Widget
      label='Subscribers'
      count={true}
      incidentCount={totalSubscribers}
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <SubscribersTable
        onChangeTotalSubscribers={handleChangeTotalSubscribers}
      />
    </Widget>
  );
};

export { SubscribersWidget };
