import React from 'react';
import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useQuery,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  AsyncMultiSearch,
  Button,
  BUTTON_VARIANT,
  Chip,
  CHIP_COLOR,
  MultiSelect,
  Typography,
} from 'Components/components.js';
import { fetcherGet } from 'utils/utils.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import {
  QUERY_TYPES,
  DATA_ASSETS_TYPES,
  ALERT_SEVERITY,
} from 'constants/constants.js';
import { ReactComponent as TableIcon } from 'assets/img/icons/projectTreeIcons/table20.svg';
import { ReactComponent as DatasetIcon } from 'assets/img/icons/projectTreeIcons/dataset.svg';

const INPUT_HEIGHT = 52;

const DATA_ASSETS_CONTROL_ELEMENTS = {
  table: {
    id: 'tableDataAsset',
    label: 'Data assets',
    placeholder: 'Start typing table name...',
  },
  dataset: {
    id: 'datasetDataAsset',
    label: 'Data assets',
    placeholder: 'Start typing dataset name...',
  },
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginBottom: theme.spacing(3),
    '& .button': {
      height: 32,
      marginRight: theme.spacing(4),
      '&.isSelected': {
        background: theme.palette.secondary.main,
      },
    },
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& .chip': {
      margin: '0 4px 4px 0',
      '& div': {
        maxWidth: 400,
      },
    },
  },
}));

const DataAssetsSection = ({ defaultDataAssets, onChangeDataAssets }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(
    defaultDataAssets?.[0]?.type || DATA_ASSETS_TYPES.dataset
  );
  const [selectedTables, setSelectedTables] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const selectedDataAssets = useMemo(() => {
    return [
      ...selectedTables.map((table) => ({
        type: DATA_ASSETS_TYPES.table,
        uuid: table.uuid,
      })),
      ...selectedDatasets.map((dataset) => ({
        type: DATA_ASSETS_TYPES.dataset,
        uuid: dataset.uuid,
      })),
    ];
  }, [selectedTables, selectedDatasets]);

  useEffect(() => {
    onChangeDataAssets(selectedDataAssets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataAssets]);

  useEffect(() => {
    if (defaultDataAssets) {
      const tables = defaultDataAssets.filter(
        (item) => item.type === DATA_ASSETS_TYPES.table
      );
      const datasets = defaultDataAssets.filter(
        (item) => item.type === DATA_ASSETS_TYPES.dataset
      );

      setSelectedTables(
        tables.map((item) => ({
          uuid: item.uuid,
          table: item.data.tableName,
          dataset: item.data.datasetName,
        }))
      );
      setSelectedDatasets(
        datasets.map((item) => ({
          uuid: item.uuid,
          name: item.data.datasetName,
        }))
      );
    }
  }, [defaultDataAssets]);

  const handleSelectTables = useCallback((values) => {
    setSelectedTables(
      values.map((item) => ({
        uuid: item.data.uuid,
        table: item.data.table,
        dataset: item.data.dataset,
      }))
    );
  }, []);

  const handleSelectDatasets = useCallback((values) => {
    setSelectedDatasets(
      values.map((item) => ({
        uuid: item.value,
        name: item.label,
      }))
    );
  }, []);

  return (
    <section>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => setSelectedType(DATA_ASSETS_TYPES.dataset)}
          text='Datasets'
          variant={BUTTON_VARIANT.outlined}
          fullWidth={false}
          disableRipple={true}
          startIcon={<DatasetIcon />}
          className={clsx(
            'button',
            selectedType === DATA_ASSETS_TYPES.dataset && 'isSelected'
          )}
        />
        <Button
          onClick={() => setSelectedType(DATA_ASSETS_TYPES.table)}
          text='Tables'
          variant={BUTTON_VARIANT.outlined}
          fullWidth={false}
          disableRipple={true}
          startIcon={<TableIcon />}
          className={clsx(
            'button',
            selectedType === DATA_ASSETS_TYPES.table && 'isSelected'
          )}
        />
      </div>

      {selectedType === DATA_ASSETS_TYPES.dataset && (
        <DatasetAssets
          selectedDatasets={selectedDatasets}
          onChangeDatasets={handleSelectDatasets}
        />
      )}

      {selectedType === DATA_ASSETS_TYPES.table && (
        <TablesAssets
          selectedTables={selectedTables}
          onChangeTables={handleSelectTables}
        />
      )}

      <AlertSection
        selectedDatasets={selectedDatasets}
        selectedTables={selectedTables}
      />
    </section>
  );
};

const TablesAssets = ({ selectedTables, onChangeTables }) => {
  const { id, label, placeholder } = DATA_ASSETS_CONTROL_ELEMENTS.table;

  const getLabelFromResponseItem = (value) => {
    return getFullTableName(value.table, value.dataset);
  };
  const getValueFromResponseItem = (value) => {
    return value.uuid;
  };

  return (
    <AsyncMultiSearch
      id={id}
      onSelect={onChangeTables}
      searchUrl={QUERY_TYPES.tables}
      searchQueryParam='searchTable'
      getLabelFromResponseItem={getLabelFromResponseItem}
      getValueFromResponseItem={getValueFromResponseItem}
      placeholderName={placeholder}
      label={label}
      defaultSelectValue={selectedTables}
      inputHeight={INPUT_HEIGHT}
    />
  );
};

const DatasetAssets = ({ selectedDatasets, onChangeDatasets }) => {
  const { id, label, placeholder } = DATA_ASSETS_CONTROL_ELEMENTS.dataset;
  const [datasets, setDatasets] = useState([]);

  const { data, isFetching } = useQuery(
    [QUERY_TYPES.datasets],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    if (data?.values.length > 0) {
      setDatasets(data.values);
    }
  }, [data?.values]);

  const getLabelFromResponseItem = (value) => {
    return value.name;
  };
  const getValueFromResponseItem = (value) => {
    return value.uuid;
  };

  return (
    <MultiSelect
      values={datasets}
      onSelect={onChangeDatasets}
      defaultSelectValues={selectedDatasets}
      getLabelFromResponseItem={getLabelFromResponseItem}
      getValueFromResponseItem={getValueFromResponseItem}
      isLoading={isFetching}
      placeholderName={placeholder}
      inputHeight={INPUT_HEIGHT}
      searchKey={id}
      label={label}
    />
  );
};

const AlertSection = ({ selectedDatasets = [], selectedTables = [] }) => {
  const classes = useStyles();
  const hasSection = selectedDatasets.length > 0 || selectedTables.length > 0;

  return (
    <>
      {hasSection ? (
        <Alert
          alertComponent={
            <div className={classes.chipsContainer}>
              <Typography>Selected data assets:</Typography>

              <div>
                {selectedDatasets.map((dataset) => (
                  <Chip
                    key={dataset.uuid}
                    label={
                      <div>
                        <span className='txt-mainDark-13-700'>dataset:</span>{' '}
                        {dataset.name}
                      </div>
                    }
                    color={CHIP_COLOR.primary}
                    className='chip'
                  />
                ))}
              </div>

              <div>
                {selectedTables.map((table) => (
                  <Chip
                    key={table.uuid}
                    label={
                      <div className='textOverflow'>
                        <span className='txt-mainDark-13-700'>table:</span>{' '}
                        {getFullTableName(table.table, table.dataset)}
                      </div>
                    }
                    color={CHIP_COLOR.primary}
                    className='chip'
                  />
                ))}
              </div>
            </div>
          }
          severity={ALERT_SEVERITY.info}
        />
      ) : null}
    </>
  );
};

export { DataAssetsSection };
