import React from 'react';
import clsx from 'clsx';
import { Table, TableBody, TableHead } from '@mui/material';
import { useStyles } from './StatsWidget.styles.js';
import { StyledTableRow, StyledTableCell } from 'Components/components.js';

const HEAD_CELLS = [
  {
    LABEL: 'Saving options',
    ALIGN: 'left',
    CLASS_NAME: 'ps-0',
  },
  {
    LABEL: 'Est. Savings',
    ALIGN: 'right',
  },
  {
    LABEL: 'Optimized costs',
    ALIGN: 'right',
    CLASS_NAME: 'pe-0',
  },
];

const OptimizedCostsWidget = ({ children = null }) => {
  const classes = useStyles();

  const hasValidChildren =
    React.isValidElement(children) &&
    children.type &&
    typeof children.type === 'function' &&
    children.type(children.props) !== null;

  if (!hasValidChildren) {
    return null;
  }

  return (
    <section className={clsx(classes.widget, 'withMinHeight')}>
      <Table>
        <OptimizedCostsTableHead />
        <TableBody>{children}</TableBody>
      </Table>
    </section>
  );
};

const OptimizedCostsTableHead = () => {
  return (
    <TableHead>
      <StyledTableRow>
        {HEAD_CELLS.map((headCell) => (
          <StyledTableCell
            key={headCell.LABEL}
            align={headCell.ALIGN}
            className={clsx('greyText pt-0', headCell?.CLASS_NAME)}
          >
            {headCell.LABEL}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export { OptimizedCostsWidget };
