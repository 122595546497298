import React from 'react';
import { CostsStatsGraph } from 'Components/components.js';
import { StatsPercentBlock } from './components.js';
import { useCostsContext } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as Speed } from 'assets/img/icons/speed.svg';

const CostsStatsGraphView = () => {
  const { totalProjectCosts, deadEndTablesStats, altPlanData } =
    useCostsContext();

  const deadEndTablesCosts = deadEndTablesStats?.costs || 0;
  const altPipelineBillingCosts = altPlanData?.costs || 0;
  const altProjectBillingCosts =
    totalProjectCosts - altPlanData?.altPlanCosts || 0;

  const maxCost = Math.max(
    deadEndTablesCosts,
    altPipelineBillingCosts,
    altProjectBillingCosts < totalProjectCosts ? altProjectBillingCosts : 0
  );

  const diff = totalProjectCosts > 0 ? (maxCost * 100) / totalProjectCosts : 0;

  return (
    <>
      <StatsPercentBlock
        diff={diff}
        costData={maxCost}
        linkPath={{ pathname: AppRoutes.Costs.path }}
        Icon={Speed}
      />
      <CostsStatsGraph />
    </>
  );
};

export { CostsStatsGraphView };
