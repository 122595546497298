import { useMemo } from 'hooks/hooks.js';
import { abbreviateNumber, numberFormat } from 'utils/helpers/helpers.js';

const DIGITS = 1;

const CostLabel = ({ value, withAbbreviateNumber = false }) => {
  const cost = useMemo(() => {
    if (!value) {
      return '< $0.01';
    }

    if (withAbbreviateNumber) {
      return `$${abbreviateNumber(value, DIGITS)}`;
    }

    return `$${numberFormat(value)}`;
  }, [value, withAbbreviateNumber]);

  return <div>{cost}</div>;
};

export { CostLabel };
