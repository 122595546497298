import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useHighchartsStyles } from './Highcharts.styles.js';
import {
  abbreviateNumber,
  getFormattedThreshold,
  getUnixDateByStr,
} from 'utils/helpers/helpers.js';
import { getDatetimeFormat } from './libs/utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import projectTheme from 'theme.js';

const DataQualityRulesExecution = ({
  data,
  frequencySeconds = null,
  selectedDatetime = null,
  graphHeight = GRAPH_HEIGHT.extraSmall,
}) => {
  const classes = useHighchartsStyles();
  const graphRef = useRef(null);

  const formattedData = useMemo(() => {
    return data.map(({ datetime, value, isAnomaly, min, max }) => ({
      x: getUnixDateByStr(datetime),
      y: value,
      isAnomaly,
      min,
      max,
    }));
  }, [data]);

  const selectedPointData = useMemo(() => {
    if (!selectedDatetime) {
      return [];
    }

    const x = getUnixDateByStr(selectedDatetime);
    const y = formattedData.find(
      (item) => item.x === getUnixDateByStr(selectedDatetime)
    )?.y;

    if (y === undefined) {
      return [];
    }

    return [{ x, y }];
  }, [formattedData, selectedDatetime]);

  const anomalyData = useMemo(() => {
    return data
      .filter(({ isAnomaly }) => isAnomaly)
      .map(({ datetime }) => ({
        x: getUnixDateByStr(datetime),
        y: 1,
      }));
  }, [data]);

  const chartOptions = {
    chart: {
      height: graphHeight,
    },
    title: { text: undefined },
    xAxis: {
      type: 'datetime',
      visible: false,
    },
    yAxis: [
      {
        title: { text: undefined },
        labels: { enabled: false },
        gridLineWidth: 0.5,
      },
      {
        visible: false,
        max: 1,
        opposite: true,
      },
    ],
    tooltip: {
      shape: 'square',
      crosshairs: true,
      shared: true,
      useHTML: true,
      formatter: function () {
        const markerColor = this.isAnomaly
          ? projectTheme.palette.error.main
          : Highcharts.getOptions().colors[0];
        const { formattedMin, formattedMax } = getFormattedThreshold(
          this.min,
          this.max
        );

        const thresholdText =
          this.min === 0 && this.max === 0
            ? 'No data available'
            : `${formattedMin} - ${formattedMax}`;

        return `
          <div style="font-size: 0.8em">${getDatetimeFormat(
            this.x,
            frequencySeconds
          )}</div>
          <div>
            <span style="color: ${markerColor}">
              ●
            </span>
            value: <b>${abbreviateNumber(this.y)}</b>
          </div>
          <div>
            <span style="color: ${Highcharts.getOptions().colors[0]}">
              ●
            </span>
            threshold: <b>${thresholdText}</b>
          </div>
        `;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
    },
    series: [
      {
        type: 'line',
        data: formattedData,
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
      {
        type: 'scatter',
        data: selectedPointData,
        marker: {
          symbol: 'circle',
          radius: 3,
          fillColor: Highcharts.getOptions().colors[0],
          lineWidth: 5,
          lineColor: 'rgba(47, 80, 255, 0.3)',
        },
        states: {
          inactive: { enabled: false },
        },
        zIndex: 1,
        enableMouseTracking: false,
      },
      {
        type: 'column',
        data: anomalyData,
        color: projectTheme.palette.error.main,
        yAxis: 1,
        pointPlacement: 'on',
        borderWidth: 0,
        opacity: 0.3,
        zIndex: 0,
        pointPadding: 0,
        groupPadding: 0,
        enableMouseTracking: false,
      },
    ],
  };

  return (
    <div className={classes.container}>
      <HighchartsReact
        ref={graphRef}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

export { DataQualityRulesExecution };
