import {
  useBqBillingData,
  useEffect,
  useMemo,
  useQuery,
  useState,
} from 'hooks/hooks.js';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment/moment';
import { useDateContext, useUserInfo } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useCostsStatsPrevious = (enabled = true) => {
  const { isCurrentProjectDemo } = useUserInfo();
  const { isWelcome } = useBqBillingData();
  const isAllowedSRequest = !isWelcome || isCurrentProjectDemo;
  const { startDate, endDate } = useDateContext();
  const [startDateBefore, setStartDateBefore] = useState();
  const [endDateBefore, setEndDateBefore] = useState();

  useEffect(() => {
    if (startDate && endDate) {
      const startMoment = moment(startDate).utc();
      const endMoment = moment(endDate).utc();
      const diffMomentDays = Math.floor(
        (endMoment.diff(startMoment, 's') + 1) / 86400
      );
      const startMomentBefore = moment(startMoment).subtract(
        diffMomentDays,
        'd'
      );
      const endMomentBefore = moment(startMomentBefore).add(
        diffMomentDays - 1,
        'd'
      );

      setStartDateBefore(startOfDay(startMomentBefore.toDate()));
      setEndDateBefore(endOfDay(endMomentBefore.toDate()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { data } = useQuery(
    [QUERY_TYPES.jobProjectCost, startDateBefore, endDateBefore],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    {
      enabled:
        startDateBefore !== undefined &&
        endDateBefore !== undefined &&
        enabled &&
        isAllowedSRequest,
    }
  );

  const costsStatsPrevious = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { costsStatsPrevious };
};

export { useCostsStatsPrevious };
