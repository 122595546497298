import { Tooltip } from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { ReactComponent as ArrowUp } from 'assets/img/icons/arrows/square-arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/img/icons/arrows/square-arrow-down.svg';
import { ReactComponent as ArrowVertical } from 'assets/img/icons/arrows/square-arrow-vertical.svg';

const THRESHOLD = 0.05;

const ComparisonValues = ({
  primaryValue,
  secondaryValue,
  threshold = THRESHOLD,
  tip = null,
}) => {
  const allowedDifference = primaryValue * threshold;

  const isPrimaryValueGreater =
    primaryValue > secondaryValue + allowedDifference;
  const isPrimaryValueLower = primaryValue < secondaryValue - allowedDifference;

  const Icon = isPrimaryValueGreater
    ? ArrowDown
    : isPrimaryValueLower
    ? ArrowUp
    : ArrowVertical;
  const colorType = isPrimaryValueGreater
    ? 'txt-blue-13-500'
    : isPrimaryValueLower
    ? 'txt-dark-red-13-500'
    : 'txt-grey-13-500';

  return (
    <Tooltip title={tip} hasMaxWidthTooltip={true}>
      <>
        <Icon />{' '}
        <span className={colorType}>${numberFormat(secondaryValue)}</span>
      </>
    </Tooltip>
  );
};

export { ComparisonValues };
