import React, { forwardRef } from 'react';
import { useEffect, useMemo, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment/moment';
import {
  subDays,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  endOfDay,
  startOfDay,
} from 'date-fns';
import { useStyles } from './DateRangePicker.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { useDateContext } from 'context/context.js';
import { DAYS_LIMIT } from 'constants/constants.js';
import { isSameDates } from 'utils/helpers/helpers.js';
import { ReactComponent as CheckMark } from 'assets/img/dropdownIcons/checkMark.svg';
import { ReactComponent as Icon } from 'assets/img/icons/sidebarIcons/arrowDown.svg';

export const BOTTOM_END = 'bottom-end';
export const BOTTOM_START = 'bottom-start';

const DateRangePicker = ({
  defaultStartDate = null,
  defaultEndDate = null,
  onChooseDates = null,
  calendarPlacement = BOTTOM_END,
  daysLimit = DAYS_LIMIT.month,
  isDisabled = false,
}) => {
  const classes = useStyles({ isDisabled });
  const {
    startDate: from,
    endDate: to,
    onChooseDates: handleChangeDates,
  } = useDateContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectCustom, setSelectCustom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [excludeDateIntervals, setExcludeDateIntervals] = useState([]);

  useEffect(() => {
    if (!defaultStartDate && !defaultEndDate) {
      setStartDate(from);
      setEndDate(to);
    } else {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
    }
  }, [defaultEndDate, defaultStartDate, from, to]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? endOfDay(end) : end);
    setSelectedRange(null);
  };

  const visibleLastMonth = useMemo(() => {
    return daysLimit > DAYS_LIMIT.month;
  }, [daysLimit]);

  const CustomInput = forwardRef(({ value, onClick, selectedRange }, ref) => (
    <div className={classes.datePickerInput} onClick={onClick} ref={ref}>
      {selectedRange !== null && `${selectedRange.label}, `}
      {value}
      {!isDisabled && <Icon style={{ marginLeft: 16 }} />}
    </div>
  ));

  const Ranges = [
    {
      id: 'today',
      label: 'Today',
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    },
    {
      id: 'yesterday',
      label: 'Yesterday',
      start: startOfDay(addDays(new Date(), -1)),
      end: endOfDay(addDays(new Date(), -1)),
    },
    {
      id: 'week',
      label: 'This week',
      start: startOfWeek(new Date()),
      end: endOfDay(new Date()),
    },
    {
      id: 'month',
      label: 'This month',
      start: startOfMonth(new Date()),
      end: endOfDay(new Date()),
    },
    {
      id: 'seven-days',
      label: 'Last 7 days',
      start: startOfDay(subDays(new Date(), 6)),
      end: endOfDay(new Date()),
    },
    {
      id: 'fourteen-days',
      label: 'Last 14 days',
      start: startOfDay(subDays(new Date(), 13)),
      end: endOfDay(new Date()),
    },
    {
      id: 'thirty-days',
      label: 'Last 30 days',
      start: startOfDay(subDays(new Date(), 29)),
      end: endOfDay(new Date()),
    },
    ...(visibleLastMonth
      ? [
          {
            id: 'last-month',
            label: 'Last month',
            start: startOfMonth(addMonths(new Date(), -1)),
            end: endOfMonth(addMonths(new Date(), -1)),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (startDate && !endDate && !excludeDateIntervals.length) {
      const startExcludeDate = addDays(startDate, daysLimit);
      if (startExcludeDate < new Date()) {
        setExcludeDateIntervals([{ start: startExcludeDate, end: new Date() }]);
      }
    }
    if (startDate && endDate) {
      if (excludeDateIntervals.length) {
        setExcludeDateIntervals([]);
      }
      const range = Ranges.find(
        (it) => isSameDates(it.start, startDate) && isSameDates(it.end, endDate)
      );
      if (range && range.id !== selectedRange?.id && !selectCustom) {
        setSelectedRange(range);
      }
    }
  }, [startDate, endDate, Ranges, excludeDateIntervals]);
  const isSelected = (range) => range.id === selectedRange?.id;
  const selectedCustom = () => {
    setSelectCustom(true);
    setSelectedRange(null);
  };
  const changeRange = (range) => {
    setStartDate(range.start);
    setEndDate(range.end);
    setSelectCustom(false);
    setSelectedRange(range);
    setExcludeDateIntervals([]);
  };
  const handleChange = () => {
    setExcludeDateIntervals([]);
    setIsOpen(false);
    if (!endDate) setEndDate(endOfDay(startDate));

    if (onChooseDates) {
      onChooseDates([startDate, endDate ? endDate : endOfDay(startDate)]);
    } else {
      handleChangeDates([startDate, endDate ? endDate : endOfDay(startDate)]);
    }
  };
  const cancel = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setIsOpen(false);
    setSelectedRange(null);
    setExcludeDateIntervals([]);
  };

  return (
    <DatePicker
      wrapperClassName={classes.datePicker}
      customInput={<CustomInput selectedRange={selectedRange} />}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      dateFormat='MMM d, yyyy'
      popperClassName={classes.calendarContainer}
      popperPlacement={calendarPlacement}
      popperModifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: false,
          },
        },
      ]}
      showYearDropdown
      dateFormatCalendar='MMMM'
      yearDropdownItemNumber={5}
      scrollableYearDropdown
      disabledKeyboardNavigation
      minDate={subDays(new Date(), daysLimit)}
      maxDate={moment().toDate()}
      excludeDateIntervals={excludeDateIntervals}
      shouldCloseOnSelect={false}
      onInputClick={() => setIsOpen(true)}
      onClickOutside={() => setIsOpen(false)}
      open={isOpen}
      openToDate={endDate || new Date()}
    >
      <div className={classes.buttonsContainer}>
        <Button
          onClick={cancel}
          className={classes.button}
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
        />
        <Button
          onClick={handleChange}
          className={classes.button}
          text='Apply'
          fullWidth={false}
        />
      </div>
      <div className={classes.listContainer}>
        <ul className={classes.list}>
          {Ranges.map((it) => (
            <li
              key={it.id}
              className={clsx(
                isSelected(it) && 'selected',
                'listItem txt-mainDark-13-500'
              )}
              onClick={() => changeRange(it)}
            >
              {it.label}
              {isSelected(it) && <CheckMark />}
            </li>
          ))}

          <li
            className={clsx(
              selectCustom && 'selected',
              'listItem txt-mainDark-13-500'
            )}
            onClick={() => selectedCustom()}
          >
            Custom
            {selectCustom && <CheckMark />}
          </li>
        </ul>
        <div className={classes.divider}></div>
      </div>
    </DatePicker>
  );
};

export { DateRangePicker };
