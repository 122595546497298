import React from 'react';
import { TableHeadWithCheckbox } from 'Components/components.js';
import {
  Principal,
  Project,
  Type,
  LastActive,
} from './libs/table-head.enum.js';

const HEAD_CELLS = [Principal, Project, Type, LastActive];

const TableHead = () => {
  return <TableHeadWithCheckbox headCells={HEAD_CELLS} />;
};

export { TableHead };
