import React from 'react';
import {
  useState,
  useEffect,
  useCreateDomain,
  useDebounce,
  useGetSlackChannelList,
  useMemo,
  useUpdateDomain,
} from 'hooks/hooks.js';
import { useModalStyles } from 'Pages/DataProdact/components/ModalBody.styles.js';
import {
  Alert,
  Button,
  BUTTON_VARIANT,
  Input,
  Select,
} from 'Components/components.js';
import { validateEmail } from 'utils/helpers/helpers.js';
import { ALERT_SEVERITY } from 'constants/constants.js';

const ERRORS = {
  invalid: 'Invalid email address',
};

const ALERT_TEXT = {
  slack:
    'To add Masthead Data app to a channel, open the channel, type `/invite @masthead_data` and hit `Enter`.',
};

const MESSAGES = {
  created: 'Domain is created',
  updated: 'Domain is updated',
};

const INPUT_IDS = {
  name: 'NAME',
  email: 'EMAIL',
  slackChannel: 'SLACK_CHANNEL',
};

const CONTROL_ELEMENTS = {
  name: {
    label: 'Name',
    id: INPUT_IDS.name,
    isRequired: true,
    placeholder: 'Enter domain name',
  },
  email: {
    label: 'Email',
    id: INPUT_IDS.email,
    isRequired: true,
    placeholder: 'Enter group email of a responsible team',
  },
  slackChannel: {
    label: 'Slack channel',
    id: INPUT_IDS.slackChannel,
    isRequired: false,
    placeholder: 'Select Slack channel',
  },
};

const REQUIRED_FIELDS = Object.values(CONTROL_ELEMENTS)
  .filter((field) => field.isRequired)
  .map((field) => field.id);

const CreateDomainModalBody = ({ domain, closeModal, onChangeActionText }) => {
  const classes = useModalStyles();
  const { uuid, name, email, slackChannel } = domain || {};
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [error, setError] = useState(null);

  const [newDomain, setNewDomain] = useState({
    [INPUT_IDS.name]: name || '',
    [INPUT_IDS.email]: email || '',
    [INPUT_IDS.slackChannel]: slackChannel?.channelName || null,
  });

  const debouncedEmail = useDebounce(newDomain[INPUT_IDS.email], 500);

  const domainParams = useMemo(() => {
    return {
      name: newDomain[INPUT_IDS.name].trim(),
      email: newDomain[INPUT_IDS.email].trim(),
      slackChannelName: newDomain[INPUT_IDS.slackChannel],
    };
  }, [newDomain]);

  const { createNewDomain } = useCreateDomain(domainParams);
  const { updateDomain } = useUpdateDomain({
    ...domainParams,
    domainUUID: uuid,
  });

  useEffect(() => {
    if (error?.length > 0) {
      setIsDisabledSaveButton(true);
      return;
    }

    const hasEmptyRequiredFields = REQUIRED_FIELDS.some(
      (field) => !newDomain[field]?.trim()
    );

    if (hasEmptyRequiredFields) {
      setIsDisabledSaveButton(true);
      return;
    }

    const defaultSlackChannel = slackChannel?.channelName || null;

    const isDataUnchanged =
      newDomain[INPUT_IDS.name].trim() === name &&
      newDomain[INPUT_IDS.email].trim() === email &&
      newDomain[INPUT_IDS.slackChannel] === defaultSlackChannel;

    if (isDataUnchanged) {
      setIsDisabledSaveButton(true);
      return;
    }

    setIsDisabledSaveButton(false);
  }, [email, name, slackChannel, error, newDomain]);

  useEffect(() => {
    const trimmedEmail = debouncedEmail.trim();

    if (trimmedEmail) {
      if (!validateEmail(trimmedEmail)) {
        setError(ERRORS.invalid);
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [debouncedEmail]);

  const handleChangeDomain = (id, value) => {
    setNewDomain((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const saveDomain = () => {
    if (uuid) {
      updateDomain()
        .then((data) => {
          if (data && data.success) {
            onChangeActionText(MESSAGES.updated);
          }
        })
        .then(() => closeModal());
    } else {
      createNewDomain()
        .then((data) => {
          if (data && data.success) {
            onChangeActionText(MESSAGES.created);
          }
        })
        .then(() => closeModal());
    }
  };

  return (
    <section className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <NameInput
          value={newDomain[INPUT_IDS.name]}
          onChangeName={(value) => handleChangeDomain(INPUT_IDS.name, value)}
        />

        <EmailInput
          value={newDomain[INPUT_IDS.email]}
          error={error}
          onChangeEmail={(value) => handleChangeDomain(INPUT_IDS.email, value)}
        />

        <SlackChannelSelect
          defaultValue={
            slackChannel
              ? {
                  value: slackChannel.channelName,
                  label: slackChannel.channelName,
                }
              : ''
          }
          onChangeSlackChannel={(value) =>
            handleChangeDomain(INPUT_IDS.slackChannel, value)
          }
        />
      </div>

      <div className={classes.footer}>
        <Button
          text='Save'
          fullWidth={false}
          className='button'
          onClick={saveDomain}
          isDisabled={isDisabledSaveButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </section>
  );
};

const NameInput = ({ value, onChangeName }) => {
  const { id, label, isRequired, placeholder } = CONTROL_ELEMENTS.name;

  return (
    <Input
      id={id}
      value={value}
      onChange={onChangeName}
      label={label}
      isRequired={isRequired}
      autoFocus={true}
      placeholder={placeholder}
    />
  );
};

const EmailInput = ({ value, error, onChangeEmail }) => {
  const { id, label, isRequired, placeholder } = CONTROL_ELEMENTS.email;

  return (
    <div>
      <Input
        id={id}
        value={value}
        onChange={onChangeEmail}
        label={label}
        isRequired={isRequired}
        placeholder={placeholder}
        errorComponent={error}
      />
    </div>
  );
};

const SlackChannelSelect = ({ defaultValue, onChangeSlackChannel }) => {
  const { id, label, isRequired, placeholder } = CONTROL_ELEMENTS.slackChannel;
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const { slackChannelList, refetch } = useGetSlackChannelList();

  const options = useMemo(() => {
    return slackChannelList.map(({ channelName }) => ({
      value: channelName,
      label: channelName,
    }));
  }, [slackChannelList]);

  useEffect(() => {
    onChangeSlackChannel(selectedValue?.value || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const onOpenSelect = () => {
    refetch();
  };

  const handleChangeSelectedValue = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
      <Select
        id={id}
        label={label}
        value={selectedValue}
        onChange={handleChangeSelectedValue}
        options={options}
        isRequired={isRequired}
        onOpen={onOpenSelect}
        emptyValue={placeholder}
      />

      <Alert alertComponent={ALERT_TEXT.slack} severity={ALERT_SEVERITY.info} />
    </div>
  );
};

export { CreateDomainModalBody };
