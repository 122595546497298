import React from 'react';
import clsx from 'clsx';
import { useLocation, useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  AsyncGeneralSearch,
  AsyncMultiSearch,
  SearchDropdown,
  TableDatasetSearch,
} from 'Components/components.js';
import { Search as DataProductSearch } from 'Pages/DataProdact/components/DataProductTab/components/components.js';
import { usePipelinePageContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  SEARCH_EMAIL,
  SELECTED_TABLE,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_TAGS,
  SELECTED_LABEL,
  SEARCH_LABEL,
  SELECTED_DESTINATION,
  SEARCH_DESTINATION_TABLE,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SEARCH_PARAMS,
} from 'constants/constants.js';

const getSearchOptions = (path) => {
  const baseSearchOptions = {
    TABLE: 'Table',
    DESTINATION: 'Destination',
    EMAIL: 'Principal email',
    TAGS: 'Pipeline tags / labels',
    LABELS: 'Table labels',
    POWER_BI_REPORT: 'Power BI report',
    POWER_BI_WORKSPACE: 'Power BI workspace',
  };

  if (path === AppRoutes.Costs.path) {
    baseSearchOptions.DATA_PRODUCT = 'Data product';
  }

  return baseSearchOptions;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    '& .searchInput': {
      flexGrow: 1,
    },
    '& .input': {
      flexGrow: 1,
    },
  },
}));

const PAGE_EVENT = `${AMPL_PAGE_EVENT.pipeline} ->`;

const getCurrentSearchType = (searchOptions, values) => {
  for (const searchKey in values) {
    const searchValue = values[searchKey];
    if (
      (searchValue && !Array.isArray(searchValue)) ||
      (Array.isArray(searchValue) && searchValue.length > 0)
    ) {
      switch (searchKey) {
        case SELECTED_EMAIL:
        case SEARCH_EMAIL:
          return searchOptions.EMAIL;
        case SELECTED_TAGS:
          return searchOptions.TAGS;
        case SELECTED_LABEL:
        case SEARCH_LABEL:
          return searchOptions.LABELS;
        case SELECTED_DESTINATION:
        case SEARCH_DESTINATION_TABLE:
          return searchOptions.DESTINATION;
        case SELECTED_POWER_BI_REPORT:
          return searchOptions.POWER_BI_REPORT;
        case SELECTED_POWER_BI_WORKSPACE:
          return searchOptions.POWER_BI_WORKSPACE;
        case SEARCH_PARAMS.SEARCH_DATA_PRODUCT:
        case SEARCH_PARAMS.SELECTED_DATA_PRODUCT:
          return searchOptions.DATA_PRODUCT;
        default:
          return searchOptions.TABLE;
      }
    }
  }

  return searchOptions.TABLE;
};

const PipelinesSearch = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const searchOptions = getSearchOptions(pathname);
  const { searchValues, handleChangeSearchValues } = usePipelinePageContext();
  const [selectedSearch, setSelectedSearch] = useState(
    getCurrentSearchType(searchOptions, searchValues)
  );

  const handleChangeSelectedSearch = (value) => {
    setSelectedSearch(value);
    handleChangeSearchValues(null, null);
  };

  const handleSearchStringValue = (searchKey, value) => {
    const newSearchValue =
      searchKey === SELECTED_DESTINATION ? { dataset: value } : value;
    handleChangeSearchValues(searchKey, newSearchValue || '');

    if (value?.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> ${value}`);
    }
  };

  const handleSearchMultiValue = (searchKey, value) => {
    handleChangeSearchValues(searchKey, value);

    if (value?.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> multi search`);
    }
  };

  const handleSelectObjectValue = (searchKey, value) => {
    handleChangeSearchValues(searchKey, value);

    if (value) {
      amplEvent(
        `${PAGE_EVENT} ${searchKey} -> ${getFullTableName(
          value?.table,
          value?.dataset
        )}`
      );
    }
  };

  const handleAsyncLabelSearchGetLabelFromResponseItem = (value) => {
    return `${value.key} : ${value.value}`;
  };

  const handleAsyncLabelSearchGetValueFromResponseItem = (value) => {
    return value;
  };
  const handleAsyncLabelSearchGetLabelFromRequestItem =
    handleAsyncLabelSearchGetLabelFromResponseItem;
  const handleAsyncLabelSearchGetValueFromRequestItem =
    handleAsyncLabelSearchGetValueFromResponseItem;

  const handleAsyncPowerBISelectGetLabelFromRequestItem = (value) => {
    return value.name;
  };

  const handleAsyncPowerBISelectGetValueFromRequestItem = (value) => {
    return value.name;
  };

  const searchConfig = {
    [searchOptions.EMAIL]: (
      <AsyncGeneralSearch
        onSearch={(value) => handleSearchStringValue(SEARCH_EMAIL, value)}
        onSelect={(value) => handleSearchStringValue(SELECTED_EMAIL, value)}
        defaultSelectValue={searchValues[SELECTED_EMAIL]}
        defaultSearchValue={searchValues[SEARCH_EMAIL]}
        searchUrl={QUERY_TYPES.jobProjectPrincipalEmails}
        searchQueryParam='searchPrincipalEmail'
        className='input'
        placeholderName='Start typing principal email...'
        searchWithDropdown={true}
        searchKey={searchOptions.EMAIL}
      />
    ),
    [searchOptions.TAGS]: (
      <AsyncMultiSearch
        onSelect={(values) =>
          handleSearchMultiValue(
            SELECTED_TAGS,
            values.map((value) => value.value)
          )
        }
        defaultSelectValue={searchValues[SELECTED_TAGS]}
        searchUrl={QUERY_TYPES.jobProjectTagName}
        searchQueryParam='searchJobTagName'
        className='input'
        placeholderName='Start typing pipeline tag / label...'
        searchWithDropdown={true}
        searchKey={searchOptions.TAGS}
      />
    ),
    [searchOptions.LABELS]: (
      <AsyncGeneralSearch
        onSearch={(value) => handleSearchStringValue(SEARCH_LABEL, value)}
        onSelect={(value) => handleSearchStringValue(SELECTED_LABEL, value)}
        defaultSelectValue={searchValues[SELECTED_LABEL]}
        defaultSearchValue={searchValues[SEARCH_LABEL]}
        searchUrl={QUERY_TYPES.projectLabels}
        searchQueryParam='searchTableLabel'
        className='input'
        placeholderName='Start typing label...'
        searchWithDropdown={true}
        searchKey={searchOptions.LABELS}
        getLabelFromResponseItem={
          handleAsyncLabelSearchGetLabelFromResponseItem
        }
        getValueFromResponseItem={
          handleAsyncLabelSearchGetValueFromResponseItem
        }
        getLabelFromRequestItem={handleAsyncLabelSearchGetLabelFromRequestItem}
        getValueFromRequestItem={handleAsyncLabelSearchGetValueFromRequestItem}
      />
    ),
    [searchOptions.DESTINATION]: (
      <TableDatasetSearch
        onTableChange={(value) =>
          handleSelectObjectValue(SELECTED_DESTINATION, value)
        }
        onDatasetChange={(value) =>
          handleSearchStringValue(SELECTED_DESTINATION, value)
        }
        onSearch={(value) =>
          handleSearchStringValue(SEARCH_DESTINATION_TABLE, value)
        }
        defaultDataset={searchValues[SELECTED_DESTINATION]?.dataset || ''}
        defaultTable={searchValues[SELECTED_DESTINATION]?.table || ''}
        defaultSearchValue={searchValues[SEARCH_DESTINATION_TABLE]}
        className='input'
        placeholderName='Start typing destination name...'
        searchWithDropdown={true}
        searchKey={searchOptions.DESTINATION}
      />
    ),
    [searchOptions.DATA_PRODUCT]: (
      <DataProductSearch
        onSearch={(value) =>
          handleSearchStringValue(SEARCH_PARAMS.SEARCH_DATA_PRODUCT, value)
        }
        onSelect={(value) =>
          handleSearchStringValue(SEARCH_PARAMS.SELECTED_DATA_PRODUCT, value)
        }
        searchWithDropdown={true}
        className='input'
      />
    ),
    [searchOptions.POWER_BI_REPORT]: (
      <AsyncGeneralSearch
        onSearch={null}
        onSelect={(value) =>
          handleSearchStringValue(SELECTED_POWER_BI_REPORT, value)
        }
        defaultSelectValue={searchValues[SELECTED_POWER_BI_REPORT]}
        searchUrl={QUERY_TYPES.powerBIReports}
        searchQueryParam='search'
        queryParams={{ forList: true }}
        className='input'
        placeholderName='Start typing Power BI report name...'
        searchWithDropdown={true}
        searchKey={searchOptions.POWER_BI_REPORT}
        getLabelFromRequestItem={
          handleAsyncPowerBISelectGetLabelFromRequestItem
        }
        getValueFromRequestItem={
          handleAsyncPowerBISelectGetValueFromRequestItem
        }
      />
    ),
    [searchOptions.POWER_BI_WORKSPACE]: (
      <AsyncGeneralSearch
        onSearch={null}
        onSelect={(value) =>
          handleSearchStringValue(SELECTED_POWER_BI_WORKSPACE, value)
        }
        defaultSelectValue={searchValues[SELECTED_POWER_BI_WORKSPACE]}
        searchUrl={QUERY_TYPES.powerBIWorkspaces}
        searchQueryParam='search'
        className='input'
        placeholderName='Start typing Power BI workspace name...'
        searchWithDropdown={true}
        searchKey={searchOptions.POWER_BI_WORKSPACE}
        getLabelFromRequestItem={
          handleAsyncPowerBISelectGetLabelFromRequestItem
        }
        getValueFromRequestItem={
          handleAsyncPowerBISelectGetValueFromRequestItem
        }
      />
    ),
    default: (
      <TableDatasetSearch
        onTableChange={(value) =>
          handleSelectObjectValue(SELECTED_TABLE, value)
        }
        onSearch={(value) => handleSearchStringValue(SEARCH_TABLE, value)}
        defaultDataset={searchValues[SELECTED_TABLE]?.dataset || ''}
        defaultTable={searchValues[SELECTED_TABLE]?.table || ''}
        defaultSearchValue={searchValues[SEARCH_TABLE]}
        className='input'
        placeholderName='Start typing table name...'
        searchWithDropdown={true}
        disableDatasets={true}
        searchKey={searchOptions.TABLE}
      />
    ),
  };

  const renderSearchField = () =>
    searchConfig[selectedSearch] || searchConfig.default;

  return (
    <div className={clsx(classes.container, 'searchInput')}>
      <SearchDropdown
        options={Object.values(searchOptions)}
        selectedOption={selectedSearch}
        onOptionClick={handleChangeSelectedSearch}
      />
      {renderSearchField()}
    </div>
  );
};

export { PipelinesSearch };
