import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processExecutedJobsData = (data) => {
  return {
    executedJobsByTime: data?.values || [],
  };
};

const useGetExecutedJobsByTime = ({ dataProductUUID }) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidUUID = getIsValidUUID(dataProductUUID);

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.executedJobsByTime, dataProductUUID, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append('dataProductUuid', dataProductUUID);
      params.append('from', startDateUtc);
      params.append('to', endDateUtc);

      return fetcherGet(url, params);
    },
    { enabled: isValidUUID }
  );

  const processedData = useMemo(() => {
    return processExecutedJobsData(data);
  }, [data]);

  return {
    executedJobsByTime: processedData.executedJobsByTime,
    isFetching,
  };
};

export { useGetExecutedJobsByTime };
