import React from 'react';
import {
  useState,
  useHistory,
  useQueryClient,
  useQuery,
  useChangeProject,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles, styled } from '@mui/styles';
import {
  CssBaseline,
  Drawer,
  List,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from 'service/services.js';
import { useProjectTree, useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import {
  Divider,
  MenuItems,
  ProjectsDropdown,
  Logo,
  ProjectTree,
  ScrollUpButton,
} from 'Components/components.js';
import { ReactComponent as DoubleArrowRight } from 'assets/img/icons/arrows/double-arrow-right.svg';
import Masthead from '../Logo/Masthead.svg';

const drawerWidth = 240;
const drawerMinWidth = 72;
const mainMargin = 32;
const searchTreeWidth = 320;
export const normalMainWidth =
  window.innerWidth - 2 * mainMargin - drawerMinWidth;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
  },
  itemsWrapper: {
    flex: 1,
  },
  openBtn: {
    '&.MuiIconButton-root': {
      maxWidth: drawerWidth,
      height: 32,
      paddingRight: 28,
      display: 'flex',
      justifyContent: 'end',
      backgroundColor: theme.palette.common.white,
      borderRadius: 0,
      borderTop: `0.5px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  rotateIcon: {
    transform: (props) => (props.isFixedSidebarOnClick ? 'rotate(180deg)' : ''),
    transition: 'transform 300ms ease',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: mainMargin,
    marginLeft: (props) => {
      let result = mainMargin;
      if (props.isFixedSidebar) {
        result += drawerWidth;
      } else {
        result += drawerMinWidth;
      }
      if (props.isOpenProjectTree) {
        result += searchTreeWidth;
      }
      return result;
    },
    width: '100%',
    minHeight: `calc(100vh - ${mainMargin * 2}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingRight: 18, // keep right padding when drawer closed
  },
  toolbarIcon: {
    margin: '32px auto 32px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    '&.MuiDrawer-paper': {
      minHeight: '100vh',
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      borderRight: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&::-webkit-scrollbar': {
        display: 'none' /* hide scrollbar for Chrome, Edge, Safari */,
      },
    },
  },
  drawerPaperClose: {
    '&.MuiDrawer-paper': {
      width: drawerMinWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  logoContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 11,
  },
  logo: {
    width: 32,
    height: 32,
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    style={{
      position: 'fixed',
      top: 0,
      left: 174,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 228,
    borderRadius: 12,
    filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16))',
    '& .divider': {
      '&:after': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    '& .userContainer': {
      backgroundColor: '#F7F8FC',
    },
    '& .menuCategory': {
      padding: 12,
      textTransform: 'uppercase',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: 12,
      fontFamily: 'Inter, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: '#050C2E',
      '&:hover': {
        background: '#fff',
        color: '#0000D2',
      },
      '&.userItems': {
        padding: '8px 12px',
        '&:hover': {
          background: '#F7F8FC',
        },
      },
    },
  },
}));

const Layout = (props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { user, setUser, isCurrentProjectDemo } = useUserInfo();
  const [isFixedSidebar, setIsFixedSidebar] = useState(false);
  const [isFixedSidebarOnClick, setIsFixedSidebarOnClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { isOpen: isOpenProjectTree } = useProjectTree();
  const classes = useStyles({
    isFixedSidebar,
    isOpenProjectTree,
    isFixedSidebarOnClick,
  });
  const queryClient = useQueryClient();
  const { selectedProject, changeProject } = useChangeProject();

  const { data: projectStatus } = useQuery(
    [QUERY_TYPES.projectStatus],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const onLogout = () => {
    signOut(auth).then(() => {
      queryClient.clear();
      auth.tenantId = null;
      setUser(null);
      history.push('/');
    });
  };

  const navigateToUsersPage = () => {
    history.push(AppRoutes.Users.path);
    handleClose();
    if (!isFixedSidebarOnClick) {
      setIsFixedSidebar(false);
      setIsOpen(false);
    }
  };

  const handleDrawerOpen = () => {
    if (!isFixedSidebarOnClick && !isFixedSidebar) {
      setIsOpen(true);
      handleDrawerFixed();
    }
  };

  const handleDrawerClose = () => {
    if (!isFixedSidebarOnClick && isFixedSidebar) {
      setIsOpen(false);
      handleClose();
      handleDrawerFixed();
    }
  };

  const handleDrawerFixed = () => setIsFixedSidebar((prev) => !prev);

  const handleDrawerFixedOnClick = () => {
    setIsFixedSidebarOnClick((prev) => !prev);
    if (!isFixedSidebar) {
      handleDrawerFixed();
    }
  };

  const navigateToHome = () => history.push('/', { ...history.location.state });
  const positionLeftTree = isFixedSidebar ? drawerWidth : drawerMinWidth;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        onMouseLeave={handleDrawerClose}
        onMouseOver={handleDrawerOpen}
        classes={{
          paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
        }}
        open={isOpen}
      >
        <div className={classes.toolbarIcon}>
          <span className={classes.logoContainer} onClick={navigateToHome}>
            <Logo className={classes.logo} />
            {isOpen && <img src={Masthead} alt='Masthead logo' />}
          </span>
        </div>

        <List className={classes.itemsWrapper}>
          <MenuItems open={isOpen} handleDropdown={handleClick} />
        </List>

        {Boolean(anchorEl) && (
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <ProjectsDropdown
              selectedProject={selectedProject}
              projectStatus={projectStatus?.value}
              onProjectClick={changeProject}
              className='menuCategory'
            />

            <Divider className='divider' />

            <div className='userContainer'>
              <div className='menuCategory txt-grey-11-500 pb-0'>User</div>
              <div
                className='pt-0 txt-grey-13-500'
                style={{ padding: '2px 12px 8px' }}
              >
                {user?.email}
              </div>
              {!isCurrentProjectDemo && (
                <MenuItem onClick={navigateToUsersPage} className='userItems'>
                  Manage users
                </MenuItem>
              )}
              <MenuItem onClick={onLogout} className='userItems'>
                Logout
              </MenuItem>
            </div>
          </StyledMenu>
        )}

        <IconButton
          className={classes.openBtn}
          onClick={handleDrawerFixedOnClick}
        >
          <DoubleArrowRight className={classes.rotateIcon} />
        </IconButton>
      </Drawer>

      <ProjectTree className={classes.searchTree} left={positionLeftTree} />

      <main className={classes.main}>{props.children}</main>

      <ScrollUpButton />
    </div>
  );
};

export { Layout };
