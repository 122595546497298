import React from 'react';
import { useEffect, useMemo } from 'hooks/hooks.js';
import { useStyles } from './DataProduct.styles.js';
import {
  PageHeader,
  Pagination,
  Tabs,
  Toolbar,
} from 'Components/components.js';
import { DataProductTab, DomainsTab } from './components/components.js';
import {
  DataProductsPageContextProvider,
  useDataProductsPageContext,
} from 'context/context.js';
import {
  DataProductTabs,
  DOCUMENT_TITLE,
  SEARCH_PARAMS,
} from 'constants/constants.js';

const PARAMS_TO_REMOVE = [
  SEARCH_PARAMS.SELECTED_DATA_PRODUCT,
  SEARCH_PARAMS.SEARCH_DATA_PRODUCT,
  SEARCH_PARAMS.SELECTED_DOMAIN,
  SEARCH_PARAMS.SEARCH_DOMAIN,
];

const DataProductsContainer = () => {
  return (
    <DataProductsPageContextProvider>
      <DataProduct />
    </DataProductsPageContextProvider>
  );
};

const DataProduct = () => {
  const classes = useStyles();
  const {
    currentTab,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationTotal,
    toolbarText,
  } = useDataProductsPageContext();

  const pageComponent = useMemo(() => {
    switch (currentTab) {
      case DataProductTabs.DATA_PRODUCTS:
        return <DataProductTab />;
      case DataProductTabs.DOMAINS:
        return <DomainsTab />;
      default:
        return <DataProductTab />;
    }
  }, [currentTab]);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dataProduct;
  }, []);

  return (
    <section className={classes.pageContainer}>
      <PageHeader hasDateRangePicker={false}>Data products</PageHeader>
      <Tabs tabs={DataProductTabs} paramsToRemove={PARAMS_TO_REMOVE} />

      <section className={classes.mainContainer}>{pageComponent}</section>

      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Toolbar actionText={toolbarText} />
    </section>
  );
};

export default DataProductsContainer;
