import React from 'react';
import {
  useState,
  useCallback,
  useStorageDatasetsList,
  useStorageStats,
  useEffect,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Costs/Costs.styles.js';
import {
  Backdrop,
  DatasetSearch,
  EmptyData,
  PipelinesDropdowns,
} from 'Components/components.js';
import { StorageTable } from './StorageTable/StorageTable.js';
import {
  usePipelinePageContext,
  useQueryParamsContext,
} from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  STORAGE_COSTS_EFFICIENCY_FILTER,
  StorageCostsDropdowns,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { ALTERNATIVE_PLAN_OPTION, SEARCH_PARAMS } from 'constants/constants.js';

const DEFAULT_SEARCH_VALUE = '';
const TRACKER_EVENT_TEXT = 'Costs page -> Storage tab ->';

const StorageOverview = () => {
  const classes = useStyles();
  const { page, rowsPerPage, handleChangePage, handleChangePaginationTotal } =
    usePipelinePageContext();
  const { queryParams } = useQueryParamsContext();

  const searchFromUrl = queryParams.get(SEARCH_PARAMS.SEARCH);
  const datasetFromURL = queryParams.get(SEARCH_PARAMS.DATASET);

  const [searchDataset, setSearchDataset] = useState(
    searchFromUrl || DEFAULT_SEARCH_VALUE
  );
  const [selectedDataset, setSelectedDataset] = useState(
    datasetFromURL || DEFAULT_SEARCH_VALUE
  );

  const [dropdownsValue, setDropdownsValue] = useState({
    [STORAGE_COSTS_EFFICIENCY_FILTER]: null,
  });

  const onlySavingsAvailable =
    dropdownsValue[STORAGE_COSTS_EFFICIENCY_FILTER]?.at(0).value ===
    ALTERNATIVE_PLAN_OPTION;

  const {
    storageDatasets,
    storageDatasetsTotal,
    isFetching: isFetchingStorageDatasets,
  } = useStorageDatasetsList({
    page,
    limit: rowsPerPage,
    dataset: selectedDataset,
    search: searchDataset,
    onlySavingsAvailable,
  });
  const { storageStatsData, isFetching: isFetchingStorageStats } =
    useStorageStats();
  const { storageCost } = storageStatsData || {};

  const isEmptyData =
    !storageDatasets.length &&
    !isFetchingStorageDatasets &&
    !searchDataset &&
    !selectedDataset;

  const isEmptySearch =
    !storageDatasets.length &&
    !isFetchingStorageDatasets &&
    (searchDataset || selectedDataset);

  useEffect(() => {
    handleChangePaginationTotal(storageDatasetsTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageDatasetsTotal]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const handleDatasetSelect = useCallback((value) => {
    handleChangePage({}, 0);
    setSelectedDataset(value);
    setSearchDataset(DEFAULT_SEARCH_VALUE);

    amplEvent(`${TRACKER_EVENT_TEXT} selected dataset -> ${value} `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDatasetSearch = useCallback((value) => {
    handleChangePage({}, 0);
    setSearchDataset(value);
    setSelectedDataset(DEFAULT_SEARCH_VALUE);

    amplEvent(`${TRACKER_EVENT_TEXT} search by -> ${value} `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.mainContainer}>
      {isEmptyData ? (
        <EmptyData title='No data for this period' />
      ) : (
        <>
          <PipelinesDropdowns
            existingDropdown={StorageCostsDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
          />
          <DatasetSearch
            onDatasetSearch={handleDatasetSearch}
            onDatasetSelect={handleDatasetSelect}
          />
          <StorageTable
            data={storageDatasets}
            isEmptySearch={isEmptySearch}
            storageCost={storageCost}
          />
        </>
      )}
      <Backdrop isOpen={isFetchingStorageDatasets || isFetchingStorageStats} />
    </div>
  );
};

export { StorageOverview };
