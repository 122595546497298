import {
  useGetSubscribers,
  usePagination,
  useParams,
  useEffect,
} from 'hooks/hooks.js';
import { Table, TableBody, TableContainer } from '@mui/material';
import {
  Pagination,
  StyledTableCell,
  StyledTableRow,
  TableLoader,
} from 'Components/components.js';
import { TableHead } from './TableHead.js';
import { TableRow } from './TableRow.js';
import { stringToColor } from 'utils/helpers/helpers.js';
import { SUBSCRIBER_COLORS } from 'Pages/DataProdact/constants/constants.js';

const DEFAULT_ROWS_PER_PAGE = 5;

const SubscribersTable = ({ onChangeTotalSubscribers }) => {
  const { uuid } = useParams();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false, 0, DEFAULT_ROWS_PER_PAGE);

  const { subscribers, totalSubscribers, isFetchingSubscribers } =
    useGetSubscribers({
      dataProductUUID: uuid,
      page,
      limit: rowsPerPage,
    });

  useEffect(() => {
    if (totalSubscribers > 0) {
      onChangeTotalSubscribers(totalSubscribers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSubscribers]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead />

          <TableBody className='position-relative'>
            {isFetchingSubscribers ? (
              <TableLoader />
            ) : totalSubscribers === 0 ? (
              <EmptySubscribers />
            ) : (
              subscribers.map((subscriber, index) => (
                <TableRow
                  subscriber={{
                    ...subscriber,
                    color:
                      index + 1 <= DEFAULT_ROWS_PER_PAGE
                        ? SUBSCRIBER_COLORS[index]
                        : stringToColor(subscriber.principal),
                  }}
                  key={`${subscriber.principal}/${subscriber.project}`}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalSubscribers}
        rowsPerPage={rowsPerPage}
        page={page}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const EmptySubscribers = () => {
  return (
    <StyledTableRow className='noBorder'>
      <StyledTableCell colSpan='100%' align='center'>
        <div className='txt-grey-13-500 text-italic'>No subscribers found</div>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { SubscribersTable };
