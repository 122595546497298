import { DictionaryTabs } from 'constants/constants.js';
import {
  DatasetsTabDropdowns,
  LookerTabDropdowns,
  TablesTabDropdowns,
} from '../constants/constants.js';

const getTabDropdowns = (currentTab, isLookerConnected) => {
  const dropdownsMapping = {
    [DictionaryTabs.TABLES]: TablesTabDropdowns,
    [DictionaryTabs.DATASETS]: DatasetsTabDropdowns,
    [DictionaryTabs.LOOKER]: isLookerConnected ? LookerTabDropdowns : null,
  };

  return dropdownsMapping[currentTab] || null;
};

export { getTabDropdowns };
