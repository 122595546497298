import React from 'react';
import { useQuery, useExpandContent, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import { Checkbox } from '@mui/material';
import {
  DATE_FORMAT,
  MAX_ITEM_IN_CELL,
  QUERY_TYPES,
} from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import {
  getAlertColorType,
  getAlertLabel,
  getFullTableName,
} from 'utils/helpers/helpers.js';
import { useIntegration } from 'context/context.js';
import {
  PositionLoader,
  Tooltip,
  StyledTableCell,
  StyledTableRow,
  Chip,
} from 'Components/components.js';
import { ReportsType, DASHBOARD_TYPE, LinkType } from '../enums/enums.js';
import { DictionaryLookerUpstreamTable } from './components.js';
import theme from 'theme.js';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';

const BLUE_COLOR = theme.palette.primary.main;
const MAX_ITEM_LENGTH = 40;

const DictionaryLookerDetails = ({
  report,
  isReportSelected,
  handleClickElement,
  highlightName,
  highlightDashboardElement,
  isDashboardElementSearch,
  highlightUpstream,
  isUpstreamSearch,
}) => {
  const { name, id, type, lookerId, createdAt, updatedAt, alertType } = report;
  const { integrations } = useIntegration();

  const linkType = useMemo(() => {
    return type === DASHBOARD_TYPE ? LinkType.DASHBOARD : LinkType.LOOK;
  }, [type]);

  const lookerUrl = useMemo(() => {
    return `${integrations?.looker?.clientUrl}/${linkType}/${lookerId}`;
  }, [integrations?.looker?.clientUrl, linkType, lookerId]);

  const { isDependenciesFetching, data: dependencies } = useQuery(
    [`${QUERY_TYPES.reportDependencies}/${id}/type/${type}/tables`],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const { isFetching: isDashboardElementsFetching, data: dashboardElements } =
    useQuery(
      [`${QUERY_TYPES.dashboardElements}/${id}/elements`],
      ({ queryKey }) => {
        const [url] = queryKey;
        return fetcherGet(url);
      },
      { enabled: type === DASHBOARD_TYPE }
    );

  const {
    content: shownUpstreamTables,
    handleChangeCollapse: handleChangeCollapseUpstreamTables,
    renderCollapsedItemsNumber: renderUpstreamTablesContent,
  } = useExpandContent(
    dependencies?.values,
    MAX_ITEM_IN_CELL,
    isUpstreamSearch
  );

  const {
    content: shownDashboardElements,
    handleChangeCollapse: handleChangeCollapseDashboardElements,
    renderCollapsedItemsNumber: renderDashboardElementsContent,
  } = useExpandContent(
    dashboardElements?.values,
    MAX_ITEM_IN_CELL,
    isDashboardElementSearch
  );

  const lookerElementType = useMemo(() => {
    return type === DASHBOARD_TYPE
      ? ReportsType.DASHBOARD_TYPE
      : ReportsType.LOOK_TYPE;
  }, [type]);

  if (isDashboardElementsFetching || isDependenciesFetching) {
    return <PositionLoader />;
  }

  return (
    <StyledTableRow
      role='checkbox'
      aria-checked={isReportSelected}
      tabIndex={-1}
      selected={isReportSelected}
    >
      <StyledTableCell className='checkboxContainer'>
        <Checkbox
          onClick={(event) => handleClickElement(event, report)}
          checked={isReportSelected}
          icon={<UncheckIcon />}
          checkedIcon={<CheckIcon />}
          className='checkbox'
        />
      </StyledTableCell>

      <StyledTableCell className='darkBold'>
        {name ? (
          highlightName(name)
        ) : (
          <span className='txt-grey-13-500'>No report name</span>
        )}
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        <a
          href={lookerUrl}
          target='_blank'
          rel='noreferrer'
          style={{ color: BLUE_COLOR }}
        >
          {lookerId}
        </a>
      </StyledTableCell>

      <StyledTableCell>
        <Chip
          label={getAlertLabel(alertType)}
          color={getAlertColorType(alertType)}
        />
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        {lookerElementType}
      </StyledTableCell>

      <StyledTableCell>
        {shownDashboardElements?.length ? (
          <div>
            {shownDashboardElements.map((element, i) => {
              const isLongDashboardElementName =
                element.length > MAX_ITEM_LENGTH;
              const shortDashboardElementName = isLongDashboardElementName
                ? `${element.substring(0, MAX_ITEM_LENGTH)}...`
                : element;
              const tooltipTitle = isLongDashboardElementName ? element : '';

              return (
                <Tooltip title={tooltipTitle} key={element + i}>
                  <div>
                    {highlightDashboardElement(shortDashboardElementName)}
                  </div>
                </Tooltip>
              );
            })}

            {dashboardElements?.values.length > MAX_ITEM_IN_CELL && (
              <div
                className='txt-grey-13-500'
                onClick={handleChangeCollapseDashboardElements}
                style={{ cursor: 'pointer' }}
              >
                {renderDashboardElementsContent()}
              </div>
            )}
          </div>
        ) : (
          <div className='txt-grey-13-500'>N/A</div>
        )}
      </StyledTableCell>

      <StyledTableCell className='blueText appLink'>
        {shownUpstreamTables.length > 0 && (
          <div>
            {shownUpstreamTables.map((item) => (
              <DictionaryLookerUpstreamTable
                key={getFullTableName(item.table, item.dataset)}
                item={item}
                maxItemLength={MAX_ITEM_LENGTH}
                highlightUpstream={highlightUpstream}
              />
            ))}
            {dependencies?.values.length > MAX_ITEM_IN_CELL && (
              <div
                className='txt-grey-13-500'
                onClick={handleChangeCollapseUpstreamTables}
                style={{ cursor: 'pointer' }}
              >
                {renderUpstreamTablesContent()}
              </div>
            )}
          </div>
        )}
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        {createdAt ? (
          moment(createdAt).utc().format(DATE_FORMAT.dayMonthYear)
        ) : (
          <div className='txt-grey-13-500'>N/A</div>
        )}
      </StyledTableCell>

      <StyledTableCell className='nowrapText'>
        {updatedAt ? (
          moment(updatedAt).utc().format(DATE_FORMAT.dayMonthYear)
        ) : (
          <div className='txt-grey-13-500'>N/A</div>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { DictionaryLookerDetails };
