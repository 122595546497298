import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import { TableHead, TableSortLabel } from '@mui/material';
import { StyledTableCell } from 'Pages/Costs/Costs.styles.js';
import { StyledTableRow } from 'Components/components.js';
import {
  SourceTable,
  Email,
  Reservations,
  Project,
  Total,
  TotalCost,
} from '../enums/enums.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const HEAD_CELLS = [
  SourceTable,
  Email,
  Reservations,
  Project,
  Total,
  TotalCost,
];

const ResourceTableHead = () => {
  const { currentBqBillingData } = useBqBillingData();
  Reservations.IS_SHOW = useMemo(() => {
    return currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData?.paymentType]);

  return (
    <TableHead>
      <StyledTableRow>
        {HEAD_CELLS.map((headCell) => {
          return (
            headCell.IS_SHOW && (
              <StyledTableCell
                key={headCell.ID}
                align={headCell.ALIGN}
                style={{ width: headCell.WIDTH || 'none' }}
              >
                <TableSortLabel hideSortIcon={true}>
                  <span className='txt-grey-13-500 nowrapText'>
                    {headCell.LABEL}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
};

export { ResourceTableHead };
