import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processSlackChannelList = (data) => {
  return {
    slackChannelList: data?.values || [],
  };
};

const useGetSlackChannelList = () => {
  const { isFetching, data, refetch } = useQuery(
    [QUERY_TYPES.slackChannelList],
    ({ queryKey }) => {
      const [url] = queryKey;

      return fetcherGet(url);
    },
    { cacheTime: 0 }
  );

  const processedData = useMemo(() => {
    return processSlackChannelList(data);
  }, [data]);

  return {
    slackChannelList: processedData.slackChannelList,
    isFetching,
    refetch,
  };
};

export { useGetSlackChannelList };
