import clsx from 'clsx';
import { Button as MaterialButton } from '@mui/material';
import { useStyles } from './Button.styles.js';

const BUTTON_VARIANT = {
  contained: 'contained',
  text: 'text',
  outlined: 'outlined',
};

const BUTTON_COLOR = {
  primary: 'primary',
  secondary: 'secondary',
  error: 'error',
  info: 'info',
};

const BUTTON_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const Button = ({
  onClick,
  text = '',
  variant = BUTTON_VARIANT.contained,
  color = BUTTON_COLOR.primary,
  size = BUTTON_SIZE.small,
  isDisabled = false,
  fullWidth = true,
  className = '',
  startIcon = null,
  endIcon = null,
  colorfulIcon = false,
  disableRipple = false,
}) => {
  const classes = useStyles({ colorfulIcon });

  return (
    <MaterialButton
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      disabled={isDisabled}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      disableRipple={disableRipple}
      className={clsx(classes.button, className)}
    >
      {text}
    </MaterialButton>
  );
};

export { Button, BUTTON_VARIANT, BUTTON_COLOR, BUTTON_SIZE };
