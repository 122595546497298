import React from 'react';
import {
  AsyncGeneralSearch,
  Button,
  BUTTON_COLOR,
  RightSideModal,
} from 'Components/components.js';
import { CreateDomainModalBody } from 'Pages/DataProdact/components/DomainsTab/components/components.js';
import { useModal } from 'context/context.js';
import { QUERY_TYPES } from 'constants/constants.js';

const INPUT_HEIGHT = 52;

const DomainSection = ({
  controlData,
  defaultValue,
  onChangeDomainUUID,
  withCreateButton,
  onChangeCreateDomainText,
}) => {
  const { setModal } = useModal();
  const { label, id, isRequired, placeholder } = controlData;

  const createNewDomain = () => {
    setModal(() => (
      <RightSideModal
        title='Create domain'
        ModalBodyComponent={CreateDomainModalBody}
        mainBlockMargin={0}
        onChangeActionText={onChangeCreateDomainText}
      />
    ));
  };

  const handleChangeDomainUUID = (value) => {
    onChangeDomainUUID(value || null);
  };

  const getLabelItem = (value) => {
    return `${value.name} / ${value.email}`;
  };
  const getValueItem = (value) => {
    return value.uuid;
  };

  return (
    <>
      <AsyncGeneralSearch
        defaultSelectValue={defaultValue}
        onSelect={handleChangeDomainUUID}
        onSearch={null}
        searchUrl={QUERY_TYPES.domainList}
        searchQueryParam='search'
        getLabelFromRequestItem={getLabelItem}
        getValueFromRequestItem={getValueItem}
        getLabelFromResponseItem={getLabelItem}
        getValueFromResponseItem={getValueItem}
        placeholderName={placeholder}
        searchKey={id}
        optionLabel=''
        inputHeight={INPUT_HEIGHT}
        id={id}
        label={label}
        isRequiredField={isRequired}
        disableCache={true}
      />

      {withCreateButton && (
        <Button
          text='Create new domain'
          onClick={createNewDomain}
          color={BUTTON_COLOR.secondary}
          fullWidth={false}
          className='mt-3'
        />
      )}
    </>
  );
};

export { DomainSection };
