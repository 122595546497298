const EDIT = 'EDIT';
const DELETE = 'DELETE';

const DATA_PRODUCT_ACTIONS_DROPDOWN = [
  { value: EDIT, label: 'Edit' },
  { value: DELETE, label: 'Delete' },
];

const SUBSCRIBER_COLORS = [
  '#D20093',
  '#A55EFF',
  '#7E0B56',
  '#5C00D2',
  '#380E6F',
];

export { DATA_PRODUCT_ACTIONS_DROPDOWN, EDIT, DELETE, SUBSCRIBER_COLORS };
