import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Typography } from 'Components/components.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    flex: '1 1 0',
    position: 'relative',
    padding: theme.spacing(6),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  statsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3, 2),
    background: theme.palette.secondary.light,
    borderRadius: theme.spacing(2),
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

const StatsWidget = ({ children }) => {
  const classes = useStyles();

  return <section className={classes.card}>{children}</section>;
};

const StatsItem = ({ widgetData }) => {
  const classes = useStyles();
  const { title, Icon, totalStats, totalStatsPath, stats } = widgetData || {};

  return (
    <>
      <div className={classes.title}>
        {Icon && <Icon width={18} height={18} />}
        <Typography>{title}</Typography>
      </div>

      {totalStatsPath ? (
        <Link to={totalStatsPath}>
          <div className='txt-mainDark-32-700 mb-4'>{totalStats}</div>
        </Link>
      ) : (
        <div className='txt-mainDark-32-700 mb-4'>{totalStats}</div>
      )}

      {stats?.map(({ name, value, path }) => (
        <section key={name} className={classes.statsContainer}>
          {path ? (
            <Link to={path}>
              <Typography>{name}</Typography>
              <div className={classes.flexContainer}>
                <div className='txt-mainDark-13-500'>{value}</div>
                <ArrowRight />
              </div>
            </Link>
          ) : (
            <div>
              <Typography>{name}</Typography>
              <div className={classes.flexContainer}>
                <div className='txt-mainDark-13-500'>{value}</div>
                <ArrowRight style={{ opacity: 0 }} />
              </div>
            </div>
          )}
        </section>
      ))}
    </>
  );
};

export { StatsWidget, StatsItem };
