import React from 'react';
import Widget from 'Components/Widget/Widget.js';
import { ExecutedJobsByTimeChart } from './components/components.js';

const ExecutedJobsByTimeWidget = () => {
  return (
    <Widget
      label='Executed jobs by data asset over time'
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <ExecutedJobsByTimeChart />
    </Widget>
  );
};

export { ExecutedJobsByTimeWidget };
