import React, { createContext } from 'react';
import {
  useContext,
  useLocation,
  useMemo,
  useState,
  useEffect,
} from 'hooks/hooks.js';
import moment from 'moment';
import { endOfDay, startOfDay } from 'date-fns';
import { useQueryParamsContext } from 'context/context.js';
import {
  convertLocalToUTCDate,
  defaultEndDate,
  defaultStartDate,
} from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { CostsTabs, DATE_FORMAT, SEARCH_PARAMS } from 'constants/constants.js';

const ROUTES_WITH_DATE_PARAMS = [
  AppRoutes.Dashboard.path,
  AppRoutes.Monitors.path,
  AppRoutes.Incidents.path,
  AppRoutes.DataProduct_details.path,
];

const checkPathWithParams = (path) => {
  for (const route of ROUTES_WITH_DATE_PARAMS) {
    const routeParts = route.split('/');
    const pathParts = path.split('/');
    if (routeParts.length !== pathParts.length) {
      continue;
    }
    let match = true;
    for (let i = 0; i < routeParts.length; i++) {
      if (routeParts[i].startsWith(':')) {
        continue;
      }
      if (routeParts[i] !== pathParts[i]) {
        match = false;
        break;
      }
    }
    if (match) {
      return true;
    }
  }
  return false;
};

const getURLDateFormat = (date) => {
  return moment(date).format(DATE_FORMAT.yearMonthDay);
};

const DateContext = createContext(null);

const DateContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const urlStartDate = queryParams.get(SEARCH_PARAMS.FROM);
  const urlEndDate = queryParams.get(SEARCH_PARAMS.TO);
  const pageTab = queryParams.get(SEARCH_PARAMS.TAB);

  const [startDate, setStartDate] = useState(defaultStartDate());
  const [endDate, setEndDate] = useState(defaultEndDate());
  const startDateURLFormat = getURLDateFormat(startDate);
  const endDateURLFormat = getURLDateFormat(endDate);

  const startDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(startDate)).utc().format();
  }, [startDate]);
  const endDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(endDate)).utc().format();
  }, [endDate]);

  const defaultStartDateUtc = moment(convertLocalToUTCDate(defaultStartDate()))
    .utc()
    .format();
  const defaultEndDateUtc = moment(convertLocalToUTCDate(defaultEndDate()))
    .utc()
    .format();

  const daysSelected = useMemo(() => {
    if (startDate && endDate) {
      return moment(endDate).diff(moment(startDate), 'days') + 1;
    }
    return 0;
  }, [startDate, endDate]);

  useEffect(() => {
    if (!urlStartDate && !urlEndDate && checkPathWithParams(pathname)) {
      updateQueryParams(
        {
          [SEARCH_PARAMS.FROM]: getURLDateFormat(startDate),
          [SEARCH_PARAMS.TO]: getURLDateFormat(endDate),
        },
        { useReplace: true }
      );
    }

    if (pathname === AppRoutes.Costs.path) {
      if (pageTab === CostsTabs.STORAGE) {
        if (urlStartDate || urlEndDate) {
          updateQueryParams(
            {
              [SEARCH_PARAMS.FROM]: null,
              [SEARCH_PARAMS.TO]: null,
            },
            { useReplace: true }
          );
        }
      } else if (!urlStartDate && !urlEndDate) {
        updateQueryParams(
          {
            [SEARCH_PARAMS.FROM]: getURLDateFormat(startDate),
            [SEARCH_PARAMS.TO]: getURLDateFormat(endDate),
          },
          { useReplace: true }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, pathname, startDate, urlEndDate, urlStartDate, pageTab]);

  useEffect(() => {
    if (urlStartDate && urlStartDate !== startDateURLFormat) {
      setStartDate(startOfDay(moment(urlStartDate).toDate()));
    }

    if (urlEndDate && urlEndDate !== endDateURLFormat) {
      setEndDate(endOfDay(moment(urlEndDate).toDate()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlStartDate, urlEndDate]);

  const onChooseDates = (dates) => {
    const [start, end] = dates;

    updateQueryParams({
      [SEARCH_PARAMS.FROM]: getURLDateFormat(start),
      [SEARCH_PARAMS.TO]: getURLDateFormat(end),
    });
  };

  return (
    <DateContext.Provider
      value={{
        startDate,
        endDate,
        startDateUtc,
        endDateUtc,
        defaultStartDateUtc,
        defaultEndDateUtc,
        daysSelected,
        onChooseDates,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

const useDateContext = () => {
  const context = useContext(DateContext);

  if (!context) {
    throw new Error('useDateContext must be used within a DateContextProvider');
  }

  return context;
};

export { DateContextProvider, useDateContext };
