import React from 'react';
import { useState } from 'hooks/hooks.js';
import { useModalStyles } from 'Pages/DataProdact/components/ModalBody.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { CreateDataProductForm } from 'Pages/DataProdact/components/DataProductCreator/components.js';

const EditDataProductModalBody = ({ dataProductUUID = null, closeModal }) => {
  const classes = useModalStyles();
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [formData, setFormData] = useState(null);

  const handleChangeIsDisabledSaveButton = (value) => {
    setIsDisabledSaveButton(value);
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const saveDataProduct = () => {
    if (formData) {
      formData.save();
      closeModal();
    }
  };

  return (
    <section className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <CreateDataProductForm
          dataProductUUID={dataProductUUID}
          onSaveButtonDisabledChange={handleChangeIsDisabledSaveButton}
          onSaveDataProduct={handleFormData}
        />
      </div>

      <div className={classes.footer}>
        <Button
          text='Save'
          fullWidth={false}
          className='button'
          onClick={saveDataProduct}
          isDisabled={isDisabledSaveButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </section>
  );
};

export { EditDataProductModalBody };
