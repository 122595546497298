import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import clsx from 'clsx';
import { StyledTableCell } from 'Pages/Costs/Costs.styles.js';
import {
  headSource,
  headJobType,
  headEmail,
  headTotalCost,
  headFrequency,
  headActivity,
  headReservation,
  headCurrentPlan,
  headAlternativePlan,
  currentPlanId,
} from './CostsTableHeadData.js';
import { BQ_BILLING_TYPE, PIPELINE_TYPE } from 'utils/constants.js';

const HEAD_CELLS = [
  headSource,
  headEmail,
  headReservation,
  headJobType,
  headFrequency,
  headActivity,
  headCurrentPlan,
  headAlternativePlan,
  headTotalCost,
];

const CoatsTableHead = ({ selectedPipelineType }) => {
  const { currentBqBillingData } = useBqBillingData();
  headJobType.isShow = useMemo(() => {
    return selectedPipelineType === PIPELINE_TYPE.BQ_DTS;
  }, [selectedPipelineType]);
  headReservation.isShow = useMemo(() => {
    return currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData?.paymentType]);

  return (
    <TableHead>
      <TableRow>
        {HEAD_CELLS.map((headCell) => {
          return (
            headCell.isShow && (
              <StyledTableCell
                key={headCell.id}
                align={headCell.align}
                className={clsx(
                  headCell.id === currentPlanId && 'withBackground'
                )}
              >
                <TableSortLabel hideSortIcon={true}>
                  <span className='txt-grey-13-500 nowrapText'>
                    {headCell.label}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CoatsTableHead;
