import { useCallback, useState, useMemo } from 'hooks/hooks.js';
import { MAX_ITEM_IN_CELL } from 'constants/constants.js';

const useExpandContent = (
  data = [],
  visibleItems = MAX_ITEM_IN_CELL,
  isOpen = false
) => {
  const [isCollapse, setIsCollapse] = useState(!isOpen);

  const content = useMemo(() => {
    return isCollapse ? data.slice(0, visibleItems) : data;
  }, [data, isCollapse, visibleItems]);

  const collapsedItemsNumber = useMemo(() => {
    return data.length - visibleItems;
  }, [data.length, visibleItems]);

  const handleChangeCollapse = useCallback((event) => {
    event.stopPropagation();
    setIsCollapse((prev) => !prev);
  }, []);

  const renderCollapsedItemsNumber = () => {
    return isCollapse ? `Show ${collapsedItemsNumber} more` : 'Show less';
  };

  return { content, handleChangeCollapse, renderCollapsedItemsNumber };
};

export { useExpandContent };
