import React from 'react';
import { TableCell } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/Pipelines.styles.js';
import { OverflownText } from 'Components/components.js';
import {
  getFullTableName,
  getReferencedType,
  highlightText,
} from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { ReactComponent as SourceUriIcon } from 'assets/img/icons/lineageIcons/node-icons/source-uri.svg';

const { DESTINATION_TABLE_NAME_WIDTH } = PipelineTableCellStyles;

const SourceTableCell = ({
  sourceData,
  sourceUri,
  selectedTable = null,
  searchValue = '',
  maxWidth = DESTINATION_TABLE_NAME_WIDTH,
}) => {
  const classes = useStyles();

  return (
    <TableCell>
      {sourceData.map((source, i) => {
        const sourceName = getFullTableName(source.name, source.dataset);
        const IconComponent = getReferencedType(source.type).icon;

        return (
          <div key={sourceName + i} className='cellContentWithIcon'>
            <IconComponent className={classes.blackIcon} />

            <OverflownText title={sourceName} maxWidth={maxWidth}>
              {highlightText(sourceName, selectedTable, searchValue)}
            </OverflownText>
          </div>
        );
      })}

      {Boolean(sourceUri) && (
        <div className='cellContentWithIcon'>
          <SourceUriIcon className={classes.blackIcon} />

          <OverflownText title={sourceUri} maxWidth={maxWidth}>
            {highlightText(sourceUri)}
          </OverflownText>
        </div>
      )}
    </TableCell>
  );
};

export { SourceTableCell };
