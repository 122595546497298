import React from 'react';
import { useDeleteDomain } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  CostLabel,
  Modal,
  RadioDropdown,
  RightSideModal,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import { DeleteDomainModalBody, CreateDomainModalBody } from '../components.js';
import {
  useDataProductsPageContext,
  useMessages,
  useModal,
} from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  DATA_PRODUCT_ACTIONS_DROPDOWN,
  EDIT,
  DELETE,
} from 'Pages/DataProdact/constants/constants.js';
import {
  Name,
  Email,
  Slack,
  Products,
  Cost,
  Incidents,
  Edit,
} from '../../libs/enums/enums.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    '&.MuiTableRow-root': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '& .editCell > *': {
      opacity: 0,
    },
    '&:hover .editCell > *': {
      opacity: 1,
    },
  },
}));

const TableRow = ({ domain }) => {
  const tableClasses = useTableStyles();
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const { handleChangeToolbarText } = useDataProductsPageContext();

  const {
    uuid,
    name,
    email,
    slackChannel,
    dataProductsNum,
    costsTotal,
    incidentsTotal,
  } = domain;

  const { deleteDomain } = useDeleteDomain({
    domainUUID: uuid,
  });

  const onDeleteDomainClick = () => {
    deleteDomain().then((data) => {
      if (data && data.success) {
        handleChangeToolbarText('The domain was successfully deleted');
      }
    });
  };

  const onFilterSelect = (event) => {
    if (event.value === EDIT) {
      setModal(() => (
        <RightSideModal
          title={`Edit ${name}`}
          ModalBodyComponent={CreateDomainModalBody}
          domain={domain}
          mainBlockMargin={0}
          onChangeActionText={handleChangeToolbarText}
        />
      ));
    }
    if (event.value === DELETE) {
      if (dataProductsNum > 0) {
        setMessage(
          'This domain cannot be deleted because it is associated with data products. Please disconnect the domain and try again'
        );
      } else {
        setModal(() => (
          <Modal
            onAgree={onDeleteDomainClick}
            title={`Delete ${name}`}
            titlePosition='center'
            ModalBodyComponent={DeleteDomainModalBody}
            agreeButtonText='Delete'
          />
        ));
      }
    }
  };

  const tableCellData = [
    {
      id: Name.id,
      label: <div className='txt-mainDark-13-700'>{name}</div>,
      align: Name.align,
    },
    {
      id: Email.id,
      label: email,
      align: Email.align,
    },
    {
      id: Slack.id,
      label: slackChannel?.channelName,
      align: Slack.align,
    },
    {
      id: Products.id,
      label: dataProductsNum,
      align: Products.align,
    },
    {
      id: Cost.id,
      label: costsTotal > 0 && (
        <CostLabel value={costsTotal} withAbbreviateNumber={true} />
      ),
      align: Cost.align,
    },
    {
      id: Incidents.id,
      label: incidentsTotal > 0 && numberFormat(incidentsTotal),
      align: Incidents.align,
    },
    {
      id: Edit.id,
      label: (
        <RadioDropdown
          options={DATA_PRODUCT_ACTIONS_DROPDOWN}
          current={{ icon: PointsIcon }}
          onSelect={(event) => onFilterSelect(event)}
          bottom={'-24px'}
          right={'12px'}
        />
      ),
      align: Edit.align,
      className: 'editCell',
    },
  ];

  return (
    <StyledTableRow className={tableClasses.tableRow}>
      {tableCellData.map((item) => (
        <StyledTableCell
          key={item.id}
          align={item.align}
          className={item?.className}
        >
          {item.label || <EmptyCell />}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

const EmptyCell = () => {
  return <span className='txt-grey-13-500'>&mdash;</span>;
};

export { TableRow };
