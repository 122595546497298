import React from 'react';
import {
  useState,
  useEffect,
  useCreateDataProduct,
  useGetDataProduct,
  useHistory,
  useUpdateDataProduct,
  useMemo,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { PositionLoader } from 'Components/components.js';
import {
  DataAssetsSection,
  DomainSection,
  DescriptionSection,
  NameSection,
} from './components.js';
import { AppRoutes } from 'app-routes.js';

const useModalStyles = makeStyles((theme) => ({
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    overflow: 'scroll',
  },
}));

const INPUT_IDS = {
  name: 'NAME',
  dataAssets: 'DATA_ASSETS',
  domainUUID: 'DOMAIN_UUID',
  description: 'DESCRIPTION',
};

const CONTROL_ELEMENTS = {
  name: {
    label: 'Name',
    id: INPUT_IDS.name,
    isRequired: true,
    placeholder: 'Enter data product name',
  },
  dataAssets: {
    id: INPUT_IDS.dataAssets,
    isRequired: true,
  },
  domain: {
    label: 'Domain',
    id: INPUT_IDS.domainUUID,
    isRequired: false,
    placeholder: 'Select a domain',
  },
  description: {
    label: 'Description',
    id: INPUT_IDS.description,
    isRequired: false,
  },
};

const REQUIRED_FIELDS = Object.values(CONTROL_ELEMENTS)
  .filter((field) => field.isRequired)
  .map((field) => field.id);

const compareDataAssetsUuids = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const uuids1 = arr1.map((item) => item.uuid);
  const uuids2 = arr2.map((item) => item.uuid);

  const check1 = uuids1.every((uuid) => uuids2.includes(uuid));
  const check2 = uuids2.every((uuid) => uuids1.includes(uuid));

  return check1 && check2;
};

const CreateDataProductForm = ({
  dataProductUUID = null,
  onSaveButtonDisabledChange,
  onSaveDataProduct,
  onChangeActionText = () => {},
}) => {
  const classes = useModalStyles();
  const history = useHistory();

  const { dataProduct, isFetchingDataProduct } = useGetDataProduct({
    dataProductUUID,
  });
  const { name, dataAssets, domain, description } = dataProduct || {};

  const [newDataProduct, setNewDataProduct] = useState(null);

  const dataProductParams = useMemo(() => {
    return {
      name: newDataProduct?.[INPUT_IDS.name]?.trim(),
      dataAssets: newDataProduct?.[INPUT_IDS.dataAssets],
      domainUUID: newDataProduct?.[INPUT_IDS.domainUUID],
      description: newDataProduct?.[INPUT_IDS.description]?.trim(),
    };
  }, [newDataProduct]);

  const { createNewDataProduct } = useCreateDataProduct(dataProductParams);

  const { updateDataProduct } = useUpdateDataProduct({
    ...dataProductParams,
    dataProductUUID,
  });

  useEffect(() => {
    setNewDataProduct({
      [INPUT_IDS.name]: name || '',
      [INPUT_IDS.dataAssets]:
        dataAssets?.map((item) => ({ type: item.type, uuid: item.uuid })) || [],
      [INPUT_IDS.domainUUID]: domain?.uuid || null,
      [INPUT_IDS.description]: description || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProduct]);

  useEffect(() => {
    if (newDataProduct !== null) {
      const hasEmptyRequiredFields = REQUIRED_FIELDS.some((field) => {
        const value = newDataProduct[field];
        if (Array.isArray(value)) {
          return value.length === 0;
        } else if (typeof value === 'string') {
          return !value?.trim();
        } else {
          return !value;
        }
      });

      if (hasEmptyRequiredFields) {
        onSaveButtonDisabledChange(true);
        return;
      }

      const domainUUID = domain?.uuid || null;

      const isDataUnchanged =
        newDataProduct[INPUT_IDS.name]?.trim() === name &&
        newDataProduct[INPUT_IDS.domainUUID] === domainUUID &&
        newDataProduct[INPUT_IDS.description]?.trim() === description &&
        compareDataAssetsUuids(
          newDataProduct[INPUT_IDS.dataAssets],
          dataAssets
        );

      if (isDataUnchanged) {
        onSaveButtonDisabledChange(true);
        return;
      }

      onSaveButtonDisabledChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAssets, description, domain?.uuid, name, newDataProduct]);

  const handleChangeDataProduct = (id, value) => {
    setNewDataProduct((prevDataProduct) => ({
      ...prevDataProduct,
      [id]: value,
    }));
  };

  const saveDataProduct = () => {
    if (dataProductUUID) {
      updateDataProduct().then(() =>
        history.push(`${AppRoutes.DataProduct.path}/${dataProductUUID}`)
      );
    } else {
      createNewDataProduct().then(({ extra }) =>
        history.push(`${AppRoutes.DataProduct.path}/${extra.uuid}`)
      );
    }
  };

  useEffect(() => {
    if (onSaveDataProduct) {
      onSaveDataProduct({ save: saveDataProduct });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingDataProduct || newDataProduct === null) {
    return <PositionLoader />;
  }

  return (
    <section className={classes.contentContainer}>
      <NameSection
        controlData={CONTROL_ELEMENTS.name}
        value={newDataProduct[INPUT_IDS.name]}
        onChangeName={(value) => handleChangeDataProduct(INPUT_IDS.name, value)}
      />

      <DataAssetsSection
        defaultDataAssets={dataAssets}
        onChangeDataAssets={(value) =>
          handleChangeDataProduct(INPUT_IDS.dataAssets, value)
        }
      />

      <div>
        <DomainSection
          controlData={CONTROL_ELEMENTS.domain}
          defaultValue={domain}
          onChangeDomainUUID={(value) =>
            handleChangeDataProduct(INPUT_IDS.domainUUID, value)
          }
          withCreateButton={Boolean(!dataProductUUID)}
          onChangeCreateDomainText={onChangeActionText}
        />
      </div>

      <DescriptionSection
        controlData={CONTROL_ELEMENTS.description}
        value={newDataProduct[INPUT_IDS.description]}
        onChangeDescription={(value) =>
          handleChangeDataProduct(INPUT_IDS.description, value)
        }
      />
    </section>
  );
};

export { CreateDataProductForm };
