import { useEffect, useHistory, useLocation } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from 'Components/components.js';
import { useUserInfo } from 'context/context.js';
import { errorBoundaryEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as Image } from 'assets/img/notFoundPage.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 336,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    border: `1px dashed ${theme.palette.common.secondaryDark}`,
    borderRadius: 12,
    color: '#627D98',
    '&.withMargin': {
      margin: theme.spacing(6),
    },
    '& > div': {
      textAlign: 'center',
    },
  },
}));

const PartialPageErrorComponent = ({ error }) => {
  const history = useHistory();
  const location = useLocation();
  const errorInfo = location.pathname + location.search;

  const navigateHomePage = () => history.push(AppRoutes.Dashboard.path);

  useEffect(() => {
    if (error.stack.length > 0) {
      errorBoundaryEvent(error.stack, errorInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.stack]);

  return <ErrorMessage onClickButton={navigateHomePage} />;
};

const GlobalUserErrorComponent = ({ error }) => {
  const { user } = useUserInfo();
  const location = useLocation();
  const errorInfo = '!!!GlobalError -> ' + location.pathname + location.search;

  useEffect(() => {
    if (error.stack.length > 0 && !!user) {
      errorBoundaryEvent(error.stack, errorInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.stack]);

  return <ErrorMessage className='withMargin' />;
};

const GlobalErrorComponent = () => {
  return <ErrorMessage className='withMargin' />;
};

const ErrorMessage = ({ className = '', onClickButton = null }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <Image />
      <div className='txt-mainDark-20-700 mt-1'>Oops!</div>
      <div>
        <div>An unexpected error seems to occur.</div>
        <div>We are working on solving the problem</div>
      </div>
      {onClickButton && (
        <Button fullWidth={false} onClick={onClickButton} text='Home page' />
      )}
    </div>
  );
};

export {
  PartialPageErrorComponent,
  GlobalUserErrorComponent,
  GlobalErrorComponent,
};
