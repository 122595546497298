import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';

const CONTAINER_SIZE = {
  LARGE: 300,
  MEDIUM: 164,
  SMALL: 46,
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: (props) => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ComingSoon = ({ containerHeight = CONTAINER_SIZE.MEDIUM }) => {
  const classes = useStyles({ height: containerHeight });

  return (
    <div className={classes.container}>
      <Typography
        variant={TYPOGRAPHY_VARIANT.h4}
        color={TYPOGRAPHY_COLOR.textSecondary}
      >
        Coming Soon
      </Typography>
    </div>
  );
};

export { ComingSoon, CONTAINER_SIZE };
