import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route } from 'react-router-dom';
import {
  AuthContextProviders,
  GlobalErrorComponent,
} from 'Components/components.js';
import AuthSide from 'Pages/Login/Auth.js';

function AuthRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthContextProviders>
          <ErrorBoundary FallbackComponent={GlobalErrorComponent}>
            <AuthSide component={Component} {...props} />
          </ErrorBoundary>
        </AuthContextProviders>
      )}
    />
  );
}

export { AuthRoute };
