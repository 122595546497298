import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  mainSection: {
    margin: theme.spacing(6, 0),
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
  },

  formContainer: {
    width: 600,
  },
}));
