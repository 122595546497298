import { AppRoutes } from 'app-routes.js';
import { ReactComponent as Dash } from 'assets/img/sidebarIcons/dashboard.svg';
import { ReactComponent as Mon } from 'assets/img/sidebarIcons/monitors.svg';
import { ReactComponent as Inc } from 'assets/img/sidebarIcons/incidents.svg';
import { ReactComponent as Lin } from 'assets/img/sidebarIcons/pipes.svg';
import { ReactComponent as Dic } from 'assets/img/sidebarIcons/dictionary.svg';
import { ReactComponent as Pipelines } from 'assets/img/sidebarIcons/pipeline.svg';
import { ReactComponent as Costs } from 'assets/img/sidebarIcons/costsIcon.svg';
import { ReactComponent as Int } from 'assets/img/sidebarIcons/integrations.svg';
import { ReactComponent as Quality } from 'assets/img/sidebarIcons/quality.svg';
import { ReactComponent as DataProduct } from 'assets/img/sidebarIcons/data-product.svg';

const MENU_ITEMS = [
  {
    id: 'dashboard',
    path: AppRoutes.Dashboard.path,
    label: 'Dashboard',
    Icon: Dash,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'monitors',
    path: AppRoutes.Monitors.path,
    label: 'Monitors',
    Icon: Mon,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'incidents',
    path: AppRoutes.Incidents.path,
    label: 'Incidents',
    Icon: Inc,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'lineage',
    path: AppRoutes.Lineage.path,
    label: 'Lineage',
    Icon: Lin,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'dictionary',
    path: AppRoutes.Dictionary.path,
    label: 'Dictionary',
    Icon: Dic,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'dataQuality',
    path: AppRoutes.DataQuality.path,
    label: 'Data Quality',
    Icon: Quality,
    isShowForDemo: true,
    isBeta: false,
  },
  {
    id: 'dataProducts',
    path: AppRoutes.DataProduct.path,
    label: 'Data Products',
    Icon: DataProduct,
    isShowForDemo: true,
    isBeta: true,
  },
  {
    id: 'pipelines',
    path: AppRoutes.Pipelines.path,
    label: 'Pipelines',
    Icon: Pipelines,
    isShowForDemo: true,
    isBeta: true,
  },
  {
    id: 'costs',
    path: AppRoutes.Costs.path,
    label: 'Cost insights',
    Icon: Costs,
    isShowForDemo: true,
    isBeta: true,
  },
  {
    id: 'integrations',
    path: AppRoutes.Integrations.path,
    label: 'Integrations',
    Icon: Int,
    isShowForDemo: true,
    isBeta: false,
  },
];

export { MENU_ITEMS };
