import { useMemo, useQuery } from 'hooks/hooks.js';
import { useCostsContext } from 'context/context.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';

const useStorageDatasetsList = ({
  page,
  limit,
  dataset,
  search,
  onlySavingsAvailable = false,
}) => {
  const { isAllowedCostsDataRequest } = useCostsContext();
  const { data, isFetching } = useQuery(
    [
      QUERY_TYPES.storageDatasetsList,
      page,
      limit,
      dataset,
      search,
      onlySavingsAvailable,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams({
        page: page + 1,
        limit,
      });

      onlySavingsAvailable && params.append('onlySavingsAvailable', 'true');
      dataset?.length && params.append('dataset', dataset);
      search?.length && params.append('search', search);

      return fetcherGet(url, params);
    },
    { enabled: isAllowedCostsDataRequest }
  );

  const storageDatasets = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const storageDatasetsTotal = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return { storageDatasets, storageDatasetsTotal, isFetching };
};

export { useStorageDatasetsList };
