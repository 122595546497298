const NAME_ID = 'NAME';
const DOMAIN_ID = 'DOMAIN';
const ANALYTICS_HUB_ID = 'ANALYTICS_HUB';
const SUBSCRIBERS_ID = 'SUBSCRIBERS';
const COST_ID = 'COST';
const INCIDENTS_ID = 'INCIDENTS';
const EDIT_ID = 'EDIT';

const Name = {
  id: NAME_ID,
  label: 'Name',
  sort: false,
  align: 'left',
  isShow: true,
};
const Domain = {
  id: DOMAIN_ID,
  label: 'Domain',
  sort: false,
  align: 'left',
  isShow: true,
};
const AnalyticsHub = {
  id: ANALYTICS_HUB_ID,
  label: 'Analytics Hub listing ',
  sort: false,
  align: 'left',
  isShow: false,
};
const Subscribers = {
  id: SUBSCRIBERS_ID,
  label: 'Subscribers',
  sort: false,
  align: 'left',
  isShow: true,
};
const Cost = {
  id: COST_ID,
  label: 'Monthly Cost',
  sort: false,
  align: 'right',
  isShow: true,
};
const Incidents = {
  id: INCIDENTS_ID,
  label: 'Open Incidents',
  sort: false,
  align: 'right',
  isShow: true,
};

const Edit = {
  id: EDIT_ID,
  label: '',
  align: 'right',
  sort: false,
  isShow: true,
};

export { Name, Domain, AnalyticsHub, Subscribers, Cost, Incidents, Edit };
