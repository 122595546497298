import React from 'react';
import { useParams } from 'hooks/hooks.js';
import { CostLabel, StatsItem, StatsWidget } from 'Components/components.js';
import { getURLDateFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { SEARCH_PARAMS, CostsTabs } from 'constants/constants.js';

const CostsWidget = ({ metricsData }) => {
  const { uuid } = useParams();
  const { costs, costsFrom, costsTo } = metricsData || {};

  const from = getURLDateFormat(costsFrom);
  const to = getURLDateFormat(costsTo);

  const widgetData = {
    title: <span className='txt-grey-13-500'>Last 30 days total Costs</span>,
    totalStats: (
      <CostLabel value={costs?.compute} withAbbreviateNumber={true} />
    ),
    stats: [
      {
        name: 'Compute costs',
        value: <CostLabel value={costs?.compute} withAbbreviateNumber={true} />,
        path: {
          pathname: AppRoutes.Costs.path,
          search: new URLSearchParams({
            [SEARCH_PARAMS.TAB]: CostsTabs.COSTS,
            [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: uuid,
            [SEARCH_PARAMS.FROM]: from,
            [SEARCH_PARAMS.TO]: to,
          }).toString(),
        },
      },
      {
        name: 'Storage costs',
        value: <CostLabel value={costs?.storage} withAbbreviateNumber={true} />,
      },
    ],
  };

  return (
    <StatsWidget>
      <StatsItem widgetData={widgetData} />
    </StatsWidget>
  );
};

export { CostsWidget };
