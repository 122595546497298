import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
  iconContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(3),
  },
}));
