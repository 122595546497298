import React from 'react';
import { useMemo, useState, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import { ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Button,
  BUTTON_VARIANT,
  Input,
  MarkdownWithSyntaxHighlighting,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { ReactComponent as CodeIcon } from 'assets/img/icons/code.svg';
import { ReactComponent as DraftIcon } from 'assets/img/icons/draft.svg';

const HELPER_TEXT_ALIGN = 'end';
const LIMIT_CHARACTERS =
  'You have reached your maximum limit of characters allowed';
const MAX_DESCRIPTION_LENGTH = 1024;

const useStyles = makeStyles((theme) => ({
  labelBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    '& .button': {
      width: '60px',
      padding: theme.spacing(1),
      '&.isSelected': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:hover': {
        zIndex: 1,
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },

  markdownBlock: {
    padding: '31.5px 30px',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
  },
}));

const DescriptionSection = ({ controlData, value, onChangeDescription }) => {
  const classes = useStyles();
  const { label, id, isRequired } = controlData;
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(true);

  const descriptionInputHelperText = useMemo(() => {
    return `${value?.length || 0} / ${MAX_DESCRIPTION_LENGTH}`;
  }, [value]);

  useEffect(() => {
    if (error) {
      const timerId = setTimeout(() => {
        setError(null);
      }, 2000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [error]);

  const handleChangeValue = (newValue) => {
    if (newValue.length > MAX_DESCRIPTION_LENGTH) {
      setError(LIMIT_CHARACTERS);
      return;
    } else {
      setError(null);
    }

    onChangeDescription(newValue);
  };

  return (
    <div>
      <div className={classes.labelBlock}>
        <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{label}</Typography>

        <div>
          <ButtonGroup>
            <Button
              onClick={() => setIsEditing(true)}
              variant={BUTTON_VARIANT.outlined}
              startIcon={<CodeIcon />}
              className={clsx('button', isEditing && 'isSelected')}
            />
            <Button
              onClick={() => setIsEditing(false)}
              variant={BUTTON_VARIANT.outlined}
              startIcon={<DraftIcon />}
              className={clsx('button', !isEditing && 'isSelected')}
            />
          </ButtonGroup>
        </div>
      </div>

      <div>
        {isEditing ? (
          <Input
            value={value}
            onChange={handleChangeValue}
            id={id}
            isRequired={isRequired}
            multiline={true}
            helperText={descriptionInputHelperText}
            helperTextAlign={HELPER_TEXT_ALIGN}
            errorComponent={error}
          />
        ) : (
          <div className={classes.markdownBlock}>
            <MarkdownWithSyntaxHighlighting markdown={value} />
          </div>
        )}
      </div>
    </div>
  );
};

export { DescriptionSection };
