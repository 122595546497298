import React from 'react';
import { useStorageStats } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import {
  StyledTableRow,
  StyledTableCell,
  Typography,
  TYPOGRAPHY_COLOR,
  LoadingTableRaw,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { abbreviateNumber, numberFormat } from 'utils/helpers/helpers.js';
import {
  SEARCH_PARAMS,
  ALTERNATIVE_PLAN_OPTION,
  AMPL_PAGE_EVENT,
} from 'constants/constants.js';
import theme from 'theme.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const StorageCostsOptimized = () => {
  const { updateQueryParams } = useQueryParamsContext();
  const { storageStatsData, isFetching } = useStorageStats();

  const { totalDatasetsForSavings, storageCost, storageCostSavings } =
    storageStatsData || {};

  if (!storageCost && !isFetching) {
    return null;
  }

  const diff = (storageCostSavings * 100) / storageCost;
  const optimizedStorageCost = storageCost - storageCostSavings;

  const onClickLink = (event) => {
    event.preventDefault();

    updateQueryParams({
      [SEARCH_PARAMS.STORAGE_COSTS_EFFICIENCY]: ALTERNATIVE_PLAN_OPTION,
    });
    amplEvent(
      `${AMPL_PAGE_EVENT.costs} -> Storage tab-> 'Switch storage billing model' selected`
    );
  };

  const cellsData = [
    {
      id: 1,
      label: (
        <>
          <span className='txt-mainDark-13-700'>
            Switch storage billing model
          </span>
          <Link
            to={'#'}
            onClick={(event) => onClickLink(event)}
            className='ms-2'
          >
            <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
              {totalDatasetsForSavings === 1
                ? 'See dataset'
                : `See ${numberFormat(totalDatasetsForSavings)} datasets`}
            </Typography>
            <ArrowRight fill={theme.palette.primary.main} />
          </Link>
        </>
      ),
      align: 'left',
      className: 'ps-0',
    },
    {
      id: 2,
      label: (
        <div className='nowrapText'>
          <Typography className='d-inline me-2'>
            {abbreviateNumber(diff)}%
          </Typography>
          <span className='txt-grey-13-500'>
            ${abbreviateNumber(storageCostSavings)}
          </span>
        </div>
      ),
      align: 'right',
    },
    {
      id: 3,
      label: <>${abbreviateNumber(optimizedStorageCost)}</>,
      align: 'right',
      className: 'pe-0',
    },
  ];

  if (isFetching) {
    return <LoadingTableRaw />;
  }

  if (!isFetching && storageCostSavings === 0) {
    return null;
  }

  return (
    <StyledTableRow>
      {cellsData.map(({ id, label, align, className }) => (
        <StyledTableCell key={id} align={align} className={className}>
          {label}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export { StorageCostsOptimized };
