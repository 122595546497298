import React from 'react';
import { useCallback, useState, useHistory } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { DataScansList, UnconnectedDataplex } from './components/components.js';
import { useIntegration } from 'context/context.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';

const DataplexWidget = ({ tableInfo = null }) => {
  const { isDataplexConnected, isDataplexApiDisabled } = useIntegration();
  const history = useHistory();
  const {
    dataplexScan: { title, subtitle },
  } = getWidgetLabel();
  const [existingDivider, setExistingDivider] = useState(true);
  const [incidentCount, setIncidentCount] = useState(0);

  const navigateToCreateScanPage = useCallback(() => {
    history.push({
      pathname: AppRoutes.DataQuality_createScan.path,
      search: new URLSearchParams({
        dataset: tableInfo?.dataset,
        table: tableInfo?.table,
      }).toString(),
    });
  }, [history, tableInfo?.dataset, tableInfo?.table]);

  const handleChangeWidgetData = (isDivider, newIncidentCount) => {
    setExistingDivider(isDivider);
    setIncidentCount(newIncidentCount);
  };

  if (!isDataplexConnected || isDataplexApiDisabled) {
    return <UnconnectedDataplex />;
  }

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      incidentCount={incidentCount}
      count={true}
      existingDivider={existingDivider}
      initialSettings={{ size: 'L', height: 'auto' }}
      isShowButton={true}
      onWidgetButtonClick={navigateToCreateScanPage}
      widgetButtonText='Create scan'
    >
      <DataScansList tableInfo={tableInfo} onChange={handleChangeWidgetData} />
    </Widget>
  );
};

export { DataplexWidget };
