import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Image } from 'react-bootstrap';
import {
  Button,
  RightSideModal,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { CreateDomainModalBody } from 'Pages/DataProdact/components/DomainsTab/components/components.js';
import { useDataProductsPageContext, useModal } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import ExampleImage from 'assets/img/page-examples/data-product-example.png';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    borderRadius: 12,
    overflow: 'hidden',
  },
  largeBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '32px 24px',
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  contentContainer: {
    width: 572,
  },
  smallBlock: {
    width: '38%',
    backgroundColor: theme.palette.secondary.main,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  image: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '46%',
    transform: 'translateY(-50%)',
  },
}));

const StartDataProduct = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToCreateProduct = () => {
    history.push(AppRoutes.DataProduct_create.path);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.largeBlock}>
        <div className={classes.contentContainer}>
          <Typography
            variant={TYPOGRAPHY_VARIANT.h1}
            color={TYPOGRAPHY_COLOR.primary}
            className='mb-2'
          >
            Welcome to Data Products
          </Typography>

          <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-4'>
            Data products transform raw information into valuable insights that
            drive better decision-making across your organization. They help
            teams work more efficiently by providing easy access to the data
            they need, while ensuring consistency and reliability in how that
            data is processed and presented.
          </Typography>
          <Button
            text='Create data product'
            fullWidth={false}
            onClick={navigateToCreateProduct}
          />
        </div>
      </div>

      <div className={classes.smallBlock}>
        <Image className={classes.image} src={ExampleImage} />
      </div>
    </div>
  );
};

const StartDomain = () => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { handleChangeToolbarText } = useDataProductsPageContext();

  const createDomain = () => {
    setModal(() => (
      <RightSideModal
        title='Create domain'
        ModalBodyComponent={CreateDomainModalBody}
        mainBlockMargin={0}
        onChangeActionText={handleChangeToolbarText}
      />
    ));
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.largeBlock}>
        <div className={classes.contentContainer}>
          <Typography
            variant={TYPOGRAPHY_VARIANT.h1}
            color={TYPOGRAPHY_COLOR.primary}
            className='mb-2'
          >
            Welcome to Domains
          </Typography>

          <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-4'>
            Domains organize your data products and underlying data assets
            within a clear structure, ensuring transparency in ownership,
            governance, and usage. They connect the teams and drive
            collaboration on trusted data.
          </Typography>

          <Button
            text='Create domain'
            fullWidth={false}
            onClick={createDomain}
          />
        </div>
      </div>

      <div className={classes.smallBlock}>
        <Image className={classes.image} src={ExampleImage} />
      </div>
    </div>
  );
};

export { StartDataProduct, StartDomain };
