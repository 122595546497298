import React from 'react';

const DeleteDomainModalBody = () => {
  return (
    <div className='txt-mainDark-13-500' style={{ textAlign: 'center' }}>
      Are you sure you want to delete domain from Masthead?
      <br /> This can't be undone.
    </div>
  );
};

export { DeleteDomainModalBody };
