import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useMessages } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const EVENT_MESSAGE = 'Create new domain ->';
const ERROR_MESSAGE = 'Got an error while creating the domain';

const useCreateDomain = ({ name, email, slackChannelName = '' }) => {
  const queryClient = useQueryClient();
  const { setMessage } = useMessages();

  const { mutateAsync: createNewDomain } = useMutation(
    () => {
      return fetcherPost(QUERY_TYPES.domain, {
        name,
        email,
        slackChannelName,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          setMessage(ERROR_MESSAGE);
          amplEvent(`${EVENT_MESSAGE} error-1`);
        } else {
          amplEvent(`${EVENT_MESSAGE} success`);
          queryClient.invalidateQueries(QUERY_TYPES.domainList);
        }
      },
      onError: () => {
        setMessage(ERROR_MESSAGE);
        amplEvent(`${EVENT_MESSAGE} error-2`);
      },
    }
  );

  return { createNewDomain };
};

export { useCreateDomain };
