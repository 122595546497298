import React from 'react';
import { TableHeadWithCheckbox } from 'Components/components.js';
import {
  Name,
  Email,
  Slack,
  Products,
  Cost,
  Incidents,
  Edit,
} from '../../libs/enums/enums.js';

const HEAD_CELLS = [Name, Email, Slack, Products, Cost, Incidents, Edit];

const TableHead = () => {
  const headCells = HEAD_CELLS.filter((headCell) => headCell.isShow);

  return <TableHeadWithCheckbox headCells={headCells} />;
};

export { TableHead };
