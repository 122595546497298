import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processDomain = (data) => {
  return {
    domain: data?.value || null,
  };
};

const useGetDomain = ({ domainUUID }) => {
  const isValidUUID = getIsValidUUID(domainUUID);

  const { isFetching: isFetchingDomain, data } = useQuery(
    [`${QUERY_TYPES.domain}/${domainUUID}`],
    ({ queryKey }) => {
      const [url] = queryKey;

      return fetcherGet(url);
    },
    { enabled: isValidUUID }
  );

  const processedData = useMemo(() => {
    return processDomain(data);
  }, [data]);

  return {
    domain: processedData.domain,
    isFetchingDomain,
  };
};

export { useGetDomain };
