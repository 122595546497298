import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    gap: 12,
    width: '100%',
  },
  icon: {
    minWidth: 41,
    width: 24,
    height: 24,
  },
  toolbar: {
    paddingRight: 18, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 'auto',
    },
    ...theme.mixins.toolbar,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    flexGrow: 3,
    padding: 24,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    position: 'relative',
  },
}));
