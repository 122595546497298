import React from 'react';
import { useLocation, useStorageStats } from 'hooks/hooks.js';
import clsx from 'clsx';
import { useStyles } from './StatsWidget.styles.js';
import {
  PositionLoader,
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { StatsPercentBlock } from './components/components.js';
import { abbreviateNumber } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { CostsTabs, SEARCH_PARAMS } from 'constants/constants.js';
import theme from 'theme.js';
import { ReactComponent as Icon } from 'assets/img/icons/database.svg';

const StorageCostsWidget = ({ className = '' }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { storageStatsData, isFetching } = useStorageStats();

  const { storageCost, optimizedStorageCost, storageCostSavings } =
    storageStatsData || {};
  const diff = storageCost > 0 ? (storageCostSavings * 100) / storageCost : 0;

  if (!storageCost) {
    return null;
  }

  return (
    <section className={clsx(classes.widget, className)}>
      {isFetching ? (
        <PositionLoader />
      ) : (
        <>
          <HeaderBlock totalStorageCosts={storageCost} />
          {pathname === AppRoutes.Dashboard.path ? (
            <StatsPercentBlock
              diff={diff}
              costData={storageCostSavings}
              linkPath={{
                pathname: AppRoutes.Costs.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: CostsTabs.STORAGE,
                }).toString(),
              }}
              Icon={Icon}
            />
          ) : (
            <CardsBlock
              totalStorageCosts={storageCost}
              storageCostSavings={storageCostSavings}
              optimizedStorageCost={optimizedStorageCost}
              diff={diff}
            />
          )}
        </>
      )}
    </section>
  );
};

const HeaderBlock = ({ totalStorageCosts }) => {
  return (
    <section>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
        Project monthly storage costs
      </Typography>

      <Typography
        variant={TYPOGRAPHY_VARIANT.h2}
        component={TYPOGRAPHY_VARIANT.h3}
      >
        ${abbreviateNumber(totalStorageCosts)}
      </Typography>
    </section>
  );
};

const CardsBlock = ({ totalStorageCosts, storageCostSavings, diff }) => {
  const classes = useStyles();

  if (!diff) {
    return null;
  }

  const optimizedStorageCost = totalStorageCosts - storageCostSavings;

  const cardsData = [
    {
      name: 'Optimized total storage cost',
      value: abbreviateNumber(optimizedStorageCost),
      diff: null,
      markerColor: '#978FED',
      lineColor: '#B6B0FB',
      lineWidth: `${Math.ceil(
        (optimizedStorageCost * 100) / totalStorageCosts
      )}%`,
      gridSize: 6,
    },
    {
      name: 'Storage cost savings ',
      value: abbreviateNumber(storageCostSavings),
      diff: abbreviateNumber(diff),
      markerColor: '#CEF8A8',
      lineColor: '#CEF8A8',
      diffColor: '#EDFFDC',
      diffTextColor: theme.palette.common.green,
      lineWidth: `${Math.ceil(
        (storageCostSavings * 100) / totalStorageCosts
      )}%`,
      gridSize: 6,
    },
  ];

  return (
    <section>
      <div className={classes.lineStats}>
        {cardsData.map((item) => (
          <Tooltip
            key={item.name}
            title={
              <div>
                {item.name}: ${item.value}
              </div>
            }
          >
            <div
              className={classes.line}
              style={{
                backgroundColor: item.lineColor,
                width: item.lineWidth,
              }}
            />
          </Tooltip>
        ))}
      </div>

      <section className={clsx(classes.cardsContainer, 'twoColumns')}>
        {cardsData.map((item) => (
          <Card key={item.name} cardData={item} />
        ))}
      </section>
    </section>
  );
};

const Card = ({ cardData }) => {
  const { name, value, markerColor, diff, diffColor, diffTextColor } = cardData;
  const classes = useStyles({
    diffContainerBackground: diffColor,
    diffContainerText: diffTextColor,
  });

  return (
    <section className={clsx(classes.card, 'column')}>
      <div className={clsx(classes.flexRawContainer, 'smallGap')}>
        <div
          className={classes.marker}
          style={{ backgroundColor: markerColor }}
        />
        <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{name}</Typography>
      </div>

      <div className={classes.flexRawContainer}>
        <Typography
          variant={TYPOGRAPHY_VARIANT.h5}
          component={TYPOGRAPHY_VARIANT.h5}
        >
          ${value}
        </Typography>
        {diff !== null && <div className={classes.diffContainer}>{diff}%</div>}
      </div>
    </section>
  );
};

export { StorageCostsWidget };
