import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processSubscribersData = (data) => {
  return {
    subscribers: data?.values || [],
    totalSubscribers: data?.pagination?.total || 0,
  };
};

const useGetSubscribers = ({ dataProductUUID, page, limit }) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidUUID = getIsValidUUID(dataProductUUID);

  const { isFetching: isFetchingSubscribers, data } = useQuery(
    [
      QUERY_TYPES.subscribers,
      dataProductUUID,
      startDateUtc,
      endDateUtc,
      page,
      limit,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append('dataProductUuid', dataProductUUID);
      params.append('from', startDateUtc);
      params.append('to', endDateUtc);
      params.append('page', page + 1);
      params.append('limit', limit);

      return fetcherGet(url, params);
    },
    { enabled: isValidUUID }
  );

  const processedData = useMemo(() => {
    return processSubscribersData(data);
  }, [data]);

  return {
    subscribers: processedData.subscribers,
    totalSubscribers: processedData.totalSubscribers,
    isFetchingSubscribers,
  };
};

export { useGetSubscribers };
