import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './StatsWidget.styles.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import { useCostsContext, useQueryParamsContext } from 'context/context.js';
import {
  CostsStatsGraphView,
  CostsStatsTableView,
} from './components/components.js';
import {
  abbreviateNumber,
  getTotalPipelineDiff,
} from 'utils/helpers/helpers.js';
import { COSTS_STATS_VIEW_TYPE, SEARCH_PARAMS } from 'constants/constants.js';

const CostsStatsWidget = ({
  viewType = COSTS_STATS_VIEW_TYPE.table,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.widget, className)}>
      <HeaderBlock withLink={viewType === COSTS_STATS_VIEW_TYPE.table} />

      {viewType === COSTS_STATS_VIEW_TYPE.graph ? (
        <CostsStatsGraphView />
      ) : (
        <CostsStatsTableView />
      )}
    </section>
  );
};

const HeaderBlock = ({ withLink = false }) => {
  const classes = useStyles();
  const { updateQueryParams } = useQueryParamsContext();
  const { totalProjectCosts, totalProjectCostsPrevious } = useCostsContext();

  const totalAmountInfo = getTotalPipelineDiff(
    totalProjectCosts,
    totalProjectCostsPrevious
  );

  const handleClickLink = (event) => {
    event.preventDefault();
    updateQueryParams({
      [SEARCH_PARAMS.PIPELINE_SUBTYPES]: null,
      [SEARCH_PARAMS.PIPELINE_TYPE]: '',
    });
  };

  return (
    <section>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
        Pipeline compute costs
      </Typography>

      <section className={classes.flexRawContainer}>
        {withLink ? (
          <Link to={'#'} onClick={(event) => handleClickLink(event)}>
            <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
              ${abbreviateNumber(totalProjectCosts, 1)}
            </Typography>
          </Link>
        ) : (
          <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
            ${abbreviateNumber(totalProjectCosts, 1)}
          </Typography>
        )}

        {totalAmountInfo !== null && (
          <PercentStatsBlock data={totalAmountInfo} />
        )}
      </section>
    </section>
  );
};

export { CostsStatsWidget };
