import React from 'react';
import {
  useAutoChangeProject,
  useBqBillingData,
  useEffect,
  useGetProjects,
} from 'hooks/hooks.js';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import {
  PageHeader,
  Pagination,
  PositionLoader,
  Tabs,
} from 'Components/components.js';
import {
  CostsTab,
  CostsWelcome,
  ResourceTab,
  StorageTab,
} from './components/costs-components.js';
import {
  PipelinePageContextProvider,
  usePipelinePageContext,
  useUserInfo,
} from 'context/context.js';
import { DAYS_LIMIT, DOCUMENT_TITLE, CostsTabs } from 'constants/constants.js';

const YALO_TENANT = 'yalo-n8gj5';
const DEFAULT_START_DATE = startOfDay(subDays(new Date(), 29));
const DEFAULT_END_DATE = endOfDay(new Date());

const CostsContainer = () => {
  return (
    <PipelinePageContextProvider>
      <Costs />
    </PipelinePageContextProvider>
  );
};

const Costs = () => {
  const {
    currentTab,
    updateParams,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationTotal,
  } = usePipelinePageContext();

  const { isWelcome, bqBillingData } = useBqBillingData();
  const { projects, isLoadingProjects } = useGetProjects();
  const { user, isCurrentProjectDemo } = useUserInfo();
  const isYaloTenant =
    user.info?.tenant === YALO_TENANT ||
    user.info?.adminData?.currentTenant === YALO_TENANT;

  useAutoChangeProject();

  useEffect(() => {
    document.title = DOCUMENT_TITLE.costs;
  }, []);

  const rangePickerDaysLimit = isYaloTenant
    ? DAYS_LIMIT.threeMonths
    : DAYS_LIMIT.month;
  const isStorageTab = currentTab === CostsTabs.STORAGE;

  const renderPage = () => {
    switch (currentTab) {
      case CostsTabs.RESOURCE_ALLOCATION:
        return <ResourceTab />;
      case CostsTabs.STORAGE:
        return <StorageTab />;
      default:
        return <CostsTab />;
    }
  };

  if (isLoadingProjects) {
    return <PositionLoader />;
  }

  if (isWelcome && !isCurrentProjectDemo) {
    return <CostsWelcome projects={projects} bqBillingData={bqBillingData} />;
  }

  return (
    <>
      <PageHeader
        daysLimit={rangePickerDaysLimit}
        defaultStartDate={isStorageTab ? DEFAULT_START_DATE : null}
        defaultEndDate={isStorageTab ? DEFAULT_END_DATE : null}
        isDisabled={isStorageTab}
      >
        Cost insights
      </PageHeader>

      <Tabs tabs={CostsTabs} onTabCLick={updateParams} />

      {renderPage()}

      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CostsContainer;
