import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useMessages, useQueryParamsContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { fetcherDelete } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES, SEARCH_PARAMS } from 'constants/constants.js';

const EVENT_MESSAGE = 'Delete domain -> uuid';
const INVALID_UUID_MESSAGE = 'Invalid UUID';

const useDeleteDomain = ({ domainUUID }) => {
  const queryClient = useQueryClient();
  const { setMessage } = useMessages();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const isValidUUID = getIsValidUUID(domainUUID);

  const domainFromUrl = queryParams.get(SEARCH_PARAMS.SELECTED_DOMAIN);

  const { mutateAsync: deleteDomain } = useMutation(
    () => {
      if (!isValidUUID) {
        setMessage(
          'An error occurred while deleting the domain. Invalid domain UUID provided.'
        );
        amplEvent(
          `${EVENT_MESSAGE} ${domainUUID} error -> ${INVALID_UUID_MESSAGE}`
        );
        throw new Error(INVALID_UUID_MESSAGE);
      }

      return fetcherDelete(`${QUERY_TYPES.domain}/${domainUUID}`);
    },
    {
      onSuccess: (data) => {
        if (data && data.success) {
          amplEvent(`${EVENT_MESSAGE} ${domainUUID} success`);
          queryClient.invalidateQueries(QUERY_TYPES.domainList);

          if (domainFromUrl === domainUUID) {
            updateQueryParams(
              { [SEARCH_PARAMS.SELECTED_DOMAIN]: null },
              { useReplace: true }
            );
          }
        } else {
          setMessage('An error occurred while deleting the domain');
          amplEvent(`${EVENT_MESSAGE} ${domainUUID} error-1`);
        }
      },
      onError: (error) => {
        if (error.message !== INVALID_UUID_MESSAGE) {
          amplEvent(`${EVENT_MESSAGE} ${domainUUID} error-2`);
          setMessage('An error occurred while deleting the domain');
        }
      },
    }
  );

  return { deleteDomain };
};

export { useDeleteDomain };
