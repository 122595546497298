import React from 'react';
import { Search, DomainsTable } from './components/components.js';
import { StartDomain } from '../components.js';
import { useDataProductsPageContext } from 'context/context.js';

const DomainsTab = () => {
  const { isStartDomainPage } = useDataProductsPageContext();

  if (isStartDomainPage) {
    return <StartDomain />;
  }

  return (
    <>
      <Search />
      <DomainsTable />
    </>
  );
};

export { DomainsTab };
