import React from 'react';
import { useLocation, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  CostsStatsWidget,
  OptimizedCostsWidget,
  StorageCostsWidget,
} from 'Components/components.js';
import { SavingsOptionsWidget } from './SavingsOptionsWidget.js';
import { COSTS_STATS_VIEW_TYPE } from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'start',
    '& > *': {
      width: ({ widgetWidth, viewType }) =>
        viewType === COSTS_STATS_VIEW_TYPE.graph ? '50%' : widgetWidth,
    },
  },
  widgetContainer: {
    minHeight: 164,
  },
}));

const CostsStats = ({
  viewType = COSTS_STATS_VIEW_TYPE.table,
  className = '',
}) => {
  const location = useLocation();
  const [savingsOptionsWidgetExists, setSavingsOptionsWidgetExists] =
    useState(true);
  const widgetWidth = savingsOptionsWidgetExists ? '50%' : '100%';
  const classes = useStyles({ widgetWidth, viewType });
  const currentPage = location.pathname;

  const handleChangeSavingsOptionsWidget = (value) => {
    setSavingsOptionsWidgetExists(value);
  };

  return (
    <div className={clsx(classes.flexContainer, className)}>
      <CostsStatsWidget viewType={viewType} />

      {currentPage === AppRoutes.Costs.path ? (
        <OptimizedCostsWidget>
          <SavingsOptionsWidget
            onChangeSavingsOptionsWidget={handleChangeSavingsOptionsWidget}
          />
        </OptimizedCostsWidget>
      ) : (
        <StorageCostsWidget className={classes.widgetContainer} />
      )}
    </div>
  );
};

export { CostsStats };
