import React from 'react';
import {
  useCallback,
  useEffect,
  useState,
  usePagination,
  useAutoChangeProject,
  useSelector,
} from 'hooks/hooks.js';
import { useStyles } from './Dictionary.styles.js';
import {
  Backdrop,
  PageHeader,
  Pagination,
  Tabs,
} from 'Components/components.js';
import {
  DictionaryTable,
  DictionaryDataset,
  DictionaryColumn,
  DictionaryLooker,
  Dropdowns,
} from './components/components.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  DictionaryTabs,
  DOCUMENT_TITLE,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import { getTabDropdowns } from './libs/helpers/helpers.js';
import { useIntegration, useQueryParamsContext } from 'context/context.js';
import {
  DATASET_ALERT_STATUS_FILTER,
  defaultDropdownsValue,
} from './libs/constants/constants.js';

const PARAMS_TO_REMOVE = [
  SEARCH_PARAMS.TABLE,
  SEARCH_PARAMS.DATASET,
  SEARCH_PARAMS.SEARCH,
  SEARCH_PARAMS.SUGGESTED_PRIORITY,
];

const Dictionary = () => {
  const classes = useStyles();
  const { queryParams } = useQueryParamsContext();
  const { isLookerConnected } = useIntegration();
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);
  const [dropdownsValue, setDropdownsValue] = useState(defaultDropdownsValue);
  const [totalNumber, setTotalNumber] = useState(0);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);

  useAutoChangeProject();

  const { tablesNumber, columnsNumber, lookerNumber } = useSelector(
    (state) => ({
      tablesNumber: state.tables.tablesNumber,
      columnsNumber: state.columns.columnsNumber,
      lookerNumber: state.looker.lookerNumber,
    })
  );

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dictionary;
    amplEvent(AMPL_PAGE_EVENT.dictionary);
  }, []);

  useEffect(() => {
    const numberMapping = {
      [DictionaryTabs.TABLES]: tablesNumber,
      [DictionaryTabs.COLUMNS]: columnsNumber,
      [DictionaryTabs.LOOKER]: lookerNumber,
    };

    setTotalNumber(numberMapping[currentTab]);
  }, [tablesNumber, columnsNumber, lookerNumber, currentTab]);

  const handleChangeTotalNumber = useCallback((value) => {
    setTotalNumber(value);
  }, []);

  const handleChangeBackdrop = useCallback((value) => {
    setIsOpenBackdrop(value);
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case DictionaryTabs.COLUMNS:
        return (
          <DictionaryColumn
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      case DictionaryTabs.DATASETS:
        return (
          <DictionaryDataset
            selectedAlertType={dropdownsValue[DATASET_ALERT_STATUS_FILTER]}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onReceiveTotalNumber={handleChangeTotalNumber}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      case DictionaryTabs.LOOKER:
        return (
          <DictionaryLooker
            filtersValue={dropdownsValue}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      default:
        return (
          <DictionaryTable
            filtersValue={dropdownsValue}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
    }
  };

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  return (
    <>
      <PageHeader hasDateRangePicker={false}>Dictionary</PageHeader>
      <Tabs tabs={DictionaryTabs} paramsToRemove={PARAMS_TO_REMOVE} />
      <div className={classes.contentContainer}>
        <Dropdowns
          dropdowns={getTabDropdowns(currentTab, isLookerConnected)}
          onChangeDropdownsValue={handleChangeDropdownsValue}
        />

        {renderPage()}

        <Backdrop isOpen={isOpenBackdrop} isPageCentering={true} />
      </div>

      {!isOpenBackdrop && (
        <Pagination
          count={totalNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default Dictionary;
