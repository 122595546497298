import React from 'react';
import { useDeleteDataProduct } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  CostLabel,
  Modal,
  RadioDropdown,
  RightSideModal,
  StyledTableCell,
  StyledTableRow,
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import {
  EditDataProductModalBody,
  DeleteDataProductModalBody,
} from '../components.js';
import { useDataProductsPageContext, useModal } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  DATA_PRODUCT_ACTIONS_DROPDOWN,
  EDIT,
  DELETE,
  SUBSCRIBER_COLORS,
} from 'Pages/DataProdact/constants/constants.js';
import {
  Name,
  Domain,
  Subscribers,
  Cost,
  Incidents,
  Edit,
} from 'Pages/DataProdact/enums/enums.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const VISIBLE_SUBSCRIBERS = 10;

const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    '&.MuiTableRow-root': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '& .editCell > *': {
      opacity: 0,
    },
    '&:hover .editCell > *': {
      opacity: 1,
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  lettersContainer: {
    display: 'flex',
  },
  letterBlock: {
    width: 22,
    height: 22,
    marginLeft: -4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    fontWeight: 600,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 2,
  },
}));

const TableRow = ({ dataProduct }) => {
  const tableClasses = useTableStyles();
  const { setModal } = useModal();
  const { handleChangeToolbarText } = useDataProductsPageContext();
  const { uuid, name, domain, subscribers, costsTotal, incidentsTotal } =
    dataProduct;

  const { deleteDataProduct } = useDeleteDataProduct({
    dataProductUUID: uuid,
  });

  const subscribersCellText = `${subscribers.total} subscriber${
    subscribers.total === 1 ? '' : 's'
  }`;

  const subscribersCellTooltipText = (
    <>
      {subscribers.values.map((item) => (
        <div key={item}>{item}</div>
      ))}
      {subscribers.total > VISIBLE_SUBSCRIBERS && <div>...</div>}
    </>
  );

  const onDeleteDataProductClick = () => {
    deleteDataProduct().then((data) => {
      if (data && data.success) {
        handleChangeToolbarText('The data product was successfully deleted');
      }
    });
  };

  const onFilterSelect = (event) => {
    if (event.value === EDIT) {
      setModal(() => (
        <RightSideModal
          title={`Edit ${name}`}
          ModalBodyComponent={EditDataProductModalBody}
          dataProductUUID={uuid}
          mainBlockMargin={0}
        />
      ));
    }

    if (event.value === DELETE) {
      setModal(() => (
        <Modal
          onAgree={onDeleteDataProductClick}
          title={`Delete ${name}`}
          titlePosition='center'
          ModalBodyComponent={DeleteDataProductModalBody}
          agreeButtonText='Delete'
        />
      ));
    }
  };

  const tableCellData = [
    {
      id: Name.id,
      label: (
        <Link
          to={{ pathname: `${AppRoutes.DataProduct.path}/${uuid}` }}
          className='txt-blue-13-700'
        >
          {name}
        </Link>
      ),
      align: Name.align,
    },
    {
      id: Domain.id,
      label: domain && (
        <div>
          {domain.name}
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
            {domain.email}
          </Typography>
        </div>
      ),
      align: Domain.align,
    },
    {
      id: Subscribers.id,
      label: subscribers.total > 0 && (
        <Tooltip title={subscribersCellTooltipText}>
          <div className={tableClasses.flexContainer}>
            <div className={tableClasses.lettersContainer}>
              {subscribers.values.slice(0, 5).map((subscriber, index) => {
                const color = SUBSCRIBER_COLORS[index];

                return (
                  <div
                    className={tableClasses.letterBlock}
                    key={subscriber}
                    style={{ backgroundColor: color }}
                  >
                    {subscriber[0].toUpperCase()}
                  </div>
                );
              })}
            </div>
            <div className='txt-grey-13-500'>{subscribersCellText}</div>
          </div>
        </Tooltip>
      ),
      align: Subscribers.align,
    },
    {
      id: Cost.id,
      label: <CostLabel value={costsTotal} withAbbreviateNumber={true} />,
      align: Cost.align,
    },
    {
      id: Incidents.id,
      label: incidentsTotal > 0 && numberFormat(incidentsTotal),
      align: Incidents.align,
    },
    {
      id: Edit.id,
      label: (
        <RadioDropdown
          options={DATA_PRODUCT_ACTIONS_DROPDOWN}
          current={{ icon: PointsIcon }}
          onSelect={(event) => onFilterSelect(event)}
          bottom={'-24px'}
          right={'12px'}
        />
      ),
      align: Edit.align,
      className: 'editCell',
    },
  ];

  return (
    <StyledTableRow className={tableClasses.tableRow}>
      {tableCellData.map((item) => (
        <StyledTableCell
          key={item.id}
          align={item.align}
          className={item?.className}
        >
          {item.label || <EmptyCell />}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

const EmptyCell = () => {
  return <span className='txt-grey-13-500'>&mdash;</span>;
};

export { TableRow };
