import React from 'react';
import { useMemo, useState, useEffect } from 'hooks/hooks.js';
import { useStyles } from 'Pages/Dictionary/Dictionary.styles.js';
import { StyledDropdown, SuggestedPriority } from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import {
  getDropdownValueByURL,
  isDropdownsEqual,
  prepareQueryParamsByFilters,
} from 'utils/helpers/helpers.js';
import {
  getDefaultDropdownsValueByKey,
  getKeyForURL,
} from 'Pages/Dictionary/libs/helpers/helpers.js';
import {
  ALERT_FILTER_OPTIONS,
  DICTIONARY_LOOKER_TYPES_OPTIONS,
  DICTIONARY_TYPES_OPTIONS,
  DICTIONARY_TYPES_STREAM,
} from 'utils/constants.js';
import { DictionaryTabs, SEARCH_PARAMS } from 'constants/constants.js';
import {
  TABLE_ALERT_STATUS_FILTER,
  DICTIONARY_STREAM_FILTER,
  LOOKER_ALERT_STATUS_FILTER,
  LOOKER_REPORTS_TYPE_FILTER,
  TABLE_TYPE_FILTER,
  DATASET_ALERT_STATUS_FILTER,
  defaultDropdownsValue,
} from 'Pages/Dictionary/libs/constants/constants.js';
import { RegularMiniIcon } from 'assets/img/alert';
import { ReactComponent as DictionaryTypeIcon } from 'assets/img/dictionaryIcons/typeIcon.svg';
import { ReactComponent as DictionaryStreamIcon } from 'assets/img/dictionaryIcons/showIcon.svg';

const Dropdowns = ({ dropdowns, onChangeDropdownsValue }) => {
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);
  const existingSuggestedCritical = currentTab === DictionaryTabs.TABLES;
  const [dropdownsValue, setDropdownsValue] = useState(defaultDropdownsValue);

  const existingDropdowns = useMemo(() => {
    return dropdowns || [];
  }, [dropdowns]);

  useEffect(() => {
    const newQueryParams = prepareQueryParamsByFilters({
      defaultDropdownsValue,
      dropdownsValue,
      existingDropdown: existingDropdowns,
      queryParams,
      getKeyForURL,
    });

    if (Object.keys(newQueryParams).length > 0) {
      const useReplace = Object.entries(newQueryParams).some(
        ([key, value]) =>
          value === null || value === undefined || queryParams.get(key) === null
      );

      updateQueryParams(newQueryParams, { useReplace });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownsValue]);

  useEffect(() => {
    const getDropdownValueByFilter = (filterKey, param, options) => {
      let dropdownValueByFilter = null;
      if (existingDropdowns.includes(filterKey)) {
        const valueFromURL = queryParams.get(param);

        if (valueFromURL === null) {
          dropdownValueByFilter = getDefaultDropdownsValueByKey(filterKey);
        } else {
          dropdownValueByFilter = getDropdownValueByURL(
            filterKey,
            options,
            valueFromURL
          );
        }
      }

      return dropdownValueByFilter;
    };

    const newDropdownsValue = {};
    newDropdownsValue[TABLE_ALERT_STATUS_FILTER] = getDropdownValueByFilter(
      TABLE_ALERT_STATUS_FILTER,
      SEARCH_PARAMS.TABLE_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[DICTIONARY_STREAM_FILTER] = getDropdownValueByFilter(
      DICTIONARY_STREAM_FILTER,
      SEARCH_PARAMS.STREAM,
      DICTIONARY_TYPES_STREAM
    );
    newDropdownsValue[TABLE_TYPE_FILTER] = getDropdownValueByFilter(
      TABLE_TYPE_FILTER,
      SEARCH_PARAMS.TABLE_TYPE,
      DICTIONARY_TYPES_OPTIONS
    );
    newDropdownsValue[DATASET_ALERT_STATUS_FILTER] = getDropdownValueByFilter(
      DATASET_ALERT_STATUS_FILTER,
      SEARCH_PARAMS.DATASET_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[LOOKER_ALERT_STATUS_FILTER] = getDropdownValueByFilter(
      LOOKER_ALERT_STATUS_FILTER,
      SEARCH_PARAMS.LOOKER_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[LOOKER_REPORTS_TYPE_FILTER] = getDropdownValueByFilter(
      LOOKER_REPORTS_TYPE_FILTER,
      SEARCH_PARAMS.LOOKER_TYPE,
      DICTIONARY_LOOKER_TYPES_OPTIONS
    );

    setDropdownsValue((prevState) => {
      if (isDropdownsEqual(newDropdownsValue, prevState)) {
        return prevState;
      }

      return newDropdownsValue;
    });
  }, [existingDropdowns, queryParams]);

  const filteredDropdownsValue = useMemo(() => {
    return Object.keys(dropdownsValue)
      .filter((key) => existingDropdowns.includes(key))
      .reduce((obj, key) => {
        obj[key] = dropdownsValue[key];
        return obj;
      }, {});
  }, [dropdownsValue, existingDropdowns]);

  useEffect(() => {
    onChangeDropdownsValue(filteredDropdownsValue);
  }, [filteredDropdownsValue, onChangeDropdownsValue]);

  const handleChangeDropdowns = (key, value) => {
    const newDropdownsValue = { [key]: value };
    setDropdownsValue((prevState) => ({ ...prevState, ...newDropdownsValue }));
  };

  const renderDropdown = (
    filterKey,
    options,
    icon,
    selectAllOption,
    multipleSelect,
    afterLabel,
    beforeLabel
  ) =>
    existingDropdowns.includes(filterKey) && (
      <StyledDropdown
        options={options}
        defaultValue={dropdownsValue[filterKey] || []}
        Icon={icon}
        onSelect={(value) => handleChangeDropdowns(filterKey, value)}
        selectAllOption={selectAllOption}
        multipleSelect={multipleSelect}
        afterLabel={afterLabel}
        beforeLabel={beforeLabel}
      />
    );

  if (!existingDropdowns.length) {
    return null;
  }

  return (
    <div className={classes.dropdownsContainer}>
      {renderDropdown(
        TABLE_ALERT_STATUS_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        dropdownsValue[TABLE_ALERT_STATUS_FILTER]?.[0] ===
          ALERT_FILTER_OPTIONS[0]
          ? ' of tables'
          : ' tables'
      )}
      {renderDropdown(
        DICTIONARY_STREAM_FILTER,
        DICTIONARY_TYPES_STREAM,
        DictionaryStreamIcon,
        DICTIONARY_TYPES_STREAM[0],
        false,
        '',
        'Show '
      )}
      {renderDropdown(
        TABLE_TYPE_FILTER,
        DICTIONARY_TYPES_OPTIONS,
        DictionaryTypeIcon,
        DICTIONARY_TYPES_OPTIONS[0],
        true,
        ' type',
        'Show '
      )}
      {renderDropdown(
        DATASET_ALERT_STATUS_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        dropdownsValue[DATASET_ALERT_STATUS_FILTER]?.[0] ===
          ALERT_FILTER_OPTIONS[0]
          ? ' of datasets'
          : ' datasets'
      )}
      {renderDropdown(
        LOOKER_ALERT_STATUS_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        dropdownsValue[LOOKER_ALERT_STATUS_FILTER]?.[0] ===
          ALERT_FILTER_OPTIONS[0]
          ? ' of dashboards'
          : ' dashboards'
      )}
      {renderDropdown(
        LOOKER_REPORTS_TYPE_FILTER,
        DICTIONARY_LOOKER_TYPES_OPTIONS,
        DictionaryTypeIcon,
        DICTIONARY_LOOKER_TYPES_OPTIONS[0],
        false,
        ' type',
        'Show '
      )}

      {existingSuggestedCritical && <SuggestedPriority />}
    </div>
  );
};

export { Dropdowns };
