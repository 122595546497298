import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
  },
  searchContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    '& .searchInput': {
      flexGrow: 1,
    },
  },
  createButton: {
    width: 140,
  },
}));
