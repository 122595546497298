import React from 'react';
import { useRef, useState, useOnClickOutside } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Chip } from 'Components/components.js';
import {
  REGULAR_ALERT_TYPE,
  MUTED_ALERT_TYPE,
  PRIORITY_ALERT_TYPE,
  CRITICAL_ALERT_TYPE,
} from 'utils/constants.js';
import {
  RegularIcon,
  PriorityIcon,
  MutedIcon,
  CriticalIcon,
} from 'assets/img/alert';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'right',
    cursor: 'pointer',
    position: 'relative',
  },
  label: {
    display: 'flex',
  },
  labelText: {
    marginLeft: 10,
  },
  list: {
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.16)',
    position: 'absolute',
    top: (props) => props.top,
    left: (props) => props.left,
    bottom: (props) => props.bottom,
    right: (props) => props.right,

    display: 'flex',
    flexDirection: 'column',
    minWidth: 130,
    background: theme.palette.common.white,
    borderRadius: 12,
    listStyle: 'none',
    padding: 0,
    zIndex: 3,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mainDarkText,
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    padding: '4px 10px',
    margin: 4,
    lineHeight: '22px',
    borderRadius: 12,
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.selected': {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '& svg': {
      height: 13,
      marginRight: 6,
    },
  },
}));

const RadioDropdown = ({
  options,
  current,
  onSelect,
  top = 'inherit',
  left = 'inherit',
  bottom = 'inherit',
  right = 'inherit',
  isAlertTypeDropdown = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const classes = useStyles({ top, left, bottom, right });

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpened(false));

  const isSelected = (value) => current.value === value;

  const onSelectHandler = (option) => {
    if (onSelect) {
      onSelect(option);
    }
    setIsOpened(false);
  };

  const handleOpen = () => {
    if (!isOpened) {
      setIsOpened(true);
    }
  };

  return (
    <div className={classes.wrapper} onClick={handleOpen}>
      {isAlertTypeDropdown ? (
        <Chip
          label={current.alertLabel}
          color={current.colorType}
          startIcon={<current.iconSvg />}
          endIcon={<ArrowDown />}
          clickable={true}
        />
      ) : (
        <div className={clsx(classes.label)}>
          {current?.icon && <img src={current.icon} alt='alert icon' />}
          {current?.label && current.label}
        </div>
      )}

      {isOpened && (
        <ul ref={ref} className={classes.list}>
          {options.map(({ value, label }) => {
            const selected = isSelected(value);
            return (
              <li
                onClick={() => onSelectHandler({ value, label })}
                className={clsx(classes.listItem, selected && ' selected')}
                key={`${value}-${label}`}
              >
                <div>
                  {value === CRITICAL_ALERT_TYPE && <CriticalIcon />}
                  {value === PRIORITY_ALERT_TYPE && <PriorityIcon />}
                  {value === REGULAR_ALERT_TYPE && <RegularIcon />}
                  {value === MUTED_ALERT_TYPE && <MutedIcon />}
                  <span className={classes.listItemText}>{label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export { RadioDropdown };
