import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, PrivateContextProviders } from 'Components/components.js';
import { useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';

function SupperPrivateRoute({ component: Component, ...rest }) {
  const { user } = useUserInfo();
  return (
    <Route
      {...rest}
      render={(props) =>
        !!user && user.info.supperAdmin ? (
          <PrivateContextProviders>
            <Layout>
              <Component {...props} />
            </Layout>
          </PrivateContextProviders>
        ) : (
          <Redirect to={{ pathname: AppRoutes.Dashboard.path }} />
        )
      }
    />
  );
}

export { SupperPrivateRoute };
