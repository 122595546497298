import React from 'react';
import { makeStyles } from '@mui/styles';
import { RadioDropdown } from 'Components/components.js';
import { CHART_TYPES_OPTIONS } from '../libs/constants.js';

const useStyles = makeStyles((theme) => ({
  widgetLabel: {
    display: 'flex',
    gap: theme.spacing(1),
    '& .dashed-underline': {
      borderBottom: '2px dashed',
    },
  },
}));

const WidgetLabel = ({ selectedChartType, onChangeChartType }) => {
  const classes = useStyles();

  return (
    <div className={classes.widgetLabel}>
      Executed jobs by
      <RadioDropdown
        options={CHART_TYPES_OPTIONS}
        current={{
          label: (
            <span className='dashed-underline'>
              {selectedChartType.toLowerCase()}
            </span>
          ),
          value: selectedChartType,
        }}
        onSelect={(event) => onChangeChartType(event)}
        top='32px'
        left='0'
      />
    </div>
  );
};

export { WidgetLabel };
