import React from 'react';
import {
  useQueryClient,
  useQuery,
  useMemo,
  useCallback,
  useState,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image, Card, Col } from 'react-bootstrap';
import { useStyles } from '../Integrations.styles.js';
import {
  useIntegration,
  useMessages,
  useModal,
  useUserInfo,
} from 'context/context.js';
import {
  Button,
  BUTTON_COLOR,
  BUTTON_VARIANT,
  LoadingButton,
} from 'Components/components.js';
import {
  connectDataPlex,
  connectDataPlexPost,
  connectDataForm,
  connectDataFormPost,
  getJiraIntegrationUrl,
} from '../libs/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import {
  INTEGRATIONS,
  REDIRECT_PAGERDUTY_URL,
} from '../libs/constants/constants.js';
import { LookerModal } from './components.js';
import {
  SlackItem,
  JiraItem,
  LookerItem,
  DataplexItem,
  DataformItem,
  PagerDutyItem,
  GoogleChatItem,
} from './IntegrationItems/items.js';
import ConnectedIcon from 'assets/img/icons/connectedIcon.svg';

const IntegrationItem = ({
  isConnected,
  Icon,
  name,
  title,
  unavailable,
  desc,
}) => {
  const { integrations } = useIntegration();
  const { user } = useUserInfo();
  const classes = useStyles();
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const queryClient = useQueryClient();
  const [isLoadingDataplexConnection, setIsLoadingDataplexConnection] =
    useState(false);
  const [isLoadingDataformConnection, setIsLoadingDataformConnection] =
    useState(false);

  const isDisconnectedVisible =
    !isConnected && name === INTEGRATIONS.googleChat;

  const { data: slackUrl } = useQuery(
    [QUERY_TYPES.slackSettings],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const { data: basePagerDutyUrl } = useQuery(
    [QUERY_TYPES.pagerDutySettings],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const isShowConnectSlackButton = useMemo(() => {
    return name === INTEGRATIONS.slack && slackUrl?.value?.connectionUrl;
  }, [name, slackUrl?.value?.connectionUrl]);

  const isShowConnectPagerDutyButton = useMemo(() => {
    return (
      name === INTEGRATIONS.pagerduty && basePagerDutyUrl?.value?.connectionUrl
    );
  }, [name, basePagerDutyUrl?.value?.connectionUrl]);

  const pagerDutyUrl = useMemo(() => {
    if (basePagerDutyUrl?.value) {
      const url = new URL(basePagerDutyUrl.value?.connectionUrl);
      url.searchParams.set('redirect_url', REDIRECT_PAGERDUTY_URL);

      return url.toString();
    }

    return null;
  }, [basePagerDutyUrl?.value]);

  const openLookerModal = () => {
    setModal(() => <LookerModal />);
  };

  const handleChangeIsLoadingDataplexConnection = useCallback((value) => {
    setIsLoadingDataplexConnection(value);
  }, []);

  const handleChangeIsLoadingDataformConnection = useCallback((value) => {
    setIsLoadingDataformConnection(value);
  }, []);

  const ViewByType =
    {
      slack: SlackItem,
      jira: JiraItem,
      looker: LookerItem,
      dataplex: DataplexItem,
      dataform: DataformItem,
      pagerduty: PagerDutyItem,
      googleChat: GoogleChatItem,
    }[name] || null;

  const renderConnectButton = () => {
    const connectActions = {
      slack: {
        show: isShowConnectSlackButton,
        onClick: () => (window.location = slackUrl.value.connectionUrl),
        buttonText: 'Connect',
      },
      jira: {
        show: name === INTEGRATIONS.jira,
        onClick: () => (window.location = getJiraIntegrationUrl()),
        buttonText: 'Connect',
      },
      pagerduty: {
        show: isShowConnectPagerDutyButton,
        onClick: () => (window.location = pagerDutyUrl),
        buttonText: 'Connect',
      },
      looker: {
        show: name === INTEGRATIONS.looker,
        onClick: openLookerModal,
        buttonText: 'Connect to project',
      },
      dataplex: {
        show: name === INTEGRATIONS.dataplex,
        isLoading: isLoadingDataplexConnection,
        onClick: () =>
          connectDataPlex(
            user,
            queryClient,
            setMessage,
            handleChangeIsLoadingDataplexConnection
          ),
        buttonText: 'Create and connect with OAuth2',
        isLoadingButton: true,
        extraButtons: [
          {
            buttonText: 'Connect to project',
            onClick: () => connectDataPlexPost(queryClient, setMessage),
          },
        ],
      },
      dataform: {
        show: name === INTEGRATIONS.dataform,
        isLoading: isLoadingDataformConnection,
        onClick: () =>
          connectDataForm(
            user,
            queryClient,
            setMessage,
            handleChangeIsLoadingDataformConnection
          ),
        buttonText: 'Create and connect with OAuth2',
        isLoadingButton: true,
        extraButtons: [
          {
            buttonText: 'Connect to project',
            onClick: () => connectDataFormPost(queryClient, setMessage),
          },
        ],
      },
    };

    const currentAction = connectActions[name];

    if (!currentAction || !currentAction.show) {
      return null;
    }

    return (
      <>
        {currentAction.extraButtons?.map((button, index) => (
          <Button
            key={index}
            text={button.buttonText}
            variant={BUTTON_VARIANT.text}
            onClick={button.onClick}
            className='mb-2'
          />
        ))}
        {currentAction.isLoadingButton ? (
          <LoadingButton
            isLoading={currentAction.isLoading}
            onClick={currentAction.onClick}
            children={currentAction.buttonText}
            fullWidth={true}
          />
        ) : (
          <Button
            text={currentAction.buttonText}
            color={BUTTON_COLOR.secondary}
            onClick={currentAction.onClick}
            fullWidth={true}
          />
        )}
      </>
    );
  };

  return (
    <Col xs={12} md='auto' style={{ marginBottom: 20 }}>
      <Card border='white'>
        <Card.Body className={classes.card}>
          <div>
            <div className={classes.logoContainer}>
              <Image src={Icon} className='logoImg' alt='company logo' />
              <div>
                {isConnected && (
                  <div className={classes.chipContainer}>
                    <img src={ConnectedIcon} alt='icon' />
                    <div className={classes.chip}>Connected</div>
                  </div>
                )}
                {!isConnected && unavailable && (
                  <span
                    style={{ padding: 4 }}
                    className={clsx(classes.chip, 'disabled')}
                  >
                    Coming Soon
                  </span>
                )}
                {isDisconnectedVisible && (
                  <div className={classes.chipContainer}>
                    <div className={classes.chip}>Disconnected</div>
                  </div>
                )}
              </div>
            </div>
            <div className='txt-mainDark-20-700'>{title}</div>
            <p className='txt-grey-13-500 my-1'>{desc}</p>
          </div>

          {ViewByType && isConnected && (
            <ViewByType {...integrations?.[name]} />
          )}

          {!isConnected && !unavailable && renderConnectButton()}
        </Card.Body>
      </Card>
    </Col>
  );
};

export { IntegrationItem };
