import React from 'react';
import { useMemo, useState, useEffect } from 'hooks/hooks.js';
import { StyledDropdown } from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import {
  getDropdownValueByURL,
  getPipelineMetaData,
  isDropdownsEqual,
  prepareQueryParamsByFilters,
} from 'utils/helpers/helpers.js';
import {
  ALERT_FILTER_OPTIONS,
  PIPELINE_SUBTYPE_LABELS,
  PIPELINE_TYPE,
} from 'utils/constants.js';
import {
  ALTERNATIVE_PLAN_OPTION,
  COST_EFFICIENCY_FILTER_OPTIONS,
  PIPELINE_TIME_PERIOD_OPTIONS,
  CostsTabs,
  SEARCH_PARAMS,
  STORAGE_COST_EFFICIENCY_FILTER_OPTIONS,
} from 'constants/constants.js';
import {
  COST_EFFICIENCY_FILTER,
  INITIAL_COST_EFFICIENCY_OPTION,
  INITIAL_PIPELINE_SUBTYPES_OPTION,
  INITIAL_TECHNOLOGY_OPTION,
  PIPELINE_SUBTYPES_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
  TIME_PERIOD_FILTER,
  STORAGE_COSTS_EFFICIENCY_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { RegularMiniIcon } from 'assets/img/alert';
import { ReactComponent as ClockIcon } from 'assets/img/icons/clock.svg';
import { ReactComponent as TechnologyIcon } from 'assets/img/icons/tech.svg';
import { ReactComponent as CostStatsIcon } from 'assets/img/icons/cost-stats.svg';
import { ReactComponent as PipelineIcon } from 'assets/img/sidebarIcons/pipeline.svg';

const getKeyForURL = (type) => {
  switch (type) {
    case TABLE_ALERT_STATUS_FILTER:
      return SEARCH_PARAMS.TABLE_ALERT_TYPE;
    case TIME_PERIOD_FILTER:
      return SEARCH_PARAMS.TIME_PERIOD;
    case TECHNOLOGY_FILTER:
      return SEARCH_PARAMS.PIPELINE_TYPE;
    case COST_EFFICIENCY_FILTER:
      return SEARCH_PARAMS.COST_EFFICIENCY;
    case PIPELINE_SUBTYPES_FILTER:
      return SEARCH_PARAMS.PIPELINE_SUBTYPES;
    case STORAGE_COSTS_EFFICIENCY_FILTER:
      return SEARCH_PARAMS.STORAGE_COSTS_EFFICIENCY;
    default:
      return null;
  }
};

const DEFAULT_DROPDOWNS_VALUE = {
  [TABLE_ALERT_STATUS_FILTER]: null,
  [TIME_PERIOD_FILTER]: null,
  [TECHNOLOGY_FILTER]: null,
  [COST_EFFICIENCY_FILTER]: null,
  [PIPELINE_SUBTYPES_FILTER]: null,
  [STORAGE_COSTS_EFFICIENCY_FILTER]: null,
};

const PipelinesDropdowns = ({
  existingDropdown = [],
  pipelineStats = [],
  pipelineSubtypesData = null,
  currentTab = null,
  onChangeDropdownsValue,
}) => {
  const { queryParams, updateQueryParams } = useQueryParamsContext();

  const technologyFilterOptions = useMemo(() => {
    if (pipelineStats?.length > 0) {
      return [
        INITIAL_TECHNOLOGY_OPTION,
        ...pipelineStats.map((item) => ({
          value: item.pipelineType,
          label: getPipelineMetaData(item.pipelineType).title,
        })),
      ];
    }

    return [];
  }, [pipelineStats]);

  const costEfficiencyFilterOptions = useMemo(() => {
    return [
      ...COST_EFFICIENCY_FILTER_OPTIONS,
      ...(currentTab === CostsTabs.COSTS
        ? [{ value: ALTERNATIVE_PLAN_OPTION, label: 'Alternative plan option' }]
        : []),
    ];
  }, [currentTab]);

  const pipelineSubtypesFilterOptions = useMemo(() => {
    if (pipelineSubtypesData?.length > 0) {
      return [
        INITIAL_PIPELINE_SUBTYPES_OPTION,
        ...pipelineSubtypesData.map((item) => ({
          value: item,
          label: PIPELINE_SUBTYPE_LABELS[item] || 'Unknown',
        })),
      ];
    }

    return [];
  }, [pipelineSubtypesData]);

  const getDefaultDropdownsValueByKey = (key) => {
    const defaultDropdownsValue = {
      [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
      [TIME_PERIOD_FILTER]: [PIPELINE_TIME_PERIOD_OPTIONS.at(-1)],
      [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
      [COST_EFFICIENCY_FILTER]: [INITIAL_COST_EFFICIENCY_OPTION],
      [PIPELINE_SUBTYPES_FILTER]: [INITIAL_PIPELINE_SUBTYPES_OPTION],
      [STORAGE_COSTS_EFFICIENCY_FILTER]: [
        STORAGE_COST_EFFICIENCY_FILTER_OPTIONS[0],
      ],
    };

    return defaultDropdownsValue[key] || null;
  };

  const [dropdownsValue, setDropdownsValue] = useState(DEFAULT_DROPDOWNS_VALUE);

  useEffect(() => {
    const isDropdownsValueReady = Object.values(dropdownsValue).some(
      (value) => value !== null
    );
    if (!isDropdownsValueReady) {
      return;
    }

    const newQueryParams = prepareQueryParamsByFilters({
      defaultDropdownsValue: DEFAULT_DROPDOWNS_VALUE,
      dropdownsValue,
      existingDropdown,
      queryParams,
      getKeyForURL,
    });

    if (Object.keys(newQueryParams).length > 0) {
      const useReplace = Object.entries(newQueryParams).some(
        ([key, value]) =>
          value === null || value === undefined || queryParams.get(key) === null
      );

      updateQueryParams(newQueryParams, { useReplace });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownsValue]);

  useEffect(() => {
    const getDropdownValueByFilter = (filterKey, param, options) => {
      let dropdownValueByFilter = null;
      if (existingDropdown.includes(filterKey)) {
        const valueFromURL = queryParams.get(param);

        if (valueFromURL === null) {
          dropdownValueByFilter = getDefaultDropdownsValueByKey(filterKey);
        } else {
          dropdownValueByFilter = getDropdownValueByURL(
            filterKey,
            options,
            valueFromURL
          );
        }
      }

      return dropdownValueByFilter;
    };

    const newDropdownsValue = {};
    newDropdownsValue[TABLE_ALERT_STATUS_FILTER] = getDropdownValueByFilter(
      TABLE_ALERT_STATUS_FILTER,
      SEARCH_PARAMS.TABLE_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[TIME_PERIOD_FILTER] = getDropdownValueByFilter(
      TIME_PERIOD_FILTER,
      SEARCH_PARAMS.TIME_PERIOD,
      PIPELINE_TIME_PERIOD_OPTIONS
    );
    newDropdownsValue[COST_EFFICIENCY_FILTER] = getDropdownValueByFilter(
      COST_EFFICIENCY_FILTER,
      SEARCH_PARAMS.COST_EFFICIENCY,
      costEfficiencyFilterOptions
    );
    newDropdownsValue[STORAGE_COSTS_EFFICIENCY_FILTER] =
      getDropdownValueByFilter(
        STORAGE_COSTS_EFFICIENCY_FILTER,
        SEARCH_PARAMS.STORAGE_COSTS_EFFICIENCY,
        STORAGE_COST_EFFICIENCY_FILTER_OPTIONS
      );

    if (technologyFilterOptions.length > 0) {
      newDropdownsValue[TECHNOLOGY_FILTER] = getDropdownValueByFilter(
        TECHNOLOGY_FILTER,
        SEARCH_PARAMS.PIPELINE_TYPE,
        technologyFilterOptions
      );
    }

    if (pipelineSubtypesFilterOptions.length > 0) {
      newDropdownsValue[PIPELINE_SUBTYPES_FILTER] = getDropdownValueByFilter(
        PIPELINE_SUBTYPES_FILTER,
        SEARCH_PARAMS.PIPELINE_SUBTYPES,
        pipelineSubtypesFilterOptions
      );
    }

    setDropdownsValue((prevState) => {
      if (isDropdownsEqual(newDropdownsValue, prevState)) {
        return prevState;
      }

      return newDropdownsValue;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existingDropdown,
    queryParams,
    technologyFilterOptions,
    costEfficiencyFilterOptions,
    pipelineSubtypesFilterOptions,
  ]);

  const filteredDropdownsValue = useMemo(() => {
    return Object.keys(dropdownsValue)
      .filter((key) => existingDropdown.includes(key))
      .reduce((obj, key) => {
        obj[key] = dropdownsValue[key];
        return obj;
      }, {});
  }, [dropdownsValue, existingDropdown]);

  useEffect(() => {
    onChangeDropdownsValue(filteredDropdownsValue);
  }, [filteredDropdownsValue, onChangeDropdownsValue]);

  const getDropdownLabel = (
    filterKey,
    filterOptions,
    afterLabelIfMatch = '',
    afterLabelIfNoMatch = '',
    beforeLabelIfMatch = '',
    beforeLabelIfNoMatch = ''
  ) => {
    const value = dropdownsValue[filterKey]?.[0]?.value;

    return {
      afterLabel:
        value === filterOptions[0]?.value
          ? afterLabelIfMatch
          : afterLabelIfNoMatch,
      beforeLabel:
        value !== filterOptions[0]?.value
          ? beforeLabelIfMatch
          : beforeLabelIfNoMatch,
    };
  };

  const tableAlertLabels = getDropdownLabel(
    TABLE_ALERT_STATUS_FILTER,
    ALERT_FILTER_OPTIONS,
    ' of destination tables',
    ' destination tables'
  );

  const handleChangeDropdowns = (key, value) => {
    const newDropdownsValue = { [key]: value };
    if (key === TECHNOLOGY_FILTER && value !== PIPELINE_TYPE.BQ_DTS) {
      newDropdownsValue[PIPELINE_SUBTYPES_FILTER] =
        getDefaultDropdownsValueByKey(PIPELINE_SUBTYPES_FILTER);
    }
    setDropdownsValue((prevState) => ({ ...prevState, ...newDropdownsValue }));
  };

  const isRenderPipelineSubtypesFilter = useMemo(() => {
    return (
      pipelineSubtypesFilterOptions.length > 0 &&
      dropdownsValue[TECHNOLOGY_FILTER] !== null &&
      dropdownsValue[TECHNOLOGY_FILTER][0].value === PIPELINE_TYPE.BQ_DTS
    );
  }, [pipelineSubtypesFilterOptions, dropdownsValue]);

  const renderDropdown = (
    filterKey,
    options,
    icon,
    selectAllOption,
    multipleSelect,
    afterLabel,
    beforeLabel
  ) =>
    existingDropdown.includes(filterKey) && (
      <StyledDropdown
        options={options}
        defaultValue={dropdownsValue[filterKey] || []}
        Icon={icon}
        onSelect={(value) => handleChangeDropdowns(filterKey, value)}
        selectAllOption={selectAllOption}
        multipleSelect={multipleSelect}
        afterLabel={afterLabel}
        beforeLabel={beforeLabel}
      />
    );

  return (
    <div>
      {technologyFilterOptions.length > 0 &&
        renderDropdown(
          TECHNOLOGY_FILTER,
          technologyFilterOptions,
          TechnologyIcon,
          technologyFilterOptions[0],
          false
        )}
      {renderDropdown(
        TIME_PERIOD_FILTER,
        PIPELINE_TIME_PERIOD_OPTIONS,
        ClockIcon,
        null,
        false
      )}
      {renderDropdown(
        TABLE_ALERT_STATUS_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        tableAlertLabels.afterLabel
      )}
      {renderDropdown(
        COST_EFFICIENCY_FILTER,
        costEfficiencyFilterOptions,
        CostStatsIcon,
        costEfficiencyFilterOptions[0],
        false
      )}
      {isRenderPipelineSubtypesFilter &&
        renderDropdown(
          PIPELINE_SUBTYPES_FILTER,
          pipelineSubtypesFilterOptions,
          PipelineIcon,
          pipelineSubtypesFilterOptions[0],
          true,
          '',
          'Show '
        )}
      {renderDropdown(
        STORAGE_COSTS_EFFICIENCY_FILTER,
        STORAGE_COST_EFFICIENCY_FILTER_OPTIONS,
        CostStatsIcon,
        STORAGE_COST_EFFICIENCY_FILTER_OPTIONS[0]
      )}
    </div>
  );
};

export { PipelinesDropdowns };
