import React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncTableToGetParam,
  useSelectedElements,
  useSelector,
  useDispatch,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { useStyles } from '../../Dictionary.styles.js';
import { amplEvent } from 'service/services.js';
import {
  Toolbar,
  Modal,
  SourcesButton,
  TableDatasetSearch,
  TableHeadWithCheckbox,
  MutedElementModalBody,
  CriticalElementModalBody,
} from 'Components/components.js';
import { CRITICAL_ALERT_TYPE, MUTED_ALERT_TYPE } from 'utils/constants.js';
import { getTables, updateTableAlertType } from 'slices/actions.js';
import {
  AMPL_PAGE_EVENT,
  ORDER_TYPES,
  DATA_STATUS,
  DictionaryTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  useModal,
  useProjectTree,
  useQueryParamsContext,
} from 'context/context.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { DictionaryTableDetails } from './components/components.js';
import {
  DICTIONARY_STREAM_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TABLE_TYPE_FILTER,
  TablesTabDropdowns,
} from 'Pages/Dictionary/libs/constants/constants.js';
import {
  TableNameHead,
  AlertStatusHead,
  CreatedTablesHead,
  ModifiedTableHead,
  SizeHead,
  DependingTableHead,
  DescriptionHead,
  ScoreTableHead,
} from './enums/enums.js';

const HEAD_CELLS = [
  TableNameHead,
  AlertStatusHead,
  ScoreTableHead,
  CreatedTablesHead,
  ModifiedTableHead,
  SizeHead,
  DependingTableHead,
  DescriptionHead,
];

const DictionaryTable = ({
  filtersValue,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeBackdrop,
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { queryParams } = useQueryParamsContext();
  const dispatch = useDispatch();

  const [order, setOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [dataset, setDataset] = useState();
  const [searchTable, setSearchTable] = useState();
  const [actionText, setActionText] = useState();
  const [tableInfo, setTableInfo] = useState();
  const isSuggestedPriority = queryParams.has(SEARCH_PARAMS.SUGGESTED_PRIORITY);

  const {
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
    setOnSelectDataset,
  } = useProjectTree();

  ScoreTableHead.isVisible = isSuggestedPriority;
  const headCells = HEAD_CELLS.filter((cell) => cell.isVisible);

  const {
    tableFromUrl,
    datasetFromUrl,
    searchValueFromUrl,
    defaultSearchValueFromUrl,
    clearGetParams,
  } = useSyncTableToGetParam(
    tableInfo?.table,
    tableInfo?.dataset || dataset,
    searchTable,
    DictionaryTabs.TABLES
  );

  const { tablesData, tablesDataStatus } = useSelector((state) => ({
    tablesData: state.tables.tablesData,
    tablesDataStatus: state.tables.tablesDataStatus,
  }));

  const {
    selected,
    clearSelected,
    handleSelectAllClick,
    isSelected,
    handleClickElement,
  } = useSelectedElements(tablesData);

  const isAllowedRequest = useMemo(() => {
    const filteredObj = Object.fromEntries(
      Object.entries(filtersValue).filter(([key]) =>
        TablesTabDropdowns.includes(key)
      )
    );
    const filtersArr = Object.values(filteredObj);

    return filtersArr.length > 0 && !filtersArr.some((value) => value === null);
  }, [filtersValue]);

  const isLoadingTablesData = useMemo(() => {
    return tablesDataStatus === DATA_STATUS.pending;
  }, [tablesDataStatus]);

  useEffect(() => {
    const sortOrder = isSuggestedPriority ? ORDER_TYPES.desc : ORDER_TYPES.asc;
    const sortField = isSuggestedPriority
      ? ScoreTableHead.id
      : TableNameHead.id;

    setOrder(sortOrder);
    setSortBy(sortField);
  }, [isSuggestedPriority]);

  useEffect(() => {
    onChangeBackdrop(isLoadingTablesData);
  }, [isLoadingTablesData, onChangeBackdrop]);

  useEffect(() => {
    if (isAllowedRequest) {
      const alertTypes = filtersValue[TABLE_ALERT_STATUS_FILTER].filter(
        (item) => item.value !== ''
      ).map((item) => item.value);
      const datasetTypes = filtersValue[TABLE_TYPE_FILTER].filter(
        (item) => item.value !== ''
      ).map((item) => item.value);

      if (order && sortBy) {
        dispatch(
          getTables({
            dataset: datasetFromUrl || tableInfo?.dataset || dataset || '',
            table: tableFromUrl || tableInfo?.table || '',
            searchTable: searchValueFromUrl || searchTable || '',
            alertTypes,
            datasetTypes,
            sortBy,
            order,
            page: page + 1,
            limit: rowsPerPage,
            onlyRecommendPriority: isSuggestedPriority,
          })
        );
      }
    }
  }, [
    sortBy,
    order,
    page,
    rowsPerPage,
    filtersValue,
    tableInfo?.dataset,
    tableInfo?.table,
    dataset,
    searchTable,
    tableFromUrl,
    datasetFromUrl,
    searchValueFromUrl,
  ]);

  const updateAlertType = useCallback(
    (selectedAlertType) =>
      dispatch(updateTableAlertType({ selected, selectedAlertType })).then(
        (action) => {
          if (action.meta.requestStatus !== DATA_STATUS.fulfilled) {
            return;
          }
          setActionText(
            `${selected.length} ${
              selected.length === 1 ? 'table is' : 'tables are'
            } ${selectedAlertType.toLowerCase()}`
          );
          setTimeout(() => {
            setActionText('');
          }, 3000);
          clearSelected();
        }
      ),
    [dispatch, selected]
  );

  const onAlertTypeChange = useCallback(
    (selectedAlertType) => {
      if (selected.some((item) => item.alertType === CRITICAL_ALERT_TYPE)) {
        setModal(() => (
          <Modal
            onAgree={() => updateAlertType(selectedAlertType)}
            title="Are you sure about changing the critical table's status?"
            titlePosition='center'
            ModalBodyComponent={CriticalElementModalBody}
            agreeButtonText='OK'
          />
        ));
      } else if (selectedAlertType === MUTED_ALERT_TYPE) {
        setModal(() => (
          <Modal
            onAgree={() => updateAlertType(selectedAlertType)}
            title='Are you sure you want to mute the table?'
            titlePosition='center'
            ModalBodyComponent={MutedElementModalBody}
            agreeButtonText='OK'
          />
        ));
      } else {
        updateAlertType(selectedAlertType);
      }
    },
    [selected, setModal, updateAlertType]
  );

  useEffect(() => {
    if (tableInfo?.table && tableInfo?.dataset) {
      amplEvent(
        `${
          AMPL_PAGE_EVENT.dictionary
        } -> Tab Table -> selected table -> ${getFullTableName(
          tableInfo.table,
          tableInfo.dataset
        )}`
      );
    } else if (dataset) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> Tab Table -> selected dataset -> ${dataset}`
      );
    }
  }, [tableInfo?.table, tableInfo?.dataset, tableInfo, dataset]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, []);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [isSuggestedPriority]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const handleDatasetChange = useCallback((value) => {
    handleChangePage({}, 0);
    setDataset(value);
    setTableInfo(null);
    setSearchTable(null);
    setSelectTable(null);
    setSelectDataset(value);
    if (value === null) clearGetParams();
  }, []);

  const handleTableChange = useCallback((value) => {
    handleChangePage({}, 0);
    setTableInfo(value);
    setDataset(null);
    setSearchTable(null);
    setSelectTable(value);
    setSelectDataset(null);
    if (value === null) clearGetParams();
  }, []);

  const handleTableSearch = useCallback((value) => {
    handleChangePage({}, 0);
    setSearchTable(value);
    setDataset(null);
    setTableInfo(null);
    setSelectTable(null);
    setSelectDataset(null);
    if (!value) clearGetParams();
  }, []);

  const handleRequestSort = (property) => {
    handleChangePage({}, 0);
    const isAsc = sortBy === property && order === ORDER_TYPES.asc;
    setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
    setSortBy(property);
  };

  useEffect(() => {
    if (handleTableChange !== undefined) {
      setOnSelectTable({ onChange: handleTableChange });
    }
  }, [handleTableChange, setOnSelectTable]);

  useEffect(() => {
    if (handleDatasetChange !== undefined) {
      setOnSelectDataset({ onChange: handleDatasetChange });
    }
  }, [handleDatasetChange, setOnSelectDataset]);

  return (
    <>
      <div className={classes.searchContainer}>
        <SourcesButton onClick={handleProjectTree} />
        <TableDatasetSearch
          optionLabel='Dictionary for:'
          onTableChange={handleTableChange}
          onDatasetChange={handleDatasetChange}
          onSearch={handleTableSearch}
          defaultDataset={datasetFromUrl}
          defaultTable={tableFromUrl}
          defaultSearchValue={defaultSearchValueFromUrl}
          className='tableSearch'
        />
      </div>

      <div className={classes.tableContainer}>
        <Toolbar
          numSelected={selected.length}
          closeModal={clearSelected}
          onTypeChange={onAlertTypeChange}
          actionText={actionText}
        />

        <Table>
          <TableHeadWithCheckbox
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={tablesData?.length}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            selectedColumn={true}
          />
          {tablesData.length > 0 && (
            <TableBody className={classes.tableBody}>
              {tablesData.map((value) => {
                const isItemSelected = isSelected(value);
                return (
                  <DictionaryTableDetails
                    key={getFullTableName(value.table, value.dataset)}
                    tableInfo={value}
                    isItemSelected={isItemSelected}
                    handleClick={handleClickElement}
                    selectedStreamType={filtersValue[DICTIONARY_STREAM_FILTER]}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

export { DictionaryTable };
