import {
  DATA_ASSETS_TYPES,
  DictionaryTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as TableIcon } from 'assets/img/icons/projectTreeIcons/table20.svg';
import { ReactComponent as DatasetIcon } from 'assets/img/icons/projectTreeIcons/dataset.svg';

const getIconByType = (type) => {
  switch (type) {
    case DATA_ASSETS_TYPES.table:
      return TableIcon;
    case DATA_ASSETS_TYPES.dataset:
      return DatasetIcon;
    default:
      return TableIcon;
  }
};

const getLinkPath = (type, data) => {
  if (type !== DATA_ASSETS_TYPES.table && type !== DATA_ASSETS_TYPES.dataset) {
    return '#';
  }

  const path =
    type === DATA_ASSETS_TYPES.table
      ? AppRoutes.Monitors.path
      : AppRoutes.Dictionary.path;

  const baseSearchParams = new URLSearchParams({
    [SEARCH_PARAMS.DATASET]: data.datasetName,
    [SEARCH_PARAMS.PROJECT]: data.project,
  });

  if (type === DATA_ASSETS_TYPES.table) {
    baseSearchParams.set(SEARCH_PARAMS.TABLE, data.tableName);
  } else {
    baseSearchParams.set(SEARCH_PARAMS.TAB, DictionaryTabs.DATASETS);
  }

  return {
    pathname: path,
    search: baseSearchParams.toString(),
  };
};

export { getIconByType, getLinkPath };
