import React from 'react';
import { StartDataProduct } from '../components.js';
import { DataProductsTable, Search } from './components/components.js';
import { useDataProductsPageContext } from 'context/context.js';

const DataProductTab = () => {
  const { isStartDataProductPage } = useDataProductsPageContext();

  if (isStartDataProductPage) {
    return <StartDataProduct />;
  }

  return (
    <>
      <Search withCreateButton={true} />
      <DataProductsTable />
    </>
  );
};

export { DataProductTab };
