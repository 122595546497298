const SEARCH_PARAMS = {
  TABLE: 'table',
  DATASET: 'dataset',
  ID: 'id',
  TYPE: 'type',
  SEARCH: 'search',
  CREATE_PROJECT: 'createProject',
  TAB: 'tab',
  PROJECT: 'project',
  JOB_HASH: 'jobHash',
  FROM: 'from',
  TO: 'to',
  PAGE: 'page',
  TABLE_ALERT_TYPE: 'tableAlertType',
  TABLE_TYPE: 'tableType',
  DATASET_ALERT_TYPE: 'datasetAlertType',
  LOOKER_ALERT_TYPE: 'lookerAlertType',
  LOOKER_TYPE: 'lookerType',
  INCIDENT_TYPE: 'incidentType',
  LOOKER_INCIDENT_TYPE: 'lookerIncidentType',
  INCIDENT_STATUS: 'incidentStatus',
  DASHBOARD_ALERT_TYPE: 'dashboardAlertType',
  PIPELINE_TYPE: 'pipelineType',
  PIPELINE_INCIDENT_TYPE: 'pipelineIncidentType',
  PIPELINE_SUBTYPES: 'pipelineSubtypes',
  TIME_PERIOD: 'timePeriod',
  COST_EFFICIENCY: 'costEfficiency',
  STORAGE_COSTS_EFFICIENCY: 'storageCostsEfficiency',
  ELEMENT_ID: 'elementId',
  STREAM: 'stream',
  SUGGESTED_PRIORITY: 'suggestedPriority',

  SELECTED_DATA_PRODUCT: 'selectedDataProductUUID',
  SEARCH_DATA_PRODUCT: 'searchDataProduct',
  SELECTED_DOMAIN: 'selectedDomainUUID',
  SEARCH_DOMAIN: 'searchDomain',
};

const SEARCH_PARAMS_SEPARATOR = ',';

export { SEARCH_PARAMS, SEARCH_PARAMS_SEPARATOR };
