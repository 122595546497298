import clsx from 'clsx';
import { useGetIsAnomalyTable, useHistory } from 'hooks/hooks.js';
import { Tooltip } from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  AMPL_PAGE_EVENT,
  DictionaryTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';

const DictionaryLookerUpstreamTable = ({
  item,
  maxItemLength,
  highlightUpstream,
}) => {
  const history = useHistory();
  const fullTableName = getFullTableName(item.table, item.dataset);
  const isLongTableName = fullTableName.length > maxItemLength;
  const shortTableName = isLongTableName
    ? `${fullTableName.substring(0, maxItemLength)}...`
    : fullTableName;
  const tooltipTitle = isLongTableName ? fullTableName : '';

  const { data: isAnomalyTable } = useGetIsAnomalyTable(
    item.dataset,
    item.table
  );

  const onMonitors = () => {
    history.push({
      pathname: AppRoutes.Monitors.path,
      search: new URLSearchParams({
        [SEARCH_PARAMS.DATASET]: item.dataset,
        [SEARCH_PARAMS.TABLE]: item.table,
      }).toString(),
    });

    amplEvent(
      `${AMPL_PAGE_EVENT.dictionary} -> ${
        DictionaryTabs.LOOKER
      } tab -> click table ${getFullTableName(
        item.table,
        item.dataset
      )} -> to Monitors page`
    );
  };

  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={clsx(isAnomalyTable && 'txt-red-13-500')}
        onClick={onMonitors}
      >
        {highlightUpstream(shortTableName)}
      </div>
    </Tooltip>
  );
};

export { DictionaryLookerUpstreamTable };
