import React, { createContext } from 'react';
import {
  useMemo,
  useAltPlanData,
  useBqBillingData,
  useContext,
  useCostsDeadEndTables,
  useCostsStats,
  useCostsStatsPrevious,
  useState,
  useEffect,
} from 'hooks/hooks.js';
import { useUserInfo } from 'context/context.js';

const CostsContext = createContext(null);

const CostsContextProvider = ({ children }) => {
  const { isCurrentProjectDemo } = useUserInfo();
  const { isWelcome, bqBillingData, currentBqBillingData } = useBqBillingData();
  const [paymentType, setPaymentType] = useState(undefined);

  const isAllowedCostsDataRequest = !isWelcome || isCurrentProjectDemo;

  const { costsStats } = useCostsStats();
  const { costsStatsPrevious } = useCostsStatsPrevious();
  const { deadEndTablesStats } = useCostsDeadEndTables({});
  const { altPlanData } = useAltPlanData();

  const isExistingCostsComponents =
    isAllowedCostsDataRequest && costsStats.length > 0;

  const totalProjectCosts = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const totalProjectCostsPrevious = useMemo(() => {
    return costsStatsPrevious.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStatsPrevious]);

  useEffect(() => {
    if (currentBqBillingData) {
      setPaymentType(currentBqBillingData.paymentType);
    } else if (currentBqBillingData === null) {
      setPaymentType(null);
    }
  }, [currentBqBillingData]);

  return (
    <CostsContext.Provider
      value={{
        isWelcome,
        bqBillingData,
        currentBqBillingData,
        isAllowedCostsDataRequest,
        costsStats,
        costsStatsPrevious,
        deadEndTablesStats,
        altPlanData,
        paymentType,
        totalProjectCosts,
        totalProjectCostsPrevious,
        isExistingCostsComponents,
      }}
    >
      {children}
    </CostsContext.Provider>
  );
};

const useCostsContext = () => {
  const context = useContext(CostsContext);

  if (!context) {
    throw new Error(
      'useCostsContext must be used within a CostsContextProvider'
    );
  }

  return context;
};

export { CostsContextProvider, useCostsContext };
