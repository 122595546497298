import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { useStyles } from '../../Dashboard.styles.js';
import {
  StatsItem,
  StatsWidget,
  UnconnectedLooker,
} from 'Components/components.js';
import { useIntegration } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  DictionaryTabs,
  IncidentTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  CRITICAL_ALERT_TYPE,
  PRIORITY_ALERT_TYPE,
  REGULAR_ALERT_TYPE,
} from 'utils/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as BigQueryLogo } from 'assets/img/technologies/bigquery-logo.svg';
import { ReactComponent as LookerLogo } from 'assets/img/technologies/looker/looker-logo-gray.svg';
import { ReactComponent as PipelineIcon } from 'assets/img/icons/pipelines-icons/custom-pipeline-icon.svg';

const IncidentsStats = ({ dashboardData }) => {
  const classes = useStyles();
  const { isLookerConnected } = useIntegration();

  const incidentsStats = useMemo(() => {
    if (dashboardData) {
      return [
        {
          title: 'BigQuery tables observed',
          Icon: BigQueryLogo,
          totalStats: numberFormat(dashboardData.bigQueryDashboard.tables),
          totalStatsPath: {
            pathname: AppRoutes.Dictionary.path,
            search: new URLSearchParams({
              [SEARCH_PARAMS.TAB]: DictionaryTabs.TABLES,
            }).toString(),
          },
          stats: [
            {
              name: 'Critical table issues',
              value: numberFormat(
                dashboardData.bigQueryDashboard.criticalIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Priority tables issues',
              value: numberFormat(
                dashboardData.bigQueryDashboard.priorityIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Unspecified tables issues',
              value: numberFormat(
                dashboardData.bigQueryDashboard.unspecifiedIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                }).toString(),
              },
            },
          ],
        },
        {
          title: 'Pipelines observed',
          Icon: PipelineIcon,
          totalStats: numberFormat(dashboardData.pipelineDashboard.pipelines),
          totalStatsPath: { pathname: AppRoutes.Pipelines.path },
          stats: [
            {
              name: 'Critical pipeline issues',
              value: numberFormat(
                dashboardData.pipelineDashboard.criticalIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Priority pipeline issues',
              value: numberFormat(
                dashboardData.pipelineDashboard.priorityIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Unspecified pipeline issues',
              value: numberFormat(
                dashboardData.pipelineDashboard.unspecifiedIssues
              ),
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                }).toString(),
              },
            },
          ],
        },
        ...(isLookerConnected
          ? [
              {
                title: 'Looker dashboards and looks connected',
                Icon: LookerLogo,
                totalStats: numberFormat(
                  dashboardData.lookerDashboard.dashboards
                ),
                totalStatsPath: {
                  pathname: AppRoutes.Dictionary.path,
                  search: new URLSearchParams({
                    [SEARCH_PARAMS.TAB]: DictionaryTabs.LOOKER,
                  }).toString(),
                },
                stats: [
                  {
                    name: 'Critical dashboard issues',
                    value: numberFormat(
                      dashboardData.lookerDashboard.criticalIssues
                    ),
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                  {
                    name: 'Priority dashboard issues',
                    value: numberFormat(
                      dashboardData.lookerDashboard.priorityIssues
                    ),
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                  {
                    name: 'Unspecified dashboard issues',
                    value: numberFormat(
                      dashboardData.lookerDashboard.unspecifiedIssues
                    ),
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                ],
              },
            ]
          : []),
      ];
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  return (
    <>
      {incidentsStats.length > 0 && (
        <section className={classes.widgetsContainer}>
          {incidentsStats.map((item) => (
            <StatsWidget key={item.title}>
              <StatsItem widgetData={item} />
            </StatsWidget>
          ))}

          {!isLookerConnected && (
            <StatsWidget>
              <UnconnectedLooker />
            </StatsWidget>
          )}
        </section>
      )}
    </>
  );
};

export { IncidentsStats };
