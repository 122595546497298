import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 20,
    '&.fourCards': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '&.fiveCards': {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
  },
  card: {
    padding: 0,
    borderRadius: theme.spacing(3),
    transition: 'box-shadow 0.3s ease-in-out',
    '&.selected': {
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingLeft: 24,
  },
  dataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 112,
      background: theme.palette.divider,
    },
  },
}));
