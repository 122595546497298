import React from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import theme from 'theme.js';

const MarkdownWithSyntaxHighlighting = ({ markdown }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <h1 style={{ fontSize: 24 }} {...props} />,
        h2: ({ node, ...props }) => <h2 style={{ fontSize: 22 }} {...props} />,
        h3: ({ node, ...props }) => <h3 style={{ fontSize: 20 }} {...props} />,
        h4: ({ node, ...props }) => <h4 style={{ fontSize: 18 }} {...props} />,
        h5: ({ node, ...props }) => <h5 style={{ fontSize: 16 }} {...props} />,
        h6: ({ node, ...props }) => <h6 style={{ fontSize: 14 }} {...props} />,
        code({ node, children, inline, ...props }) {
          if (inline) {
            return <InlineCode children={children} {...props} />;
          } else {
            return (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                language='markdown'
                style={{
                  'pre[class*="language-"]': {
                    padding: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                {...props}
              />
            );
          }
        },
        li: ({ node, ordered, ...props }) => {
          const { ordered: _, ...restProps } = props;
          return <li style={{ ...theme.typography.body1 }} {...restProps} />;
        },
        p: ({ node, ...props }) => (
          <p style={{ ...theme.typography.body1 }} {...props} />
        ),
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};

const InlineCode = ({ children, ...props }) => {
  return (
    <code
      style={{
        padding: '0 4px',
        fontSize: 'inherit',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(1),
      }}
      {...props}
    >
      {children}
    </code>
  );
};

export { MarkdownWithSyntaxHighlighting };
