import React from 'react';
import { useState } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { WidgetLabel, ExecutedJobsChart } from './components/components.js';
import { JOB_EXECUTED_TYPES } from 'constants/constants.js';

const ExecutedJobsWidget = () => {
  const [selectedChartType, setSelectedChartType] = useState(
    JOB_EXECUTED_TYPES.principal
  );

  const handleChangeChartType = (event) => {
    setSelectedChartType(event.value);
  };

  return (
    <Widget
      label={
        <WidgetLabel
          selectedChartType={selectedChartType}
          onChangeChartType={handleChangeChartType}
        />
      }
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <ExecutedJobsChart aggregatedBy={selectedChartType} />
    </Widget>
  );
};

export { ExecutedJobsWidget };
