import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  GlobalErrorComponent,
  GlobalUserErrorComponent,
} from 'Components/components.js';
import {
  DateContextProvider,
  SnackbarContextProvider,
  UserContextProvider,
  ModalContextProvider,
  IntegrationContextProvider,
  BannerContextProvider,
  SignUpContextProvider,
  QueryParamsContextProvider,
  ProjectTreeContextProvider,
  CostsContextProvider,
  PostHogContextProvider,
} from 'context/context.js';

const GlobalContextProviders = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorComponent}>
      <UserContextProvider>
        <ErrorBoundary FallbackComponent={GlobalUserErrorComponent}>
          <QueryParamsContextProvider>
            <DateContextProvider>
              <SnackbarContextProvider>
                <ModalContextProvider>{children}</ModalContextProvider>
              </SnackbarContextProvider>
            </DateContextProvider>
          </QueryParamsContextProvider>
        </ErrorBoundary>
      </UserContextProvider>
    </ErrorBoundary>
  );
};

const PrivateContextProviders = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={GlobalUserErrorComponent}>
      <PostHogContextProvider>
        <IntegrationContextProvider>
          <BannerContextProvider>
            <ProjectTreeContextProvider>
              <CostsContextProvider>{children}</CostsContextProvider>
            </ProjectTreeContextProvider>
          </BannerContextProvider>
        </IntegrationContextProvider>
      </PostHogContextProvider>
    </ErrorBoundary>
  );
};

const AuthContextProviders = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorComponent}>
      <SignUpContextProvider>{children}</SignUpContextProvider>
    </ErrorBoundary>
  );
};

export {
  AuthContextProviders,
  GlobalContextProviders,
  PrivateContextProviders,
};
