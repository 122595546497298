const PRINCIPAL_ID = 'PRINCIPAL';
const PROJECT_ID = 'PROJECT';
const TYPE_ID = 'TYPE';
const LAST_ACTIVE_ID = 'LAST_ACTIVE';

const Principal = {
  id: PRINCIPAL_ID,
  label: 'Principal',
  sort: false,
  align: 'left',
};
const Project = {
  id: PROJECT_ID,
  label: 'Project',
  sort: false,
  align: 'left',
};
const Type = {
  id: TYPE_ID,
  label: 'Type',
  sort: false,
  align: 'left',
  // tip: 'Direct - Permissions provided using IAM roles. Subscriber - read subscriptions via Analytics Hub listings.',
};
const LastActive = {
  id: LAST_ACTIVE_ID,
  label: 'Last active',
  sort: false,
  align: 'left',
};

export { Principal, Project, Type, LastActive };
