import React from 'react';
import {
  useEffect,
  useState,
  useBqBillingData,
  useCallback,
  useCostsStats,
  useCostsDestinationTables,
  usePipelinesSubtypesData,
  useMemo,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Costs/Costs.styles.js';
import { PIPELINE_TYPE } from 'utils/constants.js';
import { CostsTable } from './CostsTable/CostsTable.js';
import {
  PositionLoader,
  EmptyData,
  CostsStats,
  Backdrop,
} from 'Components/components.js';
import {
  useCostsContext,
  useDateContext,
  usePipelinePageContext,
  useUserInfo,
} from 'context/context.js';
import {
  ALTERNATIVE_PLAN_OPTION,
  DEAD_END_TABLES,
  SEARCH_DESTINATION_TABLE,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_PARAMS,
  SEARCH_TABLE,
  SELECTED_DESTINATION,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'constants/constants.js';
import {
  COST_EFFICIENCY_FILTER,
  PIPELINE_SUBTYPES_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';

const CostsTab = () => {
  const classes = useStyles();
  const { startDate, endDate } = useDateContext();
  const { isCurrentProjectDemo } = useUserInfo();
  const {
    defaultJobHash,
    searchValues,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangePaginationTotal,
    handleChangeIsJobHashFound,
  } = usePipelinePageContext();
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);
  const { isExistingCostsComponents } = useCostsContext();

  const [dropdownsValue, setDropdownsValue] = useState({
    [COST_EFFICIENCY_FILTER]: null,
    [TABLE_ALERT_STATUS_FILTER]: null,
    [TECHNOLOGY_FILTER]: null,
    [PIPELINE_SUBTYPES_FILTER]: null,
  });

  const pipelineSubtypes = useMemo(() => {
    return (
      dropdownsValue[PIPELINE_SUBTYPES_FILTER]?.map((item) => item.value) || []
    );
  }, [dropdownsValue]);
  const isDeadEndTablesSelected = useMemo(() => {
    return (
      dropdownsValue[COST_EFFICIENCY_FILTER]?.[0]?.value === DEAD_END_TABLES
    );
  }, [dropdownsValue]);
  const isAlternativePlanSelected = useMemo(() => {
    return (
      dropdownsValue[COST_EFFICIENCY_FILTER]?.[0]?.value ===
      ALTERNATIVE_PLAN_OPTION
    );
  }, [dropdownsValue]);

  const { isWelcome, isBqBillingDataFetching } = useBqBillingData();

  const isAllowedStatsRequest = !isWelcome || isCurrentProjectDemo;

  const isAllowedCostsDestinationRequest = useMemo(() => {
    if (!isAllowedStatsRequest) {
      return false;
    }

    const filteredDropdowns = { ...dropdownsValue };
    delete filteredDropdowns[PIPELINE_SUBTYPES_FILTER];

    return !Object.values(filteredDropdowns).some((value) => value === null);
  }, [dropdownsValue, isAllowedStatsRequest]);

  const isAllowedSubtypesRequest = useMemo(() => {
    if (!isAllowedStatsRequest) {
      return false;
    }

    const technologyFilterValue = dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value;
    return technologyFilterValue === PIPELINE_TYPE.BQ_DTS;
  }, [dropdownsValue, isAllowedStatsRequest]);

  const { costsStats, isCostsStatsFetching } = useCostsStats(
    isAllowedStatsRequest
  );

  const {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  } = useCostsDestinationTables({
    page,
    rowsPerPage,
    searchDestinationTable: searchValues[SELECTED_DESTINATION]?.table,
    searchDestinationDataset: searchValues[SELECTED_DESTINATION]?.dataset,
    searchDestinationValue: searchValues[SEARCH_DESTINATION_TABLE],
    searchTable: searchValues[SELECTED_TABLE]?.table,
    searchDataset: searchValues[SELECTED_TABLE]?.dataset,
    searchValue: searchValues[SEARCH_TABLE],
    selectedEmail: searchValues[SELECTED_EMAIL],
    searchEmail: searchValues[SEARCH_EMAIL],
    selectedTags: searchValues[SELECTED_TAGS],
    selectedLabelKey: searchValues[SELECTED_LABEL]?.key,
    selectedLabelValue: searchValues[SELECTED_LABEL]?.value,
    searchLabel: searchValues[SEARCH_LABEL],
    powerbiReportName: searchValues[SELECTED_POWER_BI_REPORT],
    powerbiWorkspaceName: searchValues[SELECTED_POWER_BI_WORKSPACE],
    alertTypes: dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value,
    pipelineSubtypes,
    dataProductUUID: searchValues[SEARCH_PARAMS.SELECTED_DATA_PRODUCT],
    searchDataProductName: searchValues[SEARCH_PARAMS.SEARCH_DATA_PRODUCT],
    jobHash: defaultJobHash,
    isDeadEndTablesSelected,
    isAlternativePlanSelected,
    enabled: isAllowedCostsDestinationRequest,
  });

  const { pipelineSubtypesData } = usePipelinesSubtypesData({
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value,
    enabled: isAllowedSubtypesRequest,
  });

  useEffect(() => {
    if (defaultJobHash && destinationTables.length > 0) {
      const isExistingPipeline = destinationTables.some(
        (item) => item.isSelected
      );
      handleChangeIsJobHashFound(isExistingPipeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultJobHash, destinationTables]);

  useEffect(() => {
    handleChangePaginationTotal(destinationTablesTotal);
    setIsOpenBackdrop(isFetchingDestinationTables);
  }, [
    destinationTablesTotal,
    isFetchingDestinationTables,
    handleChangePaginationTotal,
  ]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  useEffect(() => {
    handleChangePage({}, 0);
  }, [handleChangePage, startDate, endDate]);

  if (isCostsStatsFetching || isBqBillingDataFetching) {
    return <PositionLoader />;
  }

  return (
    <div className={classes.pageContainer}>
      {isExistingCostsComponents && <CostsStats />}

      <div className={classes.mainContainer}>
        {isExistingCostsComponents ? (
          <CostsTable
            destinationTables={destinationTables}
            isFetchingDestinationTables={isFetchingDestinationTables}
            pipelines={costsStats}
            alertTypes={dropdownsValue[TABLE_ALERT_STATUS_FILTER]}
            selectedPipelineType={dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value}
            pipelineSubtypes={pipelineSubtypes}
            pipelineSubtypesData={pipelineSubtypesData}
            isDeadEndTablesSelected={isDeadEndTablesSelected}
            isAlternativePlanSelected={isAlternativePlanSelected}
            onChangeDropdownsValue={handleChangeDropdownsValue}
          />
        ) : (
          <EmptyData title='No data for this period' />
        )}

        <Backdrop isOpen={isOpenBackdrop} />
      </div>
    </div>
  );
};

export { CostsTab };
