import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { ReactComponent as ArrowLeft } from 'assets/img/icons/sidebarIcons/arrowLeft.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.btn': {
      width: 40,
      borderRadius: 12,
      zIndex: 2,
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
  },
}));

const BackButton = ({ className = '', goPreviousPage = null }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = () => {
    if (goPreviousPage) {
      goPreviousPage();
    } else {
      history.goBack();
    }
  };

  return (
    <button
      type='button'
      className={clsx(classes.button, 'btn btn-white me-3', className)}
      onClick={handleGoBack}
    >
      <ArrowLeft />
    </button>
  );
};

export { BackButton };
