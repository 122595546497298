import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useMessages } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const EVENT_MESSAGE = 'Update domain ->';
const ERROR_MESSAGE = 'Got an error while updating the domain';

const useUpdateDomain = ({
  domainUUID,
  name,
  email,
  slackChannelName = '',
}) => {
  const queryClient = useQueryClient();
  const { setMessage } = useMessages();

  const { mutateAsync: updateDomain } = useMutation(
    () => {
      return fetcherPost(`${QUERY_TYPES.domain}/${domainUUID}`, {
        name,
        email,
        slackChannelName,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          setMessage(ERROR_MESSAGE);
          amplEvent(`${EVENT_MESSAGE} -> ${domainUUID} error-1`);
        } else {
          amplEvent(`${EVENT_MESSAGE} success`);
          queryClient.invalidateQueries(QUERY_TYPES.domainList);
          queryClient.invalidateQueries(QUERY_TYPES.dataProductList);
        }
      },
      onError: () => {
        setMessage(ERROR_MESSAGE);
        amplEvent(`${EVENT_MESSAGE} -> ${domainUUID} error-2`);
      },
    }
  );

  return { updateDomain };
};

export { useUpdateDomain };
