import {
  ALERT_FILTER_OPTIONS,
  DICTIONARY_LOOKER_TYPES_OPTIONS,
  DICTIONARY_TYPES_OPTIONS,
  DICTIONARY_TYPES_STREAM,
} from 'utils/constants.js';
import {
  DATASET_ALERT_STATUS_FILTER,
  DICTIONARY_STREAM_FILTER,
  LOOKER_ALERT_STATUS_FILTER,
  LOOKER_REPORTS_TYPE_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TABLE_TYPE_FILTER,
} from '../constants/constants.js';

const getDefaultDropdownsValueByKey = (key) => {
  const defaultDropdownsValue = {
    [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [DICTIONARY_STREAM_FILTER]: [DICTIONARY_TYPES_STREAM[0]],
    [TABLE_TYPE_FILTER]: [DICTIONARY_TYPES_OPTIONS[0]],
    [DATASET_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [LOOKER_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [LOOKER_REPORTS_TYPE_FILTER]: [DICTIONARY_LOOKER_TYPES_OPTIONS[0]],
  };

  return defaultDropdownsValue[key] || null;
};

export { getDefaultDropdownsValueByKey };
