import React from 'react';
import { useGetDataProduct, useParams, useEffect } from 'hooks/hooks.js';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PositionLoader } from 'Components/components.js';
import {
  EmptyDataProduct,
  HeaderSection,
  SubscribersWidget,
  ExecutedJobsWidget,
  ExecutedJobsByTimeWidget,
  SLAWidget,
  LineageWidget,
  InfoWidget,
  DataAssetsWidget,
  DescriptionWidget,
  CostsWidget,
  IncidentsWidget,
} from './components/components.js';
import { DataProductsPageContextProvider } from 'context/context.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';

const DataProductDetailsContainer = () => {
  return (
    <DataProductsPageContextProvider>
      <DataProductDetails />
    </DataProductsPageContextProvider>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(3),
  boxShadow: 'none',
}));

const DataProductDetails = () => {
  const { uuid } = useParams();

  const { dataProduct, isFetchingDataProduct } = useGetDataProduct({
    dataProductUUID: uuid,
    withMetrics: true,
  });

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dataProduct;
  }, []);

  if (isFetchingDataProduct) {
    return <PositionLoader />;
  }

  if (!dataProduct) {
    return <EmptyDataProduct />;
  }

  return (
    <section>
      <HeaderSection dataProduct={dataProduct} />

      <Grid container spacing={6} alignItems='flex-start'>
        <Grid container item xs={8} spacing={5}>
          <Grid item xs={6}>
            <Item>
              <CostsWidget metricsData={dataProduct?.metrics} />
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <IncidentsWidget metricsData={dataProduct?.metrics} />
            </Item>
          </Grid>

          {dataProduct.description && (
            <Grid item xs={12}>
              <Item>
                <DescriptionWidget description={dataProduct.description} />
              </Item>
            </Grid>
          )}

          <Grid item xs={12}>
            <Item>
              <SubscribersWidget />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <ExecutedJobsWidget />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <ExecutedJobsByTimeWidget />
            </Item>
          </Grid>
        </Grid>

        <Grid container item xs={4} spacing={5}>
          <Grid item xs={12}>
            <Item>
              <DataAssetsWidget dataAssets={dataProduct.dataAssets} />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <InfoWidget dataProduct={dataProduct} />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <SLAWidget />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <LineageWidget />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default DataProductDetailsContainer;
