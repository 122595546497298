import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import {
  BackButton,
  Typography,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';

import { AppRoutes } from 'app-routes.js';

const DataProductCreatorHeader = () => {
  const history = useHistory();

  const navigateToDataProductPage = () => {
    history.push(AppRoutes.DataProduct.path);
  };

  return (
    <div className='d-flex align-items-center'>
      <BackButton goPreviousPage={navigateToDataProductPage} />
      <Typography
        variant={TYPOGRAPHY_VARIANT.h3}
        component={TYPOGRAPHY_VARIANT.h1}
      >
        Create data product
      </Typography>
    </div>
  );
};

export { DataProductCreatorHeader };
