import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processDataProduct = (data) => {
  return {
    dataProduct: data?.value || null,
  };
};

const useGetDataProduct = ({ dataProductUUID, withMetrics = false }) => {
  const isValidUUID = getIsValidUUID(dataProductUUID);

  const { isFetching: isFetchingDataProduct, data } = useQuery(
    [`${QUERY_TYPES.dataProduct}/${dataProductUUID}`],
    ({ queryKey }) => {
      const [url] = queryKey;

      return fetcherGet(url, { withMetrics });
    },
    { enabled: isValidUUID, cacheTime: 0 }
  );

  const processedData = useMemo(() => {
    return processDataProduct(data);
  }, [data]);

  return {
    dataProduct: processedData.dataProduct,
    isFetchingDataProduct,
  };
};

export { useGetDataProduct };
