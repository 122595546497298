import React from 'react';
import { useParams } from 'hooks/hooks.js';
import { StatsItem, StatsWidget } from 'Components/components.js';
import { getURLDateFormat, numberFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { SEARCH_PARAMS, IncidentTabs } from 'constants/constants.js';

const IncidentsWidget = ({ metricsData }) => {
  const { uuid } = useParams();
  const { incidents, incidentsFrom, incidentsTo } = metricsData || {};

  const from = getURLDateFormat(incidentsFrom);
  const to = getURLDateFormat(incidentsTo);

  const widgetData = {
    title: <span className='txt-grey-13-500'>Last 7 Days Open Incidents</span>,
    totalStats: numberFormat(incidents?.bigQuery + incidents?.pipeline),
    stats: [
      {
        name: 'Table incidents',
        value: numberFormat(incidents?.bigQuery),
        path: {
          pathname: AppRoutes.Incidents.path,
          search: new URLSearchParams({
            [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
            [SEARCH_PARAMS.TABLE_ALERT_TYPE]: '',
            [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: uuid,
            [SEARCH_PARAMS.FROM]: from,
            [SEARCH_PARAMS.TO]: to,
          }).toString(),
        },
      },
      {
        name: 'Pipeline incidents',
        value: numberFormat(incidents?.pipeline),
        path: {
          pathname: AppRoutes.Incidents.path,
          search: new URLSearchParams({
            [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
            [SEARCH_PARAMS.TABLE_ALERT_TYPE]: '',
            [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: uuid,
            [SEARCH_PARAMS.FROM]: from,
            [SEARCH_PARAMS.TO]: to,
          }).toString(),
        },
      },
    ],
  };

  return (
    <StatsWidget>
      <StatsItem widgetData={widgetData} />
    </StatsWidget>
  );
};

export { IncidentsWidget };
