import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processDomainList = (data) => {
  return {
    domainList: data?.values || [],
    domainTotal: data?.pagination?.total || 0,
  };
};

const useGetDomainList = ({
  page,
  limit,
  searchDomain,
  selectedDomainUUID,
}) => {
  const isValidUUID = selectedDomainUUID?.length
    ? getIsValidUUID(selectedDomainUUID)
    : true;

  const { isFetching: isFetchingDomainList, data } = useQuery(
    [QUERY_TYPES.domainList, page, limit, searchDomain, selectedDomainUUID],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append('page', page + 1);
      params.append('limit', limit);

      searchDomain?.length && params.append('search', searchDomain);
      selectedDomainUUID?.length && params.append('uuid', selectedDomainUUID);

      return fetcherGet(url, params);
    },
    { enabled: isValidUUID }
  );

  const processedData = useMemo(() => {
    return processDomainList(data);
  }, [data]);

  return {
    domainList: processedData.domainList,
    domainTotal: processedData.domainTotal,
    isFetchingDomainList,
  };
};

export { useGetDomainList };
