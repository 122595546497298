import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { formatSearchValue, getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const processIncidentGroupsData = (data) => {
  return {
    incidentGroups: data?.values || [],
    incidentsTotal: data?.incidentPagination?.total || 0,
    incidentsGroupPagination: data?.groupPagination?.total || 0,
  };
};

const useGetIncidentGroups = ({
  page,
  rowsPerPage,
  dataset,
  table,
  searchTable,
  incidentGroupsTypes,
  incidentGroupsAlert,
  incidentGroupsStatuses,
  pipelineType,
  incidentIdAndType,
  dataProductUUID,
  searchDataProductName,
  enabled = true,
  cacheTime = 0,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidDataProductUUID = getIsValidUUID(dataProductUUID);

  const {
    isFetching: isIncidentGroupsFetching,
    data,
    refetch,
  } = useQuery(
    [
      QUERY_TYPES.incidentGroups,
      startDateUtc,
      endDateUtc,
      page,
      rowsPerPage,
      dataset,
      table,
      searchTable,
      incidentGroupsTypes,
      incidentGroupsAlert,
      incidentGroupsStatuses,
      pipelineType,
      incidentIdAndType,
      dataProductUUID,
      searchDataProductName,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const { dataset: newSearchDataset, value: newSearchValue } =
        formatSearchValue(searchTable);
      const incidentTypes = [];

      incidentGroupsTypes?.length > 0 &&
        incidentTypes.push(...incidentGroupsTypes.map((item) => item.value));

      const incidentStatuses = [];
      if (
        incidentGroupsStatuses?.length > 1 ||
        (incidentGroupsStatuses?.length &&
          incidentGroupsStatuses[0].value !== '')
      ) {
        incidentStatuses.push(
          ...incidentGroupsStatuses.map((item) => item.value)
        );
      }

      const incidentAlerts = [];
      if (
        incidentGroupsAlert?.length > 1 ||
        (incidentGroupsAlert?.length && incidentGroupsAlert[0].value !== '')
      ) {
        incidentAlerts.push(...incidentGroupsAlert.map((item) => item.value));
      }

      const params = new URLSearchParams();
      params.append('from', startDateUtc);
      params.append('to', endDateUtc);
      params.append('page', page + 1);
      params.append('limit', rowsPerPage);
      params.append('dataset', newSearchDataset || dataset || '');
      params.append('table', table || '');
      params.append('search', newSearchValue || searchTable || '');

      if (incidentIdAndType?.id && incidentIdAndType?.type) {
        params.append('id', incidentIdAndType.id);
        params.append('type', incidentIdAndType.type);
      } else {
        for (const incidentType of incidentTypes) {
          params.append('incidentTypeGroups', incidentType);
        }
        for (const incidentAlert of incidentAlerts) {
          params.append('alertTypes', incidentAlert);
        }
        for (const incidentStatus of incidentStatuses) {
          params.append('incidentStatuses', incidentStatus);
        }
        if (pipelineType) {
          params.append('pipelineType', pipelineType);
        }
        isValidDataProductUUID &&
          params.append('dataProductUuid', dataProductUUID);
        searchDataProductName?.length &&
          params.append('searchDataProductName', searchDataProductName);
      }
      return fetcherGet(url, params);
    },
    { cacheTime, enabled }
  );

  const isFinishedRequest = useMemo(() => {
    return data !== undefined;
  }, [data]);
  const processedData = useMemo(() => {
    return processIncidentGroupsData(data);
  }, [data]);

  return {
    incidentGroups: processedData.incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal: processedData.incidentsTotal,
    incidentsGroupPagination: processedData.incidentsGroupPagination,
    isFinishedRequest,
    refetch,
  };
};

export { useGetIncidentGroups };
