import React from 'react';
import { useEffect, useLocation } from 'hooks/hooks.js';
import { Row } from 'react-bootstrap';
import { IntegrationItem } from './components/components.js';
import { useStyles } from './Integrations.styles.js';
import { PageHeader } from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { useIntegration, useUserInfo } from 'context/context.js';
import { AMPL_PAGE_EVENT, DOCUMENT_TITLE } from 'constants/constants.js';
import {
  DEFAULT_INTEGRATIONS,
  INTEGRATIONS,
} from './libs/constants/constants.js';

const Integrations = () => {
  const classes = useStyles();
  const location = useLocation();
  const { user, isCurrentProjectDemo } = useUserInfo();
  const { integrations } = useIntegration();

  const items = [...DEFAULT_INTEGRATIONS];
  const openedItem = location.state?.connectTo || '';
  const isConnected = (integration, name) => {
    if (name === INTEGRATIONS.dataplex) {
      return integration !== null && integration?.isApiEnabled;
    }

    if (name === INTEGRATIONS.dataform) {
      return integration !== null && integration?.isApiEnabled;
    }

    return integration !== null && integration !== undefined;
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE.integrations;
    amplEvent(AMPL_PAGE_EVENT.integrations);
  }, []);

  if (isCurrentProjectDemo && !user?.info.supperAdmin) {
    return '';
  }

  return (
    <>
      <PageHeader hasDateRangePicker={false}>Integrations</PageHeader>
      <Row className={classes.itemContainer}>
        {items.map((item) => (
          <IntegrationItem
            key={item.name}
            {...item}
            isConnected={isConnected(integrations[item.name], item.name)}
            isOpened={openedItem === item.name}
          />
        ))}
      </Row>
    </>
  );
};

export default Integrations;
