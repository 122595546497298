import { useEffect } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useQueryParamsContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { AMPL_PAGE_EVENT, SEARCH_PARAMS } from 'constants/constants.js';
import { ReactComponent as MagicIcon } from 'assets/img/icons/magic.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
    padding: '5px 8px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
  selected: {
    background: 'linear-gradient(65deg, #2F38C8 18.97%, #886CF6 94.15%)',
    '& p': {
      color: theme.palette.common.white,
    },
    '& svg path': {
      fill: theme.palette.common.white,
    },
  },
  verticalDivider: {
    width: 1,
    backgroundColor: theme.palette.divider,
    marginRight: theme.spacing(4),
  },
}));

const SuggestedPriority = () => {
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const isSuggestedPriority = queryParams.has(SEARCH_PARAMS.SUGGESTED_PRIORITY);

  useEffect(() => {
    if (isSuggestedPriority) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> 'Suggested to prioritise' selected`
      );
    }
  }, [isSuggestedPriority]);

  const handleChangeSelected = () => {
    updateQueryParams({
      [SEARCH_PARAMS.SUGGESTED_PRIORITY]: isSuggestedPriority ? null : 'true',
    });
  };

  return (
    <>
      <div className={classes.verticalDivider} />
      <div
        className={clsx(
          classes.container,
          isSuggestedPriority && classes.selected
        )}
        onClick={handleChangeSelected}
      >
        <MagicIcon />
        <p className='txt-blue-13-700'>Suggested to prioritise</p>
      </div>
    </>
  );
};

export { SuggestedPriority };
