import React from 'react';
import { useQuery, useState, useEffect } from 'hooks/hooks.js';
import { GeneralSearch } from './search.js';
import { useQueryParamsContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES, SEARCH_PARAMS } from 'constants/constants.js';

const DatasetSearch = ({
  onDatasetSearch = null,
  onDatasetSelect = null,
  optionLabel = 'Search for:',
  className = '',
}) => {
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const [datasets, setDatasets] = useState([]);

  const searchFromUrl = queryParams.get(SEARCH_PARAMS.SEARCH);
  const datasetFromURL = queryParams.get(SEARCH_PARAMS.DATASET);

  const { data } = useQuery([QUERY_TYPES.datasets], ({ queryKey }) => {
    const [url] = queryKey;
    return fetcherGet(url);
  });

  useEffect(() => {
    if (data?.values.length > 0) {
      setDatasets(
        data.values.map((item) => {
          return { label: item.name, value: item.name };
        })
      );
    }
  }, [data?.values]);

  const handleDatasetSearch = (value) => {
    updateQueryParams(
      {
        [SEARCH_PARAMS.SEARCH]: value,
        [SEARCH_PARAMS.DATASET]: null,
      },
      { useReplace: true }
    );

    if (onDatasetSearch) {
      onDatasetSearch(value);
    }
  };

  const handleDatasetSelect = (value) => {
    const newValue = value.length ? value : null;

    updateQueryParams(
      {
        [SEARCH_PARAMS.DATASET]: newValue,
        [SEARCH_PARAMS.SEARCH]: null,
      },
      { useReplace: true }
    );

    if (onDatasetSelect) {
      onDatasetSelect(newValue);
    }
  };

  return (
    <GeneralSearch
      optionLabel={optionLabel}
      options={datasets}
      onSearch={handleDatasetSearch}
      onSelect={handleDatasetSelect}
      defaultSelectValue={datasetFromURL}
      defaultSearchValue={searchFromUrl}
      className={className}
      placeholderName='Start typing dataset name...'
    />
  );
};

export { DatasetSearch };
