import React, { createContext } from 'react';
import { useContext, useEffect } from 'hooks/hooks.js';
import posthog from 'posthog-js';
import { useUserInfo } from 'context/context.js';
import { amplEvent } from 'service/services.js';

const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY;
const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;

const PostHogContext = createContext(null);

const PostHogContextProvider = ({ children }) => {
  const { user } = useUserInfo();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.info.supperAdmin) {
      posthog.reset();
      return;
    }

    if (!posthog.__loaded) {
      try {
        posthog.init(POSTHOG_KEY, {
          api_host: POSTHOG_HOST,
          autocapture: false,
          capture_pageview: false,
          session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
              password: true,
            },
          },
          loaded: function (posthog) {
            posthog.identify(user.email);
          },
        });
      } catch (error) {
        amplEvent('PostHog initialization error');
      }
    } else {
      posthog.identify(user.email);
    }
  }, [user]);

  return (
    <PostHogContext.Provider value={{}}>{children}</PostHogContext.Provider>
  );
};

const usePostHogContext = () => {
  const context = useContext(PostHogContext);

  if (!context) {
    throw new Error(
      'usePostHogContext must be used within a PostHogContextProvider'
    );
  }

  return context;
};

export { PostHogContextProvider, usePostHogContext };
