import {
  PositionLoader,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';

const TableLoader = () => {
  return (
    <StyledTableRow className='noBorder'>
      <StyledTableCell colSpan='100%'>
        <PositionLoader />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { TableLoader };
