import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { Grid } from '@mui/material';
import { Typography, TYPOGRAPHY_COLOR } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import {
  DataProductTabs,
  DATE_FORMAT,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as SlackLogo } from 'assets/img/slack.svg';

const getDateFormat = (date) => {
  return moment(date).utc().format(DATE_FORMAT.monthYear);
};

const InfoWidget = ({ dataProduct }) => {
  const widgetData = [
    {
      label: 'Domain',
      value: dataProduct.domain?.name ? (
        <Link
          to={{
            pathname: AppRoutes.DataProduct.path,
            search: new URLSearchParams({
              [SEARCH_PARAMS.TAB]: DataProductTabs.DOMAINS,
              [SEARCH_PARAMS.SELECTED_DOMAIN]: dataProduct.domain.uuid,
            }).toString(),
          }}
        >
          <Typography color={TYPOGRAPHY_COLOR.primary}>
            {dataProduct.domain.name}
          </Typography>
        </Link>
      ) : null,
    },
    {
      label: 'Slack channel',
      value:
        dataProduct.domain && dataProduct.domain?.slackChannel !== null ? (
          <div>
            <SlackLogo width={20} height={20} style={{ marginRight: 8 }} />
            {dataProduct.domain.slackChannel.channelName}
          </div>
        ) : null,
    },
    { label: 'Created', value: getDateFormat(dataProduct.createdAt) },
  ];

  return (
    <Widget
      label='Info'
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <Grid container spacing={2}>
        {widgetData.map(({ label, value }) => (
          <Fragment key={label}>
            <Grid item xs={3}>
              <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {value || (
                <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
                  &mdash;
                </Typography>
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Widget>
  );
};

export { InfoWidget };
