import React from 'react';
import clsx from 'clsx';
import { TableHead } from '@mui/material';
import { StyledTableCell } from 'Pages/Costs/Costs.styles.js';
import { StyledTableRow } from 'Components/components.js';
import {
  DatasetName,
  CostShare,
  CurrentPlan,
  AlternativePlan,
  Costs,
  currentPlanId,
} from '../libs/enums.js';

const HEAD_CELLS = [
  DatasetName,
  CostShare,
  CurrentPlan,
  AlternativePlan,
  Costs,
];

const StorageTableHead = () => {
  return (
    <TableHead>
      <StyledTableRow>
        {HEAD_CELLS.map((headCell) => (
          <StyledTableCell
            key={headCell.ID}
            align={headCell.ALIGN}
            className={clsx(
              headCell.ID === currentPlanId && 'withBackground',
              'withoutPadding'
            )}
          >
            {headCell.LABEL}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export { StorageTableHead };
