import { SEARCH_PARAMS } from 'constants/constants.js';
import {
  DATASET_ALERT_STATUS_FILTER,
  DICTIONARY_STREAM_FILTER,
  LOOKER_ALERT_STATUS_FILTER,
  LOOKER_REPORTS_TYPE_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TABLE_TYPE_FILTER,
} from '../constants/constants.js';

const getKeyForURL = (type) => {
  switch (type) {
    case TABLE_ALERT_STATUS_FILTER:
      return SEARCH_PARAMS.TABLE_ALERT_TYPE;
    case DATASET_ALERT_STATUS_FILTER:
      return SEARCH_PARAMS.DATASET_ALERT_TYPE;
    case DICTIONARY_STREAM_FILTER:
      return SEARCH_PARAMS.STREAM;
    case TABLE_TYPE_FILTER:
      return SEARCH_PARAMS.TABLE_TYPE;
    case LOOKER_ALERT_STATUS_FILTER:
      return SEARCH_PARAMS.LOOKER_ALERT_TYPE;
    case LOOKER_REPORTS_TYPE_FILTER:
      return SEARCH_PARAMS.LOOKER_TYPE;
    default:
      return null;
  }
};

export { getKeyForURL };
