const LEFT_SHIFT = 5;
const ITERATION_NUMBER = 3;
const COLOR_LEFT_SHIFT = 8;
const RADIX_PARAM = 16;

const stringToColor = (string) => {
  let color = '#';
  let hash = 0;
  let i;

  if (string?.length > 0) {
    for (i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << LEFT_SHIFT) - hash);
    }

    for (i = 0; i < ITERATION_NUMBER; i++) {
      const value = (hash >> (i * COLOR_LEFT_SHIFT)) & 0xff;
      color += `00${value.toString(RADIX_PARAM)}`.slice(-2);
    }
  }

  return color;
};

export { stringToColor };
