import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  DictionaryTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  DICTIONARY_LOOKER_DASHBOARD_TYPE,
  DICTIONARY_LOOKER_LOOK_TYPE,
} from 'utils/constants.js';
import { EXISTING_DATA_TYPES } from '../../libs/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { PositionLoader, UnconnectedLooker } from 'Components/components.js';
import { useIntegration } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  dataItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  integrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
}));

const EVENT_CLICK = `${AMPL_PAGE_EVENT.monitors} -> ${
  getWidgetLabel().dependentReports.title
} widget -> Click -`;

const Reports = ({ table, dataset, onChangeExistingData }) => {
  const classes = useStyles();
  const { integrations } = useIntegration();

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.lookerTableReport, dataset, table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, { dataset, table });
    },
    { enabled: integrations?.looker !== null && !!table && !!dataset }
  );

  const isEmptyDashboards = useMemo(() => {
    return data?.value?.dashboards === 0;
  }, [data?.value]);

  const isEmptyLooks = useMemo(() => {
    return data?.value?.looks === 0;
  }, [data?.value]);

  useEffect(() => {
    if (isEmptyDashboards && isEmptyLooks) {
      onChangeExistingData(EXISTING_DATA_TYPES.reports, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyDashboards, isEmptyLooks]);

  if (isFetching) {
    return <PositionLoader />;
  }

  if (!data?.value && integrations?.looker !== null) {
    return 'No depending reports';
  }

  return (
    <>
      {integrations?.looker === null ? (
        <UnconnectedLooker positionAbsolute={false} />
      ) : (
        <div className={classes.dataContainer}>
          <div className={classes.dataItem}>
            <div className='txt-mainDark-13-500'>Dashboards</div>
            <Link
              to={{
                pathname: AppRoutes.Dictionary.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: DictionaryTabs.LOOKER,
                  [SEARCH_PARAMS.DATASET]: dataset,
                  [SEARCH_PARAMS.TABLE]: table,
                  [SEARCH_PARAMS.LOOKER_TYPE]: DICTIONARY_LOOKER_DASHBOARD_TYPE,
                }).toString(),
              }}
            >
              <div
                className={clsx(
                  isEmptyDashboards
                    ? 'txt-lightGrey-20-700'
                    : 'txt-mainDark-20-700'
                )}
                onClick={() => amplEvent(`${EVENT_CLICK} Dashboards`)}
              >
                {data.value.dashboards}
              </div>
            </Link>
          </div>

          <div className={classes.dataItem}>
            <div className='txt-mainDark-13-500'>Looks</div>
            <Link
              to={{
                pathname: AppRoutes.Dictionary.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: DictionaryTabs.LOOKER,
                  [SEARCH_PARAMS.DATASET]: dataset,
                  [SEARCH_PARAMS.TABLE]: table,
                  [SEARCH_PARAMS.LOOKER_TYPE]: DICTIONARY_LOOKER_LOOK_TYPE,
                }).toString(),
              }}
            >
              <div
                className={clsx(
                  isEmptyLooks ? 'txt-lightGrey-20-700' : 'txt-mainDark-20-700'
                )}
                onClick={() => amplEvent(`${EVENT_CLICK} Looks`)}
              >
                {data.value.looks}
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export const ReportsMemo = memo(Reports);
