import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import { StyledTableCell } from 'Pages/Pipelines/Pipelines.styles.js';
import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import {
  SourceTable,
  LastJobName,
  LastExecuted,
  Email,
  Frequency,
  LastDuration,
  AveSlotsHours,
  SlotsHours,
  AveBytes,
  Bytes,
} from './enums/enums.js';

const HEAD_CELLS = [
  SourceTable,
  LastJobName,
  LastExecuted,
  Email,
  Frequency,
  LastDuration,
  AveSlotsHours,
  SlotsHours,
  AveBytes,
  Bytes,
];

const PipelineTableHead = () => {
  const { currentBqBillingData } = useBqBillingData();

  AveSlotsHours.IS_SHOW = useMemo(() => {
    return currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData]);
  SlotsHours.IS_SHOW = useMemo(() => {
    return currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData]);
  AveBytes.IS_SHOW = useMemo(() => {
    return currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData]);
  Bytes.IS_SHOW = useMemo(() => {
    return currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;
  }, [currentBqBillingData]);

  return (
    <TableHead>
      <TableRow>
        {HEAD_CELLS.map((headCell) => {
          return (
            headCell.IS_SHOW && (
              <StyledTableCell
                key={headCell.ID}
                align={headCell.ALIGN}
                style={{ width: headCell.WIDTH || 'none' }}
              >
                <TableSortLabel hideSortIcon={true}>
                  <span className='txt-grey-13-500 nowrapText'>
                    {headCell.LABEL}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export { PipelineTableHead };
