import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { SearchDropdown, TableDatasetSearch } from 'Components/components.js';
import { Search as DataProductSearch } from 'Pages/DataProdact/components/DataProductTab/components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: 0,
    '& .tableSearch': {
      margin: 0,
      flexGrow: 1,
    },
  },
}));

const SearchOptions = {
  TABLE: 'Table',
  DATA_PRODUCT: 'Data Product',
};

const Search = ({
  onTableChange,
  onDatasetChange,
  onTableSearch,
  tableInfoFromUrl,
  searchFromUrl,
}) => {
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();

  const defaultSearchType = useMemo(() => {
    if (
      queryParams.has(SEARCH_PARAMS.SEARCH_DATA_PRODUCT) ||
      queryParams.has(SEARCH_PARAMS.SELECTED_DATA_PRODUCT)
    ) {
      return SearchOptions.DATA_PRODUCT;
    }

    return SearchOptions.TABLE;
  }, [queryParams]);

  const [selectedSearchType, setSelectedSearchType] =
    useState(defaultSearchType);

  const searchConfig = {
    [SearchOptions.DATA_PRODUCT]: (
      <DataProductSearch
        searchWithDropdown={true}
        className='tableSearch'
        optionLabel='Incidents for:'
      />
    ),
    default: (
      <TableDatasetSearch
        optionLabel='Incidents for:'
        onTableChange={onTableChange}
        onDatasetChange={onDatasetChange}
        onSearch={onTableSearch}
        defaultDataset={tableInfoFromUrl?.dataset}
        defaultTable={tableInfoFromUrl?.table}
        defaultSearchValue={searchFromUrl}
        className='tableSearch'
        searchWithDropdown={true}
      />
    ),
  };

  const handleChangeSelectedSearchType = (value) => {
    onTableChange(null);
    onTableSearch('');
    onDatasetChange(null);

    updateQueryParams(
      {
        [SEARCH_PARAMS.SEARCH_DATA_PRODUCT]: null,
        [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: null,
        [SEARCH_PARAMS.DATASET]: null,
        [SEARCH_PARAMS.TABLE]: null,
        [SEARCH_PARAMS.SEARCH]: null,
      },
      { useReplace: true }
    );

    setSelectedSearchType(value);
  };

  return (
    <div className={classes.container}>
      <SearchDropdown
        options={Object.values(SearchOptions)}
        selectedOption={selectedSearchType}
        onOptionClick={handleChangeSelectedSearchType}
      />
      {searchConfig[selectedSearchType] || searchConfig.default}
    </div>
  );
};

export { Search };
