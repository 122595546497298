import { SEARCH_PARAMS_SEPARATOR } from 'constants/constants.js';
import {
  INCIDENT_TYPE_FILTER,
  LOOKER_INCIDENT_TYPE_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
} from 'Pages/Incidents/libs/constants/constants.js';

const FILTERS_WITH_DEFAULT_VALUE = [
  INCIDENT_TYPE_FILTER,
  LOOKER_INCIDENT_TYPE_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
];

const getDropdownValueByURL = (filterKey, options, urlValue) => {
  if (!urlValue) {
    return [options[0]];
  }

  const selectedValues = urlValue.split(SEARCH_PARAMS_SEPARATOR);

  if (
    FILTERS_WITH_DEFAULT_VALUE.includes(filterKey) &&
    options[0].value.length === selectedValues.length &&
    options[0].value.every((item) => selectedValues.includes(item)) // values from options and url values are the same
  ) {
    return [options[0]];
  }

  return options.filter((option) => {
    if (typeof option.value === 'string' || typeof option.value === 'number') {
      return selectedValues.includes(option.value.toString());
    } else if (Array.isArray(option.value)) {
      return option.value.every((item) => selectedValues.includes(item));
    }
    return false;
  });
};

const isDropdownsEqual = (dropdown1, dropdown2) => {
  for (const key in dropdown1) {
    if (Array.isArray(dropdown1[key]) && Array.isArray(dropdown2[key])) {
      if (dropdown1[key].length !== dropdown2[key].length) {
        return false;
      }
      for (const item1 of dropdown1[key]) {
        const equalItem = dropdown2[key].some(
          (item2) => item1.value === item2.value
        );
        if (!equalItem) {
          return false;
        }
      }
    } else if (dropdown1[key] !== dropdown2[key]) {
      return false;
    }
  }

  return true;
};

const prepareQueryParamsByFilters = ({
  defaultDropdownsValue,
  dropdownsValue,
  existingDropdown,
  queryParams,
  getKeyForURL,
}) => {
  const keysToRemove = Object.keys(defaultDropdownsValue).filter(
    (filterKey) => !existingDropdown.includes(filterKey)
  );

  const newQueryParams = {};

  keysToRemove.forEach((filterKey) => {
    const paramKey = getKeyForURL(filterKey);
    if (queryParams.get(paramKey) !== null) {
      newQueryParams[paramKey] = null;
    }
  });

  Object.keys(dropdownsValue).forEach((filterKey) => {
    if (existingDropdown.includes(filterKey)) {
      const paramValue = dropdownsValue[filterKey]
        ? dropdownsValue[filterKey]
            .map((option) => option.value)
            .join(SEARCH_PARAMS_SEPARATOR)
        : null;
      const paramKey = getKeyForURL(filterKey);

      if (paramValue !== null && paramValue !== queryParams.get(paramKey)) {
        newQueryParams[paramKey] = paramValue;
      }
    }
  });

  return newQueryParams;
};

export { getDropdownValueByURL, isDropdownsEqual, prepareQueryParamsByFilters };
