import React from 'react';
import {
  useCallback,
  useEffect,
  useGetIncidentGroups,
  useHistory,
  useMemo,
  useState,
  useSyncTableToSearchParam,
} from 'hooks/hooks.js';
import { Col, Row } from 'react-bootstrap';
import { useStyles } from '../../Incidents.styles.js';
import { SourcesButton } from 'Components/components.js';
import {
  Dropdowns,
  EmptyIncidentData,
  Search,
} from '../GlobalPageComponents/components.js';
import {
  BQIncidentCard,
  QualityCheckIncidentCard,
} from './components/components.js';
import {
  useDateContext,
  useProjectTree,
  useQueryParamsContext,
} from 'context/context.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  IncidentTabs,
  ANOMALY_TYPE,
  STATUS_FILTER_OPTIONS,
  INCIDENT_FILTER_OPTIONS,
} from 'constants/constants.js';
import { formatIncidents } from 'Pages/Incidents/libs/utils/utils.js';
import { AppRoutes } from 'app-routes.js';
import {
  INCIDENT_STATUS_FILTER,
  BigQueryTabDropdowns,
  TABLE_ALERT_FILTER,
  INCIDENT_TYPE_FILTER,
} from 'Pages/Incidents/libs/constants/constants.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';

const INITIALIZE_DROPDOWNS = {
  [INCIDENT_TYPE_FILTER]: [STATUS_FILTER_OPTIONS[0]],
  [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[0]],
  [INCIDENT_STATUS_FILTER]: [INCIDENT_FILTER_OPTIONS[0]],
};

const TAB_EVENT = `${AMPL_PAGE_EVENT.incidents} -> tab: ${IncidentTabs.GBQ} ->`;

const BQTab = ({
  handleChangeTotalIncidents,
  handleChangeTitleTotalIncidents,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeOpenBackdrop,
  onChangeExistingDatePicker,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { startDate, endDate } = useDateContext();
  const [tableInfo, setTableInfo] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [searchTable, setSearchTable] = useState(null);
  const { tableFromURL, tableInfoFromUrl, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      tableInfo?.table,
      tableInfo?.dataset || dataset,
      searchTable,
      IncidentTabs.GBQ
    );
  const {
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
    setOnSelectDataset,
  } = useProjectTree();
  const { queryParams } = useQueryParamsContext();
  const incidentIdFromUrl = queryParams.get(SEARCH_PARAMS.ID);
  const incidentTypeFromUrl = queryParams.get(SEARCH_PARAMS.TYPE);

  const [dropdownsValue, setDropdownsValue] = useState({
    [INCIDENT_TYPE_FILTER]: null,
    [TABLE_ALERT_FILTER]: null,
    [INCIDENT_STATUS_FILTER]: null,
  });

  const defaultIncidentIdAndType = useMemo(() => {
    return incidentIdFromUrl && incidentTypeFromUrl
      ? {
          id: incidentIdFromUrl,
          type: incidentTypeFromUrl,
        }
      : {
          id: null,
          type: null,
        };
  }, [incidentIdFromUrl, incidentTypeFromUrl]);

  const [incidentIdAndType, setIncidentIdAndType] = useState(
    defaultIncidentIdAndType
  );

  const tableInfoByIncident = (incident) => {
    return {
      uuid: incident.tableUuid,
      dataset: incident.dataset,
      table: incident.table,
      type: incident.tableType,
      alertType: incident.alertType,
    };
  };

  useEffect(() => {
    const id = queryParams.get(SEARCH_PARAMS.ID);
    const type = queryParams.get(SEARCH_PARAMS.TYPE);

    if (id && type) {
      setIncidentIdAndType({ id, type });
      onChangeExistingDatePicker(false);
    } else {
      setIncidentIdAndType({ id: null, type: null });
      onChangeExistingDatePicker(true);
    }
  }, [queryParams, onChangeExistingDatePicker]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [tableInfo, dataset, searchTable, handleChangePage, startDate, endDate]);

  const isAllowedRequest = useMemo(() => {
    return (
      !Object.values(dropdownsValue).some((value) => value === null) ||
      Boolean(incidentIdAndType.id && incidentIdAndType.type)
    );
  }, [dropdownsValue, incidentIdAndType]);

  const {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
    isFinishedRequest,
  } = useGetIncidentGroups({
    page,
    rowsPerPage,
    dataset: tableInfo?.dataset || tableInfoFromUrl?.dataset,
    table: tableInfo?.table || tableInfoFromUrl?.table,
    searchTable: searchTable || searchFromUrl,
    incidentGroupsTypes: dropdownsValue[INCIDENT_TYPE_FILTER],
    incidentGroupsAlert: dropdownsValue[TABLE_ALERT_FILTER],
    incidentGroupsStatuses: dropdownsValue[INCIDENT_STATUS_FILTER],
    dataProductUUID: queryParams.get(SEARCH_PARAMS.SELECTED_DATA_PRODUCT),
    searchDataProductName: queryParams.get(SEARCH_PARAMS.SEARCH_DATA_PRODUCT),
    incidentIdAndType,
    enabled: isAllowedRequest,
  });

  useEffect(() => {
    const table = queryParams.get(SEARCH_PARAMS.TABLE);
    const dataset = queryParams.get(SEARCH_PARAMS.DATASET);
    const project = queryParams.get(SEARCH_PARAMS.PROJECT);

    if (
      table &&
      dataset &&
      incidentTypeFromUrl &&
      incidentIdFromUrl &&
      isFinishedRequest &&
      !isIncidentGroupsFetching &&
      !incidentGroups.length &&
      !project
    ) {
      history.push({
        pathname: AppRoutes.Monitors.path,
        search: new URLSearchParams({ dataset, table }).toString(),
        state: { ...history.location.state, navigateFromEmptyIncident: true },
      });

      amplEvent(
        `navigate to Monitors page - non-existent incident - ${getFullTableName(
          table,
          dataset
        )} (BQ Tab)`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    incidentGroups.length,
    queryParams,
    isIncidentGroupsFetching,
    isFinishedRequest,
  ]);

  useEffect(() => {
    handleChangeTitleTotalIncidents(incidentsTotal);
    handleChangeTotalIncidents(incidentsGroupPagination);
  }, [
    handleChangeTitleTotalIncidents,
    handleChangeTotalIncidents,
    incidentsGroupPagination,
    incidentsTotal,
  ]);

  const isEmptyIncidentsData = useMemo(() => {
    return Boolean(!incidentGroups.length && !isIncidentGroupsFetching);
  }, [incidentGroups.length, isIncidentGroupsFetching]);

  const formattedData = useMemo(() => {
    if (!incidentGroups.length) {
      return {};
    }
    return formatIncidents(incidentGroups);
  }, [incidentGroups]);

  useEffect(() => {
    if (tableFromURL === null) {
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setDataset(null);
    }
  }, [
    tableFromURL,
    setTableInfo,
    setSelectTable,
    setDataset,
    setSelectDataset,
  ]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchTable(null);
    }
  }, [searchFromUrl]);

  useEffect(() => {
    onChangeOpenBackdrop(isIncidentGroupsFetching);
  }, [isIncidentGroupsFetching, onChangeOpenBackdrop]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const onTableChange = useCallback(
    (value) => {
      setTableInfo(value);
      setSelectTable(value);
      setDataset(null);
      setSelectDataset(null);

      if (value !== null) {
        amplEvent(
          `${TAB_EVENT} selected table: ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onDatasetChange = useCallback(
    (value) => {
      setDataset(value);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(value);

      if (value !== null) {
        amplEvent(`${TAB_EVENT} selected dataset: ${value}`);
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onTableSearch = useCallback(
    (value) => {
      setSearchTable(value);
      setDataset(null);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);

      if (value.length) {
        amplEvent(`${TAB_EVENT} search for: ${value}`);
      }

      if (!value) clearSearchParams();
    },
    [setSearchTable, setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  useEffect(() => {
    if (onTableChange !== undefined) {
      setOnSelectTable({ onChange: onTableChange });
    }
  }, [onTableChange, setOnSelectTable]);

  useEffect(() => {
    if (onDatasetChange !== undefined) {
      setOnSelectDataset({ onChange: onDatasetChange });
    }
  }, [onDatasetChange, setOnSelectDataset]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  return (
    <>
      {(!incidentIdAndType.id || !incidentIdAndType.type) && (
        <>
          <Dropdowns
            existingDropdown={BigQueryTabDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
          />

          <div className={classes.searchContainer}>
            <SourcesButton onClick={handleProjectTree} />
            <Search
              onTableChange={onTableChange}
              onDatasetChange={onDatasetChange}
              onTableSearch={onTableSearch}
              tableInfoFromUrl={tableInfoFromUrl}
              searchFromUrl={searchFromUrl}
            />
          </div>
        </>
      )}

      {isEmptyIncidentsData && (
        <EmptyIncidentData
          dropdownsValue={dropdownsValue}
          initializeFilters={INITIALIZE_DROPDOWNS}
        />
      )}

      {Object.keys(formattedData).map((date) => (
        <Row key={date}>
          <Col xs={12}>
            <p className='txt-mainDark-20-700 mb-3'>{date}</p>
          </Col>
          {formattedData[date].map((incident) => {
            const IncidentCard =
              incident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK
                ? QualityCheckIncidentCard
                : BQIncidentCard;

            return (
              <Col key={incident.groupKey} xs={12} className='mb-3'>
                <IncidentCard
                  groupIncident={incident}
                  incidentIdAndType={incidentIdAndType}
                  incidentStatuses={dropdownsValue[INCIDENT_STATUS_FILTER]}
                  tableInfo={tableInfoByIncident(incident)}
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </>
  );
};

export { BQTab };
