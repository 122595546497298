import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useStyles } from '../StatsWidget.styles.js';
import { Typography, TYPOGRAPHY_VARIANT } from 'Components/components.js';
import { abbreviateNumber } from 'utils/helpers/helpers.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const useComponentStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2.5),
    marginTop: theme.spacing(6),
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .greenText': {
      color: theme.palette.common.green,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  iconContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(3),
  },
}));

const StatsPercentBlock = ({ diff, costData, linkPath, Icon }) => {
  const classes = useStyles({ diffContainerBackground: '#EDFFDC' });
  const componentClasses = useComponentStyles();

  return (
    <div className={componentClasses.flexContainer}>
      {diff <= 0 ? (
        <Link to={linkPath}>
          See details
          <ArrowRight className={classes.linkIcon} />
        </Link>
      ) : (
        <>
          <div className={componentClasses.itemContainer}>
            <div className={componentClasses.iconContainer}>
              <Icon />
            </div>
            <Typography variant={TYPOGRAPHY_VARIANT.h6}>
              Possible cost savings
            </Typography>
          </div>

          <div className={componentClasses.itemContainer}>
            <div className={clsx(classes.diffContainer, 'greenText')}>
              {abbreviateNumber(diff, 1)}%
            </div>
            <Typography>${abbreviateNumber(costData, 1)}</Typography>
          </div>

          <Link to={linkPath}>
            See saving suggestion
            <ArrowRight className={classes.linkIcon} />
          </Link>
        </>
      )}
    </div>
  );
};

export { StatsPercentBlock };
