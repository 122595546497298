import { getFullTableName } from 'utils/helpers/helpers.js';
import { DATA_ASSETS_TYPES } from 'constants/constants.js';

const getDataAssetNameByType = (type, data) => {
  switch (type) {
    case DATA_ASSETS_TYPES.dataset:
      return data.datasetName;
    case DATA_ASSETS_TYPES.table:
      return getFullTableName(data.tableName, data.datasetName);
    default:
      return 'Unknown type';
  }
};

export { getDataAssetNameByType };
