import React from 'react';
import { useDeleteDataProduct, useHistory } from 'hooks/hooks.js';
import moment from 'moment';
import { useStyles } from 'Pages/DataProdact/DataProductDetails.styles.js';
import {
  BackButton,
  Button,
  BUTTON_COLOR,
  BUTTON_VARIANT,
  Modal,
  PageHeader,
  RightSideModal,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import {
  EditDataProductModalBody,
  DeleteDataProductModalBody,
} from '../components.js';
import { useModal } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { DATE_FORMAT } from 'constants/constants.js';

const getDateFormat = (date) => {
  return moment(date).utc().format(DATE_FORMAT.monthYearTime);
};

const HeaderSection = ({ dataProduct }) => {
  const classes = useStyles();
  const history = useHistory();
  const { setModal } = useModal();

  const { name, updatedAt, uuid } = dataProduct;

  const { deleteDataProduct } = useDeleteDataProduct({
    dataProductUUID: uuid,
  });

  const navigateToDataProductPage = () => {
    history.push(AppRoutes.DataProduct.path);
  };

  const handleDeleteDataProduct = () => {
    deleteDataProduct().then((data) => {
      if (data && data.success) {
        navigateToDataProductPage();
      }
    });
  };

  const onDeleteClick = () => {
    setModal(() => (
      <Modal
        onAgree={handleDeleteDataProduct}
        title={`Delete ${name}`}
        titlePosition='center'
        ModalBodyComponent={DeleteDataProductModalBody}
        agreeButtonText='Delete'
      />
    ));
  };

  const onEditClick = () => {
    setModal(() => (
      <RightSideModal
        title={`Edit ${name}`}
        ModalBodyComponent={EditDataProductModalBody}
        dataProductUUID={uuid}
        mainBlockMargin={0}
      />
    ));
  };

  return (
    <PageHeader>
      <div className={classes.pageNameContainer}>
        <BackButton goPreviousPage={navigateToDataProductPage} />
        <div>
          <Typography variant={TYPOGRAPHY_VARIANT.h4}>{name}</Typography>
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
            Last update: {getDateFormat(updatedAt)}
          </Typography>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            color={BUTTON_COLOR.secondary}
            className='px-5'
            onClick={onEditClick}
            text='Edit'
            fullWidth={false}
          />
          <Button
            onClick={onDeleteClick}
            variant={BUTTON_VARIANT.text}
            text='Delete'
            fullWidth={false}
          />
        </div>
      </div>
    </PageHeader>
  );
};

export { HeaderSection };
