import React from 'react';
import {
  usePipelineSourceTables,
  useOpenTableRawBySearch,
  useTableLabels,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import {
  Chip,
  CHIP_COLOR,
  TableLabelsChips,
  DestinationTableCell,
  LoadingTableRaw,
} from 'Components/components.js';
import { PipelineTableSourceRaw } from './PipelineTableSourceRaw.js';
import { usePipelinePageContext } from 'context/context.js';
import {
  getAlertColorType,
  getAlertLabel,
  getPipelineMetaData,
} from 'utils/helpers/helpers.js';
import {
  SEARCH_EMAIL,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'constants/constants.js';

const PipelineTableRows = ({ destinationTable, startDate, endDate }) => {
  const scrollToRef = useRef(null);
  const [isScrollRefRender, setIsScrollRefRender] = useState(false);
  const { searchValues, defaultJobHash } = usePipelinePageContext();
  const [sourcesPage, setSourcesPage] = useState(0);
  const [sourcesData, setSourcesData] = useState([]);
  const { isOpenTableRaw, handleChangeOpen } = useOpenTableRawBySearch(
    searchValues[SEARCH_TABLE],
    searchValues[SELECTED_TABLE],
    destinationTable,
    destinationTable?.isSelected
  );

  const sourceSearchValues = useMemo(() => {
    const { dataset, table } = destinationTable;
    const selectedDataset = searchValues[SELECTED_TABLE]?.dataset;
    const selectedTable = searchValues[SELECTED_TABLE]?.table;
    const searchValue = searchValues[SEARCH_TABLE];

    const isDatasetMatch =
      dataset === selectedDataset ||
      (searchValue.length > 0 && dataset.includes(searchValue));
    const isTableMatch =
      table === selectedTable ||
      (searchValue.length > 0 && table.includes(searchValue));

    return {
      dataset: isDatasetMatch ? '' : selectedDataset,
      table: isTableMatch ? '' : selectedTable,
      searchValue: isDatasetMatch || isTableMatch ? '' : searchValue,
    };
  }, [destinationTable, searchValues]);

  const { sources, isFetchingSources, sourcesCount } = usePipelineSourceTables({
    dataset: destinationTable?.dataset,
    table: destinationTable?.table,
    startDate,
    endDate,
    sourceTable: sourceSearchValues.table,
    sourceDataset: sourceSearchValues.dataset,
    searchValue: sourceSearchValues.searchValue,
    principalEmail: searchValues[SELECTED_EMAIL],
    searchPrincipalEmail: searchValues[SEARCH_EMAIL],
    jobTagNames: searchValues[SELECTED_TAGS],
    powerbiReportName: searchValues[SELECTED_POWER_BI_REPORT],
    powerbiWorkspaceName: searchValues[SELECTED_POWER_BI_WORKSPACE],
    pipelineType: destinationTable?.pipelineType,
    page: sourcesPage,
    jobHash: defaultJobHash,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  useEffect(() => {
    setSourcesData((prevState) => {
      const newSources = sources.filter(
        (source) =>
          !prevState.some(
            (existingSource) => existingSource.jobHash === source.jobHash
          )
      );
      return [...prevState, ...newSources];
    });
  }, [sources]);

  useEffect(() => {
    if (scrollToRef.current && isScrollRefRender && defaultJobHash) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isScrollRefRender, defaultJobHash]);

  const handleSetNextPage = () => {
    setSourcesPage((prevState) => prevState + 1);
  };

  const handleSetIsScrollRefRender = (value) => {
    setIsScrollRefRender(value);
  };

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          searchTable={searchValues[SELECTED_TABLE]}
          searchValue={searchValues[SEARCH_TABLE]}
          icon={getPipelineMetaData(destinationTable?.pipelineType).icon}
        />
        <TableCell align='left' sx={{ alignContent: 'baseline' }} colSpan={7}>
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>
        <TableCell />
      </TableRow>

      {isOpenTableRaw && (
        <>
          {sourcesData.map((source) => (
            <PipelineTableSourceRaw
              key={source.jobHash}
              destinationTable={destinationTable}
              sourceData={source}
              scrollToRef={
                defaultJobHash === source.jobHash ? scrollToRef : null
              }
              onChangeIsScrollRefRender={handleSetIsScrollRefRender}
            />
          ))}
          {isFetchingSources && <LoadingTableRaw />}
          {sourcesCount > sourcesData.length && (
            <TableRow onClick={handleSetNextPage}>
              <TableCell colSpan={8}>
                <div className='text-center txt-blue-13-700'>Show more</div>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

export { PipelineTableRows };
