import { makeStyles } from '@mui/styles';

const useModalStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    height: '100%',
    margin: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    overflow: 'scroll',
  },
  footer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));

export { useModalStyles };
