import { makeStyles } from '@mui/styles';
import { useStyles } from 'Pages/Costs/Costs.styles.js';
import {
  OptimizedCostsWidget,
  StorageCostsOptimized,
  StorageCostsWidget,
} from 'Components/components.js';
import { StorageOverview } from './components/components.js';

const useComponentStyles = makeStyles((theme) => ({
  statsContainer: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'start',
    '& > *': {
      width: '50%',
    },
  },
}));

const StorageTab = () => {
  const classes = useStyles();
  const componentClasses = useComponentStyles();

  return (
    <div className={classes.pageContainer}>
      <div className={componentClasses.statsContainer}>
        <StorageCostsWidget />
        <OptimizedCostsWidget>
          <StorageCostsOptimized />
        </OptimizedCostsWidget>
      </div>
      <StorageOverview />
    </div>
  );
};

export { StorageTab };
