import { useBqBillingData, useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext, useUserInfo } from 'context/context.js';

const useCostsStats = (enabled = true) => {
  const { isCurrentProjectDemo } = useUserInfo();
  const { isWelcome } = useBqBillingData();
  const isAllowedSRequest = !isWelcome || isCurrentProjectDemo;
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching: isCostsStatsFetching, data } = useQuery(
    [QUERY_TYPES.jobProjectCost, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled: isAllowedSRequest && enabled }
  );

  const costsStats = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { costsStats, isCostsStatsFetching };
};

export { useCostsStats };
