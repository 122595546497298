const STORAGE_BILLING_MODEL = {
  logical: 'LOGICAL',
  physical: 'PHYSICAL',
};

const STORAGE_BILLING_MODEL_LABEL = {
  [STORAGE_BILLING_MODEL.logical]: 'Logical',
  [STORAGE_BILLING_MODEL.physical]: 'Physical',
};

export { STORAGE_BILLING_MODEL, STORAGE_BILLING_MODEL_LABEL };
