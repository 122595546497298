import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  pageNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    marginLeft: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(6),
  },
}));
