import React, { useEffect } from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import {
  StyledTableCell,
  StyledTableRow,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { useCostsContext } from 'context/context.js';
import { abbreviateNumber, numberFormat } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import {
  SEARCH_PARAMS,
  ALTERNATIVE_PLAN_OPTION,
  DEAD_END_TABLES,
} from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import theme from 'theme.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const SavingsOptionsWidget = ({ onChangeSavingsOptionsWidget }) => {
  const { paymentType, altPlanData, deadEndTablesStats, totalProjectCosts } =
    useCostsContext();

  const isStatsExists = useMemo(() => {
    return {
      altPlan: Boolean(altPlanData?.total && paymentType),
      altBillingPlan: Boolean(
        altPlanData?.altPlanCosts < totalProjectCosts && paymentType
      ),
      deadEndTables: Boolean(deadEndTablesStats?.costs),
    };
  }, [altPlanData, deadEndTablesStats, paymentType, totalProjectCosts]);

  const alternativePlan = useMemo(() => {
    if (paymentType) {
      return paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? 'Editions Standard'
        : 'On-Demand';
    }

    return null;
  }, [paymentType]);

  const altPlanWidgetData = useMemo(() => {
    const altPlanCosts = altPlanData?.costs || 0;
    const savingProjectCosts = totalProjectCosts - altPlanCosts;
    const percentageOfTotal = totalProjectCosts
      ? ((altPlanCosts / totalProjectCosts) * 100).toFixed(1)
      : 0;

    return {
      name: ALTERNATIVE_PLAN_OPTION,
      description: 'Change compute model for a pipeline',
      savingPercentage: percentageOfTotal,
      savingCosts: altPlanCosts,
      projectCosts: savingProjectCosts,
      linkText:
        altPlanData?.total === 1
          ? 'See pipeline'
          : `See ${numberFormat(altPlanData?.total)} pipelines`,
    };
  }, [altPlanData, totalProjectCosts]);

  const deadEndWidgetData = useMemo(() => {
    const deadEndCosts = deadEndTablesStats?.costs || 0;
    const savingProjectCosts = totalProjectCosts - deadEndCosts;
    const percentageOfTotal = totalProjectCosts
      ? ((deadEndCosts / totalProjectCosts) * 100).toFixed(1)
      : 0;

    return {
      name: DEAD_END_TABLES,
      description: 'Pipelines with dead-end tables',
      savingPercentage: percentageOfTotal,
      savingCosts: deadEndCosts,
      projectCosts: savingProjectCosts,
      linkText: 'See pipelines',
    };
  }, [deadEndTablesStats, totalProjectCosts]);

  const altBillingData = useMemo(() => {
    const altPlanCosts = altPlanData?.altPlanCosts || 0;
    const savingCosts = totalProjectCosts - altPlanCosts;
    const percentageOfTotal = totalProjectCosts
      ? Number(((savingCosts / totalProjectCosts) * 100).toFixed(1))
      : 0;

    return {
      name: 'ALT_BILLING',
      description: `Change project billing model to ${alternativePlan}`,
      savingPercentage: percentageOfTotal,
      savingCosts: savingCosts,
      projectCosts: altPlanCosts,
    };
  }, [altPlanData?.altPlanCosts, alternativePlan, totalProjectCosts]);

  const widgetData = [
    ...(isStatsExists.altPlan ? [altPlanWidgetData] : []),
    ...(isStatsExists.altBillingPlan ? [altBillingData] : []),
    ...(isStatsExists.deadEndTables ? [deadEndWidgetData] : []),
  ];

  useEffect(() => {
    onChangeSavingsOptionsWidget(Boolean(widgetData.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData.length]);

  const handleLinkClick = (name) => {
    amplEvent(`Costs stats widget -> click -> ${name}`);
  };

  if (!widgetData.length) {
    return null;
  }

  return (
    <>
      {widgetData.map((item) => (
        <WidgetRow
          key={item.name}
          rowData={item}
          onLinkClick={handleLinkClick}
        />
      ))}
    </>
  );
};

const WidgetRow = ({ rowData, onLinkClick }) => {
  const {
    name,
    description,
    linkText,
    savingCosts,
    savingPercentage,
    projectCosts,
  } = rowData;

  const cellsData = [
    {
      id: name,
      label: (
        <>
          <span className='txt-mainDark-13-700'>{description}</span>
          {linkText && (
            <Link
              to={{
                pathname: AppRoutes.Costs.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.COST_EFFICIENCY]: name,
                }).toString(),
              }}
              onClick={() => onLinkClick(description)}
              className='ms-2'
            >
              <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
                {linkText}
              </Typography>
              <ArrowRight fill={theme.palette.primary.main} />
            </Link>
          )}
        </>
      ),
      align: 'left',
      className: 'ps-0',
    },
    {
      id: 2,
      label: (
        <div className='nowrapText'>
          <Typography className='d-inline me-2'>
            {numberFormat(savingPercentage)}%
          </Typography>
          <span className='txt-grey-13-500'>
            ${abbreviateNumber(savingCosts, 1)}
          </span>
        </div>
      ),
      align: 'right',
    },
    {
      id: 3,
      label: <>${abbreviateNumber(projectCosts, 1)}</>,
      align: 'right',
      className: 'pe-0',
    },
  ];

  return (
    <StyledTableRow className='white'>
      {cellsData.map(({ id, label, align, className }) => (
        <StyledTableCell key={id} align={align} className={className}>
          {label}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export { SavingsOptionsWidget };
