import React from 'react';
import {
  useGetExecutedJobsByTime,
  useMemo,
  useParams,
  useEffect,
  useRef,
  useState,
} from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useHighchartsStyles } from 'Components/Highcharts/Highcharts.styles.js';
import { PositionLoader } from 'Components/components.js';
import { getDataAssetNameByType } from 'utils/helpers/helpers.js';

const ExecutedJobsByTimeChart = () => {
  const { uuid } = useParams();
  const classes = useHighchartsStyles();
  const chartRef = useRef(null);

  const [chartOptions, setChartOptions] = useState({});

  const { executedJobsByTime, isFetching } = useGetExecutedJobsByTime({
    dataProductUUID: uuid,
  });

  const chartSeries = useMemo(() => {
    const transformDataItem = (item) => ({
      x: item.date,
      y: item.value,
    });

    return executedJobsByTime
      .map(({ dataAsset, values }) => ({
        name: getDataAssetNameByType(dataAsset.type, dataAsset.data),
        data: values.map((item) => transformDataItem(item)),
      }))
      .filter((series) => {
        for (const point of series.data) {
          if (point.y !== 0) {
            return true;
          }
        }
        return false;
      });
  }, [executedJobsByTime]);

  useEffect(() => {
    setChartOptions({
      chart: { type: 'line' },
      title: { text: undefined },
      xAxis: { type: 'datetime' },
      yAxis: { title: { text: undefined } },
      legend: { enabled: true, itemWidth: 410 },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: { hover: { radiusPlus: 0 } },
          },
          states: { hover: { lineWidthPlus: 0 } },
        },
      },
      series: chartSeries,
    });
  }, [chartSeries]);

  if (isFetching) {
    return (
      <div className={classes.container}>
        <PositionLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {chartSeries.length === 0 ? (
        <div className='txt-grey-13-500 text-center text-italic'>
          No data for the graph
        </div>
      ) : (
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export { ExecutedJobsByTimeChart };
