import React from 'react';
import { useExpandContent } from 'hooks/hooks.js';
import { Box, Grid } from '@mui/material';
import { useStyles } from './DataAssetsWidget.styles.js';
import {
  Button,
  BUTTON_COLOR,
  BUTTON_VARIANT,
  Chip,
} from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { DataAssetLink } from './components/components.js';
import {
  getAlertColorType,
  getAlertIcon,
  getDataAssetNameByType,
} from 'utils/helpers/helpers.js';
import { getLinkPath, getIconByType } from './libs/helpers.js';
import { CRITICAL_ALERT_TYPE, PRIORITY_ALERT_TYPE } from 'utils/constants.js';

const MAX_VISIBLE_ITEMS = 5;
const MAX_CHIP_WIDTH = 34;
const MAX_DATA_ASSET_NAME_WIDTH = 380;

const DataAssetsWidget = ({ dataAssets }) => {
  const classes = useStyles();

  const widgetData = dataAssets.map(({ type, data, alertType }) => ({
    name: getDataAssetNameByType(type, data),
    linkPath: getLinkPath(type, data),
    IconComponent: getIconByType(type),
    AlertIconComponent: getAlertIcon(alertType),
    alertColorType: getAlertColorType(alertType),
    project: data.project,
    alertType,
  }));

  const hasAlertTypeIcon = dataAssets.some(
    ({ alertType }) =>
      alertType === CRITICAL_ALERT_TYPE || alertType === PRIORITY_ALERT_TYPE
  );

  const { content, handleChangeCollapse, renderCollapsedItemsNumber } =
    useExpandContent(widgetData, MAX_VISIBLE_ITEMS);

  return (
    <Widget
      label='Data assets'
      count={true}
      incidentCount={widgetData.length}
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <Grid container spacing={5}>
        {content.map(({ AlertIconComponent, IconComponent, ...item }) => (
          <Grid item xs={12} key={item.name}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
              {hasAlertTypeIcon && (
                <Grid item>
                  {item.alertType === CRITICAL_ALERT_TYPE ||
                  item.alertType === PRIORITY_ALERT_TYPE ? (
                    <Chip
                      startIcon={<AlertIconComponent style={{ margin: 0 }} />}
                      color={item.alertColorType}
                      maxWidth={MAX_CHIP_WIDTH}
                    />
                  ) : (
                    <Box width={MAX_CHIP_WIDTH} />
                  )}
                </Grid>
              )}
              <Grid item>
                {<IconComponent className={classes.assetTypeIcon} />}
              </Grid>
              <Grid
                item
                zeroMinWidth
                sx={{ maxWidth: MAX_DATA_ASSET_NAME_WIDTH }}
              >
                <DataAssetLink dataAsset={item} />
              </Grid>
            </Grid>
          </Grid>
        ))}

        {widgetData.length > MAX_VISIBLE_ITEMS && (
          <Grid item xs={12}>
            <Button
              text={renderCollapsedItemsNumber()}
              onClick={handleChangeCollapse}
              variant={BUTTON_VARIANT.text}
              color={BUTTON_COLOR.info}
              fullWidth={false}
              className='p-0'
              disableRipple={true}
            />
          </Grid>
        )}
      </Grid>
    </Widget>
  );
};

export { DataAssetsWidget };
