import React from 'react';
import {
  useEffect,
  useMemo,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useSort,
  useSelectedElements,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { useStyles } from '../../Dictionary.styles.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { amplEvent } from 'service/services.js';
import {
  Toolbar,
  Modal,
  TableHeadWithCheckbox,
  MutedElementModalBody,
  CriticalElementModalBody,
  DatasetSearch,
} from 'Components/components.js';
import { CRITICAL_ALERT_TYPE, MUTED_ALERT_TYPE } from 'utils/constants.js';
import {
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  useModal,
  useQueryParamsContext,
  useUserInfo,
} from 'context/context.js';
import { DictionaryDatasetDetails } from './components/components.js';
import { getComparator, stableSort } from 'utils/helpers/helpers.js';
import {
  DatasetNameHead,
  AlertStatusHead,
  DescriptionHead,
} from './enums/enums.js';

const HEAD_CELLS = [DatasetNameHead, AlertStatusHead, DescriptionHead];

const filterDataset = (
  data,
  typeFiltersAlert,
  searchDatasetName,
  datasetName
) => {
  if (!data) {
    return [];
  }

  const typeFilterAlertValues = typeFiltersAlert.map(({ value }) => value);

  if (
    typeFilterAlertValues.length === 1 &&
    typeFilterAlertValues[0] === '' &&
    !searchDatasetName &&
    !datasetName
  )
    return data;

  return data.filter(({ alertType, name }) => {
    return (
      ((typeFilterAlertValues.length === 1 &&
        typeFilterAlertValues[0] === '') ||
        typeFilterAlertValues.includes(alertType)) &&
      (!searchDatasetName || name.includes(searchDatasetName)) &&
      (!datasetName || name === datasetName)
    );
  });
};

const DictionaryDataset = ({
  selectedAlertType,
  page,
  rowsPerPage,
  handleChangePage,
  onReceiveTotalNumber,
  onChangeBackdrop,
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const queryClient = useQueryClient();
  const { queryParams } = useQueryParamsContext();
  const { user } = useUserInfo();
  const [datasets, setDatasets] = useState([]);
  const [actionText, setActionText] = useState('');
  const { order, orderBy, handleRequestSort } = useSort(DatasetNameHead.id);

  const searchDatasetFromUrl = queryParams.get(SEARCH_PARAMS.SEARCH);
  const selectedDatasetFromURL = queryParams.get(SEARCH_PARAMS.DATASET);

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.datasets],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    onChangeBackdrop(isFetching);

    if (data?.values) {
      setDatasets(data.values);
    }
  }, [data, isFetching, onChangeBackdrop]);

  useEffect(
    () => handleChangePage({}, 0),
    [
      handleChangePage,
      order,
      orderBy,
      searchDatasetFromUrl,
      selectedDatasetFromURL,
    ]
  );

  useEffect(() => {
    if (selectedDatasetFromURL) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> Tab Dataset -> selected dataset -> ${selectedDatasetFromURL}`
      );
    }

    if (searchDatasetFromUrl) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> Tab Dataset -> searched dataset -> ${searchDatasetFromUrl}`
      );
    }
  }, [selectedDatasetFromURL, searchDatasetFromUrl]);

  const filteredData = useMemo(() => {
    if (!selectedAlertType) {
      return [];
    }

    return stableSort(
      filterDataset(
        datasets,
        selectedAlertType,
        searchDatasetFromUrl,
        selectedDatasetFromURL
      ),
      getComparator(order, orderBy)
    );
  }, [
    selectedAlertType,
    order,
    orderBy,
    searchDatasetFromUrl,
    selectedDatasetFromURL,
    datasets,
  ]);

  const paginatedData = useMemo(
    () =>
      filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredData, page, rowsPerPage]
  );

  const {
    selected,
    clearSelected,
    handleSelectAllClick,
    isSelected,
    handleClickElement,
  } = useSelectedElements(paginatedData);

  const { mutateAsync: sendAlertType } = useMutation(
    (type) => {
      return Promise.all(
        selected.map((item) =>
          fetcherPost(
            `/api/v1/datasets/alert?project=${user.info.currentProject}&dataset=${item.name}`,
            { type }
          )
        )
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QUERY_TYPES.datasets);
        setActionText(
          selected.length === 1
            ? `${selected.length} dataset is ${variables.toLowerCase()}`
            : `${selected.length} datasets are ${variables.toLowerCase()}`
        );
        setTimeout(() => {
          setActionText('');
        }, 3000);
        clearSelected();
      },
    }
  );

  const onTypeChange = (selectedItem) => {
    if (selected.some((item) => item.alertType === CRITICAL_ALERT_TYPE)) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(selectedItem)}
          title="Are you sure about changing the critical dataset's status?"
          titlePosition='center'
          ModalBodyComponent={CriticalElementModalBody}
          agreeButtonText='OK'
        />
      ));
    } else if (selectedItem === MUTED_ALERT_TYPE) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(selectedItem)}
          title='Are you sure you want to mute the dataset?'
          titlePosition='center'
          ModalBodyComponent={MutedElementModalBody}
          element='dataset'
          agreeButtonText='OK'
        />
      ));
    } else {
      sendAlertType(selectedItem);
    }
  };

  const updateDataset = (dataset) => {
    setDatasets((prev) =>
      prev.map((it) => {
        if (it.name === dataset.name) {
          return dataset;
        }
        return it;
      })
    );
  };

  useEffect(() => {
    onReceiveTotalNumber(filteredData?.length);
  }, [filteredData?.length, onReceiveTotalNumber]);

  return (
    <>
      <div className={classes.searchContainer}>
        <DatasetSearch optionLabel='Dictionary for:' className='tableSearch' />
      </div>

      <div className={classes.tableContainer}>
        <Toolbar
          numSelected={selected.length}
          closeModal={clearSelected}
          onTypeChange={onTypeChange}
          actionText={actionText}
        />
        <Table>
          <TableHeadWithCheckbox
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={paginatedData?.length}
            onRequestSort={handleRequestSort}
            headCells={HEAD_CELLS}
            selectedColumn={true}
          />
          {paginatedData && (
            <TableBody className={classes.tableBody}>
              {paginatedData.map((value, index) => {
                const isItemSelected = isSelected(value);
                return (
                  <DictionaryDatasetDetails
                    key={index + value.name}
                    datasetInfo={value}
                    updateDataset={updateDataset}
                    isItemSelected={isItemSelected}
                    handleClick={handleClickElement}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

export { DictionaryDataset };
