import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Costs/Costs.styles.js';
import { EmptySearchData } from 'Components/components.js';
import { StorageTableHead, StorageTableRows } from './components/components.js';

const StorageTable = ({ data = [], isEmptySearch = false, storageCost }) => {
  const classes = useStyles();

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <StorageTableHead />
        <TableBody className={classes.tableBody}>
          {isEmptySearch ? (
            <EmptySearchData text='Dataset not found' colSpan={6} />
          ) : (
            data.map((item) => (
              <StorageTableRows
                rowData={item}
                storageCost={storageCost}
                key={item.dataset}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { StorageTable };
