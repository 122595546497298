const datasetId = 'DATASET';
const costShareId = 'COST_SHARE';
const currentPlanId = 'CURRENT_PLAN';
const alternativePlanId = 'ALTERNATIVE_PLAN';
const costsId = 'COSTS';

const DatasetName = {
  ID: datasetId,
  LABEL: 'Dataset',
  SORT: true,
  ALIGN: 'left',
};

const CostShare = {
  ID: costShareId,
  LABEL: 'Cost share, %',
  SORT: false,
  ALIGN: 'right',
};

const CurrentPlan = {
  ID: currentPlanId,
  LABEL: 'Current plan',
  SORT: false,
  ALIGN: 'right',
};

const AlternativePlan = {
  ID: alternativePlanId,
  LABEL: 'Alternative plan',
  SORT: false,
  ALIGN: 'right',
};

const Costs = {
  ID: costsId,
  LABEL: (
    <>
      Current/
      <br />
      optimized costs
    </>
  ),
  SORT: false,
  ALIGN: 'right',
};

export {
  DatasetName,
  CostShare,
  CurrentPlan,
  AlternativePlan,
  Costs,
  currentPlanId,
};
