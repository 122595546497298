const TABLE_ALERT_STATUS_FILTER = 'tableAlertStatus';
const DATASET_ALERT_STATUS_FILTER = 'datasetAlertStatus';
const DICTIONARY_STREAM_FILTER = 'stream';
const TABLE_TYPE_FILTER = 'tableType';
const LOOKER_ALERT_STATUS_FILTER = 'lookerAlertStatus';
const LOOKER_REPORTS_TYPE_FILTER = 'lookerReportsType';

const defaultDropdownsValue = {
  [TABLE_ALERT_STATUS_FILTER]: null,
  [DICTIONARY_STREAM_FILTER]: null,
  [TABLE_TYPE_FILTER]: null,
  [DATASET_ALERT_STATUS_FILTER]: null,
  [LOOKER_ALERT_STATUS_FILTER]: null,
  [LOOKER_REPORTS_TYPE_FILTER]: null,
};

const TablesTabDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  DICTIONARY_STREAM_FILTER,
  TABLE_TYPE_FILTER,
];

const DatasetsTabDropdowns = [DATASET_ALERT_STATUS_FILTER];

const LookerTabDropdowns = [
  LOOKER_ALERT_STATUS_FILTER,
  LOOKER_REPORTS_TYPE_FILTER,
];

export {
  TABLE_ALERT_STATUS_FILTER,
  DATASET_ALERT_STATUS_FILTER,
  DICTIONARY_STREAM_FILTER,
  TABLE_TYPE_FILTER,
  LOOKER_ALERT_STATUS_FILTER,
  LOOKER_REPORTS_TYPE_FILTER,
  defaultDropdownsValue,
  TablesTabDropdowns,
  DatasetsTabDropdowns,
  LookerTabDropdowns,
};
