import React from 'react';
import { useMemo, useRef, useState, useEffect } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'; // require for arearange
import HighchartsReact from 'highcharts-react-official';
import clsx from 'clsx';
import { useHighchartsStyles } from './Highcharts.styles.js';
import {
  Button,
  BUTTON_VARIANT,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import {
  calcDeviation,
  frequencyBySeconds,
  getIncidentFormatData,
  getPercentString,
  getUnixDateByStr,
  numberFormat,
} from 'utils/helpers/helpers.js';
import {
  createFilteredDataTooltipItemName,
  createFilteredDataTooltipItemValue,
  getDatetimeFormat,
  getIntervalTitle,
  getTitleByMetricType,
  getScatterElementOptions,
} from './libs/utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { GRAPH_NAME } from './libs/constants.js';
import projectTheme from 'theme.js';

const DAILY_FREQUENCY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const Volume = ({
  volumeData,
  scatterData,
  frequencyMilliseconds,
  existingScatterGraph = false,
  currentDatetime = null,
  percentThreshold = null,
  isEditingVolume = false,
  metricType = null,
  volumeHeight = GRAPH_HEIGHT.large,
}) => {
  const classes = useHighchartsStyles();
  const volumeRef = useRef(null);
  const scatterRef = useRef(null);
  const [scatterOptions, setScatterOptions] = useState(null);
  const [selectedVolumePoint, setSelectedVolumePoint] = useState(null);
  const [existingResetButton, setExistingResetButton] = useState(false);

  const formattedVolumeData = useMemo(() => {
    return volumeData.map(({ datetime, value }) => ({
      x: getUnixDateByStr(datetime),
      y: value,
    }));
  }, [volumeData]);

  const volumeIntervalData = useMemo(() => {
    return volumeData.map(({ datetime, value, interval }) => {
      const existingInterval = value !== null;

      return {
        x: getUnixDateByStr(datetime),
        low: existingInterval ? interval.at(0) : null,
        high: existingInterval ? interval.at(-1) : null,
      };
    });
  }, [volumeData]);

  const customIntervalData = useMemo(() => {
    if (isEditingVolume) {
      return null;
    }

    const filteredMinData = volumeData.filter(
      (item) => item.minFiltered !== null
    );
    const filteredMaxData = volumeData.filter(
      (item) => item.maxFiltered !== null
    );

    return {
      minLineData: filteredMinData.map((item) => ({
        x: getUnixDateByStr(item.datetime),
        y: item.minFiltered.value < 0 ? 0 : item.minFiltered.value,
        minFiltered: item.minFiltered,
        maxFiltered: item.maxFiltered,
      })),
      maxLineData: filteredMaxData.map((item) => ({
        x: getUnixDateByStr(item.datetime),
        y: item.maxFiltered.value,
        minFiltered: item.minFiltered,
        maxFiltered: item.maxFiltered,
      })),
    };
  }, [volumeData, isEditingVolume]);

  const volumeAnomalyData = useMemo(() => {
    return volumeData
      .filter(({ isAnomaly }) => isAnomaly)
      .map(({ datetime, interval, value }) => ({
        x: getUnixDateByStr(datetime),
        y: 1,
        deviation: calcDeviation(value, interval.at(0), interval.at(-1)),
      }));
  }, [volumeData]);

  const formattedScatterData = useMemo(() => {
    if (!existingScatterGraph) {
      return [];
    }

    return scatterData.map(({ datetime, value }) => ({
      x: getUnixDateByStr(datetime),
      y: value,
    }));
  }, [scatterData, existingScatterGraph]);

  const freshnessAnomalyData = useMemo(() => {
    if (!existingScatterGraph) {
      return [];
    }

    return volumeData
      .filter(({ isFreshness }) => isFreshness)
      .map(({ datetime }) => ({
        x: getUnixDateByStr(datetime) + frequencyMilliseconds / 2,
        y: 1,
        isFreshness: true,
      }));
  }, [frequencyMilliseconds, volumeData, existingScatterGraph]);

  const volumeYAxisValues = useMemo(() => {
    if (volumeData.length > 0) {
      const maxDataValue = Math.max(...volumeData.map((item) => item.value));
      const minDataValue = Math.min(...volumeData.map((item) => item.value));
      const maxYAxis = Math.floor(maxDataValue + maxDataValue * 0.05);
      const minYAxis = Math.floor(minDataValue - minDataValue * 0.05);

      return [minYAxis, maxYAxis];
    }

    return [0, 0];
  }, [volumeData]);

  const existingCustomIntervalYAxis = useMemo(() => {
    if (!customIntervalData) {
      return false;
    }

    const filteredMaxValues = customIntervalData.maxLineData.map(
      (item) => item.y
    );
    const maxFilteredValue = Math.max(...filteredMaxValues);

    return (
      maxFilteredValue >
      volumeYAxisValues.at(-1) + volumeYAxisValues.at(-1) * 0.3
    );
  }, [customIntervalData, volumeYAxisValues]);

  const volumeTitleText = useMemo(() => {
    const baseTitle = `${GRAPH_NAME.volume} <b>${frequencyBySeconds(
      frequencyMilliseconds * 0.001
    )}</b>`;

    if (existingScatterGraph) {
      return percentThreshold
        ? `${baseTitle} / Threshold coefficient: ${percentThreshold}%`
        : baseTitle;
    }

    return percentThreshold
      ? `Threshold coefficient: ${percentThreshold}%`
      : undefined;
  }, [existingScatterGraph, frequencyMilliseconds, percentThreshold]);

  const selectVolumeValue = (event) => {
    if (!existingScatterGraph) {
      return null;
    }

    const volumeGraph = volumeRef.current.chart;
    const scatterGraph = scatterRef.current.chart;

    const pointerEvent = volumeGraph.pointer.normalize(event);
    const point = volumeGraph.series[0].searchPoint(pointerEvent, true); // find the point

    if (!point) {
      return;
    }

    const intervalStart = point.x;
    const selectedValue = point.y;

    const selectScatterPoints = () => {
      const intervalEnd = intervalStart + frequencyMilliseconds;

      scatterGraph.series[0].points.forEach((scatterPoint) => {
        if (scatterPoint.x >= intervalStart && scatterPoint.x < intervalEnd) {
          scatterPoint.select(true, true);
        }
      });
    };

    const clearSelectedPoints = () => {
      scatterGraph.series[0].points.forEach((point) =>
        point.select(false, true)
      );
      volumeGraph.series[0].points.forEach((point) =>
        point.select(false, true)
      );
    };

    if (point.selected) {
      clearSelectedPoints();
      setSelectedVolumePoint(null);
    } else {
      clearSelectedPoints();
      point.select(true, true);
      selectScatterPoints();
      setSelectedVolumePoint({ x: intervalStart, y: selectedValue });
    }
  };

  const onClickResetButton = () => {
    const scatterGraph = scatterRef.current.chart;
    const volumeGraph = volumeRef.current.chart;

    if (scatterGraph) {
      scatterGraph.xAxis[0].setExtremes(null, null);
    }
    if (volumeGraph) {
      volumeGraph.xAxis[0].setExtremes(null, null);
    }

    setExistingResetButton(false);
  };

  const volumeOptions = {
    chart: {
      zooming: {
        type: 'x',
        resetButton: {
          position: {
            x: 0,
            y: existingScatterGraph ? -9999 : 0,
          },
        },
      },
      height: volumeHeight,
      events: {
        selection: (event) => {
          if (existingScatterGraph) {
            const scatterGraph = scatterRef.current.chart;
            if (event.xAxis) {
              const min = event.xAxis[0].min;
              const max = event.xAxis[0].max;

              scatterGraph.xAxis[0].setExtremes(min, max);
              setExistingResetButton(true);
            }
          }
        },
        click: (event) => selectVolumeValue(event),
      },
    },
    title: {
      text: volumeTitleText,
      align: 'left',
    },
    subtitle: {
      text: !existingScatterGraph
        ? undefined
        : selectedVolumePoint !== null
        ? `Selected value: ${numberFormat(selectedVolumePoint.y)}`
        : 'Click on the graph to select a value',
      align: 'right',
    },
    xAxis: {
      type: 'datetime',
      labels: { step: 3 },
    },
    yAxis: [
      {
        min: volumeYAxisValues.at(0),
        max: volumeYAxisValues.at(-1),
        title: { text: getTitleByMetricType(metricType) },
      },
      {
        visible: false,
        max: 1,
        opposite: true,
      },
      {
        visible: existingCustomIntervalYAxis,
        height: '10%',
        opposite: true,
        title: { text: undefined },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      column: {
        states: {
          enabled: false,
          inactive: {
            enabled: false,
          },
        },
      },
      series: {
        point: {
          events: {
            click: (event) => selectVolumeValue(event),
            mouseOver: function () {
              if (!existingScatterGraph) {
                return null;
              }

              const point = this;
              const scatterGraph = scatterRef.current.chart;
              const scatterSeries = scatterGraph.series[0];

              scatterSeries.points.forEach((scatterPoint) => {
                if (
                  scatterPoint.x >= point.x &&
                  scatterPoint.x < point.x + frequencyMilliseconds
                ) {
                  scatterPoint.setState('hover');
                } else {
                  scatterPoint.setState('');
                }
              });
            },
            mouseOut: function () {
              if (!existingScatterGraph) {
                return null;
              }

              const scatterGraph = scatterRef.current.chart;
              const scatterSeries = scatterGraph.series[0];

              scatterSeries.points.forEach((scatterPoint) =>
                scatterPoint.setState('')
              );
            },
          },
        },
        marker: {
          fillColor: 'transparent', // required for zoom
          states: {
            select: {
              fillColor: Highcharts.getOptions().colors[0],
              lineWidth: 0,
            },
          },
        },
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
    },
    series: [
      {
        type: 'line',
        data: formattedVolumeData,
        name: 'value',
        tooltip: {
          xDateFormat:
            frequencyMilliseconds === DAILY_FREQUENCY_IN_MILLISECONDS
              ? '%b %e'
              : '%b %e, %I:%M %p',
          pointFormatter: function () {
            const { y } = this;
            return `
              <div>
                <span style="color: ${Highcharts.getOptions().colors[0]}">
                  ●
                </span>
                value: <b>${getIncidentFormatData(metricType, y)}</b>
              </div><br />
            `;
          },
        },
        zIndex: 2,
        zoneAxis: 'x',
        zones: [
          {
            value: formattedVolumeData.at(-2)?.x,
          },
          {
            dashStyle: 'Dash',
          },
        ],
      },
      {
        type: 'arearange',
        data: volumeIntervalData,
        name: 'interval',
        tooltip: {
          pointFormatter: function () {
            const { low, high } = this;
            return `
              <div>
                <span style="color: ${Highcharts.getOptions().colors[0]}">
                  ●
                </span>
                interval: <b>${getIntervalTitle([low, high], metricType)}</b>
              </div><br />
            `;
          },
        },
        lineWidth: 0,
        fillOpacity: 0.1,
        zIndex: 0,
      },
      {
        type: 'column',
        data: volumeAnomalyData,
        color: projectTheme.palette.error.main,
        name: 'deviation',
        tooltip: {
          pointFormatter: function () {
            const { deviation } = this;

            if (deviation === 0) {
              return '';
            }

            return `
              <div>
                <span style="color: ${projectTheme.palette.error.main}">
                  ●
                </span>
                deviation: <b>${getPercentString(deviation)}</b>
              </div><br />
            `;
          },
        },
        yAxis: 1,
        pointPlacement: 'on',
        borderWidth: 0,
        opacity: 0.3,
        zIndex: 1,
        pointPadding: 0,
        groupPadding: 0,
      },
    ],
  };

  if (customIntervalData?.minLineData.length > 0) {
    volumeOptions.series.push({
      type: 'line',
      data: customIntervalData.minLineData,
      name: 'Custom bar min',
      lineWidth: 1,
      dashStyle: 'Dash',
      opacity: 0.5,
      zIndex: 0,
      tooltip: {
        pointFormatter: function () {
          const { minFiltered, maxFiltered } = this;

          return `
            <div>
              <span style="color: ${Highcharts.getOptions().colors[0]}">
                ●
              </span>
              ${createFilteredDataTooltipItemName(minFiltered, maxFiltered)} 
              <b>${createFilteredDataTooltipItemValue(
                minFiltered,
                maxFiltered,
                metricType
              )}</b>
            </div><br />
          `;
        },
      },
    });
  }

  if (customIntervalData?.maxLineData.length > 0) {
    volumeOptions.series.push({
      type: 'line',
      data: customIntervalData.maxLineData,
      name: 'Custom bar max',
      lineWidth: 1,
      dashStyle: 'Dash',
      opacity: 0.5,
      zIndex: 0,
      yAxis: existingCustomIntervalYAxis ? 2 : 0,
      tooltip: {
        pointFormatter: function () {
          const { minFiltered, maxFiltered } = this;

          if (customIntervalData?.minLineData.length) {
            return '';
          }

          return `
              <div>
                <span style="color: ${Highcharts.getOptions().colors[0]}">
                  ●
                </span>
                ${createFilteredDataTooltipItemName(minFiltered, maxFiltered)}
                <b>${createFilteredDataTooltipItemValue(
                  minFiltered,
                  maxFiltered,
                  metricType
                )}</b>
              </div><br />
            `;
        },
      },
    });
  }

  if (selectedVolumePoint !== null) {
    const data = [selectedVolumePoint];
    volumeOptions.series.push(getScatterElementOptions(data));
  }

  if (currentDatetime !== null) {
    const x = getUnixDateByStr(currentDatetime);
    const y = formattedVolumeData.find(
      (item) => item.x === getUnixDateByStr(currentDatetime)
    )?.y;
    const data = [{ x, y }];

    if (y !== undefined) {
      volumeOptions.series.push(getScatterElementOptions(data));
    }
  }

  useEffect(() => {
    if (existingScatterGraph) {
      setScatterOptions({
        chart: {
          zooming: {
            type: 'x',
            resetButton: {
              position: {
                x: 0,
                y: -9999,
              },
            },
          },
          height: GRAPH_HEIGHT.small,
          backgroundColor: projectTheme.palette.secondary.light,
          borderRadius: 16,
          events: {
            selection: (event) => {
              const volumeGraph = volumeRef.current.chart;
              if (event.xAxis) {
                const min = event.xAxis[0].min;
                const max = event.xAxis[0].max;

                volumeGraph.xAxis[0].setExtremes(min, max);
                setExistingResetButton(true);
              }
            },
          },
        },
        title: {
          text: GRAPH_NAME.scatter,
          align: 'left',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          labels: { step: 3 },
        },
        yAxis: [
          { title: { text: undefined } },
          {
            visible: false,
            max: 1,
            opposite: true,
          },
        ],
        legend: {
          enabled: false,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            if (this?.isFreshness) {
              const unixDatetime = getUnixDateByStr(this.x);
              const from = unixDatetime - frequencyMilliseconds / 2;
              const to = unixDatetime + frequencyMilliseconds / 2;

              return `
            <div style="font-size: 0.8em">${getDatetimeFormat(
              from
            )} - ${getDatetimeFormat(to)}</div>
            <span style="color: ${projectTheme.palette.error.main}">
              ●
            </span>raw value: <b>${numberFormat(0)}</b>
          `;
            }

            return `
          <div style="font-size: 0.8em">${getDatetimeFormat(this.x)}</div>
          <span
            style="color: ${Highcharts.getOptions().colors[0]}"
          >
            ●
          </span>raw value: <b>${numberFormat(this.y)}</b>
        `;
          },
        },
        plotOptions: {
          column: {
            states: {
              enabled: false,
              inactive: {
                enabled: false,
              },
            },
          },
          line: {
            states: {
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
              },
              inactive: {
                enabled: false,
              },
            },
          },
          series: {
            lineWidth: 0,
            marker: {
              enabled: true,
              fillColor: projectTheme.palette.divider,
              radius: 2,
              states: {
                hover: {
                  fillColor: Highcharts.getOptions().colors[0],
                  lineWidthPlus: 0,
                  radiusPlus: 0,
                },
                select: {
                  fillColor: Highcharts.getOptions().colors[0],
                  lineWidth: 0,
                },
              },
            },
            states: {
              hover: { halo: null },
              select: { halo: null },
            },
          },
        },
        series: [
          {
            type: 'line',
            data: formattedScatterData,
            name: 'raw value',
            zIndex: 1,
          },
          {
            type: 'column',
            data: freshnessAnomalyData,
            color: projectTheme.palette.error.main,
            opacity: 0.3,
            yAxis: 1,
            pointPlacement: 'on',
            borderRadius: 0,
            pointRange: frequencyMilliseconds * 2,
          },
        ],
      });
    }
  }, [
    existingScatterGraph,
    formattedScatterData,
    frequencyMilliseconds,
    freshnessAnomalyData,
  ]);

  return (
    <div className={clsx(classes.container, 'zoom-effects')}>
      {existingResetButton && (
        <Button
          onClick={onClickResetButton}
          variant={BUTTON_VARIANT.text}
          text='Reset zoom'
          fullWidth={false}
          className='resetButton'
          disableRipple={true}
        />
      )}

      <HighchartsReact
        ref={volumeRef}
        highcharts={Highcharts}
        options={volumeOptions}
      />

      {existingScatterGraph && (
        <>
          <HighchartsReact
            ref={scatterRef}
            highcharts={Highcharts}
            options={scatterOptions}
          />

          <Typography
            variant={TYPOGRAPHY_VARIANT.body2}
            color={TYPOGRAPHY_COLOR.textSecondary}
            className='text-right mt-1'
          >
            *Select section to zoom in
          </Typography>
        </>
      )}
    </div>
  );
};

export { Volume };
