import React from 'react';
import {
  useRef,
  useMemo,
  useEffect,
  useState,
  useGetExecutedJobs,
  useParams,
} from 'hooks/hooks.js';
import HighchartsStock from 'highcharts/modules/stock';
import HighchartsReact from 'highcharts-react-official';
import { useHighchartsStyles } from 'Components/Highcharts/Highcharts.styles.js';
import { PositionLoader } from 'Components/components.js';
import { getDataAssetNameByType, numberFormat } from 'utils/helpers/helpers.js';
import { JOB_EXECUTED_TYPES } from 'constants/constants.js';
import projectTheme from 'theme.js';

const VISIBLE_ITEMS_COUNT = 15;
const CHART_BY = {
  principal: 'principal',
  project: 'project',
};

const transformDataForHighcharts = (data, aggregatedBy) => {
  const filteredData = data.filter((item) => item.values.length > 0);
  const categories = filteredData.map(({ dataAsset }) =>
    getDataAssetNameByType(dataAsset.type, dataAsset.data)
  );

  const uniqueValues = (arr, key) =>
    Array.from(new Set(arr.flatMap((item) => item.values.map((v) => v[key]))));
  const principals = uniqueValues(filteredData, CHART_BY.principal);
  const projects = uniqueValues(filteredData, CHART_BY.project);

  const createSeries = (items, key) => {
    const findValue = (values, itemValue) => {
      const valueObj = values.find((value) => value[key] === itemValue);
      return valueObj ? valueObj.value : 0;
    };

    return items.map((itemValue) => ({
      name: itemValue,
      data: categories.map((category) => {
        const dataItem = filteredData.find(
          ({ dataAsset }) =>
            getDataAssetNameByType(dataAsset.type, dataAsset.data) === category
        );
        return dataItem ? findValue(dataItem.values, itemValue) : 0;
      }),
      groupPadding: 0.3,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: projectTheme.palette.common.white,
    }));
  };

  const principalsSeries = createSeries(principals, CHART_BY.principal);
  const projectsSeries = createSeries(projects, CHART_BY.project);

  return {
    categories,
    series:
      aggregatedBy === JOB_EXECUTED_TYPES.principal
        ? principalsSeries
        : projectsSeries,
  };
};

const ExecutedJobsChart = ({ aggregatedBy }) => {
  const { uuid } = useParams();
  const classes = useHighchartsStyles();
  const chartRef = useRef(null);

  const [chartOptions, setChartOptions] = useState({});

  const { executedJobs, isFetchingExecutedJobs } = useGetExecutedJobs({
    dataProductUUID: uuid,
    aggregatedBy,
  });

  const { categories, series } = useMemo(() => {
    return transformDataForHighcharts(executedJobs, aggregatedBy);
  }, [aggregatedBy, executedJobs]);

  useEffect(() => {
    setChartOptions({
      chart: { type: 'column' },
      title: { text: undefined },
      xAxis: {
        categories,
        min:
          categories.length >= VISIBLE_ITEMS_COUNT
            ? categories.length - VISIBLE_ITEMS_COUNT
            : null,
        labels: {
          rotation: 0,
          style: { textOverflow: 'ellipsis' },
          formatter: function () {
            return this.value;
          },
        },
      },
      yAxis: {
        min: 0,
        title: { text: undefined },
        stackLabels: { enabled: false },
        opposite: false,
      },
      legend: {
        enabled: true,
        itemWidth: 410,
        symbolRadius: 4,
      },
      tooltip: {
        outside: true,
        style: {
          width: 'auto',
          whiteSpace: 'nowrap',
        },
        useHTML: true,
        formatter: function () {
          const header = `
            <div style="font-size: 13px; margin-bottom: 4px; font-weight: 700">
              ${this.category}
            </div>
          `;

          const points = this.points
            .filter((point) => point.y > 0)
            .map((point) => {
              return `
                <div style="font-size: 11px">
                  <span style="color: ${point.color}; font-size: 13px;">●</span>
                  ${point.series.name}: <b>${numberFormat(point.y)}</b>
                </div>
              `;
            })
            .join('');

          return header + points;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: { enabled: false },
        },
      },
      navigator: {
        xAxis: { labels: { enabled: false } },
      },
      rangeSelector: { enabled: false },
      series,
    });
  }, [categories, series]);

  if (isFetchingExecutedJobs) {
    return (
      <div className={classes.container}>
        <PositionLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {series.length === 0 ? (
        <div className='txt-grey-13-500 text-center text-italic'>
          No data for the graph
        </div>
      ) : (
        <HighchartsReact
          ref={chartRef}
          highcharts={HighchartsStock}
          options={chartOptions}
          constructorType={'stockChart'}
        />
      )}
    </div>
  );
};

export { ExecutedJobsChart };
