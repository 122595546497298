import React from 'react';
import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { CostLabel, ComparisonValues } from 'Components/components.js';
import { abbreviateNumber, bytesToSize } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  DictionaryTabs,
  SEARCH_PARAMS,
  STORAGE_BILLING_MODEL_LABEL,
} from 'constants/constants.js';

const MIN_COST_SHARE_VALUE = 0.1;

const StorageTableRows = ({ rowData, storageCost }) => {
  const {
    dataset,
    currentStorageBillingModel,
    alternativeStorageBillingModel,
    currentStorage,
    alternativeStorage,
    currentStorageCost,
    optimizedStorageCost,
  } = rowData;

  const costShare = storageCost ? (currentStorageCost / storageCost) * 100 : 0;

  const tableCellData = [
    {
      id: 'DATASET_NAME',
      label: (
        <Link
          to={{
            pathname: AppRoutes.Dictionary.path,
            search: new URLSearchParams({
              [SEARCH_PARAMS.TAB]: DictionaryTabs.DATASETS,
              dataset,
            }).toString(),
          }}
          className='txt-blue-13-700'
        >
          {dataset}
        </Link>
      ),
      align: 'left',
      className: 'withoutPadding',
    },
    {
      id: 'COST_SHARE',
      label:
        costShare >= MIN_COST_SHARE_VALUE || costShare === 0
          ? abbreviateNumber(costShare)
          : `< ${MIN_COST_SHARE_VALUE}`,
      align: 'right',
    },
    {
      id: 'CURRENT_PLAN',
      label: (
        <>
          {bytesToSize(currentStorage) || (
            <span className='txt-grey-13-500'>&mdash;</span>
          )}
          <div className='txt-grey-13-500'>
            {STORAGE_BILLING_MODEL_LABEL[currentStorageBillingModel]}
          </div>
        </>
      ),
      align: 'right',
      className: 'withBackground',
    },
    {
      id: 'ALT_PLAN',
      label: (
        <>
          {bytesToSize(alternativeStorage) || (
            <span className='txt-grey-13-500'>&mdash;</span>
          )}
          <div className='txt-grey-13-500'>
            {STORAGE_BILLING_MODEL_LABEL[alternativeStorageBillingModel]}
          </div>
        </>
      ),
      align: 'right',
    },
    {
      id: 'COSTS',
      label: (
        <>
          <CostLabel value={currentStorageCost} />
          {optimizedStorageCost > 0 && (
            <ComparisonValues
              primaryValue={currentStorageCost}
              secondaryValue={optimizedStorageCost}
            />
          )}
        </>
      ),
      align: 'right',
    },
  ];

  return (
    <TableRow style={{ cursor: 'default' }}>
      {tableCellData.map((item) => (
        <TableCell key={item.id} align={item.align} className={item?.className}>
          {item.label || <EmptyCell />}
        </TableCell>
      ))}
    </TableRow>
  );
};

const EmptyCell = () => {
  return <span className='txt-grey-13-500'>N/A</span>;
};

export { StorageTableRows };
