import React from 'react';
import {
  useMemo,
  useEffect,
  useState,
  useRef,
  useQueryClient,
} from 'hooks/hooks.js';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Image } from 'react-bootstrap';
import { createNewOption, getCustomStyles } from './libs/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import Search from 'assets/img/search.svg';

const EVENT_KEY_ENTER = 'Enter';
const SEARCH_DEBOUNCE_DELAY = 500;
const SEARCH_LIMIT = 50;
const SEARCH_ITEM_HEIGHT = 36;
const SEARCH_STALE_TIME = 5 * 60 * 1000;
const BASE_INPUT_HEIGHT = 46;

const AsyncGeneralSearch = ({
  optionLabel = 'Search for:',
  className = '',
  onSearch = () => {},
  onSelect = () => {},
  defaultSelectValue,
  defaultSearchValue,
  searchUrl,
  searchQueryParam,
  queryParams = {},
  placeholderName = 'Start typing name...',
  searchWithDropdown = false,
  searchKey = '',
  getLabelFromResponseItem = null,
  getValueFromResponseItem = null,
  getLabelFromRequestItem = null,
  getValueFromRequestItem = null,
  disableCache = false,
  id = null,
  label = '',
  isRequiredField = false,
  inputHeight = BASE_INPUT_HEIGHT,
}) => {
  const selectRef = useRef();
  const customStyles = useMemo(
    () => getCustomStyles(optionLabel, searchWithDropdown, true, inputHeight),
    [optionLabel, searchWithDropdown, inputHeight]
  );
  const queryClient = useQueryClient();

  const [keySuffix, setKeySuffix] = useState(0);
  const [searchSelectedOption, setSearchSelectedOption] = useState(null);

  const getQueryParams = (search) => {
    return {
      [searchQueryParam]: search || '',
    };
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const searchQueryParams = search
      ? getQueryParams(search)
      : getQueryParams(defaultSearchValue);

    const params = { ...queryParams, ...searchQueryParams };
    const options = {};
    if (disableCache) {
      options.cacheTime = 0;
      options.staleTime = 0;
    } else {
      options.staleTime = SEARCH_STALE_TIME;
    }
    const response = await queryClient.fetchQuery(
      [searchUrl, params, SEARCH_LIMIT, page],
      ({ queryKey }) => {
        const [url, searchQueryParams, limit, page] = queryKey;
        return fetcherGet(url, { ...searchQueryParams, limit, page });
      },
      options
    );
    if (!response?.values || !response?.pagination) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }

    const hasMore = response.pagination.total > page * SEARCH_LIMIT;
    const newOptions = response.values.map((value) =>
      createNewOption(value, getLabelFromRequestItem, getValueFromRequestItem)
    );

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const selectSearch = (search, initChange = false) => {
    const option = createNewOption(search);
    if (option.value === searchSelectedOption?.value) {
      return;
    }
    setSearchSelectedOption(option);
    if (initChange) {
      onSearch(search);
    }
  };

  const handleChange = (item) => {
    if (item?.value === searchSelectedOption?.value) {
      return;
    }
    setSearchSelectedOption(item);
    onSelect(item?.value || '');
  };

  const handleMenuClose = () => {
    if (disableCache) {
      setKeySuffix((prev) => {
        return prev + 1;
      });
    }
  };

  useEffect(() => {
    setSearchSelectedOption(null);
  }, [searchKey]);

  useEffect(() => {
    if (defaultSelectValue) {
      const option = createNewOption(
        defaultSelectValue,
        getLabelFromResponseItem,
        getValueFromResponseItem
      );
      setSearchSelectedOption(option);
    } else if (defaultSearchValue) {
      const option = createNewOption(defaultSearchValue);
      setSearchSelectedOption(option);
    } else {
      setSearchSelectedOption(null);
    }
  }, [defaultSelectValue, defaultSearchValue]);

  const onKeyDown = (e) => {
    const inputValue = selectRef.current?.inputRef?.value;
    if (e.key === EVENT_KEY_ENTER && inputValue) {
      e.preventDefault();
      selectSearch(inputValue, true);
      blurSelect();
    }
  };

  const blurSelect = () => selectRef.current?.blur();

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder =
      scrollHeight - clientHeight - (SEARCH_LIMIT * SEARCH_ITEM_HEIGHT) / 2;
    return bottomBorder < scrollTop;
  };

  return (
    <>
      {label.length > 0 && (
        <label htmlFor={id} className='input-label'>
          {label}
          {isRequiredField && <span className='label-asterisk'>*</span>}
        </label>
      )}
      <AsyncPaginate
        key={JSON.stringify([
          defaultSelectValue,
          defaultSearchValue,
          searchUrl,
          keySuffix,
        ])}
        id={id}
        selectRef={selectRef}
        styles={customStyles}
        className={className}
        placeholder={
          <div>
            <Image src={Search} width={20} height={20} />
            <span className='ms-2 txt-grey-13-500'>{placeholderName}</span>
          </div>
        }
        isClearable={true}
        onKeyDown={onSearch && onKeyDown}
        onChange={handleChange}
        loadOptions={loadOptions}
        shouldLoadMore={shouldLoadMore}
        onMenuClose={handleMenuClose}
        debounceTimeout={SEARCH_DEBOUNCE_DELAY}
        additional={{ page: 1 }}
        value={searchSelectedOption}
        menuPortalTarget={document.body}
      />
    </>
  );
};

export { AsyncGeneralSearch };
