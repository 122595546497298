import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { useStyles } from '../StatsWidget.styles.js';
import {
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import { useCostsContext, useQueryParamsContext } from 'context/context.js';
import {
  abbreviateNumber,
  getPipelineDiff,
  getPipelineMetaData,
} from 'utils/helpers/helpers.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const CostsStatsTableView = () => {
  const classes = useStyles();
  const { costsStats, costsStatsPrevious, totalProjectCosts } =
    useCostsContext();

  const statsCardData = costsStats.map((item) => {
    const { pipelineType, costs } = item;
    const diff = getPipelineDiff(item, costsStatsPrevious);
    const lineStatWidth = `${Math.ceil((costs * 100) / totalProjectCosts)}%`;

    return {
      name: getPipelineMetaData(pipelineType).title,
      count: abbreviateNumber(costs, 1),
      color: getPipelineMetaData(pipelineType).colorType,
      diff,
      lineStatWidth,
      icon: getPipelineMetaData(pipelineType).icon,
      pipelineType: pipelineType,
    };
  });

  const isTwoColumnsClass = useMemo(() => {
    return statsCardData.length === 2 || statsCardData.length === 4;
  }, [statsCardData.length]);

  return (
    <>
      <div className={classes.lineStats}>
        {statsCardData.map((item) => (
          <Tooltip
            key={item.name}
            title={
              <div className={classes.tooltip}>
                <div className='title'>
                  <Image src={item.icon} width={16} height={16} alt='logo' />
                  <p className='txt-mainDark-13-500'>{item.name}</p>
                </div>

                <p className='txt-mainDark-13-500'>Cost: ${item.count}</p>
              </div>
            }
          >
            <div
              className={classes.line}
              style={{
                backgroundColor: item.color,
                width: item.lineStatWidth,
              }}
            />
          </Tooltip>
        ))}
      </div>

      <section
        className={clsx(
          classes.cardsContainer,
          isTwoColumnsClass && 'twoColumns'
        )}
      >
        {statsCardData.map((item) => (
          <StatsCard key={item.name} statsCardData={item} />
        ))}
      </section>
    </>
  );
};

const StatsCard = ({ statsCardData }) => {
  const { name, count, color, diff, icon, pipelineType } = statsCardData;
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const selectedPipelineType = queryParams.get(SEARCH_PARAMS.PIPELINE_TYPE);
  const isSelectedCard = pipelineType.toString() === selectedPipelineType;

  const handleClickLink = (event) => {
    event.preventDefault();
    updateQueryParams({
      [SEARCH_PARAMS.PIPELINE_SUBTYPES]: null,
      [SEARCH_PARAMS.PIPELINE_TYPE]: isSelectedCard ? '' : pipelineType,
    });
  };

  return (
    <Link
      className={clsx(classes.card, isSelectedCard && 'selected')}
      to={'#'}
      onClick={(event) => handleClickLink(event)}
    >
      <Image src={icon} width={20} height={20} alt={`${name} logo`} />

      <div>
        <div className={clsx(classes.flexRawContainer, 'smallGap')}>
          <div className={classes.marker} style={{ backgroundColor: color }} />
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{name}</Typography>
        </div>

        <section className={classes.flexRawContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT.h5} component={'h5'}>
            ${count}
          </Typography>
          {diff !== null && <PercentStatsBlock data={diff} isWhite={true} />}
        </section>
      </div>
    </Link>
  );
};

export { CostsStatsTableView };
