import React from 'react';
import { useCallback, useHistory, useLocation, useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProjectAvatar } from 'Components/components.js';
import { MENU_ITEMS } from './constants/constants.js';
import { DEMO_PROJECT_TITLE } from 'utils/constants.js';
import { useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';
import { ReactComponent as BetaImg } from 'assets/img/beta-img.svg';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .MuiListItem-root': {
      boxSizing: 'border-box',
      maxWidth: 224,
      height: 44,
      margin: '0 8px 16px',
      padding: '12px 18px',
      color: theme.palette.common.mainDarkText,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
      '&:hover .icon *': {
        fill: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 12,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      '&.Mui-selected .icon *': {
        fill: theme.palette.primary.main,
      },
      '&.MuiListItem-root': {
        width: 'inherit',
      },
    },
  },
  icons: {
    width: 20,
    height: 20,
    marginRight: 14,
    display: 'flex',
    alignItems: 'center',
    '&.MuiListItemIcon-root': {
      minWidth: 20,
    },
  },
  toolbar: {
    paddingRight: 18, // keep right padding when drawer closed
  },
  toolbarText: {
    '& .MuiTypography-root': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.projectItem .MuiTypography-root': {
      width: 132,
      fontWeight: 700,
    },
  },
  toolbarIcon: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& button': {},
  },
}));

const MenuItems = ({ open, handleDropdown }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { isCurrentProjectDemo, currentProject } = useUserInfo();

  const projectName = useMemo(() => {
    if (isCurrentProjectDemo) {
      return DEMO_PROJECT_TITLE;
    }

    return currentProject;
  }, [isCurrentProjectDemo, currentProject]);

  const navigate = (path) => {
    history.push(path);
  };

  const getSelectedMenuItem = useCallback(
    (path) => {
      if (path === AppRoutes.Dashboard.path) {
        return location.pathname === AppRoutes.Dashboard.path;
      }

      return location.pathname.includes(path);
    },
    [location.pathname]
  );

  return (
    <div className={classes.menuContainer}>
      <ListItem className={classes.menuItem} onClick={handleDropdown}>
        <ListItemIcon className={classes.icons}>
          <ProjectAvatar
            className='icon'
            project={projectName}
            isSelected={true}
          />
        </ListItemIcon>

        <div className={classes.toolbarIcon}>
          {open && (
            <>
              <ListItemText
                className={clsx(classes.toolbarText, 'projectItem')}
                primary={projectName}
              />
              <ArrowDown />
            </>
          )}
        </div>
      </ListItem>

      {MENU_ITEMS.map(({ id, path, label, Icon, isBeta }) => (
        <ListItem
          key={id}
          className={classes.menuItem}
          button
          onClick={() => navigate(path)}
          selected={getSelectedMenuItem(path)}
        >
          <ListItemIcon className={classes.icons}>
            <Icon className='icon' />
          </ListItemIcon>

          <div className={classes.toolbarIcon}>
            {open && (
              <>
                <ListItemText className={classes.toolbarText} primary={label} />
                {isBeta && <BetaImg style={{ marginLeft: 12 }} />}
              </>
            )}
          </div>
        </ListItem>
      ))}
    </div>
  );
};

export { MenuItems };
