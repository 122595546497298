import { useMemo, useQuery } from 'hooks/hooks.js';
import { useCostsContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useStorageStats = () => {
  const { isAllowedCostsDataRequest } = useCostsContext();

  const { data, isFetching } = useQuery(
    [QUERY_TYPES.storageStats],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: isAllowedCostsDataRequest }
  );

  const storageStatsData = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { storageStatsData, isFetching };
};

export { useStorageStats };
