import {
  useEffect,
  useState,
  useMemo,
  useGetIncidentGroups,
} from 'hooks/hooks.js';
import {
  INCIDENT_CHART_BY_TYPE,
  INCIDENT_STATUS,
  ANOMALY_TYPE,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { VolumeSensitiveSettingsModal } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { useModal } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const EVENT_BUTTON =
  'Monitors page -> Volume widget -> Click -> Sensitive settings modal';

const AnomaliesWidget = ({
  anomalyType,
  label,
  secondaryLabel,
  tableInfo,
  incidentGroupsType,
  isExisting,
  onChangeExistingData,
  getLinkPath,
}) => {
  const { setModal } = useModal();

  const Chart = useMemo(
    () => INCIDENT_CHART_BY_TYPE[anomalyType],
    [anomalyType]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [incidentsCount, setIncidentsCount] = useState(0);
  const [isShowButton, setIsShowButton] = useState(false);
  const [isGraphRefreshing, setIsGraphRefreshing] = useState(false);

  const { incidentsTotal, refetch } = useGetIncidentGroups({
    page: 0,
    rowsPerPage: 1,
    dataset: tableInfo?.dataset,
    table: tableInfo?.table,
    searchTable: '',
    incidentGroupsTypes: [{ value: incidentGroupsType }],
    incidentGroupsAlert: [{ value: tableInfo?.alertType }],
    incidentGroupsStatuses: [{ value: INCIDENT_STATUS.ACTIVE }],
  });

  const linkPath = useMemo(() => {
    return incidentsCount
      ? getLinkPath(AppRoutes.Incidents.path, {
          [SEARCH_PARAMS.TABLE_ALERT_TYPE]: '',
          [SEARCH_PARAMS.INCIDENT_TYPE]: incidentGroupsType,
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentsCount]);

  useEffect(() => {
    if (incidentsTotal) {
      setIncidentsCount(incidentsTotal);
    }
  }, [incidentsTotal]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (isExisting !== null && incidentsCount != null) {
      setIsLoading(false);
    }
  }, [isExisting, incidentsCount, isLoading]);

  useEffect(() => {
    setIsLoading(true);
    setIncidentsCount(null);
  }, [tableInfo?.dataset, tableInfo?.table]);

  const openSensitiveModal = () => {
    amplEvent(EVENT_BUTTON);
    setModal(
      <VolumeSensitiveSettingsModal
        table={tableInfo?.table}
        dataset={tableInfo?.dataset}
        onClose={() => setModal(null)}
      />
    );
  };

  const handleChangeButton = (value) => {
    setIsShowButton(value);
  };

  const handleClearRefreshing = () => {
    setIsGraphRefreshing(false);
  };

  const onRefreshClick = () => {
    setIsGraphRefreshing(true);
    refetch();
  };

  return (
    <Widget
      label={label}
      secondaryLabel={secondaryLabel}
      incidentCount={incidentsCount}
      count={true}
      path={linkPath}
      linkName='See all'
      existingDivider={isExisting}
      initialSettings={{ size: 'L', height: 'auto' }}
      isShowButton={anomalyType === ANOMALY_TYPE.VOLUME && isShowButton}
      onWidgetButtonClick={openSensitiveModal}
      widgetButtonText='Settings'
      IconButton={<RefreshIcon />}
      onIconButtonClick={onRefreshClick}
    >
      <Chart
        table={tableInfo?.table}
        dataset={tableInfo?.dataset}
        onChangeExistingData={onChangeExistingData}
        onChangeExistingButton={handleChangeButton}
        withScatterGraph={true}
        withGraphTitle={true}
        isGraphRefreshing={isGraphRefreshing}
        handleClearRefreshing={handleClearRefreshing}
      />
    </Widget>
  );
};

export { AnomaliesWidget };
