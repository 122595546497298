import React from 'react';
import { useLocation } from 'hooks/hooks.js';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import {
  PartialPageErrorComponent,
  Layout,
  PrivateContextProviders,
} from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { useUserInfo } from 'context/context.js';

function PrivateRoute({ component: Component, subComponent = null, ...rest }) {
  const { user } = useUserInfo();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) =>
        !!user ? (
          <PrivateContextProviders>
            <Layout>
              <ErrorBoundary
                FallbackComponent={PartialPageErrorComponent}
                resetKeys={[location.pathname]}
              >
                <Component subComponent={subComponent} {...props} />
              </ErrorBoundary>
            </Layout>
          </PrivateContextProviders>
        ) : (
          <Redirect
            to={{
              pathname: AppRoutes.Login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute };
