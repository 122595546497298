import { useQuery, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import {
  convertLocalToUTCDate,
  formatSearchValue,
  getIsValidUUID,
} from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineDestinationTables = ({
  startDate,
  endDate,
  page,
  rowsPerPage,
  searchDestinationTable,
  searchDestinationDataset,
  searchDestinationValue,
  searchTable,
  searchDataset,
  searchValue,
  selectedEmail,
  searchEmail,
  selectedTags,
  selectedLabelKey,
  selectedLabelValue,
  searchLabel,
  powerbiReportName,
  powerbiWorkspaceName,
  alertTypes,
  pipelineType,
  jobHash,
  isDeadEndTablesSelected = false,
  enabled = true,
}) => {
  const isValidJobHash = getIsValidUUID(jobHash);

  const {
    isFetching: isFetchingDestinationTables,
    data,
    refetch: refetchDestinationTables,
  } = useQuery(
    [
      QUERY_TYPES.pipelineDestinationTables,
      startDate,
      endDate,
      page,
      rowsPerPage,
      searchDestinationTable,
      searchDestinationDataset,
      searchDestinationValue,
      searchTable,
      searchDataset,
      searchValue,
      selectedEmail,
      searchEmail,
      selectedTags,
      selectedLabelKey,
      selectedLabelValue,
      searchLabel,
      powerbiReportName,
      powerbiWorkspaceName,
      alertTypes,
      pipelineType,
      isDeadEndTablesSelected,
    ],
    ({ queryKey }) => {
      const [
        url,
        start,
        end,
        page,
        limit,
        destinationTable,
        destinationDataset,
        destinationSearch,
        table,
        dataset,
        searchValue,
        principalEmail,
        searchPrincipalEmail,
        selectedTags,
        selectedLabelKey,
        selectedLabelValue,
        searchLabel,
        powerbiReportName,
        powerbiWorkspaceName,
        alertTypes,
        pipelineType,
        isDeadEndTablesSelected,
      ] = queryKey;
      const params = new URLSearchParams();
      const {
        dataset: newDestinationSearchDataset,
        value: newDestinationSearchValue,
      } = formatSearchValue(destinationSearch);
      const { dataset: newSearchDataset, value: newSearchValue } =
        formatSearchValue(searchValue);
      const newAlertTypes = [];

      if (
        alertTypes.length > 1 ||
        (alertTypes.length && alertTypes[0].value !== '')
      ) {
        newAlertTypes.push(...alertTypes.map((item) => item.value));
      }

      params.append('from', moment(start).utc().format());
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      params.append('page', (page + 1).toString());
      params.append('limit', limit.toString());
      params.append('destinationTable', destinationTable || '');
      params.append(
        'destinationDataset',
        newDestinationSearchDataset || destinationDataset || ''
      );
      params.append(
        'destinationSearch',
        newDestinationSearchValue || destinationSearch || ''
      );
      params.append('table', table || '');
      params.append('dataset', newSearchDataset || dataset || '');
      params.append('search', newSearchValue || searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      params.append('jobName', '');
      params.append('tableLabelKey', selectedLabelKey || '');
      params.append('tableLabelValue', selectedLabelValue || '');
      params.append('searchTableLabel', searchLabel || '');
      powerbiReportName?.length > 0 &&
        params.append('powerbiReportName', powerbiReportName);
      powerbiWorkspaceName?.length > 0 &&
        params.append('powerbiWorkspaceName', powerbiWorkspaceName);
      pipelineType !== undefined && params.append('pipelineType', pipelineType);
      params.append('onlyDeadEnd', isDeadEndTablesSelected);
      isValidJobHash && params.append('jobHash', jobHash);

      for (const alertType of newAlertTypes) {
        params.append('alertTypes', alertType);
      }
      for (const tag of selectedTags) {
        params.append('jobTagNames', tag);
      }
      return fetcherGet(url, params);
    },
    { enabled: enabled && pipelineType !== undefined, cacheTime: 0 }
  );

  const destinationTables = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const destinationTablesNumber = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return {
    destinationTables,
    destinationTablesNumber,
    isFetchingDestinationTables,
    refetchDestinationTables,
  };
};

export { usePipelineDestinationTables };
