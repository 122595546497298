import React from 'react';
import { useQuery } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { AppRoutes } from 'app-routes.js';
import { NodeTypes } from '../GraphPipes/enums/enums.js';
import { fetcherGet } from 'utils/utils.js';
import {
  convertLocalToUTCDate,
  defaultEndDate,
  defaultStartDate,
} from 'utils/helpers/helpers.js';
import { useUserInfo } from 'context/context.js';
import { QUERY_TYPES, SELECTED_DESTINATION } from 'constants/constants.js';
import { ReactComponent as LinkIcon } from 'assets/img/icons/linkIcon.svg';

const START_DATE = moment(convertLocalToUTCDate(defaultStartDate()))
  .utc()
  .format();
const END_DATE = moment(convertLocalToUTCDate(defaultEndDate())).utc().format();
const IS_UPSTREAM = false;
const IS_DOWNSTREAM = true;

const PipelineDetailsSubtitle = ({ jobHash }) => {
  const { currentProject } = useUserInfo();

  const { data } = useQuery(
    [QUERY_TYPES.lineagePipelineNodes, jobHash, IS_UPSTREAM, IS_DOWNSTREAM],
    ({ queryKey }) => {
      const [url, jobHash] = queryKey;
      return fetcherGet(url, {
        project: '',
        dataset: '',
        table: '',
        jobHash,
        isUpstream: IS_UPSTREAM,
        isDownstream: IS_DOWNSTREAM,
        from: START_DATE,
        to: END_DATE,
      });
    },
    { enabled: Boolean(jobHash), cacheTime: 0 }
  );

  const pipelineDownEdges = data?.downEdges[0] || {};
  const { target, targetNodeType } = pipelineDownEdges;
  const [project, dataset, table] =
    targetNodeType === NodeTypes.DATASET ? target?.split('.') : [];
  const isExistingLink = project === currentProject && dataset && table;

  const path = isExistingLink
    ? {
        pathname: AppRoutes.Pipelines.path,
        search: new URLSearchParams({
          [SELECTED_DESTINATION]: `${dataset}.${table}`,
        }).toString(),
      }
    : null;

  if (!path) {
    return null;
  }

  return (
    <Link to={path} className='d-flex align-items-center gap-1'>
      View in Pipelines
      <LinkIcon width={14} />
    </Link>
  );
};

export { PipelineDetailsSubtitle };
