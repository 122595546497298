import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { useDateContext } from 'context/context.js';
import { formatSearchValue, getIsValidUUID } from 'utils/helpers/helpers.js';

const useCostsDestinationTables = ({
  page,
  rowsPerPage,
  searchDestinationTable,
  searchDestinationDataset,
  searchDestinationValue,
  searchTable,
  searchDataset,
  searchValue,
  selectedEmail,
  searchEmail,
  selectedTags,
  selectedLabelKey,
  selectedLabelValue,
  searchLabel,
  powerbiReportName,
  powerbiWorkspaceName,
  alertTypes,
  pipelineType,
  pipelineSubtypes,
  dataProductUUID,
  searchDataProductName,
  jobHash,
  isDeadEndTablesSelected = false,
  isAlternativePlanSelected = false,
  crossProject = false,
  enabled = true,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const isValidJobHash = getIsValidUUID(jobHash);
  const isValidDataProductUUID = getIsValidUUID(dataProductUUID);

  const { isFetching: isFetchingDestinationTables, data } = useQuery(
    [
      QUERY_TYPES.jobDestinationsCost,
      startDateUtc,
      endDateUtc,
      page,
      rowsPerPage,
      searchDestinationTable,
      searchDestinationDataset,
      searchDestinationValue,
      searchTable,
      searchDataset,
      searchValue,
      selectedEmail,
      searchEmail,
      selectedTags,
      selectedLabelKey,
      selectedLabelValue,
      searchLabel,
      powerbiReportName,
      powerbiWorkspaceName,
      alertTypes,
      pipelineType,
      pipelineSubtypes,
      dataProductUUID,
      searchDataProductName,
      isDeadEndTablesSelected,
      isAlternativePlanSelected,
      crossProject,
    ],
    ({ queryKey }) => {
      const [
        url,
        start,
        end,
        page,
        limit,
        destinationTable,
        destinationDataset,
        destinationSearch,
        table,
        dataset,
        searchValue,
        principalEmail,
        searchPrincipalEmail,
        selectedTags,
        selectedLabelKey,
        selectedLabelValue,
        searchLabel,
        powerbiReportName,
        powerbiWorkspaceName,
        alertTypes,
        pipelineType,
        pipelineSubtypes,
        dataProductUUID,
        searchDataProductName,
        isDeadEndTablesSelected,
        isAlternativePlanSelected,
        crossProject,
      ] = queryKey;
      const params = new URLSearchParams();
      const {
        dataset: newDestinationSearchDataset,
        value: newDestinationSearchValue,
      } = formatSearchValue(destinationSearch);
      const { dataset: newSearchDataset, value: newSearchValue } =
        formatSearchValue(searchValue);

      const newAlertTypes = [];

      if (
        alertTypes?.length > 1 ||
        (alertTypes?.length && alertTypes[0]?.value !== '')
      ) {
        newAlertTypes.push(...alertTypes.map((item) => item.value));
      }

      params.append('from', start);
      params.append('to', end);
      params.append('page', page + 1);
      params.append('limit', limit);
      params.append('destinationTable', destinationTable || '');
      params.append(
        'destinationDataset',
        newDestinationSearchDataset || destinationDataset || ''
      );
      params.append(
        'destinationSearch',
        newDestinationSearchValue || destinationSearch || ''
      );
      params.append('table', table || '');
      params.append('dataset', newSearchDataset || dataset || '');
      params.append('search', newSearchValue || searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      params.append('tableLabelKey', selectedLabelKey || '');
      params.append('tableLabelValue', selectedLabelValue || '');
      params.append('searchTableLabel', searchLabel || '');
      powerbiReportName?.length > 0 &&
        params.append('powerbiReportName', powerbiReportName);
      powerbiWorkspaceName?.length > 0 &&
        params.append('powerbiWorkspaceName', powerbiWorkspaceName);
      pipelineType !== undefined && params.append('pipelineType', pipelineType);
      params.append('onlyDeadEnd', isDeadEndTablesSelected);
      params.append('onlyAltPlanSavings', isAlternativePlanSelected);
      params.append('crossProject', crossProject);
      isValidJobHash && params.append('jobHash', jobHash);
      isValidDataProductUUID &&
        params.append('dataProductUuid', dataProductUUID);
      searchDataProductName?.length &&
        params.append('searchDataProductName', searchDataProductName);

      newAlertTypes.forEach((alertType) =>
        params.append('alertTypes', alertType)
      );
      pipelineSubtypes?.forEach((pipelineSubtype) =>
        params.append('pipelineSubtypes', pipelineSubtype)
      );
      selectedTags?.forEach((tag) => params.append('jobTagNames', tag));

      return fetcherGet(url, params);
    },
    { enabled: enabled && pipelineType !== undefined }
  );

  const destinationTables = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const destinationTablesTotal = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  };
};

export { useCostsDestinationTables };
