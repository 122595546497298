import React from 'react';
import {
  useEffect,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useMemo,
  useDashboardData,
} from 'hooks/hooks.js';
import { useStyles } from './Dashboard.styles.js';
import {
  AMPL_PAGE_EVENT,
  COSTS_STATS_VIEW_TYPE,
  DOCUMENT_TITLE,
  QUERY_TYPES,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import {
  OnboardingInfo,
  AddProjectModal,
  IncidentsStats,
  PriorityTablesBanner,
} from './components/components.js';
import {
  useCostsContext,
  useModal,
  useQueryParamsContext,
  useUserInfo,
} from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  CostsStats,
  DemoBanner,
  PageHeader,
  Backdrop,
} from 'Components/components.js';

const Dashboard = () => {
  const classes = useStyles();
  const { setModal } = useModal();
  const [onBoarding, setOnBoarding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentProject } = useUserInfo();
  const queryClient = useQueryClient();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const { isExistingCostsComponents } = useCostsContext();

  const isExistingParams = useMemo(() => {
    return queryParams.has(SEARCH_PARAMS.CREATE_PROJECT);
  }, [queryParams]);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dashboard;
    amplEvent(AMPL_PAGE_EVENT.dashboard);
  }, []);

  const { isFetching: isProjectStatusFetching, data: projectStatus } = useQuery(
    [QUERY_TYPES.projectStatus],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    if (projectStatus?.value) {
      setOnBoarding(projectStatus.value.isShow);
    }
  }, [projectStatus]);

  const { mutateAsync: hideOnboarding } = useMutation(
    () => {
      setOnBoarding(false);
      return fetcherPost(QUERY_TYPES.projectStatus, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_TYPES.projectStatus);
      },
    }
  );

  const { isDashboardDataFetching, dashboardData } = useDashboardData();

  useEffect(() => {
    if (isDashboardDataFetching || isProjectStatusFetching) {
      setIsLoading(true);
    }
    if (!isDashboardDataFetching && !isProjectStatusFetching) {
      setIsLoading(false);
    }
  }, [isDashboardDataFetching, isProjectStatusFetching]);

  const isShowDemoBanner = useMemo(() => {
    // return (
    //   !isCurrentProjectDemo &&
    //   projectStatus?.value &&
    //   projectStatus?.value.dataPatternReady === false
    // );
    return false;
  }, []);

  if (isExistingParams) {
    setModal(() => <AddProjectModal projectName={currentProject} />);
    updateQueryParams(
      { [SEARCH_PARAMS.CREATE_PROJECT]: null },
      { useReplace: true }
    );
  }

  return (
    <div className={classes.pageContainer}>
      {isShowDemoBanner && <DemoBanner />}
      <PageHeader>Dashboard</PageHeader>

      <main className={classes.mainContainer}>
        <PriorityTablesBanner />

        {onBoarding && (
          <OnboardingInfo
            projectStatus={projectStatus?.value}
            closeHandler={hideOnboarding}
          />
        )}

        <IncidentsStats dashboardData={dashboardData} />

        {isExistingCostsComponents && (
          <CostsStats viewType={COSTS_STATS_VIEW_TYPE.graph} />
        )}
      </main>

      <Backdrop isOpen={isLoading} />
    </div>
  );
};

export default Dashboard;
