import React from 'react';
import {
  useGetDataProduct,
  useEffect,
  useState,
  useHistory,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { useStyles } from 'Pages/DataProdact/DataProduct.styles.js';
import { AsyncGeneralSearch, Button } from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { QUERY_TYPES, SEARCH_PARAMS } from 'constants/constants.js';

const Search = ({
  onSearch = null,
  onSelect = null,
  withCreateButton = false,
  searchWithDropdown = false,
  optionLabel = 'Search for:',
  className = '',
}) => {
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const [currentDataProduct, setCurrentDataProduct] = useState(null);

  const searchDataProduct = queryParams.get(SEARCH_PARAMS.SEARCH_DATA_PRODUCT);
  const selectedDataProductUUID = queryParams.get(
    SEARCH_PARAMS.SELECTED_DATA_PRODUCT
  );

  const { dataProduct, isFetchingDataProduct } = useGetDataProduct({
    dataProductUUID: selectedDataProductUUID,
  });

  useEffect(() => {
    if (dataProduct) {
      setCurrentDataProduct(dataProduct);
    }
    if (!dataProduct && !isFetchingDataProduct) {
      setCurrentDataProduct(null);
    }
  }, [dataProduct, isFetchingDataProduct]);

  const handleDataProductSearch = (searchValue) => {
    if (onSearch) {
      onSearch(searchValue);
      return;
    }
    updateQueryParams({
      [SEARCH_PARAMS.SEARCH_DATA_PRODUCT]: searchValue,
      [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: null,
    });
  };

  const handleDataProductSelect = (selectValue) => {
    const newValue = selectValue.length ? selectValue : null;

    if (onSelect) {
      onSelect(newValue);
      return;
    }

    updateQueryParams({
      [SEARCH_PARAMS.SELECTED_DATA_PRODUCT]: newValue,
      [SEARCH_PARAMS.SEARCH_DATA_PRODUCT]: null,
    });
  };

  const getLabelItem = (value) => {
    const domainName = value.domain?.name || null;

    return domainName ? `${value.name} (domain: ${domainName})` : value.name;
  };
  const getValueItem = (value) => {
    return value.uuid;
  };

  return (
    <div className={clsx(classes.searchContainer, className)}>
      <AsyncGeneralSearch
        onSearch={handleDataProductSearch}
        onSelect={handleDataProductSelect}
        defaultSelectValue={currentDataProduct}
        defaultSearchValue={searchDataProduct}
        searchUrl={`${QUERY_TYPES.dataProductList}/search`}
        searchQueryParam='search'
        className='searchInput'
        placeholderName='Start typing data product name...'
        optionLabel={optionLabel}
        getLabelFromRequestItem={getLabelItem}
        getValueFromRequestItem={getValueItem}
        getLabelFromResponseItem={getLabelItem}
        getValueFromResponseItem={getValueItem}
        searchWithDropdown={searchWithDropdown}
        disableCache={true}
      />

      {withCreateButton && <CreateDataProductButton />}
    </div>
  );
};

const CreateDataProductButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToCreateProduct = () => {
    history.push(AppRoutes.DataProduct_create.path);
  };

  return (
    <Button
      text='Create'
      onClick={navigateToCreateProduct}
      fullWidth={false}
      className={classes.createButton}
    />
  );
};

export { Search };
