import React from 'react';
import {
  useEffect,
  useState,
  useParams,
  useMemo,
  useHistory,
  useCallback,
  useDispatch,
  useSelector,
} from 'hooks/hooks.js';
import { useStyles } from './ScanDetails.styles.js';
import {
  Button,
  BackButton,
  BUTTON_VARIANT,
  BUTTON_COLOR,
  Modal,
  Tabs,
  RightSideModal,
  Toolbar,
  PositionLoader,
} from 'Components/components.js';
import {
  useMessages,
  useModal,
  useQueryParamsContext,
} from 'context/context.js';
import {
  EditScanModalBody,
  DetailsTab,
  OverviewTab,
  RemoveScanModalBody,
} from './components/components.js';
import {
  DATA_STATUS,
  DataQualityTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  getCurrentDataScan,
  removeDataScan,
  clearRemoveDataScanStatus,
  runDataScan,
  updateDataScan,
  clearUpdateDataScanStatus,
  getDataScanRules,
  getDataScanJobs,
} from 'slices/actions.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { getUpdateRequestDTOFromDataScan } from './libs/mappers/mappers.js';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const MAX_RULES_LIMIT = 1000;

const ScanDetails = () => {
  const classes = useStyles();
  const { scanId } = useParams();
  const { setModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMessage } = useMessages();
  const { queryParams } = useQueryParamsContext();

  const [toolbarText, setToolbarText] = useState('');
  const [isLoadingComponent, setIsLoadingComponent] = useState(true);
  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);

  const {
    dataScan,
    dataScanStatus,
    removeDataScanStatus,
    dataScanRunStatus,
    updateDataScanStatus,
    dataScanRulesStatus,
    updateDataScanRulesStatus,
  } = useSelector((state) => ({
    dataScan: state.dataScans.dataScan,
    dataScanStatus: state.dataScans.dataScanStatus,
    removeDataScanStatus: state.dataScans.removeDataScanStatus,
    dataScanRunStatus: state.dataScans.dataScanRunStatus,
    updateDataScanStatus: state.dataScans.updateDataScanStatus,
    dataScanRulesStatus: state.dataScans.dataScanRulesStatus,
    updateDataScanRulesStatus: state.dataScans.updateDataScanRulesStatus,
  }));

  const updateDataScanRequest = useCallback(
    (updatedDataScan) => {
      const updateDataScanRequest =
        getUpdateRequestDTOFromDataScan(updatedDataScan);
      dispatch(
        updateDataScan({
          location: updatedDataScan.location,
          dataPlexId: updatedDataScan.dataPlexId,
          updateDataScanRequest,
        })
      ).then((action) => {
        dispatch(clearUpdateDataScanStatus());
        if (action.meta.requestStatus === DATA_STATUS.fulfilled) {
          dispatch(getCurrentDataScan({ scanId: updatedDataScan.id }));
          setToolbarText('Changes saved');
          setTimeout(() => {
            setToolbarText('');
          }, 3000);
        }
        if (action.meta.requestStatus === DATA_STATUS.rejected) {
          setMessage('Something went wrong, try again');
        }
      });
    },
    [dispatch, setMessage]
  );

  const isLoadingScan = useMemo(() => {
    return dataScanStatus === DATA_STATUS.pending || isLoadingComponent;
  }, [dataScanStatus, isLoadingComponent]);

  const isLoadingScanDetails = useMemo(() => {
    return (
      removeDataScanStatus === DATA_STATUS.pending ||
      updateDataScanStatus === DATA_STATUS.pending ||
      dataScanRulesStatus === DATA_STATUS.pending ||
      updateDataScanRulesStatus === DATA_STATUS.pending
    );
  }, [
    dataScanRulesStatus,
    removeDataScanStatus,
    updateDataScanRulesStatus,
    updateDataScanStatus,
  ]);

  useEffect(() => {
    if (scanId) {
      dispatch(getCurrentDataScan({ scanId })).finally(() => {
        setIsLoadingComponent(false);
      });
    }
  }, [dispatch, scanId]);

  const removeCurrentScan = useCallback(() => {
    if (dataScan) {
      dispatch(
        removeDataScan({
          location: dataScan.location,
          dataPlexId: dataScan.dataPlexId,
        })
      );
    }
  }, [dataScan, dispatch]);

  const onRunScanClick = useCallback(() => {
    if (dataScan) {
      dispatch(
        runDataScan({
          location: dataScan.location,
          dataPlexId: dataScan.dataPlexId,
        })
      );
    }
  }, [dataScan, dispatch]);

  useEffect(() => {
    if (removeDataScanStatus === DATA_STATUS.fulfilled) {
      history.push(AppRoutes.DataQuality.path);
    }

    if (removeDataScanStatus === DATA_STATUS.rejected) {
      dispatch(clearRemoveDataScanStatus());
      setToolbarText('Something went wrong, try again');
      setTimeout(() => {
        setToolbarText('');
      }, 3000);
    }
  }, [dispatch, history, removeDataScanStatus]);

  useEffect(() => {
    if (dataScanRunStatus === DATA_STATUS.rejected) {
      setToolbarText('Something went wrong, try again');
      setTimeout(() => {
        setToolbarText('');
      }, 3000);
    }
  }, [dataScanRunStatus]);

  const onRemoveScanClick = () => {
    setModal(() => (
      <Modal
        onAgree={removeCurrentScan}
        title={`Remove ${scanId}`}
        titlePosition='center'
        ModalBodyComponent={RemoveScanModalBody}
        agreeButtonText='Remove'
      />
    ));
  };

  const onEditScanClick = () => {
    setModal(() => (
      <RightSideModal
        title='Edit data quality scan'
        ModalBodyComponent={EditScanModalBody}
        mainBlockMargin={0}
        saveDataScan={updateDataScanRequest}
        dataScan={dataScan}
      />
    ));
  };

  const onRefreshClick = useCallback(() => {
    dispatch(
      getDataScanRules({
        location: dataScan.location,
        dataPlexId: dataScan.dataPlexId,
        limit: MAX_RULES_LIMIT,
        page: 1,
      })
    );
    dispatch(
      getDataScanJobs({
        location: dataScan.location,
        dataPlexId: dataScan.dataPlexId,
      })
    );
  }, [dispatch, dataScan]);

  const isCanStartDataScan = useMemo(() => {
    return dataScan?.scheduleCron === null;
  }, [dataScan]);

  const renderPage = useMemo(() => {
    switch (currentTab) {
      case DataQualityTabs.DETAILS:
        return <DetailsTab />;

      default:
        return <OverviewTab />;
    }
  }, [currentTab]);

  const navigateToDataQualityPage = () => {
    history.push(AppRoutes.DataQuality.path);
  };

  if (isLoadingScan) {
    return <PositionLoader />;
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <BackButton goPreviousPage={navigateToDataQualityPage} />
          <div>
            <div className='txt-mainDark-20-700'>{dataScan?.dataPlexId}</div>
            <div className='txt-mainDark-13-500'>
              Table:{' '}
              <span>
                {getFullTableName(dataScan?.table, dataScan?.dataset)}
              </span>
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            onClick={onRemoveScanClick}
            variant={BUTTON_VARIANT.text}
            text='Remove scan'
            fullWidth={false}
          />
          <Button
            onClick={onEditScanClick}
            color={BUTTON_COLOR.secondary}
            text='Edit scan'
            fullWidth={false}
          />
          <Button
            onClick={onRefreshClick}
            color={BUTTON_COLOR.secondary}
            text='Refresh'
            fullWidth={false}
            startIcon={<RefreshIcon />}
          />
          {isCanStartDataScan && (
            <Button
              onClick={onRunScanClick}
              text='Run scan now'
              fullWidth={false}
            />
          )}
        </div>
      </div>

      <Tabs tabs={DataQualityTabs} />

      <div className={classes.mainContainer}>
        {isLoadingScanDetails ? <PositionLoader /> : renderPage}

        <Toolbar actionText={toolbarText} />
      </div>
    </>
  );
};

export default ScanDetails;
