import React, { createContext } from 'react';
import {
  useContext,
  useState,
  usePagination,
  useEffect,
  useGetDataProductList,
  useGetDomainList,
  useMemo,
  useCallback,
} from 'hooks/hooks.js';
import { useQueryParamsContext } from 'context/context.js';
import { DataProductTabs, SEARCH_PARAMS } from 'constants/constants.js';

const DataProductsPageContext = createContext(null);

const DataProductsPageContextProvider = ({ children }) => {
  const { queryParams } = useQueryParamsContext();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [toolbarText, setToolbarText] = useState('');

  const searchDataProduct = queryParams.get(SEARCH_PARAMS.SEARCH_DATA_PRODUCT);
  const selectedDataProductUUID = queryParams.get(
    SEARCH_PARAMS.SELECTED_DATA_PRODUCT
  );

  const searchDomain = queryParams.get(SEARCH_PARAMS.SEARCH_DOMAIN);
  const selectedDomainUUID = queryParams.get(SEARCH_PARAMS.SELECTED_DOMAIN);

  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);

  const { dataProductList, dataProductTotal, isFetchingDataProductList } =
    useGetDataProductList({
      page,
      limit: rowsPerPage,
      searchDataProduct,
      selectedDataProductUUID,
    });

  const { domainList, domainTotal, isFetchingDomainList } = useGetDomainList({
    page,
    limit: rowsPerPage,
    searchDomain,
    selectedDomainUUID,
  });

  const isStartDataProductPage = useMemo(() => {
    return (
      !isFetchingDataProductList &&
      dataProductTotal === 0 &&
      !searchDataProduct &&
      !selectedDataProductUUID
    );
  }, [
    dataProductTotal,
    isFetchingDataProductList,
    searchDataProduct,
    selectedDataProductUUID,
  ]);

  const isStartDomainPage = useMemo(() => {
    return (
      !isFetchingDomainList &&
      domainTotal === 0 &&
      !searchDomain &&
      !selectedDomainUUID
    );
  }, [domainTotal, isFetchingDomainList, searchDomain, selectedDomainUUID]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [
    searchDataProduct,
    selectedDataProductUUID,
    searchDomain,
    selectedDomainUUID,
    currentTab,
    toolbarText,
  ]);

  useEffect(() => {
    if (currentTab === DataProductTabs.DATA_PRODUCTS) {
      setPaginationTotal(dataProductTotal);
    } else if (currentTab === DataProductTabs.DOMAINS) {
      setPaginationTotal(domainTotal);
    }
  }, [currentTab, dataProductTotal, domainTotal]);

  useEffect(() => {
    if (toolbarText.length) {
      const timeoutId = setTimeout(() => {
        setToolbarText('');
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [toolbarText]);

  const handleChangeToolbarText = useCallback((text) => {
    setToolbarText(text);
  }, []);

  return (
    <DataProductsPageContext.Provider
      value={{
        isStartDataProductPage,
        isStartDomainPage,
        dataProductList,
        dataProductTotal,
        isFetchingDataProductList,
        domainList,
        domainTotal,
        isFetchingDomainList,
        currentTab,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        paginationTotal,
        toolbarText,
        handleChangeToolbarText,
      }}
    >
      {children}
    </DataProductsPageContext.Provider>
  );
};

const useDataProductsPageContext = () => {
  const context = useContext(DataProductsPageContext);

  if (!context) {
    throw new Error(
      'useDataProductsPageContext must be used within a DataProductsPageContext'
    );
  }

  return context;
};

export { DataProductsPageContextProvider, useDataProductsPageContext };
