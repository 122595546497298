import React from 'react';
import Widget from 'Components/Widget/Widget.js';
import { ComingSoon, CONTAINER_SIZE } from '../../components.js';

const LineageWidget = () => {
  return (
    <Widget
      label='Lineage'
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={true}
    >
      <ComingSoon containerHeight={CONTAINER_SIZE.SMALL} />
    </Widget>
  );
};

export { LineageWidget };
