import React from 'react';
import { useState, useCallback } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { useStyles } from 'Pages/Costs/Costs.styles.js';
import PipelineTable from './PipelineTable.js';
import {
  ReportDownloadModal,
  LoadingButton,
  PipelinesDropdowns,
  PipelinesSearch,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import {
  useModal,
  useMessages,
  usePipelinePageContext,
} from 'context/context.js';
import { fetcherPost } from 'utils/utils.js';
import {
  convertLocalToUTCDate,
  formatSearchValue,
} from 'utils/helpers/helpers.js';
import {
  ALERT_SEVERITY,
  CostsTabs,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
  SELECTED_DESTINATION,
  SEARCH_DESTINATION_TABLE,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { CostsDropdowns } from 'Pages/Pipelines/libs/constants/constants.js';
import { ReactComponent as SendEmailIcon } from 'assets/img/icons/attach-email.svg';

const DOWNLOAD_EVENT = 'Costs Tab -> Download report ->';

const CostsTable = ({
  destinationTables,
  isFetchingDestinationTables,
  pipelines,
  alertTypes,
  selectedPipelineType,
  pipelineSubtypesData,
  pipelineSubtypes,
  isDeadEndTablesSelected,
  isAlternativePlanSelected,
  onChangeDropdownsValue,
}) => {
  const globalClasses = useStyles();
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState(false);
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const { searchValues } = usePipelinePageContext();

  const onDownloadFile = () => {
    setModal(() => (
      <ReportDownloadModal
        onAgree={downloadFile}
        title='Get the Costs Insights Report'
        text={
          'It will take 5 minutes to generate your report. Once it is ready, we will send it to the email' +
          ' you use to log in to Masthead. You can also choose the dates for your report.'
        }
        agreeButtonText='Generate'
      />
    ));
  };

  const downloadFile = useCallback(
    (startDate, endDate) => {
      setIsLoadingDownloadFile(true);
      const { dataset: newSearchDataset, value: newSearchValue } =
        formatSearchValue(searchValues[SEARCH_TABLE]);
      const {
        dataset: newDestinationSearchDataset,
        value: newDestinationSearchValue,
      } = formatSearchValue(searchValues[SEARCH_DESTINATION_TABLE]);
      const newAlertTypes = [];
      if (
        alertTypes?.length > 1 ||
        (alertTypes?.length && alertTypes[0].value !== '')
      ) {
        newAlertTypes.push(...alertTypes.map((item) => item.value));
      }
      const postData = {
        table: searchValues[SELECTED_TABLE]?.table || '',
        dataset:
          newSearchDataset || searchValues[SELECTED_TABLE]?.dataset || '',
        search: newSearchValue || searchValues[SEARCH_TABLE] || '',
        destinationTable: searchValues[SELECTED_DESTINATION]?.table || '',
        destinationDataset:
          newDestinationSearchDataset ||
          searchValues[SELECTED_DESTINATION]?.dataset ||
          '',
        destinationSearch:
          newDestinationSearchValue ||
          searchValues[SEARCH_DESTINATION_TABLE] ||
          '',
        principalEmail: searchValues[SELECTED_EMAIL] || '',
        searchPrincipalEmail: searchValues[SEARCH_EMAIL] || '',
        powerbiReportName: searchValues[SELECTED_POWER_BI_REPORT] || '',
        powerbiWorkspaceName: searchValues[SELECTED_POWER_BI_WORKSPACE] || '',
        jobTagNames: searchValues[SELECTED_TAGS] || [],
        tableLabelKey: searchValues[SELECTED_LABEL]?.key || '',
        tableLabelValue: searchValues[SELECTED_LABEL]?.value || '',
        searchTableLabel: searchValues[SEARCH_LABEL] || '',
        pipelineType: selectedPipelineType,
        pipelineSubtypes: pipelineSubtypes,
        dataProductUuid:
          searchValues[SEARCH_PARAMS.SELECTED_DATA_PRODUCT] || '',
        searchDataProductName:
          searchValues[SEARCH_PARAMS.SEARCH_DATA_PRODUCT] || '',
        alertTypes: newAlertTypes,
        onlyDeadEnd: isDeadEndTablesSelected,
        onlyAltPlanSavings: isAlternativePlanSelected,
      };
      const from = moment(convertLocalToUTCDate(startDate)).utc().format();
      const to = moment(convertLocalToUTCDate(endDate)).utc().format();
      fetcherPost(`/api/v1/costs/report?from=${from}&to=${to}`, postData)
        .then((data) => {
          if (!data?.success) {
            setMessage('Got an error while generating report');
            amplEvent(`${DOWNLOAD_EVENT} ${ALERT_SEVERITY.error}`);
          } else {
            setMessage(
              'Success. Once it is ready, we will send it to the email you use to log in to Masthead',
              ALERT_SEVERITY.success
            );
            amplEvent(`${DOWNLOAD_EVENT} ${ALERT_SEVERITY.success}`);
          }
        })
        .catch((e) => {
          setMessage('Got an error while downloading file');
          amplEvent(
            `${DOWNLOAD_EVENT} ${ALERT_SEVERITY.error}; err: ${e.message}`
          );
        })
        .finally(() => setIsLoadingDownloadFile(false));
    },
    [
      alertTypes,
      isDeadEndTablesSelected,
      isAlternativePlanSelected,
      pipelineSubtypes,
      searchValues,
      selectedPipelineType,
      setMessage,
    ]
  );

  return (
    <>
      <PipelinesDropdowns
        pipelineStats={pipelines}
        onChangeDropdownsValue={onChangeDropdownsValue}
        existingDropdown={CostsDropdowns}
        currentTab={CostsTabs.COSTS}
        pipelineSubtypesData={pipelineSubtypesData}
      />

      <div className={globalClasses.searchContainer}>
        <PipelinesSearch />
        <LoadingButton
          startIcon={<SendEmailIcon />}
          children='Get Report'
          isLoading={isLoadingDownloadFile}
          onClick={onDownloadFile}
        />
      </div>

      <PipelineTable
        destinationTables={destinationTables}
        isFetchingDestinationTables={isFetchingDestinationTables}
        selectedPipelineType={selectedPipelineType}
        pipelineSubtypes={pipelineSubtypes}
        isAlternativePlanSelected={isAlternativePlanSelected}
      />
    </>
  );
};

export { CostsTable };
