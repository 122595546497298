import React from 'react';
import {
  useCallback,
  useDispatch,
  useGetIsAnomalyTable,
  useHistory,
  useLocation,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { getFullTableName, getColumnTypeIcon } from 'utils/helpers/helpers.js';
import { updateDesc } from 'slices/actions.js';
import {
  EditCell,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import {
  columnNameId,
  columnsTypeId,
  datasetId,
  descriptionId,
} from 'Components/Columns/HeadCellsEnum.js';
import { AppRoutes } from 'app-routes.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const DictionaryColumnDetails = ({ columnInfo, headCells }) => {
  const history = useHistory();
  const location = useLocation();
  const { name, table, dataset, description, type, uuid } = columnInfo;
  const dispatch = useDispatch();

  const { data: isAnomalyTable } = useGetIsAnomalyTable(dataset, table);
  const existHeadCell = (id) => !!headCells.find((it) => it.id === id);

  const updateColumnDesc = useCallback(
    (desc) => dispatch(updateDesc({ uuid, desc })),
    [dispatch, uuid]
  );

  const onMonitors = () => {
    history.push({
      pathname: AppRoutes.Monitors.path,
      search: new URLSearchParams({
        [SEARCH_PARAMS.DATASET]: dataset,
        [SEARCH_PARAMS.TABLE]: table,
      }).toString(),
    });

    amplEvent(
      `${location?.pathname} -> click table ${getFullTableName(
        table,
        dataset
      )} -> to Monitors page`
    );
  };

  return (
    <StyledTableRow key={uuid}>
      {existHeadCell(columnsTypeId) && (
        <StyledTableCell width={68}>
          <img src={getColumnTypeIcon(type)} alt='' />
        </StyledTableCell>
      )}

      {existHeadCell(columnNameId) && (
        <StyledTableCell className='darkBold'>{name}</StyledTableCell>
      )}

      {existHeadCell(descriptionId) && (
        <StyledTableCell>
          <EditCell value={description} onEdit={updateColumnDesc} />
        </StyledTableCell>
      )}

      {existHeadCell(datasetId) && (
        <StyledTableCell
          className={clsx('blueText', isAnomalyTable && 'redText')}
        >
          <span className='appLink' onClick={onMonitors}>
            {getFullTableName(table, dataset)}
          </span>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export { DictionaryColumnDetails };
