import React from 'react';
import { useAutoChangeProject, useEffect } from 'hooks/hooks.js';
import { PageHeader, Pagination } from 'Components/components.js';
import { PipelineTab } from './components/components.js';
import {
  PipelinePageContextProvider,
  usePipelinePageContext,
} from 'context/context.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';

const PipelinesContainer = () => {
  return (
    <PipelinePageContextProvider>
      <Pipelines />
    </PipelinePageContextProvider>
  );
};

const Pipelines = () => {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationTotal,
  } = usePipelinePageContext();
  useAutoChangeProject();

  useEffect(() => {
    document.title = DOCUMENT_TITLE.pipelines;
  }, []);

  return (
    <>
      <PageHeader hasDateRangePicker={false}>Pipelines</PageHeader>
      <PipelineTab />
      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PipelinesContainer;
